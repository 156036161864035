'use strict';

exports.__esModule = true;
exports.indeterminateShort = exports.indeterminate = exports.rotate = exports.spin = exports.shimmerEffect = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  0% { background-position: 100% 0%; }\n  100% { background-position: -100% 0%; }\n'], ['\n  0% { background-position: 100% 0%; }\n  100% { background-position: -100% 0%; }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  0% {\n    stroke-dasharray: 1, 150;\n    stroke-dashoffset: 0;\n  }\n\n  50% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -35;\n  }\n\n  100% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -124;\n  }\n'], ['\n  0% {\n    stroke-dasharray: 1, 150;\n    stroke-dashoffset: 0;\n  }\n\n  50% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -35;\n  }\n\n  100% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -124;\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  100% { transform: rotate(360deg); }\n'], ['\n  100% { transform: rotate(360deg); }\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  0% {\n    left: -35%;\n    right: 100%;\n  }\n\n  60% {\n    left: 100%;\n    right: -90%;\n  }\n\n  100% {\n    left: 100%;\n    right: -90%;\n  }\n'], ['\n  0% {\n    left: -35%;\n    right: 100%;\n  }\n\n  60% {\n    left: 100%;\n    right: -90%;\n  }\n\n  100% {\n    left: 100%;\n    right: -90%;\n  }\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  0% {\n    left: -200%;\n    right: 100%;\n  }\n\n  60% {\n    left: 107%;\n    right: -8%;\n  }\n\n  100% {\n    left: 107%;\n    right: -8%;\n  }\n'], ['\n  0% {\n    left: -200%;\n    right: 100%;\n  }\n\n  60% {\n    left: 107%;\n    right: -8%;\n  }\n\n  100% {\n    left: 107%;\n    right: -8%;\n  }\n']);

var _styledComponents = require('styled-components');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var shimmerEffect = exports.shimmerEffect = (0, _styledComponents.keyframes)(_templateObject);

var spin = exports.spin = (0, _styledComponents.keyframes)(_templateObject2);

var rotate = exports.rotate = (0, _styledComponents.keyframes)(_templateObject3);

var indeterminate = exports.indeterminate = (0, _styledComponents.keyframes)(_templateObject4);

var indeterminateShort = exports.indeterminateShort = (0, _styledComponents.keyframes)(_templateObject5);
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  direction: ltr;\n  display: flex;\n  align-items: center;\n  border: 1px solid;\n  justify-content: center;\n  border-radius: 4px;\n  height: 36px;\n  padding: 0 ', 'px;\n  transition: all 100ms ease-out;\n  outline: none;\n  position: relative;\n  max-width: max-content;\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n\n  &:not(:disabled) {\n    cursor: pointer;\n  }\n'], ['\n  direction: ltr;\n  display: flex;\n  align-items: center;\n  border: 1px solid;\n  justify-content: center;\n  border-radius: 4px;\n  height: 36px;\n  padding: 0 ', 'px;\n  transition: all 100ms ease-out;\n  outline: none;\n  position: relative;\n  max-width: max-content;\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n\n  &:not(:disabled) {\n    cursor: pointer;\n  }\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Space = require('../base/Space');

var _Space2 = _interopRequireDefault(_Space);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ButtonBase = _styledComponents2.default.button(_templateObject, _Space2.default[5], _styledSystem.minWidth, _styledSystem.bgColor, _styledSystem.borderColor, _styledSystem.hover, _styledSystem.space, _styledSystem.flexDirection);

exports.default = ButtonBase;
module.exports = exports['default'];
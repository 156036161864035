'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', '\n  ', '\n  ', '\n  ', ';\n  ', '\n  ', '\n  ', '\n  ', ';\n  ', '\n  cursor: ', ';\n  font-display: fallback;\n'], ['\n  ', '\n  ', '\n  ', '\n  ', ';\n  ', '\n  ', '\n  ', '\n  ', ';\n  ', '\n  cursor: ', ';\n  font-display: fallback;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Typography = require('../base/Typography');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var propTypes = {
  fontWeight: _propTypes2.default.oneOf(Object.values(_Typography.FONT_WEIGHTS)),
  fontSize: _propTypes2.default.oneOf(Object.values(_Typography.FONT_SIZES)),
  lineHeight: _propTypes2.default.oneOf(Object.values(_Typography.LINE_HEIGHTS)),
  color: _propTypes2.default.oneOf(Object.values(_Colors.COLORS)),
  tag: _propTypes2.default.string,
  children: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
  cursor: _propTypes2.default.string
};

/**
 * Default props will render <label type="body" />
 */
var defaultProps = {
  fontWeight: _Typography.FONT_WEIGHTS.NORMAL,
  fontSize: _Typography.FONT_SIZES[_Typography.FONT_TYPES.BODY],
  lineHeight: _Typography.LINE_HEIGHTS[_Typography.FONT_TYPES.BODY],
  color: _Colors.COLORS.TEXT,
  tag: 'label',
  children: undefined,
  cursor: 'default'
};

/**
 * Generate basic HTML node
 * @param {object} props
 */
var Base = function Base(props) {
  var Tag = props.tag;
  var filteredProps = _extends({}, props);
  delete filteredProps.lineHeight;
  delete filteredProps.zIndex;
  delete filteredProps.textAlign;

  return React.createElement(
    Tag,
    filteredProps,
    props.children
  );
};

Base.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
Base.defaultProps = defaultProps;

var Text = (0, _styledComponents2.default)(Base)(_templateObject, _styledSystem.space, _styledSystem.width, _styledSystem.color, _styledSystem.lineHeight, _styledSystem.fontWeight, _styledSystem.fontSize, _styledSystem.zIndex, _styledSystem.textAlign, _styledSystem.minWidth, function (props) {
  return props.cursor;
});

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

var TextHOC = function TextHOC(props) {
  var type = props.type;

  return React.createElement(Text, _extends({}, props, { fontSize: _Typography.FONT_SIZES[type], lineHeight: _Typography.LINE_HEIGHTS[type] }));
};

TextHOC.propTypes = process.env.NODE_ENV !== "production" ? {
  type: _propTypes2.default.oneOf(Object.values(_Typography.FONT_TYPES))
} : {};

TextHOC.defaultProps = {
  type: _Typography.FONT_TYPES.BODY
};

exports.default = TextHOC;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background: white;\n  height: 100vh;\n  overflow: hidden;\n  position: absolute;\n  z-index: 100000;\n'], ['\n  background: white;\n  height: 100vh;\n  overflow: hidden;\n  position: absolute;\n  z-index: 100000;\n']);

var _gridStyled = require('../grid-styled');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ErrorContainer = _gridStyled.Flex.extend(_templateObject);

exports.default = ErrorContainer;
module.exports = exports['default'];
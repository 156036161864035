'use strict';

exports.__esModule = true;
exports.ValueTagContainer = exports.FieldTag = exports.FieldValue = exports.FieldLabel = exports.FieldContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-color: #f1f4f6;\n  border: 1px solid #e3e6ea;\n  box-sizing: border-box;\n  border-radius: 8px;\n  width: 100%;\n  height: 56px;\n  display: flex;\n  flex-direction: column;\n  padding: 7px 16px;\n  // margin: 4px 0px;\n  ', ';\n'], ['\n  background-color: #f1f4f6;\n  border: 1px solid #e3e6ea;\n  box-sizing: border-box;\n  border-radius: 8px;\n  width: 100%;\n  height: 56px;\n  display: flex;\n  flex-direction: column;\n  padding: 7px 16px;\n  // margin: 4px 0px;\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: #9c9c9c;\n  font-size: 12px;\n  line-height: 18px;\n'], ['\n  color: #9c9c9c;\n  font-size: 12px;\n  line-height: 18px;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  font-size: inherit;\n  line-height: 24px;\n  color: #484848;\n  direction: ', ';\n  margin: ', ';\n  ', ';\n'], ['\n  font-size: inherit;\n  line-height: 24px;\n  color: #484848;\n  direction: ', ';\n  margin: ', ';\n  ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  margin: 0px 0px;\n'], ['\n  margin: 0px 0px;\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n'], ['\n  display: flex;\n  flex-direction: row;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FieldContainer = exports.FieldContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.extendFieldContainer ? props.extendFieldContainer : '';
});
var FieldLabel = exports.FieldLabel = _styledComponents2.default.p(_templateObject2);
var FieldValue = exports.FieldValue = _styledComponents2.default.p(_templateObject3, function (props) {
  return props.language === 'ar' ? 'ltr' : 'unset';
}, function (props) {
  return props.isTagged ? '0px 8px' : 'unset';
}, function (props) {
  return props.extendFieldText ? props.extendFieldText : '';
});

var FieldTag = exports.FieldTag = (0, _styledComponents2.default)(FieldValue)(_templateObject4);

var ValueTagContainer = exports.ValueTagContainer = _styledComponents2.default.div(_templateObject5);
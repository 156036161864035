'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 16px;\n  height: ', 'px;\n  ', ';\n  ', ';\n'], ['\n  border-radius: 16px;\n  height: ', 'px;\n  ', ';\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Rect = _styledComponents2.default.div(_templateObject, function (props) {
  return props.height;
}, _styledSystem.width, _styledSystem.space);

Rect.propTypes = {
  height: _propTypes2.default.number
};

Rect.defaultProps = {
  height: 25,
  width: 250
};

exports.default = Rect;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDatePicker = require('react-date-picker');

var _reactDatePicker2 = _interopRequireDefault(_reactDatePicker);

require('./DatePicker.css');

var _DatePicker = require('./DatePicker.styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DatePickerComponent = function DatePickerComponent(_ref) {
  var onchangeDate = _ref.onchangeDate,
      value = _ref.value,
      locale = _ref.locale,
      isDisabled = _ref.isDisabled,
      language = _ref.language,
      placeholder = _ref.placeholder,
      isValid = _ref.isValid,
      isRequired = _ref.isRequired;
  return _react2.default.createElement(
    _DatePicker.Container,
    { isValid: isValid },
    _react2.default.createElement(
      'div',
      { style: { position: 'relative' } },
      _react2.default.createElement(
        _DatePicker.PlaceholderContainer,
        { isValid: isValid, isRtl: language === 'ar', isDisabled: isDisabled },
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            _DatePicker.PlaceholderTitle,
            { isEmpty: !value },
            placeholder,
            isRequired && _react2.default.createElement(
              _DatePicker.IsRequiredNote,
              null,
              '*'
            )
          ),
          value && _react2.default.createElement(
            'p',
            null,
            new Date(value).toLocaleDateString(language + '-EG', {
              month: 'long',
              day: '2-digit',
              year: 'numeric'
            })
          )
        )
      ),
      _react2.default.createElement(_reactDatePicker2.default, {
        clearIcon: null,
        onChange: function onChange(e) {
          return onchangeDate(e);
        },
        value: value,
        locale: locale,
        disabled: isDisabled,
        language: language
      })
    )
  );
}; /* eslint-disable prettier/prettier */


DatePickerComponent.propTypes = process.env.NODE_ENV !== "production" ? {
  onchangeDate: _propTypes2.default.func,
  value: _propTypes2.default.instanceOf(Date),
  locale: _propTypes2.default.string,
  isDisabled: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  placeholder: _propTypes2.default.string,
  isValid: _propTypes2.default.bool,
  isRequired: _propTypes2.default.bool
} : {};

exports.default = DatePickerComponent;
module.exports = exports['default'];
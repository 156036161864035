'use strict';

exports.__esModule = true;
exports.iconPropTypes = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n'], ['\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Colors = require('../base/Colors');

var _GenerateKey = require('../base/GenerateKey');

var _GenerateKey2 = _interopRequireDefault(_GenerateKey);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var IconContainer = _styledComponents2.default.svg(_templateObject, _styledSystem.space);

var HEIGHT = 1024;

var Icon = function Icon(props) {
  var icon = props.icon,
      width = props.width,
      color = props.color;

  var viewBoxWidth = icon.icon.width || HEIGHT;
  var viewBox = '0 0 ' + viewBoxWidth + ' ' + HEIGHT;
  var paths = icon.icon.paths;


  return _react2.default.createElement(
    IconContainer,
    _extends({ viewBox: viewBox, width: width }, props),
    _react2.default.createElement(
      'g',
      null,
      paths.map(function (path) {
        return _react2.default.createElement('path', { fill: _Colors.COLORS_VALUES[color] || color, key: (0, _GenerateKey2.default)('path'), d: path });
      })
    )
  );
};

var iconPropTypes = exports.iconPropTypes = _propTypes2.default.shape({
  paths: _propTypes2.default.array,
  width: _propTypes2.default.number,
  attrs: _propTypes2.default.array,
  isMulticolor: _propTypes2.default.bool,
  isMulticolor2: _propTypes2.default.bool,
  colorPermutations: _propTypes2.default.any,
  tags: _propTypes2.default.array,
  grid: _propTypes2.default.number
});

Icon.propTypes = process.env.NODE_ENV !== "production" ? {
  icon: iconPropTypes.isRequired,
  width: _propTypes2.default.number,
  color: _propTypes2.default.string
} : {};

Icon.defaultProps = {
  color: _Colors.COLORS.TEXT,
  width: HEIGHT
};

exports.default = Icon;
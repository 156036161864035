'use strict';

exports.__esModule = true;
var PHOTO_FIELD_TYPE = {
  PERSONAL: 'PERSONAL',
  ATTACHMENT: 'ATTACHMENT'
};

var PHOTO_VALIDATION = {
  MAX_PHOTO_SIZE_IN_BYTES: 2 * 1024 * 1024,
  MAX_PHOTO_WIDTH: 500,
  MAX_PHOTO_HEIGHT: 500
};

var PHOTO_SHAPE = {
  CIRCLE: { radius: 50 },
  SQUARE: { radius: 0 }
};

exports.PHOTO_FIELD_TYPE = PHOTO_FIELD_TYPE;
exports.PHOTO_VALIDATION = PHOTO_VALIDATION;
exports.PHOTO_SHAPE = PHOTO_SHAPE;
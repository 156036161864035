'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _StaticField = require('./StaticField.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable react/forbid-prop-types */


var StaticField = function StaticField(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var fieldLabel = props.fieldLabel,
      fieldValue = props.fieldValue,
      fieldTag = props.fieldTag,
      extendFieldContainer = props.extendFieldContainer,
      language = props.language,
      extendFieldText = props.extendFieldText;

  return _react2.default.createElement(
    _StaticField.FieldContainer,
    { extendFieldContainer: extendFieldContainer },
    fieldLabel && _react2.default.createElement(
      _StaticField.FieldLabel,
      null,
      fieldLabel
    ),
    _react2.default.createElement(
      _StaticField.ValueTagContainer,
      null,
      fieldTag && _react2.default.createElement(
        _StaticField.FieldTag,
        null,
        fieldTag
      ),
      _react2.default.createElement(
        _StaticField.FieldValue,
        { language: language, isTagged: fieldTag, extendFieldText: extendFieldText },
        fieldValue
      )
    )
  );
};

StaticField.propTypes = process.env.NODE_ENV !== "production" ? {
  fieldValue: _propTypes2.default.string,
  fieldLabel: _propTypes2.default.string.isRequired,
  fieldTag: _propTypes2.default.string,
  extendFieldContainer: _propTypes2.default.array,
  extendFieldText: _propTypes2.default.array,
  language: _propTypes2.default.string
} : {};
exports.default = StaticField;
module.exports = exports['default'];
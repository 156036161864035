'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border: none;\n  background-color: ', ';\n  color: ', ';\n  outline: none;\n  padding: 0;\n  flex: 1;\n  min-width: 100px;\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  border: none;\n  background-color: ', ';\n  color: ', ';\n  outline: none;\n  padding: 0;\n  flex: 1;\n  min-width: 100px;\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Field = _styledComponents2.default.input(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.PAGE_BACKGROUND], _Colors.COLORS_VALUES[_Colors.COLORS.TEXT], _styledSystem.fontSize, _styledSystem.fontWeight, _styledSystem.space, function (props) {
  return props.extendSearchStyle ? props.extendSearchStyle : '';
});

exports.default = Field;
module.exports = exports['default'];
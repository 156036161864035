'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _ = require('../');

var _ButtonWithDropDown = require('./ButtonWithDropDown.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonWithDropDown = function ButtonWithDropDown(_ref) {
  var extendButtonContainer = _ref.extendButtonContainer,
      iconName = _ref.iconName,
      children = _ref.children,
      buttonName = _ref.buttonName,
      language = _ref.language,
      isOpened = _ref.isOpened,
      toggleDropDown = _ref.toggleDropDown,
      componentId = _ref.componentId;
  return _react2.default.createElement(
    _ButtonWithDropDown.Container,
    null,
    _react2.default.createElement(
      _ButtonWithDropDown.ButtonContainer,
      {
        id: componentId + '-id',
        isOpened: isOpened,
        extendButtonContainer: extendButtonContainer,
        onClick: function onClick() {
          return toggleDropDown(!isOpened);
        }
      },
      iconName && _react2.default.createElement(
        'div',
        { style: { display: 'inline-flex', margin: '0px 3px' } },
        _react2.default.createElement(_.Icon, {
          className: 'icon',
          icon: new _.IconsStore(_webIcons2.default).getIcon(iconName),
          width: 16,
          color: isOpened ? '#0070CD' : '#484848'
        })
      ),
      _react2.default.createElement(
        'div',
        { style: { margin: '0px 3px' } },
        _react2.default.createElement(
          'p',
          null,
          buttonName
        )
      )
    ),
    _react2.default.createElement(
      _ButtonWithDropDown.DropDownContainer,
      {
        id: componentId,
        style: isOpened ? { display: 'block' } : { display: 'none' },
        language: language
      },
      children
    )
  );
}; /* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */


ButtonWithDropDown.propTypes = process.env.NODE_ENV !== "production" ? {
  extendButtonContainer: _propTypes2.default.array,
  children: _propTypes2.default.node,
  iconName: _propTypes2.default.string,
  buttonName: _propTypes2.default.string,
  language: _propTypes2.default.string,
  isOpened: _propTypes2.default.bool,
  toggleDropDown: _propTypes2.default.func,
  componentId: _propTypes2.default.string
} : {};

exports.default = ButtonWithDropDown;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  ', ';\n'], ['\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Calendar = require('../calendar/Calendar');

var _Calendar2 = _interopRequireDefault(_Calendar);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

var _Colors = require('../base/Colors');

require('./DateInput.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var DateInputContainer = _styledComponents2.default.div(_templateObject, _styledSystem.width, _styledSystem.space, function (props) {
  return props.extendInputDate ? props.extendInputDate : '';
});

var InputContainerCalendar = _styledComponents2.default.div(_templateObject2, function (props) {
  return props.extendInputContainerCalendar ? props.extendInputContainerCalendar : '';
});

var DateInput = function (_Component) {
  _inherits(DateInput, _Component);

  function DateInput(props) {
    _classCallCheck(this, DateInput);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onChange = function (e) {
      _this.setState({
        date: _this.props.enableClear && _this.state.date ? '' : e.date,
        enableValidation: true
      }, function () {
        _this.hideCalendar();
        if (_this.props.onChange) {
          _this.props.onChange({ date: e.date });
        }
      });
    };

    _this.setWrapperRef = function (node) {
      _this.wrapperRef = node;
    };

    _this.toggleCalendar = function () {
      if (!_this.props.disabled) {
        if (_this.state.isCalendarOpened) {
          _this.hideCalendar();
        } else {
          _this.showCalendar();
        }
      }
    };

    _this.showCalendar = function () {
      if (!_this.props.disabled) {
        _this.setState({
          isCalendarOpened: true,
          iconClassName: 'icon--up',
          enableValidation: true
        });
      }
    };

    _this.hideCalendar = function () {
      if (!_this.props.disabled) {
        _this.setState({
          isCalendarOpened: false,
          iconClassName: 'icon--down',
          enableValidation: true
        });
      }
    };

    _this.handleClickOutside = function (event) {
      var isVisibleScrollBar = window.innerWidth > document.documentElement.clientWidth;
      var isClickOnScrollBar = window.outerWidth - 18 <= event.x;
      if (_this.wrapperRef && !_this.wrapperRef.contains(event.target) && (!isVisibleScrollBar || isVisibleScrollBar && !isClickOnScrollBar) && _this.state.isCalendarOpened) {
        _this.setState({
          isCalendarOpened: false,
          iconClassName: 'icon--down',
          enableValidation: true
        });
      }
    };

    _this.showErrorMessage = function (message) {
      _this.setState({
        errorMessage: message,
        isDanger: true,
        enableValidation: true
      });
    };

    _this.hideErrorMessage = function () {
      _this.setState({
        errorMessage: '',
        isDanger: false,
        enableValidation: true
      });
    };

    _this.isValid = function () {
      if (_this.props.isRequired && _this.state.enableValidation) {
        if (_this.state.date === '' || _this.state.date === undefined) {
          return false;
        }
      }

      return true;
    };

    _this.validate = function () {
      if (!_this.isValid()) {
        _this.showErrorMessage(_this.props.isArabic ? _this.props.placeholder + ' \u0645\u0637\u0644\u0648\u0628' : _this.props.placeholder + ' required');
      } else {
        _this.hideErrorMessage();
      }
    };

    _this.focus = function () {
      _this.showCalendar();
    };

    _this.resetSelect = function () {
      _this.setState({
        date: '',
        enableValidation: false
      });
    };

    _this.localizeDate = function (date, locale) {
      if (date !== '' && date !== undefined) {
        var dateArr = void 0;
        if (date.includes('/')) {
          dateArr = ('' + date).split('/');
        } else if (date.includes('-')) {
          dateArr = ('' + date).split('-');
        }
        var selectedYear = dateArr[2];
        var selectedMonth = dateArr[0] - 1;
        var selectedDay = dateArr[1];
        var localized = new Date(selectedYear, selectedMonth, selectedDay).toLocaleDateString(locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        });
        return date !== undefined ? localized : '';
      }
      return date;
    };

    _this.iconsStore = new _IconsStore2.default(_webIcons2.default);
    _this.state = {
      isCalendarOpened: _this.props.showCalendar,
      date: _this.props.date,
      errorMessage: '',
      iconClassName: undefined,
      isDanger: false,
      enableValidation: true
    };
    return _this;
  }

  DateInput.prototype.componentDidMount = function componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  DateInput.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (nextProps.date === undefined || nextProps.date === null) {
      if (nextProps.resetOnUndefined) {
        this.resetSelect();
      }
    } else {
      var stateObj = {};
      var check = false;
      if (nextProps.showCalendar === false) {
        stateObj.showCalendar = false;
        check = true;
      }
      if (nextProps.date !== this.props.date) {
        stateObj.date = nextProps.date;
        check = true;
      }
      if (check) {
        this.setState(stateObj);
      }
    }
  };

  DateInput.prototype.componentDidUpdate = function componentDidUpdate(_, prevState) {
    if (prevState.showCalendar && !this.state.isCalendarOpened || this.state.date !== prevState.date) {
      this.validate();
    }
  };

  DateInput.prototype.componentWillUnmount = function componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  /**
   * Changes the error message and shows it
   * @param {string} message
   */


  /**
   * Removes error message
   */


  /**
   * Checks if input is valid
   */


  /**
   * Validates input
   */


  DateInput.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        onChange = _props.onChange,
        isArabic = _props.isArabic,
        reverse = _props.reverse,
        className = _props.className,
        extendInputDate = _props.extendInputDate,
        extendInputContainerCalendar = _props.extendInputContainerCalendar,
        filteredProps = _objectWithoutProperties(_props, ['onChange', 'isArabic', 'reverse', 'className', 'extendInputDate', 'extendInputContainerCalendar']);

    var calendarInputContainerClasses = (0, _classnames2.default)('input-date-container', className, {
      'input-date-container--disabled': this.props.disabled
    });
    var calendarContainerClasses = (0, _classnames2.default)('calendar', {
      'calendar-show': this.state.isCalendarOpened
    }, {
      'calendar-hide': !this.state.isCalendarOpened
    });

    return _react2.default.createElement(
      DateInputContainer,
      _extends({
        innerRef: function innerRef(div) {
          _this2.wrapperRef = div;
        },
        className: calendarInputContainerClasses
      }, filteredProps, {
        extendInputDate: extendInputDate
      }),
      _react2.default.createElement(
        InputContainerCalendar,
        {
          className: (0, _classnames2.default)('input-container--calendar', {
            'input-container--active': this.state.isCalendarOpened,
            'input-date-container--danger': this.state.isDanger
          }),
          tabIndex: '0',
          onClick: this.toggleCalendar,
          onKeyDown: function onKeyDown() {},
          extendInputContainerCalendar: extendInputContainerCalendar
        },
        _react2.default.createElement('input', {
          className: isArabic || reverse ? 'input-value-reverse' : 'input-value',
          placeholder: this.props.placeholder,
          value: this.localizeDate(this.state.date, isArabic ? 'ar-EG' : 'en-US'),
          readOnly: 'readonly',
          autoComplete: 'off'
        }),
        _react2.default.createElement(
          'div',
          { className: (0, _classnames2.default)('icon-container', this.state.iconClassName) },
          _react2.default.createElement(_Icon2.default, { icon: this.iconsStore.getIcon('dropdown'), width: 18, color: _Colors.COLORS.TEXT })
        )
      ),
      _react2.default.createElement(
        _Text2.default,
        { tag: 'span', type: _Typography.FONT_TYPES.CAPTION, className: 'date-input-error' },
        this.state.errorMessage
      ),
      _react2.default.createElement(
        'div',
        { className: calendarContainerClasses },
        _react2.default.createElement(_Calendar2.default, {
          onChange: this.onChange,
          date: this.state.date,
          type: 'single',
          minDate: this.props.minDate,
          maxDate: this.props.maxDate,
          dateFormat: this.props.dateFormat,
          id: this.props.id,
          isArabic: isArabic
        })
      )
    );
  };

  return DateInput;
}(_react.Component);

DateInput.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  id: _propTypes2.default.string,
  placeholder: _propTypes2.default.string, // show, if label empty
  onChange: _propTypes2.default.func,
  date: _propTypes2.default.string,
  minDate: _propTypes2.default.string,
  maxDate: _propTypes2.default.string,
  dateFormat: _propTypes2.default.oneOf(['yyyy-mm-dd', 'mm/dd/yyyy', '']),
  showCalendar: _propTypes2.default.bool, // false: hide Calendar
  enableClear: _propTypes2.default.bool, // false: each click- input, true: first click- input, second- clear
  isRequired: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  resetOnUndefined: _propTypes2.default.bool,
  isArabic: _propTypes2.default.bool,
  reverse: _propTypes2.default.bool
} : {};

DateInput.defaultProps = {
  enableClear: false,
  resetOnUndefined: false,
  className: undefined,
  id: undefined,
  placeholder: undefined,
  onChange: function onChange() {},
  date: undefined,
  minDate: undefined,
  maxDate: undefined,
  dateFormat: 'mm/dd/yyyy',
  showCalendar: false,
  isRequired: false,
  disabled: false,
  isArabic: false,
  reverse: false
};

exports.default = (0, _WithDisplayName2.default)(DateInput, 'DateInput');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  div {\n    animation-duration: 1.3s;\n    animation-fill-mode: forwards;\n    animation-iteration-count: infinite;\n    animation-name: ', ';\n    animation-timing-function: linear;\n\n    background: ', ';\n    background-attachment: fixed;\n    background-image: linear-gradient(\n      to right,\n      ', ' 0%,\n      ', ' 40%,\n      ', ' 60%,\n      ', ' 100%\n    );\n    background-repeat: no-repeat;\n    background-size: 250%;\n  }\n'], ['\n  div {\n    animation-duration: 1.3s;\n    animation-fill-mode: forwards;\n    animation-iteration-count: infinite;\n    animation-name: ', ';\n    animation-timing-function: linear;\n\n    background: ', ';\n    background-attachment: fixed;\n    background-image: linear-gradient(\n      to right,\n      ', ' 0%,\n      ', ' 40%,\n      ', ' 60%,\n      ', ' 100%\n    );\n    background-repeat: no-repeat;\n    background-size: 250%;\n  }\n']);

var _gridStyled = require('../grid-styled');

var _Animations = require('../base/Animations');

var _Colors = require('../base/Colors');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ShimmerEffect = _gridStyled.Flex.extend(_templateObject, _Animations.shimmerEffect, _Colors.COLORS_VALUES[_Colors.COLORS.SHIMMER_BACKGROUND], _Colors.COLORS_VALUES[_Colors.COLORS.SHIMMER_BACKGROUND], _Colors.COLORS_VALUES[_Colors.COLORS.SHIMMER_EFFECT], _Colors.COLORS_VALUES[_Colors.COLORS.SHIMMER_BACKGROUND], _Colors.COLORS_VALUES[_Colors.COLORS.SHIMMER_BACKGROUND]);

exports.default = ShimmerEffect;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Image = require('../image/Image');

var _Image2 = _interopRequireDefault(_Image);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./Country.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Country(props) {
  if (props.loading) {
    return _react2.default.createElement(
      'div',
      { className: 'loading' },
      _react2.default.createElement('div', { className: 'flag--loading' }),
      _react2.default.createElement('div', { className: 'name--loading' })
    );
  }
  return _react2.default.createElement(
    'div',
    { className: (0, _classnames2.default)('country-item', props.className) },
    _react2.default.createElement(_Image2.default, {
      src: props.flag,
      radius: 14,
      alt: props.name,
      name: props.name,
      m: props.language === 'en' ? '0 10px 0 0' : '0 0 0 10px'
    }),
    _react2.default.createElement(
      _Text2.default,
      { className: 'name' },
      props.name
    )
  );
}

Country.propTypes = process.env.NODE_ENV !== "production" ? {
  loading: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  flag: _propTypes2.default.string.isRequired,
  name: _propTypes2.default.string.isRequired,
  language: _propTypes2.default.string.isRequired
} : {};

Country.defaultProps = {
  loading: false,
  className: undefined
};

exports.default = (0, _WithDisplayName2.default)(Country, 'Country');
module.exports = exports['default'];
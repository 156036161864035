'use strict';

exports.__esModule = true;
exports.renderer = exports.STATES = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _ramda = require('ramda');

var create = function create(type, data) {
  return _extends({
    type: type
  }, data, {
    fold: function fold(definitions) {
      var fn = (0, _ramda.prop)(type, definitions);
      return fn ? fn(data) : undefined;
    }
  });
};

var STATES = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  EMPTY: 'EMPTY',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS'
};

var renderer = {
  init: function init() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return create.apply(undefined, [STATES.NOT_LOADED].concat(args));
  },
  loading: function loading() {
    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return create.apply(undefined, [STATES.LOADING].concat(args));
  },
  empty: function empty() {
    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return create.apply(undefined, [STATES.EMPTY].concat(args));
  },
  failure: function failure() {
    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return create.apply(undefined, [STATES.FAILURE].concat(args));
  },
  success: function success() {
    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return create.apply(undefined, [STATES.SUCCESS].concat(args));
  }
};

exports.STATES = STATES;
exports.renderer = renderer;
exports.default = renderer;
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _InputField = require('./InputField');

var _InputField2 = _interopRequireDefault(_InputField);

var _DropDown = require('./DropDown');

var _DropDown2 = _interopRequireDefault(_DropDown);

var _ImageUploader = require('./ImageUploader');

var _ImageUploader2 = _interopRequireDefault(_ImageUploader);

var _DropdownMultiSelect = require('./DropdownMultiSelect');

var _DropdownMultiSelect2 = _interopRequireDefault(_DropdownMultiSelect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var DynamicFieldSelector = function DynamicFieldSelector(_ref) {
  var isDropDown = _ref.isDropDown,
      isImageUploader = _ref.isImageUploader,
      multipleSelection = _ref.multipleSelection,
      props = _objectWithoutProperties(_ref, ['isDropDown', 'isImageUploader', 'multipleSelection']);

  if (isDropDown) return _react2.default.createElement(_DropDown2.default, props);
  if (isImageUploader) return _react2.default.createElement(_ImageUploader2.default, props);
  if (multipleSelection) return _react2.default.createElement(_DropdownMultiSelect2.default, props);
  return _react2.default.createElement(_InputField2.default, props);
};

exports.default = DynamicFieldSelector;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
exports.ShowDevice = exports.ShowDesktop = exports.RowContainer = exports.InnerWrapper = exports.TooltipMessage = exports.TooltipContainer = exports.RowItemContentWrapper = exports.RowItemWrapper = exports.IconContainer = exports.AdditionalItem = exports.MainItem = exports.PaginationContainer = exports.HeaderRowItemsPhone = exports.HeaderRowItems = exports.ExpandIcon = exports.RowItem = exports.ExpandedRow = exports.RowGrid = exports.Row = exports.HeaderGrid = exports.TableContainer = exports.TableHeader = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  color: #fff;\n  background: #0070cd;\n  height: 55px;\n  border-radius: 3px;\n  margin: 10px 0px 10px;\n  ', '\n  ', '\n  ', ';\n'], ['\n  color: #fff;\n  background: #0070cd;\n  height: 55px;\n  border-radius: 3px;\n  margin: 10px 0px 10px;\n  ', '\n  ', '\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n    display: none;  \n  '], ['\n    display: none;  \n  ']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n    display: none;\n  '], ['\n    display: none;\n  ']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  ', ';\n'], ['\n  ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  grid-template-columns: 17% 13% 15% 15% 27% 10%;\n  display: grid;\n  text-align: center;\n  padding: 17px 0px;\n  font-size: 14px;\n  text-align: left;\n  width: 100%;\n  ', ';\n'], ['\n  grid-template-columns: 17% 13% 15% 15% 27% 10%;\n  display: grid;\n  text-align: center;\n  padding: 17px 0px;\n  font-size: 14px;\n  text-align: left;\n  width: 100%;\n  ', ';\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  color: #000;\n  background-color: #fff;\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  display: flex;\n  color: #000;\n  background-color: #fff;\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n    height: unset;\n  '], ['\n    height: unset;\n  ']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  cursor: ', ';\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  width: 100%;\n  cursor: ', ';\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n    display: flex;\n    flex-direction: column;\n    grid-template-columns: unset;\n    height: fit-content;\n  '], ['\n    display: flex;\n    flex-direction: column;\n    grid-template-columns: unset;\n    height: fit-content;\n  ']),
    _templateObject10 = _taggedTemplateLiteralLoose(['\n    display: flex;\n    flex-direction: column;\n    grid-template-columns: unset;\n    height: fit-content;\n    \n  '], ['\n    display: flex;\n    flex-direction: column;\n    grid-template-columns: unset;\n    height: fit-content;\n    \n  ']),
    _templateObject11 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  padding: 0px;\n  flex-wrap: wrap;\n  max-height: ', ';\n  // background-color: #f6f6f6;\n  overflow: hidden;\n  transition: max-height 0.5s ease-in-out;\n  padding: 0px 0px;\n'], ['\n  display: flex;\n  padding: 0px;\n  flex-wrap: wrap;\n  max-height: ', ';\n  // background-color: #f6f6f6;\n  overflow: hidden;\n  transition: max-height 0.5s ease-in-out;\n  padding: 0px 0px;\n']),
    _templateObject12 = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: ', ';\n\n  ', ';\n  ', ';\n'], ['\n  display: inline-block;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: ', ';\n\n  ', ';\n  ', ';\n']),
    _templateObject13 = _taggedTemplateLiteralLoose(['\n    padding: 5px 10px;\n    &:nth-child(even) {\n    background-color: #9c9c9c1c;\n    border-radius: 7px;\n  }\n  '], ['\n    padding: 5px 10px;\n    &:nth-child(even) {\n    background-color: #9c9c9c1c;\n    border-radius: 7px;\n  }\n  ']),
    _templateObject14 = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n']),
    _templateObject15 = _taggedTemplateLiteralLoose(['\n    display: none;\n  }\n  '], ['\n    display: none;\n  }\n  ']),
    _templateObject16 = _taggedTemplateLiteralLoose(['\n  white-space: unset;\n  overflow: unset;\n  text-overflow: unset;\n  font-size: 14px;\n  line-height: 24px;\n  font-weight: normal;\n  color: #666666;\n'], ['\n  white-space: unset;\n  overflow: unset;\n  text-overflow: unset;\n  font-size: 14px;\n  line-height: 24px;\n  font-weight: normal;\n  color: #666666;\n']),
    _templateObject17 = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject18 = _taggedTemplateLiteralLoose(['\n    width: 50%;\n    line-height: 11px;\n  '], ['\n    width: 50%;\n    line-height: 11px;\n  ']),
    _templateObject19 = _taggedTemplateLiteralLoose([''], ['']),
    _templateObject20 = _taggedTemplateLiteralLoose(['\n  font-weight: ', ';\n  font-size: 14px !important;\n  color: #484848 !important;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  /* max-width: 20ch; */\n  overflow: hidden;\n  /* position: relative; */\n  max-width: 92%;\n  direction: ', ';\n  cursor: ', ';\n  ', ';\n'], ['\n  font-weight: ', ';\n  font-size: 14px !important;\n  color: #484848 !important;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  /* max-width: 20ch; */\n  overflow: hidden;\n  /* position: relative; */\n  max-width: 92%;\n  direction: ', ';\n  cursor: ', ';\n  ', ';\n']),
    _templateObject21 = _taggedTemplateLiteralLoose(['\n  font-weight: normal !important;\n  font-size: 14px !important;\n  color: #9c9c9c !important;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 92%;\n  display: inline-block;\n  direction: ', ';\n  ', ';\n'], ['\n  font-weight: normal !important;\n  font-size: 14px !important;\n  color: #9c9c9c !important;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 92%;\n  display: inline-block;\n  direction: ', ';\n  ', ';\n']),
    _templateObject22 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  justify-content: center;\n  padding: 10px 0px;\n  transform: rotateZ(0deg);\n  transition: transform 0.4s ease-in;\n'], ['\n  display: flex;\n  justify-content: center;\n  padding: 10px 0px;\n  transform: rotateZ(0deg);\n  transition: transform 0.4s ease-in;\n']),
    _templateObject23 = _taggedTemplateLiteralLoose(['\n  display: block;\n  height: 100%;\n  padding-top: ', ';\n  ', ';\n  ', ';\n'], ['\n  display: block;\n  height: 100%;\n  padding-top: ', ';\n  ', ';\n  ', ';\n']),
    _templateObject24 = _taggedTemplateLiteralLoose(['\n    padding-top: unset;\n  '], ['\n    padding-top: unset;\n  ']),
    _templateObject25 = _taggedTemplateLiteralLoose(['\n  // position: absolute;\n  height: 100%;\n  &:hover > p {\n    visibility: visible;\n  }\n  ', ';\n  ', ';\n'], ['\n  // position: absolute;\n  height: 100%;\n  &:hover > p {\n    visibility: visible;\n  }\n  ', ';\n  ', ';\n']),
    _templateObject26 = _taggedTemplateLiteralLoose(['\n    width: 50%;\n  '], ['\n    width: 50%;\n  ']),
    _templateObject27 = _taggedTemplateLiteralLoose(['\n  visibility: hidden;\n  z-index: 1;\n  background-color: #FF9999;\n  position: absolute;\n  /* margin: 0px 5px;\n  left: ', ';\n  right: ', '; */\n  white-space: break-spaces !important;\n  max-width: 730px;\n  text-align: center;\n  border-radius: 6px;\n  font-size: 14px;\n  padding: 5px 8px;\n  background: #e2e3e4;\n  transform: translate(0%, -100%);\n  ', ';\n  ', ';\n'], ['\n  visibility: hidden;\n  z-index: 1;\n  background-color: #FF9999;\n  position: absolute;\n  /* margin: 0px 5px;\n  left: ', ';\n  right: ', '; */\n  white-space: break-spaces !important;\n  max-width: 730px;\n  text-align: center;\n  border-radius: 6px;\n  font-size: 14px;\n  padding: 5px 8px;\n  background: #e2e3e4;\n  transform: translate(0%, -100%);\n  ', ';\n  ', ';\n']),
    _templateObject28 = _taggedTemplateLiteralLoose(['\n    display: flex;\n  '], ['\n    display: flex;\n  ']),
    _templateObject29 = _taggedTemplateLiteralLoose(['\n  border-bottom: 1px solid #f1f4f6;\n  &:nth-child(1) {\n    border-top: 1px solid #f1f4f6;\n  }\n  ', ';\n  ', ';\n'], ['\n  border-bottom: 1px solid #f1f4f6;\n  &:nth-child(1) {\n    border-top: 1px solid #f1f4f6;\n  }\n  ', ';\n  ', ';\n']),
    _templateObject30 = _taggedTemplateLiteralLoose(['\n    &:nth-child(1) {\n      border-top: 1px solid #7878788a;\n    }\n    border-bottom: 1px solid #7878788a;\n  '], ['\n    &:nth-child(1) {\n      border-top: 1px solid #7878788a;\n    }\n    border-bottom: 1px solid #7878788a;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var TableHeader = exports.TableHeader = _styledComponents2.default.div(_templateObject, function (props) {
  return props.theme.media.phone(_templateObject2);
}, function (props) {
  return props.theme.media.tablet(_templateObject3);
}, function (props) {
  return props.extendTableHeaderRow ? props.extendTableHeaderRow : '';
});

var TableContainer = exports.TableContainer = _styledComponents2.default.div(_templateObject4, function (props) {
  return props.extendTableWidth ? props.extendTableWidth : '';
});

var HeaderGrid = exports.HeaderGrid = _styledComponents2.default.div(_templateObject5, function (props) {
  return props.extendTableGrid ? props.extendTableGrid : '';
});

var Row = exports.Row = (0, _styledComponents2.default)(TableHeader)(_templateObject6, function (props) {
  return props.extendTableRow ? props.extendTableRow : '';
}, function (props) {
  return props.theme.media.phone(_templateObject7);
}, function (props) {
  return props.theme.media.tablet(_templateObject7);
});

var RowGrid = exports.RowGrid = (0, _styledComponents2.default)(HeaderGrid)(_templateObject8, function (props) {
  return props.isClickable ? 'pointer' : 'unset';
}, function (props) {
  return props.extendTableGrid ? props.extendTableGrid : '';
}, function (props) {
  return props.theme.media.phone(_templateObject9);
}, function (props) {
  return props.theme.media.tablet(_templateObject10);
});

var ExpandedRow = exports.ExpandedRow = (0, _styledComponents2.default)(RowGrid)(_templateObject11, function (props) {
  return props.isExpanded ? props.totalHeight + 'px' : '0px';
});

var RowItem = exports.RowItem = _styledComponents2.default.div(_templateObject12, function (props) {
  return props.language === 'ar' ? 'right' : 'unset';
}, function (props) {
  return props.theme.media.phone(_templateObject13);
}, function (props) {
  return props.theme.media.tablet(_templateObject13);
});

var ExpandIcon = exports.ExpandIcon = (0, _styledComponents2.default)(RowItem)(_templateObject14, function (props) {
  return props.theme.media.phone(_templateObject15);
}, function (props) {
  return props.theme.media.tablet(_templateObject15);
});

var HeaderRowItems = exports.HeaderRowItems = (0, _styledComponents2.default)(RowItem)(_templateObject16);

var HeaderRowItemsPhone = exports.HeaderRowItemsPhone = (0, _styledComponents2.default)(HeaderRowItems)(_templateObject17, function (props) {
  return props.theme.media.desktop(_templateObject3);
}, function (props) {
  return props.theme.media.phone(_templateObject18);
}, function (props) {
  return props.theme.media.tablet(_templateObject18);
});

var PaginationContainer = exports.PaginationContainer = _styledComponents2.default.div(_templateObject19);

var MainItem = exports.MainItem = _styledComponents2.default.div(_templateObject20, function (props) {
  return props.isBold ? '700 !important' : 'normal !important';
}, function (props) {
  return props.isRtl ? 'rtl' : 'ltr';
}, function (props) {
  return props.isClickable ? 'pointer' : 'unset';
}, function (props) {
  return props.extendMainItem ? props.extendMainItem : '';
});
var AdditionalItem = exports.AdditionalItem = _styledComponents2.default.p(_templateObject21, function (props) {
  return props.isRtl ? 'rtl' : 'ltr';
}, function (props) {
  return props.extendAdditionalItem ? props.extendAdditionalItem : '';
});

var IconContainer = exports.IconContainer = _styledComponents2.default.div(_templateObject22);

var RowItemWrapper = exports.RowItemWrapper = _styledComponents2.default.div(_templateObject23, function (props) {
  return props.isOneRow ? '10px' : 'unset';
}, function (props) {
  return props.theme.media.phone(_templateObject24);
}, function (props) {
  return props.theme.media.tablet(_templateObject24);
});

var RowItemContentWrapper = exports.RowItemContentWrapper = _styledComponents2.default.div(_templateObject25, function (props) {
  return props.theme.media.phone(_templateObject26);
}, function (props) {
  return props.theme.media.tablet(_templateObject26);
});

var TooltipContainer = exports.TooltipContainer = _styledComponents2.default.p(_templateObject27, function (props) {
  return props.language === 'en' ? '100%' : 'unset';
}, function (props) {
  return props.language === 'ar' ? '100%' : 'unset';
}, function (props) {
  return props.theme.media.phone(_templateObject3);
}, function (props) {
  return props.theme.media.tablet(_templateObject3);
});
var TooltipMessage = exports.TooltipMessage = _styledComponents2.default.p(_templateObject19);

var InnerWrapper = exports.InnerWrapper = _styledComponents2.default.div(_templateObject14, function (props) {
  return props.theme.media.phone(_templateObject28);
}, function (props) {
  return props.theme.media.tablet(_templateObject28);
});

var RowContainer = exports.RowContainer = _styledComponents2.default.div(_templateObject29, function (props) {
  return props.theme.media.phone(_templateObject30);
}, function (props) {
  return props.theme.media.tablet(_templateObject30);
});

var ShowDesktop = exports.ShowDesktop = _styledComponents2.default.div(_templateObject14, function (props) {
  return props.theme.media.phone(_templateObject3);
}, function (props) {
  return props.theme.media.tablet(_templateObject3);
});

var ShowDevice = exports.ShowDevice = _styledComponents2.default.div(_templateObject4, function (props) {
  return props.theme.media.desktop(_templateObject3);
});
'use strict';

exports.__esModule = true;
exports.NoAnimationFlex = exports.NoAnimationBox = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  animation: none;\n  background: transparent !important;\n'], ['\n  animation: none;\n  background: transparent !important;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  animation: none;\n  background: transparent !important;\n  ', ';\n  ', ';\n'], ['\n  animation: none;\n  background: transparent !important;\n  ', ';\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var NoAnimationFlex = _gridStyled.Flex.extend(_templateObject);

var NoAnimationBox = _styledComponents2.default.div(_templateObject2, _styledSystem.space, _styledSystem.width);

exports.NoAnimationBox = NoAnimationBox;
exports.NoAnimationFlex = NoAnimationFlex;
exports.default = NoAnimationBox;
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _gridStyled = require('../grid-styled');

var _Button = require('../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Separator = require('../separator/Separator');

var _Separator2 = _interopRequireDefault(_Separator);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Colors = require('../base/Colors');

var _Typography = require('../base/Typography');

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _BlockContainer = require('./BlockContainer.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable react/require-default-props */


var BlockContainer = function BlockContainer(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var name = props.name,
      icon = props.icon,
      iconWidth = props.iconWidth,
      children = props.children,
      buttonText = props.buttonText,
      buttonIcon = props.buttonIcon,
      buttonIconWidth = props.buttonIconWidth,
      onClick = props.onClick,
      disabled = props.disabled,
      hideButton = props.hideButton,
      reverse = props.reverse,
      toolTipMessage = props.toolTipMessage;


  var containerPaddingProps = void 0;
  var iconPaddingProps = void 0;
  if (reverse) {
    containerPaddingProps = {
      pr: 5,
      pl: 2
    };
    iconPaddingProps = {
      ml: 3
    };
  } else {
    containerPaddingProps = {
      pl: 5,
      pr: 2
    };
    iconPaddingProps = {
      mr: 3
    };
  }
  return _react2.default.createElement(
    _BlockContainer.BlockBorder,
    { width: 1, flexDirection: 'column' },
    _react2.default.createElement(
      _BlockContainer.HeaderContainer,
      _extends({
        alignItems: 'center',
        justifyContent: 'space-between'
      }, containerPaddingProps, {
        py: 2
      }),
      _react2.default.createElement(
        _gridStyled.Flex,
        { alignItems: 'center', justifyContent: 'flex-start' },
        _react2.default.createElement(_Icon2.default, _extends({
          icon: new _IconsStore2.default(_webIcons2.default).getIcon(icon),
          width: iconWidth,
          color: _Colors.COLORS.TEXT
        }, iconPaddingProps)),
        _react2.default.createElement(
          _Text2.default,
          { type: _Typography.FONT_TYPES.SUBHEADING },
          name
        )
      ),
      !hideButton && _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _BlockContainer.ExtendedToolTip,
          {
            message: toolTipMessage,
            showOnHover: !!toolTipMessage,
            position: 'bottom',
            arrowPosition: 'bottom'
          },
          _react2.default.createElement(
            _Button2.default,
            {
              primary: false,
              color: _Colors.COLORS.PRIMARY_BLUE,
              icon: new _IconsStore2.default(_webIcons2.default).getIcon(buttonIcon),
              iconWidth: buttonIconWidth,
              onClick: onClick,
              disabled: disabled,
              reverse: reverse
            },
            buttonText
          )
        )
      )
    ),
    _react2.default.createElement(_Separator2.default, null),
    _react2.default.createElement(
      _gridStyled.Flex,
      { width: 1, height: 'max-content' },
      children
    )
  );
};

BlockContainer.propTypes = process.env.NODE_ENV !== "production" ? {
  toolTipMessage: _propTypes2.default.string,
  name: _propTypes2.default.string,
  icon: _propTypes2.default.string,
  iconWidth: _propTypes2.default.number,
  children: _propTypes2.default.node,
  buttonText: _propTypes2.default.string,
  buttonIcon: _propTypes2.default.string,
  buttonIconWidth: _propTypes2.default.number,
  disabled: _propTypes2.default.bool,
  hideButton: _propTypes2.default.bool,
  onClick: _propTypes2.default.func,
  reverse: _propTypes2.default.bool
} : {};

BlockContainer.defaultProps = {
  onClick: function onClick() {},
  disabled: false,
  hideButton: false,
  iconWidth: 14,
  reverse: false
};

exports.default = BlockContainer;
module.exports = exports['default'];
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { SetGlobalStyle, Card } from '@vezeeta/web-components';
import { jsonToQueryString } from '@vezeeta/web-utils';
import { MixpanelConsumer } from 'react-mixpanel';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Payment, Confirmation } from 'app/Routes';
import 'app/shared/Utils.scss';
import 'app/shared/bootstrap.min.css';
import { withTranslation } from 'app/withTranslation/withTranslation';
import RoleBasedRoute from 'app/RoleBasedAccess/RoleBasedRoute';
import { configurations, cookies } from 'app/Config';
import Header from 'app/modules/components/Header/Header';
import { initSetUpMixpanel } from 'app/SDKIntegrations';
import { SSLDecision, getDomain, deleteCookies } from 'app/payment-utils';
import './App.css';

const App = ({ ...props }) => {
  let vezeetaUrl;
  useEffect(() => {
    SSLDecision(process.env.REACT_APP_FORCE_HTTPS);
    vezeetaUrl = getDomain(Cookie.get('CallBack'), true);
  }, []);

  const stateCallBack = async () => {
    if (Cookie.get('PaymentType') === 'reservation') {
      await fetch(
        `${process.env.REACT_APP_CANCEL_RESERVATION_URL}${jsonToQueryString({
          reservationKey: Cookie.get('RK'),
        })}`,
        {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            CountryId: Cookie.get('CD'),
            Language: 'en-US',
          },
        },
      );
    }
    deleteCookies(cookies);
    window.location.replace(`//${this.vezeetaUrl}`);
  };

  return (
    <div>
      <Header
        onLogoClick={() => {
          if (Cookie.get('LogoClickStatus') !== false) {
            stateCallBack();
          }
        }}
      />
      <div className="container">
        <div className="card col-md-10 col-md-offset-1 col-xs-12">
          <Card>
            <Router>
              <Switch>
                <RoleBasedRoute
                  path="/"
                  exact
                  render={routeProps => (
                    <Payment {...routeProps} language="en" localization={props.localization} />
                  )}
                />
                <RoleBasedRoute
                  path="/check"
                  render={routeProps => (
                    <Confirmation {...routeProps} language="en" localization={props.localization} />
                  )}
                />
              </Switch>
            </Router>
          </Card>
        </div>
      </div>
    </div>
  );
};

App.propTypes = {};

const mapStateToProps = state => ({});

export default withTranslation(connect(mapStateToProps)(App));

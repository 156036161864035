import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const withTranslation = WrappedComponent => {
  const Localization = props => {
    const [localization, setLocalization] = useState(undefined);
    useEffect(() => {
      fetch('/localization/en/en-EG.json')
        .then(response => response.json())
        .then(locale => {
          setLocalization(locale);
        });
    }, [props]);

    return <WrappedComponent localization={localization} />;
  };

  return Localization;
};

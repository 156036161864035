'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Colors = require('../../base/Colors');

var _WithDisplayName = require('../../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./PhotoInput.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PhotoInput = function (_Component) {
  _inherits(PhotoInput, _Component);

  function PhotoInput(props) {
    _classCallCheck(this, PhotoInput);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.openFileDialog = function () {
      _this.input.click();
    };

    _this.loadPreview = function () {
      var _this$props = _this.props,
          loadPreview = _this$props.loadPreview,
          photoPreview = _this$props.photoPreview,
          isPhoto = _this$props.isPhoto;


      loadPreview(_this.input, function (photo) {
        var reader = new FileReader();
        reader.readAsDataURL(photo);

        reader.onloadend = function () {
          _this.props.updatePhoto({
            preview: photoPreview,
            crop: reader.result,
            isPhoto: isPhoto,
            showCropper: true
          });
          _this.input.value = null;
        };
      });
    };

    _this.uploadPhoto = function () {
      var _this$props2 = _this.props,
          updatePhoto = _this$props2.updatePhoto,
          photoPreview = _this$props2.photoPreview,
          photoCrop = _this$props2.photoCrop,
          isPhoto = _this$props2.isPhoto;


      if (isPhoto) {
        updatePhoto({
          preview: photoPreview,
          crop: photoCrop,
          isPhoto: isPhoto,
          showCropper: true
        });
      } else {
        _this.input.click();
      }
    };

    _this.iconsStore = new _IconsStore2.default(_webIcons2.default);
    return _this;
  }

  /**
   * Returns the photo object
   */


  PhotoInput.prototype.getInputValue = function getInputValue() {
    return this.img.src;
  };

  /**
   * Opens file dialog
   */


  /**
   * Loads the image from local device and load a preview
   */


  /**
   * Check if open file dialog or image cropper tool
   */


  PhotoInput.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        isPhoto = _props.isPhoto,
        photoPreview = _props.photoPreview,
        disabled = _props.disabled;

    var icon = void 0;

    if (isPhoto) {
      icon = '';
    } else {
      icon = _react2.default.createElement(_Icon2.default, {
        icon: this.iconsStore.getIcon('camera'),
        width: 30,
        color: disabled ? _Colors.COLORS.DISABLED : _Colors.COLORS.PRIMARY_BLUE
      });
    }

    return _react2.default.createElement(
      'div',
      { className: 'upload-photo-container' },
      _react2.default.createElement(
        'div',
        { className: 'upload-photo', onClick: this.uploadPhoto, onKeyDown: function onKeyDown() {} },
        _react2.default.createElement('img', {
          src: photoPreview,
          ref: function ref(img) {
            _this2.img = img;
          },
          alt: 'doctor',
          onError: this.onError
        }),
        icon,
        _react2.default.createElement('input', {
          autoComplete: 'off',
          className: 'hide',
          type: 'file',
          ref: function ref(input) {
            _this2.input = input;
          },
          accept: '.png, .jpg, .jpeg',
          onChange: this.loadPreview
        })
      )
    );
  };

  return PhotoInput;
}(_react.Component);

PhotoInput.propTypes = process.env.NODE_ENV !== "production" ? {
  photoPreview: _propTypes2.default.string.isRequired,
  photoCrop: _propTypes2.default.string.isRequired,
  isPhoto: _propTypes2.default.bool.isRequired,
  updatePhoto: _propTypes2.default.func.isRequired,
  loadPreview: _propTypes2.default.func.isRequired,
  disabled: _propTypes2.default.bool
} : {};

PhotoInput.defaultProps = {
  disabled: false
};

exports.default = (0, _WithDisplayName2.default)(PhotoInput, 'PhotoInput');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border: 0;\n  border-top: 1px solid ', ';\n  display: block;\n  height: 1px;\n  padding: 0;\n  ', ';\n'], ['\n  border: 0;\n  border-top: 1px solid ', ';\n  display: block;\n  height: 1px;\n  padding: 0;\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var StyledSeparator = _styledComponents2.default.hr(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.SEPARATOR], _styledSystem.space);

exports.default = StyledSeparator;
module.exports = exports['default'];
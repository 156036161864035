'use strict';

exports.__esModule = true;

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 50%;\n  height: 30px;\n  width: 30px;\n  max-width: none;\n  padding: 0;\n'], ['\n  border-radius: 50%;\n  height: 30px;\n  width: 30px;\n  max-width: none;\n  padding: 0;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _gridStyled = require('../grid-styled');

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Colors = require('../base/Colors');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

var _Button = require('../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var CounterButton = (0, _styledComponents2.default)(_Button2.default)(_templateObject);

var Counter = (_temp2 = _class = function (_Component) {
  _inherits(Counter, _Component);

  function Counter() {
    var _temp, _this, _ret;

    _classCallCheck(this, Counter);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      count: _this.props.value,
      disableMinus: _this.props.value <= _this.props.minimumCount
    }, _this.getInputValue = function () {
      return _this.state.count;
    }, _this.toIndiaDigits = function (number) {
      var id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      return number.replace(/[0-9]/g, function (w) {
        return id[+w];
      });
    }, _this.iconsStore = new _IconsStore2.default(_webIcons2.default), _this.increaseCount = function () {
      var _this$props = _this.props,
          minimumCount = _this$props.minimumCount,
          onChange = _this$props.onChange;

      _this.setState(function (prevState) {
        var count = prevState.count + 1;
        var disableMinus = count <= minimumCount;
        onChange(count);
        return {
          count: count,
          disableMinus: disableMinus
        };
      });
    }, _this.decreaseCount = function () {
      var _this$props2 = _this.props,
          minimumCount = _this$props2.minimumCount,
          onChange = _this$props2.onChange;
      var _this$state = _this.state,
          count = _this$state.count,
          disableMinus = _this$state.disableMinus;

      if (count > 1 && !disableMinus) {
        _this.setState(function (prevState) {
          count = prevState.count - 1;
          disableMinus = count <= minimumCount;
          onChange(count);
          return {
            count: count,
            disableMinus: disableMinus
          };
        });
      }
    }, _this.isValid = function () {
      return true;
    }, _this.validate = function () {}, _temp), _possibleConstructorReturn(_this, _ret);
  }

  /**
   * Returns the current count
   */


  /**
   * localize the numbers to arabic
   */

  /**
   * Increase the current count by one
   */


  /**
   * Check if the current count if bigger than one, then decrease it by one
   */


  /**
   * Always returns true
   */


  /**
   * Do nothing, just to have the same field method
   */


  Counter.prototype.render = function render() {
    var _props = this.props,
        className = _props.className,
        disabled = _props.disabled,
        isArabic = _props.isArabic;
    var _state = this.state,
        disableMinus = _state.disableMinus,
        count = _state.count;

    return _react2.default.createElement(
      _gridStyled.Flex,
      { alignItems: 'center', justifyContent: 'center', className: className },
      _react2.default.createElement(
        CounterButton,
        {
          primary: false,
          color: _Colors.COLORS.PRIMARY_BLUE,
          onClick: this.decreaseCount,
          onKeyDown: function onKeyDown() {},
          disabled: disableMinus || disabled
        },
        _react2.default.createElement(_Icon2.default, {
          icon: this.iconsStore.getIcon('minus'),
          width: 24,
          color: disableMinus || disabled ? _Colors.COLORS.DISABLED : _Colors.COLORS.PRIMARY_BLUE
        })
      ),
      _react2.default.createElement(
        _Text2.default,
        { mx: 5, minWidth: 20, textAlign: 'center', type: _Typography.FONT_TYPES.TITLE },
        isArabic ? this.toIndiaDigits(count.toString()) : count
      ),
      _react2.default.createElement(
        CounterButton,
        {
          primary: false,
          color: _Colors.COLORS.PRIMARY_BLUE,
          onClick: this.increaseCount,
          onKeyDown: function onKeyDown() {},
          disabled: disabled
        },
        _react2.default.createElement(_Icon2.default, {
          icon: this.iconsStore.getIcon('plus'),
          width: 12,
          color: disabled ? _Colors.COLORS.DISABLED : _Colors.COLORS.PRIMARY_BLUE
        })
      )
    );
  };

  return Counter;
}(_react.Component), _class.defaultProps = {
  className: '',
  onChange: function onChange() {},
  value: 1,
  minimumCount: 1,
  disabled: false,
  isArabic: false
}, _temp2);
Counter.propTypes = process.env.NODE_ENV !== "production" ? {
  value: _propTypes2.default.number,
  onChange: _propTypes2.default.func,
  className: _propTypes2.default.string,
  minimumCount: _propTypes2.default.number,
  disabled: _propTypes2.default.bool,
  isArabic: _propTypes2.default.bool
} : {};
exports.default = (0, _WithDisplayName2.default)(Counter, 'Counter');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 4px;\n  cursor: ', ';\n  padding: ', 'px ', 'px;\n  &:hover {\n    background-color: ', '};\n  }\n  ', ';\n'], ['\n  border-radius: 4px;\n  cursor: ', ';\n  padding: ', 'px ', 'px;\n  &:hover {\n    background-color: ', '};\n  }\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  font-weight: bold;\n'], ['\n  font-weight: bold;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRouterDom = require('react-router-dom');

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _gridStyled = require('../grid-styled');

var _Colors = require('../base/Colors');

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Space = require('../base/Space');

var _Space2 = _interopRequireDefault(_Space);

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Step = (0, _styledComponents2.default)(_Text2.default)(_templateObject, function (props) {
  return props.cursor;
}, _Space2.default[2], _Space2.default[3], function (props) {
  return _Colors.COLORS_VALUES[props.hover];
}, function (props) {
  return props.extendStepTab ? props.extendStepTab : '';
});

var extendStepTab = (0, _styledComponents.css)(_templateObject2);

var Breadcrumb = (_temp2 = _class = function (_Component) {
  _inherits(Breadcrumb, _Component);

  function Breadcrumb() {
    var _temp, _this, _ret;

    _classCallCheck(this, Breadcrumb);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      activeStepIndex: [0, 0],
      pathname: undefined
    }, _this.changeRoute = function (pathname, isLatest) {
      // If the step is the last or the only one, we don't redirect
      if (isLatest) {
        return;
      }

      var _this$props = _this.props,
          history = _this$props.history,
          validationElementName = _this$props.validationElementName,
          alertMessage = _this$props.alertMessage;

      var validationElement = document.querySelector(validationElementName);
      var couldRedirect = true;

      // Check if hidden input is exists
      if (validationElement) {
        // Check if field value is true
        couldRedirect = validationElement.value === 'true';
      }

      // Checks if user input is required
      if (!couldRedirect) {
        couldRedirect = window.confirm(alertMessage);
      }

      if (couldRedirect) {
        history.push(pathname);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Breadcrumb.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.pathname) {
      var stepsArray = nextProps.stepsArray;
      var pathname = nextProps.location.pathname;


      var i = 0;
      var activeStepIndex = prevState.activeStepIndex;

      stepsArray.forEach(function (array) {
        var isActiveArray = array.some(function (step) {
          return step.pathname && !step.enableBackLink === pathname;
        });
        if (isActiveArray) {
          var activeStep = array.findIndex(function (step) {
            return step.pathname === pathname;
          });
          activeStepIndex = [i, activeStep];
        }
        i += 1;
      });

      return {
        activeStepIndex: activeStepIndex,
        pathname: nextProps.location.pathname
      };
    }

    return null;
  };

  /**
   * Change pathname by using history from react-router-dom
   * @param {string} pathname
   */


  Breadcrumb.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        stepsArray = _props.stepsArray,
        fontType = _props.fontType,
        arrowWidth = _props.arrowWidth,
        reverse = _props.reverse;
    var _state = this.state,
        activeStepIndex = _state.activeStepIndex,
        pathname = _state.pathname;


    var content = [];
    stepsArray.map(function (array, index) {
      var isActiveArray = index === activeStepIndex[0];
      var enableBackLink = array.filter(function (routeStep) {
        return routeStep.enableBackLink && routeStep.pathname === pathname;
      });
      var enableViewTitle = array.filter(function (routeStep) {
        return routeStep.isViewTitle && routeStep.pathname === pathname;
      });
      if (isActiveArray && !(enableBackLink && enableBackLink[0] || enableViewTitle && enableViewTitle[0])) {
        array.map(function (step, stepIndex) {
          var isLatest = stepIndex === activeStepIndex[1];
          var cursor = isLatest ? 'default' : 'pointer';
          var bg = isLatest ? _Colors.COLORS.WHITE : _Colors.COLORS.HOVER;

          if (stepIndex <= activeStepIndex[1]) {
            content.push(_react2.default.createElement(
              _gridStyled.Flex,
              { key: step.name, alignItems: 'center' },
              _react2.default.createElement(
                Step,
                {
                  cursor: cursor,
                  hover: bg,
                  type: fontType,
                  onClick: function onClick() {
                    return _this2.changeRoute(step.pathname, isLatest);
                  }
                },
                step.name
              ),
              step.helpComponent ? step.helpComponent : null,
              !isLatest && _react2.default.createElement(_Icon2.default, {
                icon: new _IconsStore2.default(_webIcons2.default).getIcon(reverse ? 'arrow_left' : 'arrow_right'),
                color: _Colors.COLORS.HELP_TEXT,
                width: arrowWidth,
                mx: 1
              })
            ));
          }
          return null;
        });
      } else if (isActiveArray && enableBackLink && enableBackLink[0]) {
        content.push(_react2.default.createElement(
          _gridStyled.Flex,
          { key: enableBackLink[0].name, alignItems: 'center' },
          _react2.default.createElement(
            Step,
            {
              cursor: 'pointer',
              hover: _Colors.COLORS.WHITE,
              type: fontType,
              onClick: function onClick() {
                return _this2.changeRoute(enableBackLink[0].fallBack, false);
              },
              extendStepTab: extendStepTab
            },
            enableBackLink[0].name
          ),
          enableBackLink[0].helpComponent ? enableBackLink[0].helpComponent : null
        ));
      } else if (isActiveArray && enableViewTitle && enableViewTitle[0]) {
        content.push(_react2.default.createElement(
          _gridStyled.Flex,
          { key: enableViewTitle[0].name, alignItems: 'center' },
          _react2.default.createElement(
            Step,
            {
              cursor: 'default',
              hover: _Colors.COLORS.WHITE,
              type: fontType
              // onClick={() => this.changeRoute(enableBackLink[0].fallBack, false)}
              , extendStepTab: extendStepTab
            },
            enableViewTitle[0].name
          ),
          enableViewTitle[0].helpComponent ? enableViewTitle[0].helpComponent : null
        ));
      }
      return null;
    });

    return _react2.default.createElement(
      _gridStyled.Flex,
      { alignItems: 'center' },
      content.map(function (step) {
        return step;
      })
    );
  };

  return Breadcrumb;
}(_react.Component), _class.defaultProps = {
  fontType: _Typography.FONT_TYPES.BODY,
  arrowWidth: 6,
  validationElementName: undefined,
  alertMessage: 'Data will be lost, are you sure you want to proceed?',
  reverse: false
}, _temp2);
Breadcrumb.propTypes = process.env.NODE_ENV !== "production" ? {
  stepsArray: _propTypes2.default.arrayOf(_propTypes2.default.arrayOf(_propTypes2.default.shape({
    name: _propTypes2.default.string,
    pathname: _propTypes2.default.path
  }))).isRequired,
  location: _propTypes2.default.object.isRequired, // eslint-disable-line
  history: _propTypes2.default.object.isRequired, // eslint-disable-line
  fontType: _propTypes2.default.string,
  arrowWidth: _propTypes2.default.number,
  alertMessage: _propTypes2.default.string,
  validationElementName: _propTypes2.default.string,
  reverse: _propTypes2.default.bool
} : {};
exports.default = (0, _reactRouterDom.withRouter)(Breadcrumb);
module.exports = exports['default'];
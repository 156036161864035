'use strict';

exports.__esModule = true;
exports.IsRequiredNote = exports.Container = exports.PlaceholderTitle = exports.PlaceholderContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  z-index: 1;\n  width: 85%;\n  min-height: 100%;\n  border: ', ';\n  border-width: ', ';\n  border-radius: ', ';\n  background-color: ', ';\n  display: flex;\n  align-items: center;\n  padding: 0px 16px;\n  & > div {\n    color: ', ';\n  }\n'], ['\n  position: absolute;\n  z-index: 1;\n  width: 85%;\n  min-height: 100%;\n  border: ', ';\n  border-width: ', ';\n  border-radius: ', ';\n  background-color: ', ';\n  display: flex;\n  align-items: center;\n  padding: 0px 16px;\n  & > div {\n    color: ', ';\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: #9c9c9c;\n  font-size: ', ';\n  line-height: ', ';\n'], ['\n  color: #9c9c9c;\n  font-size: ', ';\n  line-height: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  & > div > div > .react-date-picker__wrapper {\n    border: ', ';\n    border-radius: 8px;\n  }\n'], ['\n  & > div > div > .react-date-picker__wrapper {\n    border: ', ';\n    border-radius: 8px;\n  }\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  display: inline-flex;\n  margin: 0px 3px;\n  color: #db3226;\n'], ['\n  display: inline-flex;\n  margin: 0px 3px;\n  color: #db3226;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; } /* eslint-disable indent */


var PlaceholderContainer = exports.PlaceholderContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.isValid ? '1px solid #e3e6ea' : '1px solid #db3226';
}, function (props) {
  return props.isRtl ? '1px 1px 1px 0px' : '1px 0px 1px 1px';
}, function (props) {
  return props.isRtl ? '0px 8px 8px 0px' : '8px 0px 0px 8px';
}, function (props) {
  return props.isDisabled ? '#f6f8f9' : '#fff';
}, function (props) {
  return props.isDisabled ? '#9c9c9c' : 'unset';
});

var PlaceholderTitle = exports.PlaceholderTitle = _styledComponents2.default.div(_templateObject2, function (props) {
  return props.isEmpty ? '16px' : '12px';
}, function (props) {
  return props.isEmpty ? '40px' : '18px';
});

var Container = exports.Container = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.isValid ? '1px solid #e3e6ea !important' : '1px solid #db3226 !important';
});

var IsRequiredNote = exports.IsRequiredNote = _styledComponents2.default.p(_templateObject4);
'use strict';

exports.__esModule = true;
exports.SnackBarMessage = exports.SnackBarContainer = exports.SnackBarWindow = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  align-items: flex-end;\n  display: flex;\n  height: 100%;\n  left: 0;\n  pointer-events: none;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 9999;\n'], ['\n  align-items: flex-end;\n  display: flex;\n  height: 100%;\n  left: 0;\n  pointer-events: none;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 9999;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  border-radius: 8px;\n  background-color: ', ';\n  min-height: 48px;\n  margin: 0 auto;\n  margin-bottom: ', ';\n  max-width: 50%;\n  min-width: 300px;\n  padding: 14px 16px;\n  position: relative;\n  transition: margin 200ms ease-in-out;\n  width: auto;\n  display: flex;\n  flex-direction: row;\n'], ['\n  border-radius: 8px;\n  background-color: ', ';\n  min-height: 48px;\n  margin: 0 auto;\n  margin-bottom: ', ';\n  max-width: 50%;\n  min-width: 300px;\n  padding: 14px 16px;\n  position: relative;\n  transition: margin 200ms ease-in-out;\n  width: auto;\n  display: flex;\n  flex-direction: row;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  letter-spacing: 0.1px;\n  margin: 0;\n  margin-right: 48px;\n  margin: 0px 10px;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 22px;\n'], ['\n  color: ', ';\n  letter-spacing: 0.1px;\n  margin: 0;\n  margin-right: 48px;\n  margin: 0px 10px;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 22px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var SnackBarWindow = exports.SnackBarWindow = _styledComponents2.default.div(_templateObject);

var SnackBarContainer = exports.SnackBarContainer = _styledComponents2.default.div(_templateObject2, function (props) {
  return props.barBackgroundColor ? props.barBackgroundColor : '#31a636';
}, function (props) {
  return props.show ? '40px' : '-90px';
});

var SnackBarMessage = exports.SnackBarMessage = (0, _styledComponents2.default)(_Text2.default)(_templateObject3, function (props) {
  return props.textColor ? props.textColor : '#fff';
});
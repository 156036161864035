'use strict';

exports.__esModule = true;
exports.Flex = exports.Box = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  box-sizing: border-box;\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  box-sizing: border-box;\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  ', ';\n  ', ';\n  ', ';\n\n  ', ';\n\n  ', ';\n'], ['\n  display: flex;\n  ', ';\n  ', ';\n  ', ';\n\n  ', ';\n\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Box = exports.Box = _styledComponents2.default.div(_templateObject, _styledSystem.space, _styledSystem.typography, _styledSystem.layout, _styledSystem.color, _styledSystem.hover, _styledSystem.border, _styledSystem.borderColor, _styledSystem.bgColor, _styledSystem.borderRadius, _styledSystem.maxHeight, _styledSystem.maxWidth, _styledSystem.height, _styledSystem.width, _styledSystem.minWidth, _styledSystem.minHeight, _styledSystem.lineHeight, _styledSystem.fontSize, _styledSystem.fontWeight, _styledSystem.textAlign, _styledSystem.left, _styledSystem.right, _styledSystem.zIndex);

Box.displayName = 'Box';

var Flex = exports.Flex = (0, _styledComponents2.default)(Box)(_templateObject2, _styledSystem.flexbox, _styledSystem.flexDirection, function (props) {
  return props.alignItems && '\n    align-items: ' + props.alignItems + ';\n  ';
}, function (props) {
  return props.justifyContent && '\n    justify-content: ' + props.justifyContent + ';\n  ';
}, function (props) {
  return props.flexWrap && '\n    flex-wrap: ' + props.flexWrap + ';\n  ';
});

Flex.displayName = 'Flex';
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Button = require('../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Colors = require('../base/Colors');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Pagination = function (_Component) {
  _inherits(Pagination, _Component);

  function Pagination() {
    var _temp, _this, _ret;

    _classCallCheck(this, Pagination);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { pageNumber: 0 }, _this.onClick = function () {
      _this.setState({
        pageNumber: _this.state.pageNumber += 1
      }, function () {
        if (_this.props.onClick) {
          _this.props.onClick(_this.state.pageNumber);
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Pagination.prototype.render = function render() {
    return _react2.default.createElement(
      _react.Fragment,
      null,
      this.props.children,
      !this.props.disable && _react2.default.createElement(
        'div',
        { className: 'center' },
        _react2.default.createElement(
          _Button2.default,
          {
            className: (0, _classnames2.default)('btn--transparent', this.props.buttonClassName),
            onClick: this.onClick,
            isLoading: this.props.isLoading,
            loadingText: this.props.loadingText,
            primary: false,
            color: _Colors.COLORS.PRIMARY_BLUE
          },
          this.props.defaultText
        )
      )
    );
  };

  return Pagination;
}(_react.Component);

Pagination.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.element.isRequired,
  defaultText: _propTypes2.default.string,
  loadingText: _propTypes2.default.string,
  buttonClassName: _propTypes2.default.string,
  onClick: _propTypes2.default.func,
  isLoading: _propTypes2.default.bool,
  disable: _propTypes2.default.bool
} : {};

Pagination.defaultProps = {
  defaultText: 'Load more',
  loadingText: 'Loading...',
  disable: false,
  buttonClassName: undefined,
  onClick: function onClick() {},
  isLoading: false
};

exports.default = (0, _WithDisplayName2.default)(Pagination, 'Pagination');
module.exports = exports['default'];
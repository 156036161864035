'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  border-radius: 15px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-left: ', ';\n  padding-right: ', ';\n  white-space: nowrap;\n'], ['\n  ', ';\n  ', ';\n  border-radius: 15px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-left: ', ';\n  padding-right: ', ';\n  white-space: nowrap;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  background: ', ';\n  outline: none;\n  display: flex;\n  border: none;\n  align-items: center;\n  justify-content: center;\n  margin-left: ', ';\n  margin-right: ', ';\n  height: 24px;\n  width: 24px;\n  border-radius: 15px;\n'], ['\n  background: ', ';\n  outline: none;\n  display: flex;\n  border: none;\n  align-items: center;\n  justify-content: center;\n  margin-left: ', ';\n  margin-right: ', ';\n  height: 24px;\n  width: 24px;\n  border-radius: 15px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _color = require('color');

var _color2 = _interopRequireDefault(_color);

var _styledSystem = require('styled-system');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Colors = require('../base/Colors');

var _Space = require('../base/Space');

var _Space2 = _interopRequireDefault(_Space);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var TagContainer = (0, _styledComponents2.default)(_Text2.default)(_templateObject, _styledSystem.bgColor, _styledSystem.width, function (props) {
  return props.reverse && props.removable ? '0px' : _Space2.default[3] + 'px';
}, function (props) {
  return (
    // eslint-disable-next-line no-nested-ternary
    props.reverse ? _Space2.default[3] + 'px' : props.removable ? '0px' : _Space2.default[3] + 'px'
  );
});

var CloseButton = _styledComponents2.default.button(_templateObject2, function (props) {
  return (0, _color2.default)(_Colors.COLORS_VALUES[props.bg]).darken(0.15).hex();
}, function (props) {
  return props.reverse ? '0px' : _Space2.default[2] + 'px';
}, function (props) {
  return props.reverse ? _Space2.default[2] + 'px' : '0px';
});

var Tag = function Tag(_ref) {
  var children = _ref.children,
      removable = _ref.removable,
      onRemove = _ref.onRemove,
      callbackParams = _ref.callbackParams,
      reverse = _ref.reverse,
      otherProps = _objectWithoutProperties(_ref, ['children', 'removable', 'onRemove', 'callbackParams', 'reverse']);

  return _react2.default.createElement(
    TagContainer,
    _extends({
      lineHeight: 14,
      pr: removable ? 0 : 3,
      color: _Colors.COLORS.WHITE,
      bg: _Colors.COLORS.PRIMARY_BLUE,
      width: 'max-content',
      reverse: reverse,
      removable: removable
    }, otherProps),
    children,
    removable && _react2.default.createElement(
      CloseButton,
      {
        reverse: reverse,
        bg: _Colors.COLORS.PRIMARY_BLUE,
        onClick: function onClick() {
          return onRemove(callbackParams);
        }
      },
      _react2.default.createElement(_Icon2.default, { icon: new _IconsStore2.default(_webIcons2.default).getIcon('close'), width: 8, color: _Colors.COLORS.WHITE })
    )
  );
};

Tag.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.string.isRequired,
  removable: _propTypes2.default.bool,
  onRemove: _propTypes2.default.func,
  callbackParams: _propTypes2.default.any, // eslint-disable-line
  reverse: _propTypes2.default.bool
} : {};

Tag.defaultProps = {
  removable: true,
  onRemove: function onRemove() {},
  reverse: false
};

exports.default = Tag;
module.exports = exports['default'];
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';
import { Spinner } from '@vezeeta/web-components';

const UseRoute = ({ ...props }) => {
  const { path, render } = props;
  const onRenderPropsChanges = routeProps => {
    const isAccessed = true;
    if (isAccessed) return render(routeProps);

    return (
      <Flex justifyContent="center" alignItems="center" py={10}>
        <Spinner />
      </Flex>
    );
  };
  return <Route path={path} render={onRenderPropsChanges} />;
};

UseRoute.propTypes = {
  path: PropTypes.string,
  render: PropTypes.func,
  localization: PropTypes.object,
  isUserLoaded: PropTypes.bool,
};
export default UseRoute;

'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  border-radius: 50%;\n  outline: none;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n\n  &:not(:disabled) {\n    cursor: pointer;\n  }\n'], ['\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  border-radius: 50%;\n  outline: none;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n\n  &:not(:disabled) {\n    cursor: pointer;\n  }\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var StyledButton = _styledComponents2.default.button(_templateObject, _styledSystem.height, _styledSystem.minHeight, _styledSystem.width, _styledSystem.minWidth, _styledSystem.border, _styledSystem.borderColor, _styledSystem.bgColor, _styledSystem.space);

var IconButton = (_temp2 = _class = function (_PureComponent) {
  _inherits(IconButton, _PureComponent);

  function IconButton() {
    var _temp, _this, _ret;

    _classCallCheck(this, IconButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _PureComponent.call.apply(_PureComponent, [this].concat(args))), _this), _this.state = {
      iconColor: _this.props.disabled ? _Colors.COLORS.WHITE : _Colors.COLORS.PRIMARY_BLUE,
      buttonBorderColor: _this.props.disabled ? _Colors.COLORS.DISABLED : _Colors.COLORS.PRIMARY_BLUE,
      buttonBackgroundColor: _this.props.disabled ? _Colors.COLORS.DISABLED : _Colors.COLORS.WHITE
    }, _this.onMouseOver = function () {
      _this.changeButtonColors({
        iconColor: _Colors.COLORS.WHITE,
        buttonBackgroundColor: _Colors.COLORS.PRIMARY_BLUE
      });
    }, _this.onMouseOut = function () {
      _this.changeButtonColors({
        iconColor: _Colors.COLORS.PRIMARY_BLUE,
        buttonBackgroundColor: _Colors.COLORS.WHITE
      });
    }, _this.changeButtonColors = function (_ref) {
      var iconColor = _ref.iconColor,
          buttonBackgroundColor = _ref.buttonBackgroundColor;

      // If the button is disabled no more to update the state
      var disabled = _this.props.disabled;

      if (disabled) {
        return;
      }

      _this.setState({
        iconColor: iconColor,
        buttonBackgroundColor: buttonBackgroundColor
      });
    }, _this.render = function () {
      var _this$props = _this.props,
          radius = _this$props.radius,
          icon = _this$props.icon,
          iconWidth = _this$props.iconWidth;
      var _this$state = _this.state,
          iconColor = _this$state.iconColor,
          buttonBackgroundColor = _this$state.buttonBackgroundColor,
          buttonBorderColor = _this$state.buttonBorderColor;


      return _react2.default.createElement(
        StyledButton,
        _extends({}, _this.props, {
          height: radius,
          minHeight: radius,
          width: radius,
          minWidth: radius,
          borderColor: buttonBorderColor,
          bg: buttonBackgroundColor,
          onMouseEnter: _this.onMouseOver,
          onMouseLeave: _this.onMouseOut
        }),
        _react2.default.createElement(_Icon2.default, { icon: icon, width: iconWidth, color: iconColor })
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return IconButton;
}(_react.PureComponent), _class.defaultProps = {
  radius: 40,
  disabled: false,
  iconWidth: 16
}, _temp2);
IconButton.propTypes = process.env.NODE_ENV !== "production" ? {
  radius: _propTypes2.default.number,
  disabled: _propTypes2.default.bool,
  icon: _Icon.iconPropTypes.isRequired,
  iconWidth: _propTypes2.default.number
} : {};
exports.default = IconButton;
module.exports = exports['default'];
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

class Header extends Component {
  render() {
    const itemsClass = ` ${this.props.itemsClass}`;

    return (
      <header className="col-xs-12">
        <div className="container">
          <div className="header-container col-md-10 col-xs-12 col-md-offset-1">
            <div
              className="logo-container col-xs-3"
              onClick={this.props.onLogoClick}
              onKeyDown={() => {}}
            >
              <img
                className="Logo"
                src="https://d3ggiwwizihk0i.cloudfront.net/vezeeta-live-9-0-4-7/Assets/Img/whitelogowithdotcom.png"
                ref={img => {
                  this.logo = img;
                }}
                alt="Logo"
              />
            </div>
            <div className={`menu-container col-xs-9${itemsClass}`}>{this.props.headerItems}</div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  headerItems: PropTypes.object,
  itemsClass: PropTypes.string,
  onLogoClick: PropTypes.func.isRequired,
};

Header.defaultProps = {
  itemsClass: '',
};

export default Header;

'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Select = require('../select/Select');

var _Select2 = _interopRequireDefault(_Select);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Colors = require('../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./Calendar.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ARROW_WIDTH = 8;

var Calendar = function (_Component) {
  _inherits(Calendar, _Component);

  function Calendar(props) {
    _classCallCheck(this, Calendar);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onClickRightArrowCalendar = function () {
      var currentMonthNum = _this.state.currentMonthNum;

      var year = +_this.state.currentYear;
      var maxYear = new Date(_this.props.maxDate).getFullYear();
      var month = +currentMonthNum + 1;

      if (currentMonthNum === 12) {
        month = 1;
        year += 1;
      }

      _this.setState({
        currentMonthNum: +month,
        currentMonthName: _this.props.isArabic ? _this.getMonthArabicNames()[month - 1] : _this.getMonthNames()[month - 1],
        currentYear: +year,
        disableNextMonthButton: year === maxYear && month === 12,
        disablePreviousMonthButton: false
      });
    };

    _this.onClickLeftArrowCalendar = function () {
      var currentMonthNum = _this.state.currentMonthNum;

      var year = +_this.state.currentYear;
      var minYear = new Date(_this.props.minDate).getFullYear();
      var month = +currentMonthNum - 1;
      if (currentMonthNum === 1) {
        month = 12;
        year -= 1;
      }
      _this.setState({
        currentMonthNum: +month,
        currentMonthName: _this.props.isArabic ? _this.getMonthArabicNames()[month - 1] : _this.getMonthNames()[month - 1],
        currentYear: +year,
        disableNextMonthButton: false,
        disablePreviousMonthButton: year === minYear && month === 1
      });
    };

    _this.onClickCalendar = function (e) {
      var fullId = e.target.id;
      var id = fullId.substr(_this.props.id.length);
      if (_this.checkYYYYMMDD(id)) {
        var _this$state = _this.state,
            dateFormat = _this$state.dateFormat,
            err = _this$state.err,
            minDate = _this$state.minDate,
            maxDate = _this$state.maxDate;

        if (_this.props.type === 'single') {
          if (_this.compareDate(minDate, id) && _this.compareDate(id, maxDate)) {
            var date = id;
            _this.setState({
              date: date
            });
            var dateOut = date === '' ? '' : _this.transformDate(date, dateFormat);
            if (_this.props.onChange) {
              _this.props.onChange({ date: dateOut, err: err });
            }
          } else if (!_this.compareDate(minDate, id)) {
            _this.switchErrorPopUp(_this.props.isArabic ? _this.state.errMessageLessThanMinDateArabic.concat(_this.transformYYYYMMDDtoMMDDYYYY(_this.state.minDate, _this.props.isArabic)) : // eslint-disable-line
            _this.state.errMassageLessThenMinDate.concat(_this.transformYYYYMMDDtoMMDDYYYY(_this.state.minDate) // eslint-disable-line
            ) // eslint-disable-line
            );
          } else if (!_this.compareDate(id, maxDate)) {
            _this.switchErrorPopUp(_this.props.isArabic ? _this.state.errMessageMorethanMaxDateArabic.concat(_this.transformYYYYMMDDtoMMDDYYYY(_this.state.maxDate, _this.props.isArabic)) : // eslint-disable-line
            _this.state.errMassageMoreThenMxaDate.concat(_this.transformYYYYMMDDtoMMDDYYYY(_this.state.maxDate) // eslint-disable-line
            ) // eslint-disable-line
            );
          }
        } else {
          var _this$state2 = _this.state,
              startDate = _this$state2.startDate,
              endDate = _this$state2.endDate;

          if (endDate === '') {
            if (startDate === '') {
              if (_this.compareDate(minDate, id) && _this.compareDate(id, maxDate)) {
                startDate = id;
                _this.setState({
                  startDate: startDate
                });
                var _dateOut = !startDate ? '' : _this.transformDate(startDate, dateFormat);
                if (_this.props.onChange) {
                  _this.props.onChange({ startDate: _dateOut, err: err });
                }
              }
            } else if (_this.compareDate(startDate, id) && _this.compareDate(id, maxDate)) {
              endDate = id;
              _this.setState({
                endDate: endDate
              });
              var startDateOut = startDate === '' ? '' : _this.transformDate(startDate, dateFormat);
              var endDateOut = endDate === '' ? '' : _this.transformDate(endDate, dateFormat);
              if (_this.props.onChange) {
                _this.props.onChange({ startDate: startDateOut, endDate: endDateOut, err: err });
              }
            } else if (_this.compareDate(minDate, id) && _this.compareDate(id, startDate)) {
              endDate = startDate;
              startDate = id;
              _this.setState({
                startDate: startDate,
                endDate: endDate
              });
              var _startDateOut = startDate === '' ? '' : _this.transformDate(startDate, dateFormat);
              var _endDateOut = endDate === '' ? '' : _this.transformDate(endDate, dateFormat);
              if (_this.props.onChange) {
                _this.props.onChange({ startDate: _startDateOut, endDate: _endDateOut, err: err });
              }
            } else if (!_this.compareDate(minDate, id)) {
              _this.switchErrorPopUp(_this.state.errMassageLessThenMinDate.concat(_this.state.minDate));
            } else if (!_this.compareDate(id, maxDate)) {
              _this.switchErrorPopUp(_this.state.errMassageMoreThenMxaDate.concat(_this.state.maxDate));
            }
          } else if (startDate !== '') {
            startDate = '';
            endDate = '';
            _this.setState({
              startDate: startDate,
              endDate: endDate
            });
            if (_this.props.onChange) {
              _this.props.onChange({ startDate: '', endDate: '', err: err });
            }
          }
        }
      }
    };

    _this.onClickErrorPopUp = function () {
      _this.setState({
        showErrorPopUp: false,
        errPopUp: ''
      });
    };

    _this.onChangeTitleYear = function (year) {
      var currentYear = year;
      var minYear = new Date(_this.state.minDate).getFullYear();
      var maxYear = new Date(_this.state.maxDate).getFullYear();
      var checkTitleYear = +currentYear >= +minYear && +currentYear <= +maxYear;

      _this.setState({
        currentYear: +currentYear,
        checkTitleYear: checkTitleYear,
        disableNextMonthButton: currentYear === maxYear && _this.state.currentMonthNum === 12,
        disablePreviousMonthButton: currentYear === minYear && _this.state.currentMonthNum === 1
      });
    };

    _this.getStartState = function () {
      var minDateDefault = '01/01/1900';
      var maxDateDefault = '12/31/2099';
      var now = new Date();
      var err = [];
      var monthNames = _this.props.isArabic ? _this.getMonthArabicNames() : _this.getMonthNames();
      var monthNum = now.getMonth();
      var monthName = monthNames[now.getMonth()];
      var year = now.getFullYear();
      var minDate = _this.props.minDate ? _this.props.minDate : minDateDefault;
      var maxDate = _this.props.maxDate ? _this.props.maxDate : maxDateDefault;
      var _this$props = _this.props,
          dateFormat = _this$props.dateFormat,
          date = _this$props.date,
          startDate = _this$props.startDate,
          endDate = _this$props.endDate;

      var dateArr = [minDate, maxDate];
      if (_this.props.type === 'single') {
        dateArr.push(date);
      } else {
        dateArr.push(startDate);
        dateArr.push(endDate);
      }
      if (dateFormat === '') {
        dateFormat = _this.setDateFormat(dateArr);
      }
      if (minDate !== '' && !_this.checkYYYYMMDD(minDate)) {
        if (_this.checkMMDDYYYY(minDate)) {
          minDate = _this.transformMMDDYYYYtoYYYYMMDD(minDate);
        } else {
          err.push('minDate format != yyyy-mm-dd or mm/dd/yyyy');
          minDate = minDateDefault;
        }
      }
      if (maxDate !== '' && !_this.checkYYYYMMDD(maxDate)) {
        if (_this.checkMMDDYYYY(maxDate)) {
          maxDate = _this.transformMMDDYYYYtoYYYYMMDD(maxDate);
        } else {
          err.push('maxDate format != yyyy-mm-dd or mm/dd/yyyy');
          maxDate = maxDateDefault;
        }
      }
      if (minDate !== '' && maxDate !== '' && !_this.compareDate(minDate, maxDate)) {
        maxDate = maxDateDefault >= minDate ? minDateDefault : minDate;
      }
      if (_this.props.type === 'single') {
        if (date !== '' && !_this.checkYYYYMMDD(date)) {
          if (_this.checkMMDDYYYY(date)) {
            date = _this.transformMMDDYYYYtoYYYYMMDD(date);
          } else {
            err.push('date format != yyyy-mm-dd or mm/dd/yyyy');
            startDate = '';
          }
        }
      } else {
        if (startDate !== '' && !_this.checkYYYYMMDD(startDate)) {
          if (_this.checkMMDDYYYY(startDate)) {
            startDate = _this.transformMMDDYYYYtoYYYYMMDD(startDate);
          } else {
            err.push('startDate format != yyyy-mm-dd or mm/dd/yyyy');
            startDate = '';
          }
        }
        if (startDate === '') endDate = '';
        if (endDate !== '' && !_this.checkYYYYMMDD(endDate)) {
          if (_this.checkMMDDYYYY(endDate)) {
            endDate = _this.transformMMDDYYYYtoYYYYMMDD(endDate);
          } else {
            err.push('endDate format != yyyy-mm-dd or mm/dd/yyyy');
            endDate = '';
          }
        }
        if (startDate !== '' && endDate !== '' && !_this.compareDate(startDate, endDate)) {
          startDate = '';
          endDate = '';
        }
      }

      return {
        now: now,
        currentMonthNum: monthNum + 1,
        currentMonthName: monthName,
        currentYear: year,
        minDate: minDate,
        maxDate: maxDate,
        date: date,
        startDate: startDate,
        endDate: endDate,
        dateFormat: dateFormat,
        err: err,
        weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        weekDaysArabic: ['سبت', 'جمعة', 'خميس', 'أربعاء', 'ثلاثاء', 'إثنين', 'أحد'],
        showErrorPopUp: false,
        errPopUp: '',
        errMassageLessThenMinDate: 'Please select a date starting from ',
        errMessageLessThanMinDateArabic: '  الرجاء اختيار يوم بداية من ',
        errMassageMoreThenMxaDate: 'Please select a date before ',
        errMessageMorethanMaxDateArabic: ' الرجاء اختيار يوم قبل ',
        checkTitleYear: true,
        disableNextMonthButton: false,
        disablePreviousMonthButton: false
      };
    };

    _this.getMonthNames = function () {
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    _this.getMonthArabicNames = function () {
      return ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
    };

    _this.getIconColor = function (isDisabled) {
      if (isDisabled) {
        return _Colors2.default.mediumGrey;
      }
      return _Colors2.default.vezeetaBlue;
    };

    _this.switchErrorPopUp = function (err) {
      _this.setState({
        showErrorPopUp: true,
        errPopUp: err
      });
      setTimeout(function () {
        _this.setState({
          showErrorPopUp: false,
          errPopUp: ''
        });
      }, 6000);
    };

    _this.createDayStyles = function (obj) {
      var val = obj.val,
          now = obj.now,
          type = obj.type,
          date = obj.date,
          startDate = obj.startDate,
          endDate = obj.endDate,
          isArabic = obj.isArabic;

      var arr = val.split('-');
      var startDateObj = startDate !== '' ? new Date(startDate) : '';
      var endDateObj = startDate !== '' ? new Date(endDate) : '';
      var classDiv = '';
      if (type === 'single') {
        if (val === date) {
          classDiv += ' calendar-one-date ';
        }
      } else if (startDate !== '') {
        var valDate = new Date(val);
        if (val === startDate && val === endDate) {
          classDiv += ' calendar-one-date ';
        } else if (val === startDate) {
          classDiv += isArabic ? ' calendar-start-date-arabic ' : ' calendar-start-date ';
        } else if (endDate !== '') {
          if (valDate > startDateObj && valDate < endDateObj) {
            classDiv += ' calendar-diapason-date ';
          } else if (val === endDate) {
            classDiv += isArabic ? ' calendar-end-date-arabic ' : ' calendar-end-date ';
          }
        }
      }
      var todayClass = +arr[0] === +now.getFullYear() && +arr[1] === now.getMonth() + 1 && +arr[2] === +now.getDate() ? 'calendar-today' : '';
      classDiv += todayClass;
      // if(isArabic&&todayClass==='') classDiv+='calendar-today'
      return classDiv;
    };

    _this.findDaysInMonth = function (month, year) {
      if (month === 'January' || month === 'March' || month === 'May' || month === 'July' || month === 'August' || month === 'October' || month === 'December') {
        return 31;
      }
      if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) {
        return 31;
      } else if (month === 'April' || month === 'June' || month === 'September' || month === 'November') {
        return 30;
      } else if (month === 4 || month === 6 || month === 9 || month === 11) {
        return 30;
      } else if ((year - 2016) % 4 !== 0) {
        return 28;
      }
      return 29;
    };

    _this.transformMMDDYYYYtoYYYYMMDD = function (mmddyyyy) {
      var arr = mmddyyyy.split('/');
      return arr[2] + '-' + arr[0] + '-' + arr[1]; // eslint-disable-line
    };

    _this.transformYYYYMMDDtoMMDDYYYY = function (yyyymmdd, isArabic) {
      var arr = yyyymmdd.split('-');
      if (!isArabic || isArabic === undefined) return arr[1] + '/' + arr[2] + '/' + arr[0]; // eslint-disable-line
      return _this.toIndiaDigits(arr[2]) + ' - ' + _this.toIndiaDigits(arr[1]) + ' - ' + _this.toIndiaDigits(arr[0]);
    };

    _this.compareDate = function (startDate, endDate) {
      var startDateObj = new Date(startDate);
      var endDateObj = new Date(endDate);
      var startYear = startDateObj.getFullYear();
      var startMonth = startDateObj.getMonth();
      var startDateNum = startDateObj.getDate();
      var startTime = startYear * 365 + startMonth * 30 + startDateNum;
      var endYear = endDateObj.getFullYear();
      var endMonth = endDateObj.getMonth();
      var endDateNum = endDateObj.getDate();
      var endTime = endYear * 365 + endMonth * 30 + endDateNum;
      if (startTime > endTime) return false;
      return true;
    };

    _this.toIndiaDigits = function (number) {
      var id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      return number.replace(/[0-9]/g, function (w) {
        return id[+w];
      });
    };

    _this.generateYears = function () {
      var startYear = new Date(_this.props.minDate).getFullYear();
      var currentYear = new Date(_this.props.maxDate).getFullYear();
      var yearsOptions = [];

      for (var counter = currentYear; counter >= startYear; counter -= 1) {
        var year = {
          data: {
            placeholder: _this.props.isArabic ? _this.toIndiaDigits('' + counter) : '' + counter,
            value: counter
          },
          component: _react2.default.createElement(
            _Text2.default,
            null,
            _this.props.isArabic ? _this.toIndiaDigits('' + counter) : '' + counter
          )
        };
        yearsOptions.push(year);
      }
      return yearsOptions;
    };

    _this.state = _this.getStartState(_this.props);
    _this.iconsStore = new _IconsStore2.default(_webIcons2.default);
    return _this;
  }

  /**
   * Change state, if props change
   * @param {string} this.props.minDate
   * @param {string} nextProps.minDate
   * @param {string} this.props.maxDate
   * @param {string} nextProps.maxDate
   * @param {string} this.props.type
   * @param {string} this.props.date
   * @param {string} this.props.startDate
   * @param {string} nextProps.startDate
   * @param {string} this.props.endDate
   * @param {string} nextProps.endDate
   */


  Calendar.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    var arr = [];
    if (this.props.minDate !== nextProps.minDate) arr.push({ key: 'minDate', val: nextProps.minDate });
    if (this.props.maxDate !== nextProps.maxDate) arr.push({ key: 'maxDate', val: nextProps.maxDate });
    if (this.props.type === 'single') {
      if (this.props.date !== nextProps.date) arr.push({ key: 'date', val: nextProps.date });
    } else {
      if (this.props.startDate !== nextProps.startDate) arr.push({ key: 'startDate', val: nextProps.startDate });
      if (this.props.endDate !== nextProps.endDate) arr.push({ key: 'endDate', val: nextProps.endDate });
    }
    var arrLength = arr.length;
    if (arrLength > 0) {
      var stateObj = {};
      for (var i = 0; i < arrLength; i += 1) {
        var key = arr[i].key;
        var val = arr[i].val;

        if (this.checkMMDDYYYY(val)) {
          val = this.transformMMDDYYYYtoYYYYMMDD(val);
        }
        if (this.checkYYYYMMDD(val) || val === '') {
          stateObj[key] = val;
        }
      }
      this.setState(stateObj);
    }
  };

  /**
   * event onClick Right Arrow
   */


  /**
   * event onClick Left Arrow
   */


  /**
   * event onClick Calendar Body, check id cell
   * @param {event} e
   */


  /**
   * event onClick ErrorPopUp - for close
   */


  /**
   * Change Year
   * @param {event} e
   */


  /**
   * get Start State
   * @return {object} state
   */


  /**
   * get Month Names Array
   * @return {array}
   */


  /**
   * set Date Format
   * @param {array} dateArr
   * @return {string} dateFormat
   */
  Calendar.prototype.setDateFormat = function setDateFormat(dateArr) {
    var dateArrLen = dateArr.length;
    var dateFormat = 'mm/dd/yyyy';
    var sumYYYYMMDD = 0;
    for (var i = 0; i < dateArrLen; i += 1) {
      if (dateArr[i] === '' || this.checkYYYYMMDD(dateArr[i])) sumYYYYMMDD += 1;
    }
    if (sumYYYYMMDD === dateArrLen) {
      dateFormat = 'yyyy-mm-dd';
    }
    return dateFormat;
  };

  /**
   * switch on and switch off Error PopUp
   * @param {string} err
   */


  /**
   * create Day Styles
   * @param {object} obj
   * @return {string} classDiv
   */


  /**
   * find Days In Month
   * @param {number} month
   * @param {string} year
   * @return {number}
   */


  /**
   * check YYYYMMDD
   * @param {string} val
   * @return (bool)
   */
  Calendar.prototype.checkYYYYMMDD = function checkYYYYMMDD(val) {
    var arr = val.split('-');
    if (arr.length === 3) {
      if (!Number.isNaN(+arr[0])) {
        if (+arr[1] >= 1 && +arr[1] <= 12) {
          var daysInMonth = this.findDaysInMonth(+arr[1], +arr[0]);
          if (+arr[2] >= 1 && +arr[2] <= daysInMonth) return true;
        }
      }
    }
    return false;
  };

  /**
   * check MMDDYYYY
   * @param {string} val
   * @return (bool)
   */


  Calendar.prototype.checkMMDDYYYY = function checkMMDDYYYY(val) {
    var arr = val.split('/');
    if (arr.length === 3) {
      if (!Number.isNaN(+arr[2])) {
        if (+arr[0] >= 1 && +arr[0] <= 12) {
          var daysInMonth = this.findDaysInMonth(+arr[0], +arr[2]);
          if (+arr[1] >= 1 && +arr[1] <= daysInMonth) return true;
        }
      }
    }
    return false;
  };

  /**
   * Return date in dateFormat format
   * @param {string} date
   * @param {string} dateFormat
   */


  Calendar.prototype.transformDate = function transformDate(date, dateFormat) {
    switch (dateFormat) {
      case 'mm/dd/yyyy':
        if (this.checkMMDDYYYY(date)) return date;
        if (this.checkYYYYMMDD(date)) return this.transformYYYYMMDDtoMMDDYYYY(date);
        break;
      case 'yyyy-mm-dd':
        if (this.checkYYYYMMDD(date)) return date;
        if (this.checkMMDDYYYY(date)) return this.transformMMDDYYYYtoYYYYMMDD(date);
        break;
      default:
        return false;
    }
  };

  /**
   * transform MMDDYYYY to YYYYMMDD
   * @param {string} mmddyyyy
   */


  /**
   * ttransform YYYYMMDD to MMDDYYYY
   * @param {string} yyyymmdd
   */


  /**
   * Compare dates: if startDate <= endDate return true
   * @param {string} startDate
   * @param {string} endDate
   */


  /**
   * Generate a list of years descending from current year to 1900
   * @returns {array} yearsOptions
   */


  Calendar.prototype.render = function render() {
    var _this2 = this;

    var cellNums = 42;
    var _state = this.state,
        now = _state.now,
        date = _state.date,
        startDate = _state.startDate,
        endDate = _state.endDate,
        currentMonthNum = _state.currentMonthNum,
        currentMonthName = _state.currentMonthName,
        currentYear = _state.currentYear,
        weekDays = _state.weekDays,
        errPopUp = _state.errPopUp,
        showErrorPopUp = _state.showErrorPopUp,
        checkTitleYear = _state.checkTitleYear,
        weekDaysArabic = _state.weekDaysArabic;
    var _props = this.props,
        type = _props.type,
        isArabic = _props.isArabic;

    var prevMonthAr = [];
    var thisMonthAr = [];
    var nextMonthAr = [];
    var monthDays = this.findDaysInMonth(currentMonthNum, currentYear);
    var thisMonthDate = new Date(currentYear + '-' + currentMonthNum);
    var thisMonth1Day = thisMonthDate.getDay();
    for (var i = 1; i <= monthDays; i += 1) {
      thisMonthAr.push(currentYear + '-' + currentMonthNum + '-' + i);
    }
    var prevMonthNum = currentMonthNum - 1;
    var prevYear = currentYear;
    if (currentMonthNum === 1) {
      prevYear = currentYear - 1;
      prevMonthNum = 12;
    }
    var prevMonthDays = this.findDaysInMonth(prevMonthNum, prevYear);
    var prevMonthShowDays = thisMonth1Day;
    for (var _i = prevMonthDays - prevMonthShowDays + 1; _i <= prevMonthDays; _i += 1) {
      prevMonthAr.push(prevYear + '-' + prevMonthNum + '-' + _i);
    }
    var nextMonthNum = currentMonthNum % 12 + 1;
    var nextYear = currentMonthNum === 12 ? currentYear + 1 : currentYear;
    var nextMonthShowDays = cellNums - prevMonthShowDays - monthDays;
    for (var _i2 = 1; _i2 <= nextMonthShowDays; _i2 += 1) {
      nextMonthAr.push(nextYear + '-' + nextMonthNum + '-' + _i2);
    }
    var titleYearClass = 'calendar-header-title-year-input ';
    if (!checkTitleYear) {
      titleYearClass += ' year--dange';
    }
    return _react2.default.createElement(
      'div',
      { className: 'calendar-wrapper' },
      _react2.default.createElement(
        'div',
        { className: 'calendar-header' },
        _react2.default.createElement(
          'div',
          {
            className: (0, _classnames2.default)('calendar-arrow', {
              'calendar-arrow--disable': this.state.disablePreviousMonthButton,
              'calendar-arrow--rotated': isArabic
            }),
            onClick: this.onClickLeftArrowCalendar,
            onKeyDown: function onKeyDown() {}
          },
          _react2.default.createElement(_Icon2.default, {
            icon: this.iconsStore.getIcon('arrow_left'),
            width: ARROW_WIDTH,
            color: this.getIconColor(this.state.disablePreviousMonthButton)
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'calendar-header-title' },
          _react2.default.createElement(
            'div',
            { className: 'calendar-header-title-month' },
            currentMonthName
          ),
          _react2.default.createElement(
            'div',
            { className: 'calendar-header-title-year' },
            _react2.default.createElement(_Select2.default, {
              className: titleYearClass,
              items: this.generateYears(),
              select: currentYear,
              onChange: this.onChangeTitleYear,
              placeholder: 'Year',
              noSearch: true,
              noIcon: true,
              reverse: isArabic
            })
          )
        ),
        _react2.default.createElement(
          'div',
          {
            className: (0, _classnames2.default)('calendar-arrow', {
              'calendar-arrow--disable': this.state.disableNextMonthButton,
              'calendar-arrow--rotated': isArabic
            }),
            onClick: this.onClickRightArrowCalendar,
            onKeyDown: function onKeyDown() {}
          },
          _react2.default.createElement(_Icon2.default, {
            icon: this.iconsStore.getIcon('arrow_right'),
            width: ARROW_WIDTH,
            color: this.getIconColor(this.state.disableNextMonthButton)
          })
        )
      ),
      _react2.default.createElement(
        'div',
        { className: 'calendar-body', onClick: this.onClickCalendar, onKeyDown: function onKeyDown() {} },
        _react2.default.createElement(
          'div',
          {
            className: showErrorPopUp ? 'calendar-error-popup-show' : 'calendar-error-popup-hide',
            onClick: this.onClickErrorPopUp,
            onKeyDown: function onKeyDown() {}
          },
          errPopUp
        ),
        _react2.default.createElement(
          'div',
          { className: 'calendar-week-days' },
          isArabic ? _react2.default.createElement(
            'div',
            { className: 'week-names' },
            weekDaysArabic.map(function (day) {
              return _react2.default.createElement(
                'div',
                { key: day, className: 'calendar-weekday calendar-weekday-arabic' },
                day
              );
            })
          ) : _react2.default.createElement(
            'div',
            { className: 'week-names' },
            weekDays.map(function (day) {
              return _react2.default.createElement(
                'div',
                { key: day, className: 'calendar-weekday' },
                day
              );
            })
          )
        ),
        isArabic ? _react2.default.createElement(
          'div',
          { className: 'calendar-days-arabic' },
          prevMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-arabic calendar-opacity-50 ';
            classDiv += _this2.createDayStyles({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate,
              isArabic: isArabic
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              _this2.toIndiaDigits(arr[2])
            );
          }),
          thisMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-arabic ';
            classDiv += _this2.createDayStyles({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate,
              isArabic: isArabic
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              _this2.toIndiaDigits(arr[2])
            );
          }),
          nextMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-arabic calendar-opacity-50 ';
            classDiv += _this2.createDayStyles({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate,
              isArabic: isArabic
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              _this2.toIndiaDigits(arr[2])
            );
          })
        ) : _react2.default.createElement(
          'div',
          { className: 'calendar-days' },
          prevMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day calendar-opacity-50 ';
            classDiv += _this2.createDayStyles({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              arr[2]
            );
          }),
          thisMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day ';
            classDiv += _this2.createDayStyles({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              arr[2]
            );
          }),
          nextMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day calendar-opacity-50 ';
            classDiv += _this2.createDayStyles({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              arr[2]
            );
          })
        )
      )
    );
  };

  return Calendar;
}(_react.Component);

Calendar.propTypes = process.env.NODE_ENV !== "production" ? {
  onChange: _propTypes2.default.func.isRequired,
  minDate: _propTypes2.default.string,
  maxDate: _propTypes2.default.string,
  date: _propTypes2.default.string,
  startDate: _propTypes2.default.string,
  endDate: _propTypes2.default.string,
  dateFormat: _propTypes2.default.oneOf(['yyyy-mm-dd', 'mm/dd/yyyy', '']),
  type: _propTypes2.default.oneOf(['range', 'single']),
  id: _propTypes2.default.string,
  isArabic: _propTypes2.default.bool
} : {};

Calendar.defaultProps = {
  minDate: '2015-01-01',
  maxDate: new Date().getFullYear() + 1 + '-12-31',
  startDate: '', // for type: range
  endDate: '', // for type: range
  date: '', // for type: single
  dateFormat: '',
  type: 'range',
  id: '', // add string id to calendar-cell-id
  isArabic: false
};

exports.default = (0, _WithDisplayName2.default)(Calendar, 'Calendar');
module.exports = exports['default'];
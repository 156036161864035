'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  @media only screen and (max-width: ', 'px) {\n    ', ';\n  }\n'], ['\n  @media only screen and (max-width: ', 'px) {\n    ', ';\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  @media only screen and (min-width: ', 'px) and (max-width: ', 'px) {\n    ', ';\n  }\n'], ['\n  @media only screen and (min-width: ', 'px) and (max-width: ', 'px) {\n    ', ';\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  @media only screen and (min-width: ', 'px) {\n    ', ';\n  }\n'], ['\n  @media only screen and (min-width: ', 'px) {\n    ', ';\n  }\n']);

var _styledComponents = require('styled-components');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var sizes = {
  maxTablet: 1023,
  maxPhone: 767
};

// create a media template
var media = {};
media.phone = function () {
  return (0, _styledComponents.css)(_templateObject, sizes.maxPhone, _styledComponents.css.apply(undefined, arguments));
};
media.tablet = function () {
  return (0, _styledComponents.css)(_templateObject2, sizes.maxPhone + 1, sizes.maxTablet, _styledComponents.css.apply(undefined, arguments));
};
media.desktop = function () {
  return (0, _styledComponents.css)(_templateObject3, sizes.maxTablet + 1, _styledComponents.css.apply(undefined, arguments));
};

exports.default = media;
module.exports = exports['default'];
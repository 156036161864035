'use strict';

exports.__esModule = true;

var _class, _temp2; /* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Separator = require('../separator/Separator');

var _Separator2 = _interopRequireDefault(_Separator);

var _Colors = require('../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

var _Item = require('./Item.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Item = (_temp2 = _class = function (_Component) {
  _inherits(Item, _Component);

  function Item() {
    var _temp, _this, _ret;

    _classCallCheck(this, Item);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      iconColor: _Colors2.default.defaultGrey
    }, _this.onHover = function () {
      _this.setState({
        iconColor: '#fff'
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Item.prototype.render = function render() {
    var _props = this.props,
        icon = _props.icon,
        image = _props.image,
        text = _props.text,
        disabled = _props.disabled,
        component = _props.component,
        isDivider = _props.isDivider,
        isHighlighted = _props.isHighlighted,
        options = _props.options;
    var _onClick = this.props.onClick;
    var iconColor = this.state.iconColor;

    var isItemClickable = _onClick;

    // Add empty arrow function to prevent passing `false` to onClick
    _onClick = _onClick && !disabled ? _onClick : function () {};

    if (isDivider) {
      return _react2.default.createElement(_Separator2.default, null);
    }

    var renderItemIconImage = function renderItemIconImage() {
      if (icon || image) {
        return _react2.default.createElement(
          'div',
          { className: 'icon--container center' },
          icon && _react2.default.createElement(_Icon2.default, { icon: icon, width: 15, color: iconColor }),
          image && _react2.default.createElement('img', { src: image, alt: 'desc', width: '15px' })
        );
      }
    };

    var renderOptions = function renderOptions() {
      var renderedOptionsList = [];
      if (options && options.length) {
        options.forEach(function (option) {
          renderedOptionsList.push(_react2.default.createElement(
            _Item.ItemContainer,
            { onClick: function onClick() {
                return _onClick();
              } },
            _react2.default.createElement(
              'div',
              { key: option.key },
              _react2.default.createElement(
                'div',
                {
                  style: {
                    display: 'flex',
                    justifyContent: 'space-between'
                  }
                },
                _react2.default.createElement(
                  'p',
                  null,
                  option.name
                ),
                option.isSelected && _react2.default.createElement(_Icon2.default, {
                  icon: new _IconsStore2.default(_webIcons2.default).getIcon('success'),
                  width: 9,
                  color: '#0070cd'
                })
              )
            )
          ));
        });
      }
      return renderedOptionsList;
    };

    var onClickItem = function onClickItem() {
      if (options && options.length) {
        document.getElementById('options-list--container').toggle('expanded');
      } else {
        _onClick();
      }
    };

    return _react2.default.createElement(
      _Item.ItemsListContainer,
      { hasSubList: options.length > 0 },
      _react2.default.createElement(
        'li',
        {
          className: (0, _classnames2.default)('item', {
            'item--disabled': disabled,
            'item--not-clickable': !isItemClickable,
            'item--grid': !component,
            'item--highlighted': isHighlighted
          }),
          onClick: function onClick() {
            return onClickItem();
          }
          // onKeyDown={() => onClickItem()}
        },
        component || _react2.default.createElement(
          _react.Fragment,
          null,
          renderItemIconImage(),
          _react2.default.createElement(
            _Text2.default,
            { className: 'item--text' },
            text
          )
        )
      ),
      _react2.default.createElement(
        'div',
        {
          id: 'options-list--container',
          className: 'collapsed',
          style: {
            backgroundColor: 'rgba(0, 112, 205, 0.05)',
            boxShadow: 'inset 0px 1px 0px 1px #e9e6e657'
          }
        },
        renderOptions()
      )
    );
  };

  return Item;
}(_react.Component), _class.defaultProps = {
  icon: undefined,
  image: undefined,
  text: undefined,
  isDivider: false,
  disabled: false,
  onClick: undefined,
  isHighlighted: false,
  options: []
}, _temp2);
Item.propTypes = process.env.NODE_ENV !== "production" ? {
  icon: _Icon.iconPropTypes,
  image: _propTypes2.default.string,
  text: _propTypes2.default.string,
  onClick: _propTypes2.default.func,
  disabled: _propTypes2.default.bool,
  component: _propTypes2.default.any, // eslint-disable-line
  isDivider: _propTypes2.default.bool,
  isHighlighted: _propTypes2.default.bool,
  options: _propTypes2.default.array
} : {};
exports.default = Item;
module.exports = exports['default'];
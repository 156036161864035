'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n  width: 100%;\n  position: relative;\n  flex-direction: column;\n'], ['\n  ', ';\n  width: 100%;\n  position: relative;\n  flex-direction: column;\n']);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Container = _gridStyled.Flex.extend(_templateObject, _styledSystem.height);

Container.propTypes = {
  height: _propTypes2.default.number
};

Container.defaultProps = {
  height: 300
};

exports.default = Container;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  height: 60px;\n  display: ', ';\n  ', ';\n'], ['\n  height: 60px;\n  display: ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  cursor: pointer;\n  transition: opacity 150ms ease;\n  width: 134px;\n'], ['\n  cursor: pointer;\n  transition: opacity 150ms ease;\n  width: 134px;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var HeaderContainer = _gridStyled.Flex.extend(_templateObject, function (props) {
  return props.hideHeader ? 'none' : 'flex';
}, _styledSystem.space);

var Logo = _styledComponents2.default.img(_templateObject2);

var Header = function Header(_ref) {
  var onLogoClick = _ref.onLogoClick,
      logoUrl = _ref.logoUrl,
      items = _ref.items,
      hideHeader = _ref.hideHeader,
      props = _objectWithoutProperties(_ref, ['onLogoClick', 'logoUrl', 'items', 'hideHeader']);

  return _react2.default.createElement(
    HeaderContainer,
    _extends({
      hideHeader: hideHeader,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 1,
      bg: _Colors.COLORS.PRIMARY_BLUE
    }, props),
    _react2.default.createElement(Logo, { src: logoUrl, alt: 'logo', onClick: onLogoClick, onKeyDown: function onKeyDown() {}, role: 'button' }),
    _react2.default.createElement(
      _gridStyled.Flex,
      { alignItems: 'center', justifyContent: 'flex-end' },
      items
    )
  );
};

Header.propTypes = process.env.NODE_ENV !== "production" ? {
  items: _propTypes2.default.node,
  logoUrl: _propTypes2.default.string.isRequired,
  onLogoClick: _propTypes2.default.func,
  hideHeader: _propTypes2.default.bool
} : {};

Header.defaultProps = {
  items: [],
  onLogoClick: function onLogoClick() {}
};

exports.default = Header;
module.exports = exports['default'];
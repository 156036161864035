'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n\n  object-fit: ', ';\n'], ['\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n\n  object-fit: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  border-radius: 50%;\n  width: ', 'px;\n  min-width: ', 'px;\n  height: ', 'px;\n  min-height: ', 'px;\n'], ['\n  border-radius: 50%;\n  width: ', 'px;\n  min-width: ', 'px;\n  height: ', 'px;\n  min-height: ', 'px;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  font-size: ', 'px;\n  text-transform: uppercase;\n  letter-spacing: 0.5px;\n  ', ';\n'], ['\n  font-size: ', 'px;\n  text-transform: uppercase;\n  letter-spacing: 0.5px;\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var StyledImage = _styledComponents2.default.img(_templateObject, _styledSystem.width, _styledSystem.height, _styledSystem.space, _styledSystem.minHeight, _styledSystem.minWidth, _styledSystem.maxHeight, _styledSystem.maxWidth, _styledSystem.borderRadius, function (props) {
  return props.objectFit;
});

var TextContainer = _gridStyled.Flex.extend(_templateObject2, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
});

var DisplayText = (0, _styledComponents2.default)(_Text2.default)(_templateObject3, function (props) {
  return props.fontSize;
}, _styledSystem.space);

var Image = (_temp2 = _class = function (_PureComponent) {
  _inherits(Image, _PureComponent);

  function Image() {
    var _temp, _this, _ret;

    _classCallCheck(this, Image);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _PureComponent.call.apply(_PureComponent, [this].concat(args))), _this), _this.state = {
      showImage: _this.props.src,
      src: _this.props.src
    }, _this.onError = function () {
      _this.setState({
        showImage: false
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Image.prototype.render = function render() {
    var _props = this.props,
        name = _props.name,
        alt = _props.alt,
        radius = _props.radius,
        textColor = _props.textColor,
        bgColor = _props.bgColor,
        objectFit = _props.objectFit,
        borderRadius = _props.borderRadius,
        filteredProps = _objectWithoutProperties(_props, ['name', 'alt', 'radius', 'textColor', 'bgColor', 'objectFit', 'borderRadius']);

    var _state = this.state,
        showImage = _state.showImage,
        src = _state.src;

    var matches = [];
    var displayName = void 0;
    var fontSize = (radius / 2.5).toFixed();

    if (!showImage && name) {
      matches = name.match(/\b(\w)/g);
      if (matches) {
        if (matches.length === 1) {
          displayName = '' + matches[0];
        } else {
          displayName = '' + matches[0] + matches[1];
        }
      }
    }

    return _react2.default.createElement(
      _react2.default.Fragment,
      null,
      _react2.default.createElement('img', { src: src, onError: this.onError, style: { display: 'none' }, alt: 'none' }),
      showImage ? _react2.default.createElement(StyledImage, _extends({
        alt: alt || name,
        src: src,
        width: radius,
        height: radius,
        minWidth: radius,
        minHeight: radius,
        maxWidth: radius,
        maxHeight: radius,
        borderRadius: this.props.borderRadius,
        objectFit: objectFit
      }, filteredProps)) : _react2.default.createElement(
        TextContainer,
        _extends({
          radius: radius,
          bg: bgColor,
          justifyContent: 'center',
          alignItems: 'center'
        }, filteredProps),
        _react2.default.createElement(
          DisplayText,
          { fontSize: fontSize, color: textColor },
          displayName
        )
      )
    );
  };

  return Image;
}(_react.PureComponent), _class.defaultProps = {
  src: undefined,
  alt: undefined,
  name: '',
  radius: 40,
  textColor: _Colors.COLORS.PRIMARY_BLUE,
  bgColor: _Colors.COLORS.DISPLAY_NAME_BACKGROUND,
  borderRadius: '50%',
  objectFit: 'cover'
}, _class.getDerivedStateFromProps = function (props, state) {
  if (props.src !== state.src) {
    return {
      showImage: props.src,
      src: props.src
    };
  }

  return null;
}, _temp2);
Image.propTypes = process.env.NODE_ENV !== "production" ? {
  src: _propTypes2.default.string,
  alt: _propTypes2.default.string,
  name: _propTypes2.default.string,
  radius: _propTypes2.default.number,
  textColor: _propTypes2.default.string,
  bgColor: _propTypes2.default.string,
  borderRadius: _propTypes2.default.string,
  objectFit: _propTypes2.default.string
} : {};
exports.default = Image;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames2 = require('classnames');

var _classnames3 = _interopRequireDefault(_classnames2);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./Tooltip.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TOP = 'top';
var BOTTOM = 'bottom';
var LEFT = 'left';
var RIGHT = 'right';

var Tooltip = (_temp2 = _class = function (_Component) {
  _inherits(Tooltip, _Component);

  function Tooltip() {
    var _temp, _this, _ret;

    _classCallCheck(this, Tooltip);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      show: _this.props.show
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Tooltip.prototype.render = function render() {
    var _classnames;

    var _props = this.props,
        position = _props.position,
        children = _props.children,
        arrowPosition = _props.arrowPosition,
        message = _props.message,
        className = _props.className,
        showOnHover = _props.showOnHover;
    var show = this.state.show;


    return _react2.default.createElement(
      'div',
      {
        className: (0, _classnames3.default)('tooltip', className, (_classnames = {}, _classnames['tooltip--' + position] = true, _classnames), {
          'tooltip--show': show
        }, {
          'tooltip--toggler': showOnHover
        })
      },
      _react2.default.createElement(
        'div',
        null,
        children
      ),
      _react2.default.createElement(
        _Text2.default,
        { className: 'tooltip--message arrow--' + arrowPosition },
        message
      )
    );
  };

  return Tooltip;
}(_react.Component), _class.defaultProps = {
  position: BOTTOM,
  arrowPosition: TOP,
  className: '',
  show: false,
  showOnHover: false
}, _temp2);
Tooltip.propTypes = process.env.NODE_ENV !== "production" ? {
  message: _propTypes2.default.string.isRequired,
  position: _propTypes2.default.oneOf([TOP, BOTTOM, RIGHT, LEFT]),
  arrowPosition: _propTypes2.default.oneOf([TOP, BOTTOM, RIGHT, LEFT]),
  show: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  children: _propTypes2.default.node.isRequired,
  showOnHover: _propTypes2.default.bool
} : {};
exports.default = (0, _WithDisplayName2.default)(Tooltip, 'Tooltip');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
exports.renderDropDownList = exports.renderSelectedOptions = exports.dropDownListTogglerBySearchOptions = exports.dropDownListToggler = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DropDown = require('./DropDown.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var dropDownListToggler = exports.dropDownListToggler = function dropDownListToggler(optionsList, listId) {
  if (optionsList && optionsList.length) {
    var list = document.getElementById(listId);
    if (list.style.display === 'none') {
      list.style.display = 'block';
    } else {
      list.style.display = 'none';
    }
  }
};

var dropDownListTogglerBySearchOptions = exports.dropDownListTogglerBySearchOptions = function dropDownListTogglerBySearchOptions(optionsList, listId) {
  if (optionsList && optionsList.length) {
    var list = document.getElementById(listId);
    if (list.style.display === 'none') {
      list.style.display = 'block';
    }
  }
};

var renderSelectedOptions = exports.renderSelectedOptions = function renderSelectedOptions(selectedOptionsList, isMultipleSelection) {
  if (selectedOptionsList.length) {
    if (isMultipleSelection) {
      var firstTwoItems = selectedOptionsList.slice(0, 3);
      var composedValue = '';
      firstTwoItems.forEach(function (item) {
        if (!composedValue) {
          composedValue = item.fieldValue;
        } else {
          composedValue += ', ' + item.fieldValue;
        }
      });
      composedValue += selectedOptionsList.length > 3 ? ' +' + (selectedOptionsList.length - 3) : '';
      return composedValue;
    }
    return selectedOptionsList[0].fieldValue;
  }
};

var renderDropDownList = exports.renderDropDownList = function renderDropDownList(optionsList, isMultipleSelection, callback, extendedStyle, componentName, language) {
  var mappedOptions = [];

  if (optionsList && optionsList.length) {
    mappedOptions = optionsList.map(function (option) {
      return _react2.default.createElement(
        _DropDown.ListItem,
        {
          extendDropDownListItem: extendedStyle.extendDropDownListItem,
          onClick: function onClick() {
            if (option.onClickItemAction) {
              option.onClickItemAction();
              dropDownListToggler(optionsList, 'dropdown-list-' + componentName);
            } else {
              callback(option);
            }
          },
          isMultipleSelection: isMultipleSelection
        },
        _react2.default.createElement(
          _DropDown.ItemContentContainer,
          null,
          isMultipleSelection && _react2.default.createElement(_DropDown.CheckBox, { type: 'checkbox', checked: option.isChecked }),
          _react2.default.createElement(
            'div',
            { style: { margin: '0px 0px', display: 'flex', flexDirection: 'column' } },
            _react2.default.createElement(
              _DropDown.ItemLabel,
              null,
              option.fieldValue
            ),
            option.description && _react2.default.createElement(
              _DropDown.ItemDescription,
              { language: language },
              option.description
            )
          )
        )
      );
    });
  }

  return mappedOptions;
};
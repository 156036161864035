'use strict';

exports.__esModule = true;

var _FONT_SIZES, _LINE_HEIGHTS;

var FONT_TYPES = exports.FONT_TYPES = {
  MEGA_TITLE: 'megaTitle',
  SUPER_TITLE: 'superTitle',
  TITLE: 'title',
  HEADING: 'heading',
  SUBHEADING: 'subheading',
  BODY: 'body',
  CAPTION: 'caption'
};

var FONT_SIZES = exports.FONT_SIZES = (_FONT_SIZES = {}, _FONT_SIZES[FONT_TYPES.MEGA_TITLE] = ['70px', '70px', '70px', '70px'], _FONT_SIZES[FONT_TYPES.SUPER_TITLE] = ['30px', '30px', '30px', '30px'], _FONT_SIZES[FONT_TYPES.TITLE] = ['18px', '20px', '21px', '21px'], _FONT_SIZES[FONT_TYPES.HEADING] = ['19px', '19px', '18px', '18px'], _FONT_SIZES[FONT_TYPES.SUBHEADING] = ['16px', '16px', '17px', '17px'], _FONT_SIZES[FONT_TYPES.BODY] = ['15px', '15px', '14px', '13px'], _FONT_SIZES[FONT_TYPES.CAPTION] = ['13px', '13px', '12px', '12px'], _FONT_SIZES);

var FONT_WEIGHTS = exports.FONT_WEIGHTS = {
  SEMI_BOLD: 600,
  NORMAL: 500,
  LIGHT: 400
};

var LINE_HEIGHTS = exports.LINE_HEIGHTS = (_LINE_HEIGHTS = {}, _LINE_HEIGHTS[FONT_TYPES.MEGA_TITLE] = '74px', _LINE_HEIGHTS[FONT_TYPES.SUPER_TITLE] = '34px', _LINE_HEIGHTS[FONT_TYPES.TITLE] = '24px', _LINE_HEIGHTS[FONT_TYPES.HEADING] = '20px', _LINE_HEIGHTS[FONT_TYPES.SUBHEADING] = '18px', _LINE_HEIGHTS[FONT_TYPES.BODY] = '16px', _LINE_HEIGHTS[FONT_TYPES.CAPTION] = '14px', _LINE_HEIGHTS);
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp, _initialiseProps;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Checkbox = require('../checkbox/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./CheckboxList.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CheckboxList = (_temp = _class = function (_Component) {
  _inherits(CheckboxList, _Component);

  function CheckboxList(props) {
    _classCallCheck(this, CheckboxList);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _initialiseProps.call(_this);

    var _this$props = _this.props,
        items = _this$props.items,
        selectedIds = _this$props.selectedIds,
        isAllSelected = _this$props.isAllSelected;

    _this.state = {
      isAllSelected: isAllSelected,
      selectedIds: isAllSelected ? _this.getSelectableIds(items) : selectedIds || []
    };
    return _this;
  }

  /**
   * Updates state isAllSelected and selectedIds values based on received props
   * @param {object} nextProps
   */


  CheckboxList.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    var _this2 = this;

    if (!_lodash2.default.isEqual(this.props.items, nextProps.items) || this.props.isAllSelected !== nextProps.isAllSelected) {
      this.setState(function (state) {
        var nextState = _extends({}, state);

        nextState.isAllSelected = nextProps.isAllSelected || state.isAllSelected;
        nextState.selectedIds = nextState.isAllSelected ? _this2.getSelectableIds(nextProps.items) : nextProps.selectedIds || state.selectedIds.filter(function (id) {
          return _this2.getSelectableIds(nextProps.items).includes(id);
        });

        return nextState;
      });
    }
  };

  /**
   * Gets all selectable ids of items
   * @param {array} items
   * @returns {array} selectable ids
   */


  /**
   * Gets selected ids values with updated checkbox value
   * @param {object} changedItem
   * @param {bool} isChecked
   * @returns {array} selected ids
   */


  /**
   * Handles checkbox change
   * @param {object} changedItem
   * @param {bool} isChecked
   */


  CheckboxList.prototype.render = function render() {
    var _this3 = this;

    var _state = this.state,
        isAllSelected = _state.isAllSelected,
        selectedIds = _state.selectedIds;
    var _props = this.props,
        items = _props.items,
        className = _props.className,
        renderAsRows = _props.renderAsRows,
        isArabic = _props.isArabic,
        isFilterChanged = _props.isFilterChanged;

    var displayedItems = void 0;
    if (!isFilterChanged) {
      if (isAllSelected) {
        displayedItems = items.map(function (item) {
          return _extends({}, item, { isChecked: true });
        });
      } else {
        displayedItems = items.map(function (item) {
          return selectedIds.includes(item.id) ? _extends({}, item, { isChecked: true }) : _extends({}, item, { isChecked: false });
        });
      }
    } else {
      displayedItems = items.map(function (item) {
        return _extends({}, item, { isChecked: false });
      });
    }

    if (this.props.hideNotSelected) {
      displayedItems = displayedItems.filter(function (item) {
        return item.isChecked;
      });
    }
    return _react2.default.createElement(
      'div',
      {
        className: (0, _classnames2.default)('checkbox-list-container', className, {
          'checkbox-list--row': renderAsRows,
          'checkbox-list--arabic': isArabic
        })
      },
      displayedItems.map(function (item) {
        return _react2.default.createElement(_Checkbox2.default, {
          key: item.id || item.label,
          label: item.label,
          isChecked: item.isChecked,
          onChange: function onChange(isChecked) {
            return _this3.handleCheckboxChange(item, isChecked);
          },
          htmlFor: item.id
        });
      })
    );
  };

  return CheckboxList;
}(_react.Component), _initialiseProps = function _initialiseProps() {
  var _this4 = this;

  this.getSelectableIds = function (items) {
    return items.filter(function (item) {
      return !item.isAllCheckbox;
    }).map(function (item) {
      return item.id;
    });
  };

  this.getSelectedIds = function (changedItem, isChecked) {
    if (changedItem.isAllCheckbox) {
      if (isChecked) {
        return _this4.getSelectableIds(_this4.props.items);
      }
      return [];
    }

    if (isChecked) {
      if (_this4.state.isAllSelected) {
        return [changedItem.id];
      }
      return _this4.state.selectedIds.concat(changedItem.id);
    }
    return _this4.state.selectedIds.filter(function (id) {
      return id !== changedItem.id;
    });
  };

  this.handleCheckboxChange = function (changedItem, isChecked) {
    var selectableIds = _this4.getSelectableIds(_this4.props.items);
    var selectedIds = _this4.getSelectedIds(changedItem, isChecked);
    var isAllIdsSelected = selectableIds.every(function (id) {
      return selectedIds.includes(id);
    });
    var isAllSelected = changedItem.isAllCheckbox ? isChecked : isAllIdsSelected;

    _this4.setState({
      isAllSelected: isAllSelected,
      selectedIds: selectedIds
    }, function () {
      if (_this4.props.onChange) {
        _this4.props.onChange(selectedIds);
      }
    });
  };
}, _temp);


CheckboxList.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  items: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    label: _propTypes2.default.string,
    isChecked: _propTypes2.default.bool,
    id: _propTypes2.default.string,
    isAllCheckbox: _propTypes2.default.bool
  })).isRequired,
  selectedIds: _propTypes2.default.arrayOf(_propTypes2.default.string),
  onChange: _propTypes2.default.func,
  hideNotSelected: _propTypes2.default.bool,
  isAllSelected: _propTypes2.default.bool,
  renderAsRows: _propTypes2.default.bool,
  isArabic: _propTypes2.default.bool,
  isFilterChanged: _propTypes2.default.bool
} : {};

CheckboxList.defaultProps = {
  className: undefined,
  selectedIds: [],
  onChange: function onChange() {},
  hideNotSelected: false,
  isAllSelected: false,
  renderAsRows: false,
  isArabic: false,
  isFilterChanged: false
};

exports.default = (0, _WithDisplayName2.default)(CheckboxList, 'CheckboxList');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icon = require('../../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _InputField = require('./InputField.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable react/forbid-prop-types */


var InputField = function InputField(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var fieldLabel = props.fieldLabel,
      componentName = props.componentName,
      fieldValue = props.fieldValue,
      fieldTag = props.fieldTag,
      extendDropDownStyle = props.extendDropDownStyle,
      extendFieldText = props.extendFieldText,
      icon = props.icon,
      iconSize = props.iconSize,
      onChange = props.onChange,
      validation = props.validation,
      isDisabled = props.isDisabled,
      isRequired = props.isRequired,
      range = props.range,
      isValid = props.isValid,
      isPassword = props.isPassword,
      placeHolder = props.placeHolder;

  var _useState = (0, _react.useState)(true),
      isFocued = _useState[0],
      setFocus = _useState[1];

  var onChangeAndValidation = function onChangeAndValidation(inputedValue) {
    var isValidInput = true;
    if (validation && validation.length) {
      validation.forEach(function (check) {
        isValidInput = isValidInput && check.regex.test(inputedValue);
      });
    }
    if (range && range.length) {
      isValidInput = isValidInput && !(Number(inputedValue) < 0 || Number(inputedValue) > 100);
    }
    if (isValidInput || !inputedValue) {
      if (onChange) onChange(inputedValue);
    }
  };

  var getFocusOnInputField = function getFocusOnInputField() {
    setFocus(true);
    if (document.getElementById('input-container-' + componentName)) {
      document.getElementById('input-container-' + componentName).focus();
    }
  };

  return _react2.default.createElement(
    'div',
    { style: { position: 'relative' }, key: componentName },
    _react2.default.createElement(_InputField.DisableOverLay, { extendDropDownStyle: extendDropDownStyle, isDisabled: isDisabled }),
    _react2.default.createElement(
      _InputField.FieldContainer,
      {
        extendDropDownStyle: extendDropDownStyle,
        className: 'input-' + componentName,
        onClick: function onClick() {
          return getFocusOnInputField();
        },
        isValid: isValid
      },
      _react2.default.createElement(
        'div',
        {
          style: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }
        },
        fieldLabel && _react2.default.createElement(
          _InputField.FieldLabel,
          { isFocused: isFocued },
          fieldLabel,
          isRequired && _react2.default.createElement(
            _InputField.IsRequiredNote,
            null,
            '*'
          )
        ),
        isFocued && _react2.default.createElement(
          _InputField.ValueTagContainer,
          null,
          _react2.default.createElement(
            _InputField.FieldTag,
            null,
            fieldTag
          ),
          isFocued && _react2.default.createElement(_InputField.SearchInput, {
            autoComplete: 'off',
            placeholder: placeHolder,
            id: 'input-container-' + componentName,
            type: isPassword ? 'password' : 'text',
            value: fieldValue,
            onChange: function onChange(e) {
              onChangeAndValidation(e.target.value);
            },
            extendFieldText: extendFieldText
          })
        )
      ),
      icon && _react2.default.createElement(_Icon2.default, { className: 'icon', icon: _IconsStore2.default.getIcon(icon), width: iconSize })
    )
  );
};

InputField.propTypes = process.env.NODE_ENV !== "production" ? {
  placeHolder: _propTypes2.default.string,
  fieldValue: _propTypes2.default.string,
  fieldLabel: _propTypes2.default.string.isRequired,
  componentName: _propTypes2.default.string,
  fieldTag: _propTypes2.default.string,
  icon: _propTypes2.default.string,
  onChange: _propTypes2.default.func.isRequired,
  iconSize: _propTypes2.default.number,
  extendDropDownStyle: _propTypes2.default.string,
  validation: _propTypes2.default.array,
  isDisabled: _propTypes2.default.bool,
  isRequired: _propTypes2.default.bool,
  range: _propTypes2.default.array,
  isValid: _propTypes2.default.bool,
  isPassword: _propTypes2.default.bool,
  extendFieldText: _propTypes2.default.string
} : {};
exports.default = InputField;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n'], ['\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n  ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  margin: ', ';\n  margin-right: ', ';\n  line-height: 20px;\n'], ['\n  margin: ', ';\n  margin-right: ', ';\n  line-height: 20px;\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  ', ';\n  cursor: pointer;\n  position: relative;\n  right: 0;\n  transition: -webkit-transform 200ms ease-in-out;\n  transition: transform 200ms ease-in-out;\n  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;\n  &.icon--up {\n    transform: rotate(180deg);\n  }\n  svg {\n    margin-right: 0;\n  }\n'], ['\n  ', ';\n  cursor: pointer;\n  position: relative;\n  right: 0;\n  transition: -webkit-transform 200ms ease-in-out;\n  transition: transform 200ms ease-in-out;\n  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;\n  &.icon--up {\n    transform: rotate(180deg);\n  }\n  svg {\n    margin-right: 0;\n  }\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  margin: ', ';\n'], ['\n  margin: ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Image = require('../image/Image');

var _Image2 = _interopRequireDefault(_Image);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _MenuItem = require('./MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _Search = require('../search/Search');

var _Search2 = _interopRequireDefault(_Search);

var _Tag = require('../tag/Tag');

var _Tag2 = _interopRequireDefault(_Tag);

var _TagsContainer = require('./TagsContainer');

var _TagsContainer2 = _interopRequireDefault(_TagsContainer);

var _Separator = require('../separator/Separator');

var _Separator2 = _interopRequireDefault(_Separator);

var _SelectUtils = require('./SelectUtils');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Colors = require('../base/Colors');

var _Colors2 = require('../shared/Colors');

var _Colors3 = _interopRequireDefault(_Colors2);

require('./Select.css');

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var SELECT_ALL = 'SELECT_ALL';
var UP_ARROW = 38;
var DOWN_ARROW = 40;
var ENTER = 13;
var ESCAPE = 27;
var SUPPORTED_SHORTCUTS = [UP_ARROW, DOWN_ARROW, ENTER, ESCAPE];

var NEXT = 0;
var PREVIOUS = 1;

// TODO: Separate the menu item and menu list into component
// TODO: Migrate the new `MenuItem` and `MenuList` into `Search`

var MenuList = _styledComponents2.default.ul(_templateObject, function (props) {
  return props.extendComboboxMenuList ? props.extendComboboxMenuList : '';
});

var SelectContainer = _styledComponents2.default.div(_templateObject2, _styledSystem.width, _styledSystem.space, function (props) {
  return props.extendSelect ? props.extendSelect : '';
});

var InputContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.extendInputContainer ? props.extendInputContainer : '';
});

var Input = _styledComponents2.default.input(_templateObject3, function (props) {
  return props.reverse ? '0 0 0 ' + (props.small ? '16px' : '24px') : '0 ' + (props.small ? '16px' : '24px') + ' 0 0';
}, function (props) {
  return props.reverse ? '0px !important' : (props.small ? '16px' : '24px') + ' !important';
});

var IconContainer = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject4, function (props) {
  return props.hideArrowIcon && 'display: none';
});

var SelectIcon = (0, _styledComponents2.default)(_Icon2.default)(_templateObject5, function (props) {
  return props.reverse ? '0 0 0 12px' : '0 12px 0 0';
});

var Select = (_temp2 = _class = function (_Component) {
  _inherits(Select, _Component);

  function Select() {
    var _temp, _this, _ret;

    _classCallCheck(this, Select);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      list: new Map(),
      prevList: new Map(),
      fieldValue: new Map(),
      fieldImage: undefined,
      select: undefined,
      value: new Map(),
      isOpen: false,
      isDanger: false,
      errorMessage: (0, _SelectUtils.getErrorMessage)(_this.props),
      currentFocusedItemKey: undefined,
      currentFocusedItemIndex: -1,
      isFocusedByMouse: false,
      searchValue: '',
      isAllSelected: false,
      isAllItemsDisabled: false
    }, _this.componentDidMount = function () {
      document.addEventListener('mousedown', _this.onBlur);
      var list = _this.state.list;
      var _this$props = _this.props,
          select = _this$props.select,
          selectFirst = _this$props.selectFirst;

      _this.originalList = new Map(list);

      if (select !== null && select !== undefined && list && list.size !== 0) {
        _this.selectItem(select);
      }

      if (selectFirst && list && list.size !== 0) {
        _this.selectFirstItem();
      }
    }, _this.componentDidUpdate = function (prevProps, prevState) {
      var _this$state = _this.state,
          list = _this$state.list,
          value = _this$state.value,
          select = _this$state.select,
          searchValue = _this$state.searchValue;
      var _this$props2 = _this.props,
          allowCustomChoice = _this$props2.allowCustomChoice,
          allowSelectAll = _this$props2.allowSelectAll,
          selectFirst = _this$props2.selectFirst,
          items = _this$props2.items,
          multiple = _this$props2.multiple;

      var prevItems = (0, _SelectUtils.itemsAdapter)(prevProps.items);
      var nextItems = (0, _SelectUtils.itemsAdapter)(items);
      var prevSelect = prevProps.select;
      var nextSelect = _this.props.select;
      var firstItem = Array.from(list.values())[0];
      var firsItemValue = firstItem && firstItem.value;
      var isItemsChanged = !(0, _SelectUtils.isListsEqual)(prevItems, nextItems);

      // Comparing the current and the new items
      if (!(0, _SelectUtils.isListsEqual)(prevItems, nextItems)) {
        // Update originalList
        _this.originalList = new Map(list);
      }

      // Disable any operations if specific values changed
      if (prevState.isOpen !== _this.state.isOpen || prevState.value !== _this.state.value || prevState.fieldValue !== _this.state.fieldValue || prevState.fieldImage !== _this.state.fieldImage || prevState.isDanger !== _this.state.isDanger || prevState.currentFocusedItemIndex !== _this.state.currentFocusedItemIndex || prevState.currentFocusedItemKey !== _this.state.currentFocusedItemKey || prevState.searchValue !== _this.state.searchValue) return;

      // Update originalList
      _this.originalList = new Map(list);

      // If allowCustomChoice enabled, we inject the item in the top of the list
      if (allowCustomChoice && !list.has('custom') && searchValue !== '') {
        _this.injectCustomItem(_this.renderCustomInputItem());
      }

      // If allowSelectAll enabled, we inject the item in the top of the list
      if (allowSelectAll && !list.has(SELECT_ALL) && list.size !== 0) {
        _this.injectCustomItem(_this.renderSelectAllItem());
      }

      if (!allowCustomChoice && !selectFirst && nextSelect !== null && nextSelect !== undefined && (!_this.isItemsEqual(nextSelect, multiple ? Array.from(value.values()) : value.get(nextSelect)) && !_this.isItemsEqual(nextSelect, prevSelect) || isItemsChanged)) {
        _this.selectItem(nextSelect);
      }

      // To support @deprecated select() method
      if (!_this.isItemsEqual(select, value.get(select)) && select) {
        _this.selectItem(select);
      }

      if (selectFirst) {
        if (!_this.isItemsEqual(value, firsItemValue) && isItemsChanged) {
          _this.selectFirstItem();
        }
      }
    }, _this.onClick = function () {
      var disabled = _this.props.disabled;

      // If field disabled don't open/close the menu

      if (!disabled) {
        _this.setState(function (prevState) {
          return {
            isOpen: !prevState.isOpen
          };
        });
      }
    }, _this.onBlur = function (event) {
      var isOpen = _this.state.isOpen;

      var isVisibleScrollBar = window.innerWidth > document.documentElement.clientWidth;
      var isClickOnScrollBar = window.outerWidth - 18 <= event.x;

      // Only enter if the menu is closed and the click isn't inside the field or the menu
      if (_this.selectContainer && !_this.selectContainer.contains(event.target) && (!isVisibleScrollBar || isVisibleScrollBar && !isClickOnScrollBar) && isOpen) {
        // Validate field
        _this.validate();

        _this.setState({
          isOpen: false,
          currentFocusedItemIndex: -1,
          currentFocusedItemKey: undefined,
          isFocusedByMouse: false
        });
      }
    }, _this.onSelect = function (selection) {
      var _this$props3 = _this.props,
          multiple = _this$props3.multiple,
          allowSelectAll = _this$props3.allowSelectAll,
          allowCustomChoice = _this$props3.allowCustomChoice;
      var _this$state2 = _this.state,
          list = _this$state2.list,
          prevList = _this$state2.prevList,
          isAllSelected = _this$state2.isAllSelected,
          isDanger = _this$state2.isDanger;

      var items = [];
      var isSelectingAll = false;
      var lockMultipleClear = false;

      if (Array.isArray(selection)) {
        // This case only happens when passing an array as `select` prop
        items = selection;
        lockMultipleClear = true;
      } else {
        var selectionValue = selection.value;

        if (selectionValue === SELECT_ALL) {
          // Handles select all option
          items = Array.from(list.values());
          isSelectingAll = true;
        } else {
          // Handles single selection
          items.push(selection);
        }
      }

      _this.setState(function (prevState) {
        var newValues = void 0;
        var newFieldValues = void 0;
        var newFieldImage = void 0;
        var newIsAllSelected = isAllSelected;
        var enabledItemsSize = 0;
        var lastFocusedItemKey = void 0;
        var lastFocusedItemIndex = -1;

        if (!multiple) {
          // If not multiple select, clear current selected items
          newValues = new Map();
          newFieldValues = new Map();
        } else {
          // If multiple select, add to the previous values
          newValues = prevState.value;
          newFieldValues = prevState.fieldValue;
        }

        if (multiple) {
          prevList.forEach(function (item) {
            if (!item) return;
            var itemKey = item.itemKey,
                disabled = item.disabled;

            if (itemKey === SELECT_ALL || disabled) return;
            enabledItemsSize += 1;
          });
        }

        // Get value and fieldValue from each item
        items.forEach(function (item) {
          if (!item) return;
          var itemKey = item.itemKey,
              value = item.value,
              fieldValue = item.fieldValue,
              fieldImage = item.fieldImage,
              disabled = item.disabled,
              index = item.index;

          if (itemKey === SELECT_ALL || disabled) return;

          lastFocusedItemKey = itemKey;
          lastFocusedItemIndex = index;
          newFieldImage = fieldImage;
          if (isSelectingAll) {
            if (isAllSelected) {
              newValues.delete(itemKey);
              newFieldValues.delete(itemKey);
            } else {
              newValues.set(itemKey, value);
              newFieldValues.set(itemKey, fieldValue);
            }
          } else if (newValues.has(itemKey) && !lockMultipleClear) {
            // This case only happens with multiple select
            // If the item is already selected, we remove it from selected values
            newValues.delete(itemKey);
            newFieldValues.delete(itemKey);
          } else {
            // Add the selected value
            newValues.set(itemKey, value);
            newFieldValues.set(itemKey, fieldValue);
          }
        });

        if (newValues.size === enabledItemsSize && enabledItemsSize !== 0) {
          newIsAllSelected = true;
        } else {
          newIsAllSelected = false;
        }

        return {
          value: newValues,
          fieldValue: newFieldValues,
          fieldImage: newFieldImage,
          list: _this.originalList,
          isDanger: !allowCustomChoice ? false : isDanger,
          isOpen: multiple && (!lockMultipleClear || allowSelectAll && !lockMultipleClear),
          isAllSelected: newIsAllSelected,
          currentFocusedItemKey: lastFocusedItemKey,
          currentFocusedItemIndex: lastFocusedItemIndex
        };
      }, function () {
        // Return an array of selected items to onChange prop
        var selectedValue = Array.from(_this.state.value.values());

        // If not multiple select, return selected item.
        // Why not return Array? to support backward compatibility
        if (!multiple) {
          selectedValue = selectedValue.pop();
        }

        if (allowSelectAll) {
          _this.injectCustomItem(_this.renderSelectAllItem());
        }

        _this.props.onChange(selectedValue, _this.props.callbackParams);

        if (allowCustomChoice) _this.validate();
      });
    }, _this.onUnSelect = function (itemValue) {
      // Get the actual item, then pass it to onSelect and onSelect will
      // find that this item is already selected so it will remove it from selected values
      var item = _this.getItemByValue(itemValue);
      _this.onSelect(item);
    }, _this.onSearch = function (value) {
      var overrideFieldValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var _this$props4 = _this.props,
          allowSelectAll = _this$props4.allowSelectAll,
          items = _this$props4.items,
          searchTimeout = _this$props4.searchTimeout,
          allowCustomChoice = _this$props4.allowCustomChoice;

      // This is just for performance, since onSearch prop
      // is mainly for calling APIs, so if each key press will trigger
      // an API call this will cause a lot calls.
      // What we do here is, we wait for searchTimeout (default 500ms)
      // before we call onSearch prop, if the user pressed on another key
      // the previous timer is canceled and a new one is created

      if (searchTimeout > 0) {
        clearTimeout(_this.typingTimer);
        _this.typingTimer = setTimeout(function () {
          _this.props.onSearch(value);
        }, searchTimeout);
      } else {
        _this.props.onSearch(value);
      }

      // Filter current items then update the state
      var list = _this.filterList(value);
      _this.setState(function (prevState) {
        var fieldValue = prevState.fieldValue;


        if (overrideFieldValue) {
          fieldValue = new Map();
          fieldValue.set(value, value);
        }

        return {
          searchValue: value,
          fieldValue: fieldValue,
          value: overrideFieldValue ? new Map() : prevState.value,
          list: list,
          isOpen: true,
          currentFocusedItemKey: undefined,
          currentFocusedItemIndex: -1
        };
      }, function () {
        if (allowSelectAll && list.size === items.length) {
          _this.injectCustomItem(_this.renderSelectAllItem());
        }

        if (allowCustomChoice && value !== '') {
          _this.injectCustomItem(_this.renderCustomInputItem());
        }
      });
    }, _this.onItemHover = function (key, index) {
      _this.setState({
        currentFocusedItemIndex: index,
        currentFocusedItemKey: key,
        isFocusedByMouse: true
      });
    }, _this.getItemByValue = function (value) {
      var list = _this.state.list;

      // Loop through current list and get matched items

      var matchedItem = Array.from(list.values()).filter(function (item) {
        return _this.isItemsEqual(item.value, value);
      });

      if (matchedItem.length === 1) {
        // Return the first item in an array
        return matchedItem.pop();
      }
    }, _this.getInputValue = function () {
      var multiple = _this.props.multiple;
      var value = _this.state.value;

      // Get values as an Array

      value = Array.from(value.values());

      // Returns an array if multiple otherwise return on item
      if (!multiple) {
        value = value.pop();
      }
      return value;
    }, _this.getAvailableItem = function (direction, index) {
      var list = _this.state.list;

      var listArray = Array.from(list.values());
      var newIndex = index;
      var item = void 0;

      switch (direction) {
        case NEXT:
          {
            newIndex = index + 1 === list.size ? 0 : index + 1;
            item = listArray[newIndex];

            // Checks if the item should be skipped
            if (item) {
              if (_this.shouldSkipItem(item)) {
                return _this.getAvailableItem(NEXT, newIndex);
              }
            }

            return newIndex;
          }

        case PREVIOUS:
          {
            newIndex = index - 1 === -1 ? list.size - 1 : index - 1;
            item = listArray[newIndex];

            // Checks if the item should be skipped
            if (item) {
              if (_this.shouldSkipItem(item)) {
                return _this.getAvailableItem(PREVIOUS, newIndex);
              }
            }

            return newIndex;
          }

        default:
          {
            return newIndex;
          }
      }
    }, _this.iconsStore = new _IconsStore2.default(_webIcons2.default), _this.selectItem = function (values) {
      var items = void 0;

      // Incase selecting multiple items at a time
      if (Array.isArray(values)) {
        items = values.map(function (value) {
          return _this.getItemByValue(value);
        }).filter(function (value) {
          return !!value;
        });
      } else {
        items = _this.getItemByValue(values);
      }

      // If the requested item is exists we select it, if not we reset the field values
      if (items && items.length !== 0) {
        _this.onSelect(items);
      } else {
        _this.resetSelect();
      }
    }, _this.selectFirstItem = function () {
      var list = _this.state.list;

      // Get the first item from the current list

      var item = Array.from(list.values())[0];

      if (item) {
        _this.onSelect(item);
      }
    }, _this.isItemsEqual = function (firstItem, secondItem) {
      var isEqual = void 0;

      /**
       * If the item's type is string or number we do a normal comparison
       * Otherwise we use lodash to do comparison on Arrays and Objects
       */
      if ((typeof firstItem === 'undefined' ? 'undefined' : _typeof(firstItem)) !== 'object' && (typeof secondItem === 'undefined' ? 'undefined' : _typeof(secondItem)) !== 'object') {
        isEqual = firstItem === secondItem;
      } else if ((typeof firstItem === 'undefined' ? 'undefined' : _typeof(firstItem)) === 'object' && (typeof secondItem === 'undefined' ? 'undefined' : _typeof(secondItem)) === 'object') {
        isEqual = _lodash2.default.isEqual(firstItem, secondItem);
      }
      return isEqual;
    }, _this.filterList = function (query) {
      var _this$props5 = _this.props,
          allowCustomChoice = _this$props5.allowCustomChoice,
          placeholder = _this$props5.placeholder,
          emptyStateMessage = _this$props5.emptyStateMessage;

      if (!query && query === '') {
        if (!allowCustomChoice) {
          // If query is undefined or an empty string
          return _this.originalList;
        }

        return new Map();
      }

      var results = new Map();
      var stringQuery = '' + query;

      // Filtering current items list
      _this.originalList.forEach(function (value, key) {
        if (value.searchable) {
          value.searchable.forEach(function (search) {
            var stringSearch = '' + search;

            // Matching part of the search with entered query
            if (stringSearch.toLowerCase().includes(stringQuery.toLowerCase())) {
              results.set(key, value);
            }
          });
        }
      });

      // If there is no results, we will show an empty state
      if (results.size === 0 && !allowCustomChoice) {
        results.set(-1, {
          disabled: true,
          key: placeholder + '-empty-results',
          itemKey: 'empty-results',
          children: emptyStateMessage,
          keyPrefix: placeholder,
          index: 0
        });
      }

      return results;
    }, _this.resetSelect = function () {
      var list = _this.state.list;
      var _this$props6 = _this.props,
          items = _this$props6.items,
          allowSelectAll = _this$props6.allowSelectAll,
          allowCustomChoice = _this$props6.allowCustomChoice;


      _this.setState({
        value: new Map(),
        fieldValue: new Map(),
        fieldImage: undefined,
        list: _this.originalList,
        isAllSelected: false
      }, function () {
        if (allowSelectAll && list.size === items.length) {
          _this.injectCustomItem(_this.renderSelectAllItem());
        }
        if (allowCustomChoice) {
          _this.onSearch('');
        }
      });
    }, _this.clearSelected = function () {
      return _this.resetSelect();
    }, _this.navigateWithKeyboard = function (e) {
      var key = e.keyCode;

      // Return if the keyCode isn't supported
      if (!SUPPORTED_SHORTCUTS.includes(key)) {
        return;
      }
      e.preventDefault();

      var _this$state3 = _this.state,
          currentFocusedItemIndex = _this$state3.currentFocusedItemIndex,
          list = _this$state3.list,
          isOpen = _this$state3.isOpen;

      var itemToBeSelected = void 0;
      var newIndex = currentFocusedItemIndex;

      switch (key) {
        case DOWN_ARROW:
          _this.setState({
            isOpen: true
          });
          newIndex = _this.getAvailableItem(NEXT, currentFocusedItemIndex);
          break;

        case UP_ARROW:
          _this.setState({
            isOpen: true
          });
          newIndex = _this.getAvailableItem(PREVIOUS, currentFocusedItemIndex);
          break;

        case ENTER:
          if (isOpen) {
            itemToBeSelected = Array.from(list.values())[currentFocusedItemIndex];
            _this.onSelect(itemToBeSelected);
          }
          break;
        case ESCAPE:
          _this.onBlur(e);
          break;
        default:
          break;
      }

      var currentFocusedItem = Array.from(list.values())[newIndex];

      _this.setState({
        currentFocusedItemIndex: newIndex,
        currentFocusedItemKey: currentFocusedItem ? currentFocusedItem.itemKey : undefined,
        isFocusedByMouse: false
      });
    }, _this.focus = function () {
      _this.input.focus();
      _this.setState({
        isOpen: true
      });
    }, _this.validate = function () {
      _this.isValid();
    }, _this.isValid = function () {
      var _this$state4 = _this.state,
          value = _this$state4.value,
          isDanger = _this$state4.isDanger,
          searchValue = _this$state4.searchValue;
      var _this$props7 = _this.props,
          hideErrorMessage = _this$props7.hideErrorMessage,
          allowCustomChoice = _this$props7.allowCustomChoice,
          validationChecks = _this$props7.validationChecks;

      var validationChecksResult = true;

      if (validationChecks) {
        validationChecks.forEach(function (check) {
          var regexResult = check.regex.test(searchValue);

          if (!regexResult) {
            _this.showErrorMessage(check.errorMessage[_this.props.language]);
            validationChecksResult = false;
          }
        });
      }

      if (validationChecksResult) {
        if ((value.size === 0 || isDanger) && !hideErrorMessage && (!allowCustomChoice || allowCustomChoice && searchValue === '')) {
          _this.showErrorMessage();
          return false;
        }
        _this.hideErrorMessage();
        return true;
      }
      return false;
    }, _this.showErrorMessage = function () {
      var errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _SelectUtils.getErrorMessage)(_this.props);

      _this.setState({
        isDanger: true,
        errorMessage: errorMessage
      });
    }, _this.hideErrorMessage = function () {
      _this.setState({
        isDanger: false
      });
    }, _this.updateItems = function (items) {
      var list = _this.state.list;

      var newItems = (0, _SelectUtils.itemsAdapter)(items);

      // Only updates if the list changed
      if (!(0, _SelectUtils.isListsEqual)(newItems, list)) {
        _this.setState(function () {
          var newList = (0, _SelectUtils.generateListFromItems)(newItems, _this.props.language).list;

          return {
            list: newList
          };
        });
      }
    }, _this.select = function (value) {
      var multiple = _this.props.multiple;

      if (!_this.isItemsEqual(value, multiple ? Array.from(_this.state.value.values()) : _this.state.value.get(value))) {
        _this.setState({
          select: value
        });
      }
    }, _this.injectCustomItem = function (item) {
      var currentItems = (0, _SelectUtils.itemsAdapter)(_this.props.items);
      currentItems.unshift(item);

      var _generateListFromItem = (0, _SelectUtils.generateListFromItems)(currentItems, _this.props.language),
          groupedList = _generateListFromItem.groupedList;

      _this.setState({
        list: groupedList
      });
    }, _this.shouldSkipItem = function (_ref) {
      var disabled = _ref.disabled,
          isGroupHeadline = _ref.isGroupHeadline,
          isSelected = _ref.isSelected;
      return disabled || isGroupHeadline || isSelected;
    }, _this.renderCustomInputItem = function () {
      var _this$props8 = _this.props,
          customChoiceMessage = _this$props8.customChoiceMessage,
          placeholder = _this$props8.placeholder;
      var fieldValue = _this.state.fieldValue;

      var value = Array.from(fieldValue.values()).join(', ');

      return {
        value: value,
        fieldValue: value,
        itemKey: 'custom',
        key: placeholder + '-custom',
        keyPrefix: placeholder,
        children: _react2.default.createElement(
          'div',
          { className: 'custom-option--container' },
          _react2.default.createElement(_Icon2.default, {
            className: 'custom-option--icon',
            icon: _this.iconsStore.getIcon('plus'),
            width: 9,
            color: _Colors3.default.vezeetaBlue
          }),
          _react2.default.createElement(
            _Text2.default,
            { ml: 2, className: 'custom-option--text' },
            customChoiceMessage + ': ' + value
          )
        )
      };
    }, _this.renderSelectAllItem = function () {
      return {
        value: SELECT_ALL,
        fieldValue: SELECT_ALL,
        itemKey: SELECT_ALL,
        key: _this.props.placeholder + '-' + SELECT_ALL,
        keyPrefix: _this.props.placeholder,
        disabled: _this.state.isAllItemsDisabled,
        children: _react2.default.createElement(
          _Text2.default,
          { fontWeight: _Typography.FONT_WEIGHTS.SEMI_BOLD },
          _this.props.language === 'en' ? (_this.state.isAllSelected ? 'Unselect' : 'Select') + ' All ' + _this.props.placeholder : (_this.state.isAllSelected ? 'إلغاء التحديد على' : 'اختر') + ' \u0643\u0644 ' + _this.props.placeholder // eslint-disable-line
          // eslint-disable-line

        )
      };
    }, _this.renderItems = function () {
      var _this$state5 = _this.state,
          list = _this$state5.list,
          isOpen = _this$state5.isOpen,
          value = _this$state5.value,
          searchValue = _this$state5.searchValue;
      var _this$props9 = _this.props,
          allowCustomChoice = _this$props9.allowCustomChoice,
          emptyStateMessage = _this$props9.emptyStateMessage,
          multiple = _this$props9.multiple,
          placeholder = _this$props9.placeholder;

      var menuItems = [];

      if (list.size === 0 && (!allowCustomChoice || allowCustomChoice && searchValue === '')) {
        menuItems.push(_react2.default.createElement(
          _MenuItem2.default,
          {
            keyPrefix: placeholder,
            itemKey: 'empty',
            key: placeholder + '-empty',
            index: 0,
            disabled: true
          },
          emptyStateMessage
        ));
      } else {
        list.forEach(function (item, index) {
          menuItems.push(_react2.default.createElement(_MenuItem2.default, _extends({}, item, {
            keyPrefix: placeholder,
            onHover: _this.onItemHover,
            isSelected: value.has(index),
            itemKey: item.itemKey,
            isFocused: _this.isItemsEqual(index, _this.state.currentFocusedItemKey),
            isMenuOpen: isOpen,
            isMultiple: multiple,
            disableScroll: _this.state.isFocusedByMouse,
            onClick: _this.onSelect,
            key: placeholder + '-' + item.itemKey
          })));
        });
      }

      return menuItems;
    }, _this.renderTags = function () {
      var fieldValue = _this.state.fieldValue;
      var _this$props10 = _this.props,
          placeholder = _this$props10.placeholder,
          reverse = _this$props10.reverse;

      var tags = [];

      fieldValue.forEach(function (value, key) {
        if (key === SELECT_ALL) return;

        tags.push(_react2.default.createElement(
          _Tag2.default,
          {
            key: placeholder + '-' + value,
            mr: reverse ? 0 : 1,
            ml: reverse ? 1 : 0,
            mt: 1,
            onRemove: function onRemove() {
              return _this.onUnSelect(key);
            },
            reverse: reverse
          },
          value
        ));
      });

      return tags;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Select.prototype.componentWillUnmount = function componentWillUnmount() {
    document.removeEventListener('mousedown', this.onBlur);
  };

  /**
   * Handle user's click within the field container
   */


  /**
   * Handles users' click outside field container
   * @param {event} event
   */


  /**
   * Select an item
   * @param {object} item
   */


  /**
   * Only called from Tag component
   * @param {string} itemValue
   */


  /**
   * Called when user's types in search field
   * @param {string} value
   */


  /**
   * Triggered when hovering on an item
   * @param {string | number} key
   * @param {number} index
   */


  /**
   * Get item by it's value
   * @param {any} value
   * @returns {object} item
   */


  /**
   * Returns current value
   */


  /**
   * Gets the next/previous available item
   * @param {number} direction
   * @param {number} index
   * @return {number} newIndex
   */


  /**
   * Selects an item or reset current selection if the item
   * @param {any} value
   */


  /**
   * Selects first item from the list
   */


  /**
   * Compares two items
   * @param {any} firstItem
   * @param {any} secondtItem
   * @returns {boolean} isEqual
   */


  /**
   * Filter current list based on a query
   * @param {string} query
   */


  /**
   * Resets current state
   */


  /**
   * @deprecated
   */


  /**
   * Navigate between items using keyboard
   */


  /**
   * Focus on input field
   */


  /**
   * Validate current state and show an error message if not valid
   */


  /**
   * Checks if the current value is valid
   * @returns {boolean}
   */


  /**
   * Show an error message
   * @param {string} errorMessage
   */


  /**
   * Hide error message
   */


  /**
   * Update current items
   * @deprecated
   * @param {Array} items
   */


  /**
   * Selects an item
   * @deprecated
   * @param {any} value
   */


  /**
   * Add custom item into menu items
   */


  /**
   *
   */


  /**
   * Renders custom input item
   * @return {element}
   */


  /**
   * Create select/unselect all option
   */


  /**
   * Render an array of <MenuItem />
   * @returns {Array} listItems
   */


  /**
   * Render selected items in tags
   */


  Select.prototype.render = function render() {
    var _this2 = this;

    var _state = this.state,
        fieldValue = _state.fieldValue,
        fieldImage = _state.fieldImage,
        isOpen = _state.isOpen,
        errorMessage = _state.errorMessage,
        isDanger = _state.isDanger,
        searchValue = _state.searchValue;

    var _props = this.props,
        hideErrorMessage = _props.hideErrorMessage,
        icon = _props.icon,
        iconWidth = _props.iconWidth,
        noIcon = _props.noIcon,
        className = _props.className,
        disabled = _props.disabled,
        noBottomBorder = _props.noBottomBorder,
        placeholder = _props.placeholder,
        multiple = _props.multiple,
        items = _props.items,
        reverse = _props.reverse,
        small = _props.small,
        language = _props.language,
        onChange = _props.onChange,
        onSearch = _props.onSearch,
        extendSelect = _props.extendSelect,
        extendInputContainer = _props.extendInputContainer,
        extendComboboxMenuList = _props.extendComboboxMenuList,
        hideArrowIcon = _props.hideArrowIcon,
        filteredProps = _objectWithoutProperties(_props, ['hideErrorMessage', 'icon', 'iconWidth', 'noIcon', 'className', 'disabled', 'noBottomBorder', 'placeholder', 'multiple', 'items', 'reverse', 'small', 'language', 'onChange', 'onSearch', 'extendSelect', 'extendInputContainer', 'extendComboboxMenuList', 'hideArrowIcon']);

    var showSearchBar = multiple && items.length !== 0;
    var inputIcon = void 0;
    var iconColor = void 0;

    // Changing icon color depending on component state
    if (isDanger) {
      iconColor = _Colors3.default.vezeetaRed;
    } else if (isOpen) {
      iconColor = _Colors3.default.vezeetaBlue;
    } else {
      iconColor = _Colors3.default.defaultGrey;
    }

    if (icon) {
      inputIcon = _react2.default.createElement(SelectIcon, { reverse: reverse, icon: icon, width: iconWidth, color: iconColor });
    } else {
      inputIcon = _react2.default.createElement(_Image2.default, {
        src: fieldImage,
        radius: 20,
        alt: fieldImage,
        name: fieldImage,
        m: !reverse ? '0 10px 0 0' : '0 0 0 10px'
      });
    }

    var containerClasses = (0, _classnames2.default)('select', className, {
      'select--danger': isDanger,
      'select--no-icon': noIcon,
      'select--disabled': disabled,
      'select--focused': isOpen,
      'select--no_borders': noBottomBorder
    });

    return _react2.default.createElement(
      SelectContainer,
      _extends({
        innerRef: function innerRef(div) {
          _this2.selectContainer = div;
        },
        className: containerClasses
      }, filteredProps, {
        extendSelect: extendSelect
      }),
      _react2.default.createElement(
        InputContainer,
        {
          className: (0, _classnames2.default)('input-container', { 'input-container--multiple': multiple }),
          onClick: this.onClick,
          onKeyDown: this.navigateWithKeyboard,
          tabIndex: '-1',
          extendInputContainer: extendInputContainer
        },
        !noIcon && inputIcon,
        multiple && fieldValue.size !== 0 ? _react2.default.createElement(
          _TagsContainer2.default,
          { width: 1, flexWrap: 'wrap' },
          this.renderTags()
        ) : _react2.default.createElement(Input, {
          reverse: reverse,
          type: 'text',
          value: Array.from(fieldValue.values()).join(', '),
          placeholder: '' + placeholder + (!hideErrorMessage ? '*' : ''),
          tabIndex: '0',
          innerRef: function innerRef(input) {
            _this2.input = input;
          },
          onChange: function onChange(e) {
            return _this2.onSearch(e.target.value, true);
          },
          onKeyDown: this.onKeyDown,
          readOnly: multiple,
          disabled: disabled,
          autoComplete: 'off',
          small: small
        }),
        _react2.default.createElement(
          IconContainer,
          {
            alignItems: 'center',
            justifyContent: 'center',
            className: (0, _classnames2.default)({ 'icon--up': isOpen }),
            onKeyDown: function onKeyDown() {},
            reverse: reverse,
            hideArrowIcon: hideArrowIcon
          },
          _react2.default.createElement(_Icon2.default, { icon: this.iconsStore.getIcon('dropdown'), width: 18, color: _Colors.COLORS.TEXT })
        )
      ),
      !hideErrorMessage && _react2.default.createElement(
        'span',
        { className: 'error-message' },
        errorMessage
      ),
      _react2.default.createElement(
        MenuList,
        {
          extendComboboxMenuList: extendComboboxMenuList,
          ref: function ref(menu) {
            _this2.menu = menu;
          },
          className: (0, _classnames2.default)('menu', { 'menu--open': isOpen })
        },
        showSearchBar && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(
            _gridStyled.Flex,
            { className: 'search--container', p: 2, justifyContent: 'center', alignItems: 'center' },
            _react2.default.createElement(_Search2.default, {
              p: 2,
              width: 1,
              placeholder: reverse ? '\u0627\u0628\u062D\u062B \u0628' + placeholder : 'Search ' + placeholder.toLowerCase(),
              onChange: this.onSearch,
              value: searchValue,
              flexDirection: 'row-reverse',
              iconProps: { m: 0 },
              language: language
            })
          ),
          _react2.default.createElement(_Separator2.default, null)
        ),
        _react2.default.createElement(
          'div',
          { className: 'items--container' },
          this.renderItems()
        )
      )
    );
  };

  return Select;
}(_react.Component), _class.defaultProps = {
  items: [],
  placeholder: '',
  className: '',
  noIcon: false,
  noSearch: false,
  emptyStateMessage: 'No options are available...',
  noBottomBorder: false,
  multiple: false,
  onChange: function onChange() {},
  onSearch: function onSearch() {},
  hideErrorMessage: false,
  icon: undefined,
  iconWidth: undefined,
  callbackParams: undefined,
  select: undefined,
  selectFirst: false,
  allowCustomChoice: false,
  customChoiceMessage: undefined,
  disabled: false,
  allowSelectAll: false,
  searchTimeout: 500,
  reverse: false,
  language: 'en',
  small: false,
  customErrorMassage: '',
  hideArrowIcon: false
}, _class.getDerivedStateFromProps = function (nextProps, prevState) {
  // Remove children key from object comparison
  var prevItems = Array.from(prevState.prevList.values());
  var nextItems = (0, _SelectUtils.itemsAdapter)(nextProps.items);

  // Comparing the current and the new items
  if (!(0, _SelectUtils.isListsEqual)(prevItems, nextItems)) {
    // Adapting items to new structure
    var _generateListFromItem2 = (0, _SelectUtils.generateListFromItems)(nextItems, nextProps.language),
        list = _generateListFromItem2.list,
        groupedList = _generateListFromItem2.groupedList,
        isAllItemsDisabled = _generateListFromItem2.isAllItemsDisabled;

    return { list: groupedList, prevList: list, isAllItemsDisabled: isAllItemsDisabled };
  }

  // There is no update to the state
  return null;
}, _temp2);
Select.propTypes = process.env.NODE_ENV !== "production" ? {
  items: _propTypes2.default.arrayOf(_propTypes2.default.oneOfType([_propTypes2.default.shape({
    data: _propTypes2.default.shape({
      placeholder: _propTypes2.default.string.isRequired,
      value: _propTypes2.default.any.isRequired,
      searchable: _propTypes2.default.array,
      img: _propTypes2.default.string,
      disabled: _propTypes2.default.bool,
      key: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
      groupBy: _propTypes2.default.string,
      groupIcon: _propTypes2.default.string
    }),
    component: _propTypes2.default.any
  }), _propTypes2.default.shape({
    children: _propTypes2.default.any,
    fieldValue: _propTypes2.default.string,
    value: _propTypes2.default.any,
    searchable: _propTypes2.default.array,
    fieldImage: _propTypes2.default.string,
    disabled: _propTypes2.default.bool,
    groupBy: _propTypes2.default.string,
    groupIcon: _propTypes2.default.string,
    key: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number])
  })])),
  placeholder: _propTypes2.default.string,
  onChange: _propTypes2.default.func,
  onSearch: _propTypes2.default.func,
  hideErrorMessage: _propTypes2.default.bool,
  icon: _Icon.iconPropTypes,
  iconWidth: _propTypes2.default.number,
  noIcon: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  disabled: _propTypes2.default.bool,
  noSearch: _propTypes2.default.bool,
  callbackParams: _propTypes2.default.bool,
  select: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
  selectFirst: _propTypes2.default.bool,
  allowCustomChoice: _propTypes2.default.bool,
  customChoiceMessage: _propTypes2.default.string,
  emptyStateMessage: _propTypes2.default.string,
  noBottomBorder: _propTypes2.default.bool,
  multiple: _propTypes2.default.bool,
  allowSelectAll: _propTypes2.default.bool,
  searchTimeout: _propTypes2.default.number,
  reverse: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  small: _propTypes2.default.bool,
  customErrorMassage: _propTypes2.default.string,
  hideArrowIcon: _propTypes2.default.bool
} : {};
exports.default = (0, _WithDisplayName2.default)(Select, 'Select');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Select = require('../../select/Select');

var _Select2 = _interopRequireDefault(_Select);

var _CheckboxList = require('../../checkboxList/CheckboxList');

var _CheckboxList2 = _interopRequireDefault(_CheckboxList);

var _RadioButton = require('../../radioButton/RadioButton');

var _RadioButton2 = _interopRequireDefault(_RadioButton);

var _Typography = require('../../base/Typography');

var _Colors = require('../../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

var _WithDisplayName = require('../../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./FilterSection.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FilterSection = (_temp2 = _class = function (_Component) {
  _inherits(FilterSection, _Component);

  function FilterSection() {
    var _temp, _this, _ret;

    _classCallCheck(this, FilterSection);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      hideNotSelected: false
    }, _this.getComboSection = function () {
      var _this$props = _this.props,
          filters = _this$props.filters,
          placeholder = _this$props.placeholder,
          selectedValue = _this$props.selectedValue,
          noSearch = _this$props.noSearch,
          selectFirst = _this$props.selectFirst,
          icon = _this$props.icon,
          language = _this$props.language,
          emptyStateMessage = _this$props.emptyStateMessage;

      return _react2.default.createElement(
        'div',
        { className: 'filter-section' },
        _react2.default.createElement(
          _Text2.default,
          { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'filter-placeholder bold' },
          placeholder
        ),
        _react2.default.createElement(_Select2.default, {
          items: filters,
          placeholder: placeholder,
          onChange: _this.handleFilterItemChange,
          select: selectedValue,
          noSearch: noSearch || false,
          selectFirst: selectFirst || false,
          icon: _this.iconsStore.getIcon(icon),
          iconWidth: 12,
          hideErrorMessage: true,
          noBottomBorder: true,
          reverse: language === 'ar',
          emptyStateMessage: emptyStateMessage,
          language: language
        })
      );
    }, _this.getCheckListSection = function () {
      var result = [];
      var _this$props2 = _this.props,
          filters = _this$props2.filters,
          isAllSelected = _this$props2.isAllSelected,
          selectedIds = _this$props2.selectedIds,
          placeholder = _this$props2.placeholder,
          language = _this$props2.language,
          isFilterChanged = _this$props2.isFilterChanged;

      result.push(_react2.default.createElement(_CheckboxList2.default, {
        key: 'checkList',
        items: filters,
        onChange: _this.handleFilterItemChange,
        hideNotSelected: _this.state.hideNotSelected,
        isAllSelected: isAllSelected,
        selectedIds: selectedIds,
        isArabic: language === 'ar',
        isFilterChanged: isFilterChanged
      }));
      var hideNotSelectedIconClass = 'icon-container icon--' + (_this.state.hideNotSelected ? 'up' : 'down');

      return _react2.default.createElement(
        'div',
        { className: 'filter-section' },
        _react2.default.createElement(
          'div',
          {
            className: 'filter-section-title',
            onClick: function onClick() {
              _this.setState(function (state) {
                return {
                  hideNotSelected: !state.hideNotSelected
                };
              });
            },
            onKeyPress: function onKeyPress() {}
          },
          _react2.default.createElement(
            _Text2.default,
            { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'filter-placeholder bold' },
            placeholder
          ),
          _react2.default.createElement(
            'div',
            { className: hideNotSelectedIconClass },
            _react2.default.createElement(_Icon2.default, {
              icon: _this.iconsStore.getIcon('dropdown'),
              width: 18,
              color: _Colors2.default.vezeetaBlue
            })
          )
        ),
        result
      );
    }, _this.getRadioButtonsSection = function () {
      var result = [];
      var _this$props3 = _this.props,
          filters = _this$props3.filters,
          selectedValue = _this$props3.selectedValue,
          placeholder = _this$props3.placeholder;

      result.push(_react2.default.createElement(_RadioButton2.default, {
        key: 'radioButton',
        options: filters,
        onChange: _this.handleFilterItemChange,
        select: selectedValue,
        listAlignment: 'flex-col'
      }));

      return _react2.default.createElement(
        'div',
        { className: 'filter-section' },
        _react2.default.createElement(
          _Text2.default,
          { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'filter-placeholder bold' },
          placeholder
        ),
        result
      );
    }, _this.iconsStore = new _IconsStore2.default(_webIcons2.default), _this.handleFilterItemChange = function (filterValue) {
      var _this$props4 = _this.props,
          onChange = _this$props4.onChange,
          name = _this$props4.name;

      if (onChange) {
        onChange({
          name: name,
          data: filterValue
        });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  /**
   * Gets section with Select
   * @returns {object} section
   */


  /**
   * Gets section with CheckboxList
   * @returns {object} section
   */


  /**
   * Gets section with RadioButton
   * @returns {object} section
   */


  /**
   * Handles change in filter section
   * @param {object} filterValue
   */


  FilterSection.prototype.render = function render() {
    var type = this.props.type;

    if (type === 'combo') {
      return this.getComboSection();
    } else if (type === 'check') {
      return this.getCheckListSection();
    } else if (type === 'radio') {
      return this.getRadioButtonsSection();
    }
    return null;
  };

  return FilterSection;
}(_react.Component), _class.defaultProps = {
  placeholder: undefined,
  name: undefined,
  onChange: function onChange() {},
  isAllSelected: false,
  noSearch: false,
  selectFirst: false,
  selectedIds: [],
  selectedValue: undefined,
  isFilterChanged: false
}, _temp2);
FilterSection.propTypes = process.env.NODE_ENV !== "production" ? {
  placeholder: _propTypes2.default.string,
  name: _propTypes2.default.string,
  filters: _propTypes2.default.array.isRequired, // eslint-disable-line
  type: _propTypes2.default.string.isRequired,
  icon: _propTypes2.default.string.isRequired,
  onChange: _propTypes2.default.func,
  isAllSelected: _propTypes2.default.bool,
  noSearch: _propTypes2.default.bool,
  selectFirst: _propTypes2.default.bool,
  selectedIds: _propTypes2.default.arrayOf(_propTypes2.default.string),
  selectedValue: _propTypes2.default.string,
  language: _propTypes2.default.string.isRequired,
  emptyStateMessage: _propTypes2.default.string.isRequired,
  isFilterChanged: _propTypes2.default.bool
} : {};
exports.default = (0, _WithDisplayName2.default)(FilterSection, 'FilterSection');
module.exports = exports['default'];
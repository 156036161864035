'use strict';

exports.__esModule = true;
exports.PHOTO_FIELD_TYPE = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  pointer-events: ', ';\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n'], ['\n  position: relative;\n  pointer-events: ', ';\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _PhotoInput = require('../photoInput/PhotoInput');

var _PhotoInput2 = _interopRequireDefault(_PhotoInput);

var _AttachInput = require('../attachInput/AttachInput');

var _AttachInput2 = _interopRequireDefault(_AttachInput);

var _PhotoCropper = require('../photoCropper/PhotoCropper');

var _PhotoCropper2 = _interopRequireDefault(_PhotoCropper);

var _ErrorMessage = require('./ErrorMessage');

var _ErrorMessage2 = _interopRequireDefault(_ErrorMessage);

var _WithDisplayName = require('../../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Colors = require('../../base/Colors');

var _Typography = require('../../base/Typography');

var _constants = require('../constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

// TODO: add action to showCropper prop

var Container = _styledComponents2.default.div(_templateObject, function (props) {
  return props.disabled ? 'none' : 'initial';
});

var PhotoUploader = function (_Component) {
  _inherits(PhotoUploader, _Component);

  function PhotoUploader(props) {
    _classCallCheck(this, PhotoUploader);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onError = function () {
      _this.updatePhoto({
        preview: _this.imgPlaceholder,
        crop: _this.imgPlaceholder,
        isPhoto: false,
        showCropper: false
      });
    };

    _this.updatePhoto = function (newData) {
      var onChange = _this.props.onChange;
      var preview = newData.preview,
          crop = newData.crop,
          isPhoto = newData.isPhoto,
          showCropper = newData.showCropper;


      _this.setState({
        preview: preview,
        crop: crop,
        isPhoto: isPhoto,
        showCropper: showCropper
      }, function () {
        _this.validate();
      });

      var extension = crop.substring('data:image/'.length, crop.indexOf(';base64'));

      if (onChange) {
        if (isPhoto) {
          onChange(preview, extension);
        }
      }
    };

    _this.loadPreview = function (inputFile, callback) {
      var input = inputFile;
      var _this$props = _this.props,
          resize = _this$props.resize,
          language = _this$props.language,
          overridePhotoValidationObject = _this$props.overridePhotoValidationObject;


      if (input.files && input.files[0]) {
        var img = new Image();
        var photo = input.files[0];
        var photoName = photo.name;

        img.src = window.URL.createObjectURL(photo);

        img.onload = function () {
          var photoSize = photo.size;
          // check if overridePhotoValidationObject prop is provided and not empty

          var _ref = overridePhotoValidationObject && Object.keys(overridePhotoValidationObject).length !== 0 ? overridePhotoValidationObject : _constants.PHOTO_VALIDATION,
              MAX_PHOTO_HEIGHT = _ref.MAX_PHOTO_HEIGHT,
              MAX_PHOTO_WIDTH = _ref.MAX_PHOTO_WIDTH,
              MAX_PHOTO_SIZE_IN_BYTES = _ref.MAX_PHOTO_SIZE_IN_BYTES;

          var maxPhotSizeInMb = parseInt(MAX_PHOTO_SIZE_IN_BYTES) / 1048576;
          var width = img.width,
              height = img.height;


          window.URL.revokeObjectURL(img.src);

          if (photoSize > MAX_PHOTO_SIZE_IN_BYTES) {
            _this.showErrorMessage(language === 'en' ? 'Maximum allowed is ' + maxPhotSizeInMb + ' MBs' : '\u0627\u0644\u062D\u062F \u0627\u0644\u0623\u0642\u0635\u0649 \u0627\u0644\u0645\u0633\u0645\u0648\u062D \u0628\u0647 \u0647\u0648 ' + maxPhotSizeInMb.toLocaleString('ar-EG') + ' \u0645\u064A\u062C\u0627\u0628\u0627\u064A\u062A');
            _this.onError();
          } else if (photo.type !== 'image/png' && photo.type !== 'image/jpg' && photo.type !== 'image/jpeg') {
            _this.showErrorMessage(language === 'en' ? 'Only files with these extensions are allowed: jpg, jpeg, png' : 'jpg, jpeg, png نحن ندعم الصيغ التالية فقط');
            _this.onError();
          } else {
            var reader = new FileReader();
            reader.readAsDataURL(photo);
            reader.onloadend = function () {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');

              if (resize) {
                if (width > height) {
                  if (width > MAX_PHOTO_WIDTH) {
                    height *= MAX_PHOTO_WIDTH / width;
                    width = MAX_PHOTO_WIDTH;
                  }
                } else if (height > MAX_PHOTO_HEIGHT) {
                  width *= MAX_PHOTO_HEIGHT / height;
                  height = MAX_PHOTO_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(img, 0, 0, width, height);
              ctx = canvas.getContext('2d');

              var resizedPhoto = void 0;
              ctx.canvas.toBlob(function (blob) {
                resizedPhoto = new File([blob], photoName, {
                  type: 'image/jpeg',
                  lastModified: Date.now()
                });

                callback(resizedPhoto);
                _this.hideErrorMessage();
              }, 'image/jpeg', 1);
            };
          }
        };

        img.onerror = function () {
          _this.showErrorMessage(language === 'en' ? 'Only files with these extensions are allowed: jpg, jpeg, png' : 'jpg, jpeg, png نحن ندعم الصيغ التالية فقط');
          _this.onError();
        };
      }
    };

    _this.focus = function () {};

    _this.isValid = function () {
      var _this$props2 = _this.props,
          isRequired = _this$props2.isRequired,
          language = _this$props2.language;
      var _this$state = _this.state,
          isPhoto = _this$state.isPhoto,
          isDanger = _this$state.isDanger;


      if (isRequired) {
        if (!isPhoto || isDanger) {
          _this.showErrorMessage(language === 'en' ? 'Photo required' : 'الصورة مطلوبة');
        }
        return isPhoto && !isDanger;
      }

      return true;
    };

    _this.validate = function () {
      var isRequired = _this.props.isRequired;
      var _this$state2 = _this.state,
          showCropper = _this$state2.showCropper,
          isPhoto = _this$state2.isPhoto,
          isDanger = _this$state2.isDanger;


      if (isRequired) {
        if (!isPhoto && !isDanger && !showCropper) {
          _this.showErrorMessage(_this.props.language === 'en' ? 'Photo required' : 'الصورة مطلوبة');
        } else if (!isDanger) {
          _this.hideErrorMessage();
        }
      }
    };

    _this.showErrorMessage = function (message) {
      _this.setState({
        errorMessage: message,
        isDanger: true
      });
    };

    _this.hideErrorMessage = function () {
      _this.setState({
        isDanger: false
      });
    };

    _this.imgPlaceholder = process.env.REACT_APP_CDN_URL + '/assets/img-placeholder.png';

    _this.state = {
      preview: _this.props.value || _this.imgPlaceholder,
      crop: _this.props.value || _this.imgPlaceholder,
      isPhoto: _this.props.value !== undefined,
      showCropper: false,
      isDanger: false
    };
    return _this;
  }

  /**
   * When the image failed to load
   */


  /**
   * Returns the current value of the uploaded photo
   */
  PhotoUploader.prototype.getInputValue = function getInputValue() {
    this.photoInput.getInputValue();
  };

  /**
   * Takes an object and updates it in the component's state
   * @param {object} newData
   */


  /**
   * Change the error message and change the isDanger state to true
   * @param {string} message
   */


  /**
   * Change the idDanger state to false
   */


  PhotoUploader.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        type = _props.type,
        disabled = _props.disabled,
        desc = _props.desc,
        isRequired = _props.isRequired,
        containerClassName = _props.containerClassName,
        className = _props.className,
        shape = _props.shape,
        language = _props.language;
    var _state = this.state,
        preview = _state.preview,
        crop = _state.crop,
        isPhoto = _state.isPhoto,
        showCropper = _state.showCropper,
        isDanger = _state.isDanger,
        errorMessage = _state.errorMessage;


    if (type === _constants.PHOTO_FIELD_TYPE.ATTACHMENT) {
      return _react2.default.createElement(
        Container,
        { disabled: disabled, className: (0, _classnames2.default)(containerClassName) },
        _react2.default.createElement(_AttachInput2.default, {
          photoPreview: preview,
          photoCrop: crop,
          isPhoto: isPhoto,
          showCropper: showCropper,
          updatePhoto: this.updatePhoto,
          loadPreview: this.loadPreview,
          isDanger: isDanger,
          errorMessage: errorMessage,
          ref: function ref(photoInput) {
            _this2.photoInput = photoInput;
          },
          desc: desc,
          disabled: disabled,
          isRequired: isRequired,
          language: language
        })
      );
    }
    return _react2.default.createElement(
      Container,
      { disabled: disabled, className: (0, _classnames2.default)(containerClassName) },
      _react2.default.createElement(_PhotoInput2.default, {
        photoPreview: preview,
        photoCrop: crop,
        isPhoto: isPhoto,
        showCropper: showCropper,
        updatePhoto: this.updatePhoto,
        loadPreview: this.loadPreview,
        ref: function ref(photoInput) {
          _this2.photoInput = photoInput;
        },
        disabled: disabled,
        imgPlaceholder: this.imgPlaceholder
      }),
      _react2.default.createElement(_PhotoCropper2.default, {
        rangeClassName: className,
        photoPreview: preview,
        photoCrop: crop,
        shape: shape,
        isPhoto: isPhoto,
        showCropper: showCropper,
        updatePhoto: this.updatePhoto,
        loadPreview: this.loadPreview,
        language: language
      }),
      isDanger && _react2.default.createElement(
        _ErrorMessage2.default,
        {
          mt: 2,
          textAlign: 'center',
          color: _Colors.COLORS.PRIMARY_RED,
          type: _Typography.FONT_TYPES.CAPTION
        },
        errorMessage
      )
    );
  };

  return PhotoUploader;
}(_react.Component);

PhotoUploader.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  containerClassName: _propTypes2.default.string,
  value: _propTypes2.default.string,
  showCropper: _propTypes2.default.bool, // eslint-disable-line
  shape: _propTypes2.default.oneOf([_constants.PHOTO_SHAPE.CIRCLE, _constants.PHOTO_SHAPE.SQUARE]),
  onChange: _propTypes2.default.func,
  isRequired: _propTypes2.default.bool,
  type: _propTypes2.default.oneOf([_constants.PHOTO_FIELD_TYPE.PERSONAL, _constants.PHOTO_FIELD_TYPE.ATTACHMENT]),
  desc: _propTypes2.default.string,
  disabled: _propTypes2.default.bool,
  resize: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  overridePhotoValidationObject: _propTypes2.default.object // eslint-disable-line
} : {};

PhotoUploader.defaultProps = {
  className: undefined,
  showCropper: true,
  containerClassName: undefined,
  value: undefined,
  isRequired: false,
  onChange: function onChange() {},
  type: _constants.PHOTO_FIELD_TYPE.PERSONAL,
  desc: undefined,
  disabled: false,
  resize: true,
  language: 'en',
  overridePhotoValidationObject: {}
};

exports.PHOTO_FIELD_TYPE = _constants.PHOTO_FIELD_TYPE;
exports.default = (0, _WithDisplayName2.default)(PhotoUploader, 'PhotoUploader');
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Select = require('../select/Select');

var _Select2 = _interopRequireDefault(_Select);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Calendar = require('./Calendar.helper');

require('./Calendar.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/** Note
 * type='single'
 * onChange output format => {date: 'mm/dd/yyyy'}
 * date input format "yyyy-mm-dd"
 *
 * type='week'
 * onChange output format => {startDate: 'mm/dd/yyyy', endDate: 'mm/dd/yyyy'}
 * startDate / endDate input format "yyyy-mm-dd"
 *
 * range selection: if "type" not specified
 * onChange output format => {startDate: 'mm/dd/yyyy', endDate: 'mm/dd/yyyy'}
 * startDate / endDate input format "yyyy-mm-dd"
 *
 * Doesn't support week selection
 */

var Calendar = function (_Component) {
  _inherits(Calendar, _Component);

  function Calendar(props) {
    _classCallCheck(this, Calendar);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onClickRightArrowCalendar = function () {
      var currentMonthNum = _this.state.currentMonthNum;

      var year = +_this.state.currentYear;
      var maxYear = new Date(_this.props.maxDate).getFullYear();
      var month = +currentMonthNum + 1;

      if (currentMonthNum === 12) {
        month = 1;
        year += 1;
      }

      _this.setState({
        currentMonthNum: +month,
        currentMonthName: _this.props.isArabic ? (0, _Calendar.getMonthArabicNames)()[month - 1] : (0, _Calendar.getMonthNames)()[month - 1],
        currentYear: +year,
        disableNextMonthButton: year === maxYear && month === 12,
        disablePreviousMonthButton: false
      });
    };

    _this.onClickLeftArrowCalendar = function () {
      var currentMonthNum = _this.state.currentMonthNum;

      var year = +_this.state.currentYear;
      var minYear = new Date(_this.props.minDate).getFullYear();
      var month = +currentMonthNum - 1;
      if (currentMonthNum === 1) {
        month = 12;
        year -= 1;
      }
      _this.setState({
        currentMonthNum: +month,
        currentMonthName: _this.props.isArabic ? (0, _Calendar.getMonthArabicNames)()[month - 1] : (0, _Calendar.getMonthNames)()[month - 1],
        currentYear: +year,
        disableNextMonthButton: false,
        disablePreviousMonthButton: year === minYear && month === 1
      });
    };

    _this.onClickCalendar = function (e) {
      var fullId = e.target.id;
      var id = fullId.substr(_this.props.id.length); // date YYYY-MM-DD
      if ((0, _Calendar.checkYYYYMMDD)(id)) {
        var _this$state = _this.state,
            dateFormat = _this$state.dateFormat,
            err = _this$state.err,
            minDate = _this$state.minDate,
            maxDate = _this$state.maxDate;

        if (_this.props.type === 'single') {
          if ((0, _Calendar.compareDate)(minDate, id) && (0, _Calendar.compareDate)(id, maxDate)) {
            var date = id;
            _this.setState({
              date: date
            });
            var dateOut = date === '' ? '' : (0, _Calendar.transformDate)(date, dateFormat);
            if (_this.props.onChange) {
              _this.props.onChange({ date: dateOut, err: err });
            }
          } else if (!(0, _Calendar.compareDate)(minDate, id)) {
            _this.switchErrorPopUp(_this.props.isArabic ? _this.state.errMessageLessThanMinDateArabic.concat((0, _Calendar.transformYYYYMMDDtoMMDDYYYY)(_this.state.minDate, _this.props.isArabic)) : // eslint-disable-line
            _this.state.errMassageLessThenMinDate.concat((0, _Calendar.transformYYYYMMDDtoMMDDYYYY)(_this.state.minDate) // eslint-disable-line
            ) // eslint-disable-line
            );
          } else if (!(0, _Calendar.compareDate)(id, maxDate)) {
            _this.switchErrorPopUp(_this.props.isArabic ? _this.state.errMessageMorethanMaxDateArabic.concat((0, _Calendar.transformYYYYMMDDtoMMDDYYYY)(_this.state.maxDate, _this.props.isArabic)) : // eslint-disable-line
            _this.state.errMassageMoreThenMxaDate.concat((0, _Calendar.transformYYYYMMDDtoMMDDYYYY)(_this.state.maxDate) // eslint-disable-line
            ) // eslint-disable-line
            );
          }
        } else if (_this.props.type === 'week') {
          if ((0, _Calendar.compareDate)(minDate, id) && (0, _Calendar.compareDate)(id, maxDate)) {
            var _date = id;
            _this.setState({
              date: _date
            });
            var _dateOut = _date === '' ? '' : (0, _Calendar.transformDate)(_date, dateFormat); // ex: dateOut 2022-11-15
            var curr = new Date(_dateOut + 'Z'); // get current date
            var first = void 0;
            var last = void 0;
            if (_this.props.weekStartDay === _Calendar.WEEK_START_DAY.MONDAY) {
              first = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
              last = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
            } else if (_this.props.weekStartDay === _Calendar.WEEK_START_DAY.SUNDAY) {
              first = new Date(curr.setDate(curr.getDate() - curr.getDay()));
              last = new Date(curr.setDate(curr.getDate() + 6));
            } else {
              var i = curr.getDay() + 1 > 6 ? 0 : curr.getDay() + 1;
              first = new Date(curr.setDate(curr.getDate() - i));
              last = new Date(curr.setDate(curr.getDate() + 6));
            }
            var firstday = (0, _Calendar.convertIsoTOMMDDYYYY)(first);
            var lastday = (0, _Calendar.convertIsoTOMMDDYYYY)(last);

            if (_this.props.onChange) {
              _this.props.onChange({ startDate: firstday, endDate: lastday, err: err });
            }
          } else if (!(0, _Calendar.compareDate)(minDate, id)) {
            _this.switchErrorPopUp(_this.state.errMassageLessThenMinDate.concat(_this.state.minDate));
          } else if (!(0, _Calendar.compareDate)(id, maxDate)) {
            _this.switchErrorPopUp(_this.state.errMassageMoreThenMxaDate.concat(_this.state.maxDate));
          }
        } else {
          var _this$state2 = _this.state,
              startDate = _this$state2.startDate,
              endDate = _this$state2.endDate;

          if (endDate === '') {
            if (startDate === '') {
              if ((0, _Calendar.compareDate)(minDate, id) && (0, _Calendar.compareDate)(id, maxDate)) {
                startDate = id;
                _this.setState({
                  startDate: startDate
                });
                var _dateOut2 = !startDate ? '' : (0, _Calendar.transformDate)(startDate, dateFormat);
                if (_this.props.onChange) {
                  _this.props.onChange({ startDate: _dateOut2, err: err });
                }
              }
            } else if ((0, _Calendar.compareDate)(startDate, id) && (0, _Calendar.compareDate)(id, maxDate)) {
              endDate = id;
              _this.setState({
                endDate: endDate
              });
              var startDateOut = startDate === '' ? '' : (0, _Calendar.transformDate)(startDate, dateFormat);
              var endDateOut = endDate === '' ? '' : (0, _Calendar.transformDate)(endDate, dateFormat);
              if (_this.props.onChange) {
                _this.props.onChange({ startDate: startDateOut, endDate: endDateOut, err: err });
              }
            } else if ((0, _Calendar.compareDate)(minDate, id) && (0, _Calendar.compareDate)(id, startDate)) {
              endDate = startDate;
              startDate = id;
              _this.setState({
                startDate: startDate,
                endDate: endDate
              });
              var _startDateOut = startDate === '' ? '' : (0, _Calendar.transformDate)(startDate, dateFormat);
              var _endDateOut = endDate === '' ? '' : (0, _Calendar.transformDate)(endDate, dateFormat);
              if (_this.props.onChange) {
                _this.props.onChange({ startDate: _startDateOut, endDate: _endDateOut, err: err });
              }
            } else if (!(0, _Calendar.compareDate)(minDate, id)) {
              _this.switchErrorPopUp(_this.state.errMassageLessThenMinDate.concat(_this.state.minDate));
            } else if (!(0, _Calendar.compareDate)(id, maxDate)) {
              _this.switchErrorPopUp(_this.state.errMassageMoreThenMxaDate.concat(_this.state.maxDate));
            }
          } else if (startDate !== '') {
            startDate = '';
            endDate = '';
            _this.setState({
              startDate: startDate,
              endDate: endDate
            });
            if (_this.props.onChange) {
              _this.props.onChange({ startDate: '', endDate: '', err: err });
            }
          }
        }
      }
    };

    _this.onClickErrorPopUp = function () {
      _this.setState({
        showErrorPopUp: false,
        errPopUp: ''
      });
    };

    _this.onChangeTitleYear = function (year) {
      var currentYear = year;
      var minYear = new Date(_this.state.minDate).getFullYear();
      var maxYear = new Date(_this.state.maxDate).getFullYear();
      var checkTitleYear = +currentYear >= +minYear && +currentYear <= +maxYear;

      _this.setState({
        currentYear: +currentYear,
        checkTitleYear: checkTitleYear,
        disableNextMonthButton: currentYear === maxYear && _this.state.currentMonthNum === 12,
        disablePreviousMonthButton: currentYear === minYear && _this.state.currentMonthNum === 1
      });
    };

    _this.getStartState = function () {
      var minDateDefault = '01/01/1900';
      var maxDateDefault = '12/31/2099';
      var now = new Date();
      var err = [];
      var monthNames = _this.props.isArabic ? (0, _Calendar.getMonthArabicNames)() : (0, _Calendar.getMonthNames)();
      var monthNum = now.getMonth();
      var monthName = monthNames[now.getMonth()];
      var year = now.getFullYear();
      var minDate = _this.props.minDate ? _this.props.minDate : minDateDefault;
      var maxDate = _this.props.maxDate ? _this.props.maxDate : maxDateDefault;
      var _this$props = _this.props,
          dateFormat = _this$props.dateFormat,
          date = _this$props.date,
          startDate = _this$props.startDate,
          endDate = _this$props.endDate;

      var dateArr = [minDate, maxDate];
      if (_this.props.type === 'single') {
        dateArr.push(date);
      } else {
        dateArr.push(startDate);
        dateArr.push(endDate);
      }
      if (dateFormat === '') {
        dateFormat = (0, _Calendar.setDateFormat)(dateArr);
      }
      if (minDate !== '' && !(0, _Calendar.checkYYYYMMDD)(minDate)) {
        if ((0, _Calendar.checkMMDDYYYY)(minDate)) {
          minDate = (0, _Calendar.transformMMDDYYYYtoYYYYMMDD)(minDate);
        } else {
          err.push('minDate format != yyyy-mm-dd or mm/dd/yyyy');
          minDate = minDateDefault;
        }
      }
      if (maxDate !== '' && !(0, _Calendar.checkYYYYMMDD)(maxDate)) {
        if ((0, _Calendar.checkMMDDYYYY)(maxDate)) {
          maxDate = (0, _Calendar.transformMMDDYYYYtoYYYYMMDD)(maxDate);
        } else {
          err.push('maxDate format != yyyy-mm-dd or mm/dd/yyyy');
          maxDate = maxDateDefault;
        }
      }
      if (minDate !== '' && maxDate !== '' && !(0, _Calendar.compareDate)(minDate, maxDate)) {
        maxDate = maxDateDefault >= minDate ? minDateDefault : minDate;
      }
      if (_this.props.type === 'single') {
        if (date !== '' && !(0, _Calendar.checkYYYYMMDD)(date)) {
          if ((0, _Calendar.checkMMDDYYYY)(date)) {
            date = (0, _Calendar.transformMMDDYYYYtoYYYYMMDD)(date);
          } else {
            err.push('date format != yyyy-mm-dd or mm/dd/yyyy');
            startDate = '';
          }
        }
      } else {
        if (startDate !== '' && !(0, _Calendar.checkYYYYMMDD)(startDate)) {
          if ((0, _Calendar.checkMMDDYYYY)(startDate)) {
            startDate = (0, _Calendar.transformMMDDYYYYtoYYYYMMDD)(startDate);
          } else {
            err.push('startDate format != yyyy-mm-dd or mm/dd/yyyy');
            startDate = '';
          }
        }
        if (startDate === '') endDate = '';
        if (endDate !== '' && !(0, _Calendar.checkYYYYMMDD)(endDate)) {
          if ((0, _Calendar.checkMMDDYYYY)(endDate)) {
            endDate = (0, _Calendar.transformMMDDYYYYtoYYYYMMDD)(endDate);
          } else {
            err.push('endDate format != yyyy-mm-dd or mm/dd/yyyy');
            endDate = '';
          }
        }
        if (startDate !== '' && endDate !== '' && !(0, _Calendar.compareDate)(startDate, endDate)) {
          startDate = '';
          endDate = '';
        }
      }

      return {
        now: now,
        currentMonthNum: monthNum + 1,
        currentMonthName: monthName,
        currentYear: year,
        minDate: minDate,
        maxDate: maxDate,
        date: date,
        startDate: startDate,
        endDate: endDate,
        dateFormat: dateFormat,
        err: err,
        weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        weekDaysArabic: ['سبت', 'جمعة', 'خميس', 'أربعاء', 'ثلاثاء', 'إثنين', 'أحد'],
        showErrorPopUp: false,
        errPopUp: '',
        errMassageLessThenMinDate: 'Please select a date starting from ',
        errMessageLessThanMinDateArabic: '  الرجاء اختيار يوم بداية من ',
        errMassageMoreThenMxaDate: 'Please select a date before ',
        errMessageMorethanMaxDateArabic: ' الرجاء اختيار يوم قبل ',
        checkTitleYear: true,
        disableNextMonthButton: false,
        disablePreviousMonthButton: false
      };
    };

    _this.switchErrorPopUp = function (err) {
      _this.setState({
        showErrorPopUp: true,
        errPopUp: err
      });
      setTimeout(function () {
        _this.setState({
          showErrorPopUp: false,
          errPopUp: ''
        });
      }, 6000);
    };

    _this.generateYears = function () {
      var startYear = new Date(_this.props.minDate).getFullYear();
      var currentYear = new Date(_this.props.maxDate).getFullYear();
      var yearsOptions = [];

      for (var counter = currentYear; counter >= startYear; counter -= 1) {
        var year = {
          data: {
            placeholder: _this.props.isArabic ? (0, _Calendar.toIndiaDigits)('' + counter) : '' + counter,
            value: counter
          },
          component: _react2.default.createElement(
            _Text2.default,
            null,
            _this.props.isArabic ? (0, _Calendar.toIndiaDigits)('' + counter) : '' + counter
          )
        };
        yearsOptions.push(year);
      }
      return yearsOptions;
    };

    _this.state = _this.getStartState(_this.props);
    return _this;
  }

  /**
   * Change state, if props change
   * @param {string} this.props.minDate
   * @param {string} nextProps.minDate
   * @param {string} this.props.maxDate
   * @param {string} nextProps.maxDate
   * @param {string} this.props.type
   * @param {string} this.props.date
   * @param {string} this.props.startDate
   * @param {string} nextProps.startDate
   * @param {string} this.props.endDate
   * @param {string} nextProps.endDate
   */
  // eslint-disable-next-line react/no-deprecated


  Calendar.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    var arr = [];
    if (this.props.minDate !== nextProps.minDate) arr.push({ key: 'minDate', val: nextProps.minDate });
    if (this.props.maxDate !== nextProps.maxDate) arr.push({ key: 'maxDate', val: nextProps.maxDate });
    if (this.props.type === 'single') {
      if (this.props.date !== nextProps.date) arr.push({ key: 'date', val: nextProps.date });
    } else {
      if (this.props.startDate !== nextProps.startDate) arr.push({ key: 'startDate', val: nextProps.startDate });
      if (this.props.endDate !== nextProps.endDate) arr.push({ key: 'endDate', val: nextProps.endDate });
    }
    var arrLength = arr.length;
    if (arrLength > 0) {
      var stateObj = {};
      for (var i = 0; i < arrLength; i += 1) {
        var key = arr[i].key;
        var val = arr[i].val;

        if ((0, _Calendar.checkMMDDYYYY)(val)) {
          val = (0, _Calendar.transformMMDDYYYYtoYYYYMMDD)(val);
        }
        if ((0, _Calendar.checkYYYYMMDD)(val) || val === '') {
          stateObj[key] = val;
        }
      }
      this.setState(stateObj);
    }
  };

  /**
   * event onClick Right Arrow
   */


  /**
   * event onClick Left Arrow
   */


  /**
   * event onClick Calendar Body, check id cell
   * @param {event} e
   */


  /**
   * event onClick ErrorPopUp - for close
   */


  /**
   * Change Year
   * @param {event} e
   */


  /**
   * get Start State
   * @return {object} state
   */


  /**
   * switch on and switch off Error PopUp
   * @param {string} err
   */


  /**
   * Generate a list of years descending from current year to 1900
   * @returns {array} yearsOptions
   */


  Calendar.prototype.render = function render() {
    var _this2 = this;

    var _state = this.state,
        now = _state.now,
        date = _state.date,
        startDate = _state.startDate,
        endDate = _state.endDate,
        currentMonthNum = _state.currentMonthNum,
        currentMonthName = _state.currentMonthName,
        currentYear = _state.currentYear,
        weekDays = _state.weekDays,
        errPopUp = _state.errPopUp,
        showErrorPopUp = _state.showErrorPopUp,
        checkTitleYear = _state.checkTitleYear,
        weekDaysArabic = _state.weekDaysArabic;
    var _props = this.props,
        type = _props.type,
        isArabic = _props.isArabic;

    var prevMonthAr = [];
    var thisMonthAr = [];
    var nextMonthAr = [];
    var monthDays = (0, _Calendar.findDaysInMonth)(currentMonthNum, currentYear);
    var thisMonthDate = new Date(currentYear + '-' + currentMonthNum);
    var thisMonth1Day = thisMonthDate.getDay();
    for (var i = 1; i <= monthDays; i += 1) {
      thisMonthAr.push(currentYear + '-' + currentMonthNum + '-' + i);
    }
    var prevMonthNum = currentMonthNum - 1;
    var prevYear = currentYear;
    if (currentMonthNum === 1) {
      prevYear = currentYear - 1;
      prevMonthNum = 12;
    }
    var prevMonthDays = (0, _Calendar.findDaysInMonth)(prevMonthNum, prevYear);
    var prevMonthShowDays = thisMonth1Day;
    for (var _i = prevMonthDays - prevMonthShowDays + 1; _i <= prevMonthDays; _i += 1) {
      prevMonthAr.push(prevYear + '-' + prevMonthNum + '-' + _i);
    }
    var nextMonthNum = currentMonthNum % 12 + 1;
    var nextYear = currentMonthNum === 12 ? currentYear + 1 : currentYear;
    var nextMonthShowDays = _Calendar.CELL_NUMS - prevMonthShowDays - monthDays;
    for (var _i2 = 1; _i2 <= nextMonthShowDays; _i2 += 1) {
      nextMonthAr.push(nextYear + '-' + nextMonthNum + '-' + _i2);
    }
    var titleYearClass = 'calendar-header-title-year-input-v2 ';
    if (!checkTitleYear) {
      titleYearClass += ' year-v2--dange';
    }
    return _react2.default.createElement(
      'div',
      { className: 'calendar-wrapper-v2' },
      _react2.default.createElement(
        'div',
        { className: 'calendar-header-v2' },
        _react2.default.createElement(
          'div',
          { className: 'calendar-header-title-v2' },
          _react2.default.createElement(
            'div',
            { className: 'calendar-header-title-month-v2' },
            currentMonthName
          ),
          _react2.default.createElement(
            'div',
            { className: 'calendar-header-title-year-v2' },
            _react2.default.createElement(_Select2.default, {
              className: titleYearClass,
              items: this.generateYears(),
              select: currentYear,
              onChange: this.onChangeTitleYear,
              placeholder: 'Year',
              noSearch: true,
              noIcon: true,
              reverse: isArabic
            })
          )
        ),
        _react2.default.createElement(
          'div',
          {
            className: (0, _classnames2.default)('calendar-arrow-v2', {
              'calendar-arrow-v2--disable': this.state.disablePreviousMonthButton,
              'calendar-arrow-v2--rotated': isArabic
            }),
            onClick: this.onClickLeftArrowCalendar,
            onKeyDown: function onKeyDown() {}
          },
          _react2.default.createElement(_Icon2.default, {
            icon: new _IconsStore2.default(_webIcons2.default).getIcon('arrow_left'),
            width: _Calendar.ARROW_WIDTH,
            color: (0, _Calendar.getIconColor)(this.state.disablePreviousMonthButton)
          })
        ),
        _react2.default.createElement(
          'div',
          {
            className: (0, _classnames2.default)('calendar-arrow-v2', {
              'calendar-arrow-v2--disable': this.state.disableNextMonthButton,
              'calendar-arrow-v2--rotated': isArabic
            }),
            onClick: this.onClickRightArrowCalendar,
            onKeyDown: function onKeyDown() {}
          },
          _react2.default.createElement(_Icon2.default, {
            icon: new _IconsStore2.default(_webIcons2.default).getIcon('arrow_right'),
            width: _Calendar.ARROW_WIDTH,
            color: (0, _Calendar.getIconColor)(this.state.disableNextMonthButton)
          })
        )
      ),
      _react2.default.createElement(
        'div',
        { className: 'calendar-body-v2', onClick: this.onClickCalendar, onKeyDown: function onKeyDown() {} },
        _react2.default.createElement(
          'div',
          {
            className: showErrorPopUp ? 'calendar-error-popup-show-v2' : 'calendar-error-popup-hide-v2',
            onClick: this.onClickErrorPopUp,
            onKeyDown: function onKeyDown() {}
          },
          errPopUp
        ),
        _react2.default.createElement(
          'div',
          { className: 'calendar-week-days-v2' },
          isArabic ? _react2.default.createElement(
            'div',
            { className: 'week-names-v2' },
            weekDaysArabic.map(function (day) {
              return _react2.default.createElement(
                'div',
                { key: day, className: 'calendar-weekday-v2 calendar-weekday-arabic-v2' },
                day
              );
            })
          ) : _react2.default.createElement(
            'div',
            { className: 'week-names-v2' },
            weekDays.map(function (day) {
              return _react2.default.createElement(
                'div',
                { key: day, className: 'calendar-weekday-v2' },
                day
              );
            })
          )
        ),
        isArabic ? _react2.default.createElement(
          'div',
          { className: 'calendar-days-arabic-v2' },
          prevMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-arabic-v2 calendar-opacity-50-v2 ';
            classDiv += (0, _Calendar.createDayStyles)({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate,
              isArabic: isArabic
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              (0, _Calendar.toIndiaDigits)(arr[2])
            );
          }),
          thisMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-arabic-v2 ';
            classDiv += (0, _Calendar.createDayStyles)({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate,
              isArabic: isArabic
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              (0, _Calendar.toIndiaDigits)(arr[2])
            );
          }),
          nextMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-arabic-v2 calendar-opacity-50-v2 ';
            classDiv += (0, _Calendar.createDayStyles)({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate,
              isArabic: isArabic
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              (0, _Calendar.toIndiaDigits)(arr[2])
            );
          })
        ) : _react2.default.createElement(
          'div',
          { className: 'calendar-days-v2' },
          prevMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-v2 calendar-opacity-50-v2 ';
            classDiv += (0, _Calendar.createDayStyles)({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              arr[2]
            );
          }),
          thisMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-v2 ';
            classDiv += (0, _Calendar.createDayStyles)({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              arr[2]
            );
          }),
          nextMonthAr.map(function (val) {
            var arr = val.split('-');
            var classDiv = 'calendar-day-v2 calendar-opacity-50-v2 ';
            classDiv += (0, _Calendar.createDayStyles)({
              val: val,
              now: now,
              type: type,
              date: date,
              startDate: startDate,
              endDate: endDate
            });
            return _react2.default.createElement(
              'div',
              { key: val, id: _this2.props.id.concat(val), className: classDiv },
              arr[2]
            );
          })
        )
      )
    );
  };

  return Calendar;
}(_react.Component);

Calendar.propTypes = process.env.NODE_ENV !== "production" ? {
  onChange: _propTypes2.default.func.isRequired,
  minDate: _propTypes2.default.string,
  weekStartDay: _propTypes2.default.string,
  maxDate: _propTypes2.default.string,
  date: _propTypes2.default.string,
  startDate: _propTypes2.default.string,
  endDate: _propTypes2.default.string,
  dateFormat: _propTypes2.default.oneOf(['yyyy-mm-dd', 'mm/dd/yyyy', '']),
  type: _propTypes2.default.oneOf(['range', 'single']),
  id: _propTypes2.default.string,
  isArabic: _propTypes2.default.bool
} : {};

Calendar.defaultProps = {
  minDate: '2015-01-01',
  maxDate: new Date().getFullYear() + 1 + '-12-31',
  startDate: '', // for type: range
  endDate: '', // for type: range
  date: '', // for type: single
  dateFormat: '',
  type: 'range',
  id: '', // add string id to calendar-cell-id
  isArabic: false
};

exports.default = (0, _WithDisplayName2.default)(Calendar, 'Calendar');
module.exports = exports['default'];
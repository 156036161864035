'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n  width: 100%;\n'], ['\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n  width: 100%;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  height: auto;\n  width: 300px;\n  margin-bottom: 40px;\n  ', ';\n  ', ';\n'], ['\n  height: auto;\n  width: 300px;\n  margin-bottom: 40px;\n  ', ';\n  ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n    width: 150px;\n    margin-bottom: 20px;\n  '], ['\n    width: 150px;\n    margin-bottom: 20px;\n  ']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  width: 300px;\n  ', ';\n  ', ';\n'], ['\n  width: 300px;\n  ', ';\n  ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n    width: 150px;\n  '], ['\n    width: 150px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _InfiniteLoadingBar = require('../infiniteLoadingBar/InfiniteLoadingBar');

var _InfiniteLoadingBar2 = _interopRequireDefault(_InfiniteLoadingBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var SplashContainer = _styledComponents2.default.div(_templateObject);

var Logo = _styledComponents2.default.img(_templateObject2, function (props) {
  return props.theme.media.tablet(_templateObject3);
}, function (props) {
  return props.theme.media.phone(_templateObject3);
});

var InfiniteLoadingBarExtended = (0, _styledComponents2.default)(_InfiniteLoadingBar2.default)(_templateObject4, function (props) {
  return props.theme.media.tablet(_templateObject5);
}, function (props) {
  return props.theme.media.phone(_templateObject5);
});

var Splash = function Splash(_ref) {
  var logoUrl = _ref.logoUrl;
  return _react2.default.createElement(
    SplashContainer,
    null,
    _react2.default.createElement(Logo, { src: logoUrl, alt: 'logo' }),
    _react2.default.createElement(InfiniteLoadingBarExtended, null)
  );
};

Splash.propTypes = process.env.NODE_ENV !== "production" ? {
  logoUrl: _propTypes2.default.string.isRequired
} : {};

exports.default = Splash;
module.exports = exports['default'];
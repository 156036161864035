'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 4px;\n  overflow: hidden;\n'], ['\n  border-radius: 4px;\n  overflow: hidden;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _gridStyled = require('../grid-styled');

var _Colors = require('../base/Colors');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var CautionContainer = _gridStyled.Flex.extend(_templateObject);

var Caution = function Caution(_ref) {
  var bgColor = _ref.bgColor,
      textColor = _ref.textColor,
      children = _ref.children,
      otherProps = _objectWithoutProperties(_ref, ['bgColor', 'textColor', 'children']);

  return _react2.default.createElement(
    CautionContainer,
    _extends({ width: 1 }, otherProps, { alignItems: 'center', bg: bgColor, p: 2 }),
    _react2.default.createElement(_Icon2.default, { icon: new _IconsStore2.default(_webIcons2.default).getIcon('warning'), color: textColor, width: 12 }),
    _react2.default.createElement(
      _Text2.default,
      { ml: 1, color: textColor, fontWeight: _Typography.FONT_WEIGHTS.SEMI_BOLD },
      children
    )
  );
};

Caution.propTypes = process.env.NODE_ENV !== "production" ? {
  bgColor: _propTypes2.default.string,
  textColor: _propTypes2.default.string,
  children: _propTypes2.default.string.isRequired
} : {};

Caution.defaultProps = {
  bgColor: _Colors.COLORS.ILLUSTRATION_BACKGROUND,
  textColor: _Colors.COLORS.PRIMARY_BLUE
};

exports.default = Caution;
module.exports = exports['default'];
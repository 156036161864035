'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background: ', ';\n  box-shadow: 0 1px 1px rgba(24, 24, 24, 0.1);\n  border-radius: 4px;\n  position: relative;\n  overflow: ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  background: ', ';\n  box-shadow: 0 1px 1px rgba(24, 24, 24, 0.1);\n  border-radius: 4px;\n  position: relative;\n  overflow: ', ';\n  ', ';\n  ', ';\n  ', ';\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Colors = require('../base/Colors');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Card = _styledComponents2.default.div(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.WHITE], function (props) {
  return props.overflow;
}, _styledSystem.space, _styledSystem.minHeight, _styledSystem.width, _styledSystem.height);

exports.default = (0, _WithDisplayName2.default)(Card, 'Card');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _recompose = require('recompose');

var _reactGoogleMaps = require('react-google-maps');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _SearchBox = require('react-google-maps/lib/components/places/SearchBox');

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _gridStyled = require('../grid-styled');

var _Container = require('./Container');

var _Container2 = _interopRequireDefault(_Container);

var _Loading = require('./Loading');

var _Loading2 = _interopRequireDefault(_Loading);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Space = require('../base/Space');

var _Space2 = _interopRequireDefault(_Space);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Button = require('../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Colors = require('../base/Colors');

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var onChangeMiddleware = function onChangeMiddleware(_ref) {
  var lat = _ref.lat,
      lng = _ref.lng;
  return {
    lat: lat(),
    lng: lng()
  };
};

var GoogleMaps = (0, _recompose.compose)((0, _recompose.withProps)(function (props) {
  return {
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_MAPS_KEY + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: _react2.default.createElement(_Loading2.default, { height: props.height, radius: props.radius }),
    containerElement: _react2.default.createElement(_Container2.default, { height: props.height }),
    mapElement: _react2.default.createElement('div', { style: { height: '100%', width: '100%' } })
  };
}), (0, _recompose.lifecycle)({
  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    if (nextProps.lat !== this.props.lat || nextProps.lng !== this.props.lng) {
      this.setState({
        center: {
          lat: nextProps.lat,
          lng: nextProps.lng
        }
      });
    }
  },
  componentWillMount: function componentWillMount() {
    var _this = this;

    var refs = {};
    var _props = this.props,
        lat = _props.lat,
        lng = _props.lng,
        onChange = _props.onChange;


    this.setState({
      bounds: null,
      center: {
        lat: lat,
        lng: lng
      },
      markers: [],
      onMapMounted: function onMapMounted(ref) {
        refs.map = ref;
      },
      onBoundsChanged: function onBoundsChanged() {
        _this.setState({
          bounds: refs.map.getBounds(),
          center: refs.map.getCenter()
        });
      },
      onSearchBoxMounted: function onSearchBoxMounted(ref) {
        refs.searchBox = ref;
      },
      onPlacesChanged: function onPlacesChanged() {
        var places = refs.searchBox.getPlaces();
        var bounds = new window.google.maps.LatLngBounds();

        places.forEach(function (place) {
          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        var nextMarkers = places.map(function (place) {
          return {
            position: place.geometry.location
          };
        });

        var nextCenter = _lodash2.default.get(nextMarkers, '0.position', _this.state.center);

        _this.setState({
          center: nextCenter,
          markers: nextCenter
        }, function () {
          onChange(onChangeMiddleware(nextCenter));
        });
      },
      getCurrentLocation: function getCurrentLocation() {
        if ('geolocation' in navigator) {
          _this.setState({
            isGettingLocation: true
          });

          navigator.geolocation.getCurrentPosition(function (location) {
            _this.setState({
              isGettingLocation: false,
              center: {
                lat: location.coords.latitude,
                lng: location.coords.longitude
              }
            });
          });
        }
      }
    });
  }
}), _reactGoogleMaps.withScriptjs, _reactGoogleMaps.withGoogleMap)(function (props) {
  var center = props.center,
      isMarkerDraggable = props.isMarkerDraggable,
      onChange = props.onChange,
      defaultZoom = props.defaultZoom,
      onSearchBoxMounted = props.onSearchBoxMounted,
      bounds = props.bounds,
      onPlacesChanged = props.onPlacesChanged,
      getCurrentLocation = props.getCurrentLocation,
      isGettingLocation = props.isGettingLocation,
      showGetCurrentLocation = props.showGetCurrentLocation,
      options = props.options,
      searhText = props.searhText,
      permissionText = props.permissionText,
      getLocationText = props.getLocationText;


  return _react2.default.createElement(
    _gridStyled.Flex,
    { flexDirection: 'column', width: 1 },
    _react2.default.createElement(
      _reactGoogleMaps.GoogleMap,
      {
        clickableIcons: false,
        defaultZoom: defaultZoom,
        defaultCenter: center,
        center: center,
        options: options
      },
      _react2.default.createElement(_reactGoogleMaps.Marker, {
        onDragEnd: function onDragEnd(location) {
          onChange(onChangeMiddleware(location.latLng));
        },
        position: center,
        draggable: isMarkerDraggable
      }),
      props.showSearch && _react2.default.createElement(
        _SearchBox.SearchBox,
        {
          ref: onSearchBoxMounted,
          bounds: bounds,
          controlPosition: window.google.maps.ControlPosition.TOP_CENTER,
          onPlacesChanged: onPlacesChanged
        },
        _react2.default.createElement('input', {
          autoComplete: 'off',
          type: 'text',
          placeholder: searhText,
          style: {
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '70%',
            height: '34px',
            marginTop: _Space2.default[3] + 'px',
            padding: '0 ' + _Space2.default[4] + 'px',
            borderRadius: '4px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '13px',
            outline: 'none',
            textOverflow: 'ellipses'
          }
        })
      )
    ),
    showGetCurrentLocation && _react2.default.createElement(
      _gridStyled.Flex,
      {
        p: 3,
        width: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        bg: _Colors.COLORS.PAGE_BACKGROUND
      },
      _react2.default.createElement(
        _Text2.default,
        { fontWeight: _Typography.FONT_WEIGHTS.SEMI_BOLD, width: 0.5 },
        permissionText
      ),
      _react2.default.createElement(
        _Button2.default,
        {
          primary: false,
          icon: new _IconsStore2.default(_webIcons2.default).getIcon('gps'),
          iconWidth: 14,
          color: _Colors.COLORS.PRIMARY_BLUE,
          onClick: getCurrentLocation,
          disabled: isGettingLocation
        },
        getLocationText
      )
    )
  );
});

GoogleMaps.propTypes = process.env.NODE_ENV !== "production" ? {
  lat: _propTypes2.default.number.isRequired,
  lng: _propTypes2.default.number.isRequired,
  defaultZoom: _propTypes2.default.number,
  showSearch: _propTypes2.default.bool,
  isMarkerDraggable: _propTypes2.default.bool,
  showGetCurrentLocation: _propTypes2.default.bool,
  onChange: _propTypes2.default.func,
  options: _propTypes2.default.any, // eslint-disable-line
  getLocationText: _propTypes2.default.string,
  permissionText: _propTypes2.default.string,
  searhText: _propTypes2.default.string
} : {};

GoogleMaps.defaultProps = {
  height: 300,
  defaultZoom: 14,
  showSearch: false,
  isMarkerDraggable: false,
  showGetCurrentLocation: true,
  onChange: function onChange() {},
  getLocationText: 'Get my Location',
  permissionText: 'Vezeeta needs permission to detect your location.',
  searhText: 'Search'
};

exports.default = GoogleMaps;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n* {\n  font-family: ', ';\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n\n  &:before,\n  &:after {\n    box-sizing: border-box;\n  }\n\n  button {\n    cursor: pointer;\n\n    &:disabled {\n      cursor: default;\n    }\n  }\n\n  &:lang(ar) {\n    font-family: TheSansArabic, ', ';\n  }\n}\n'], ['\n* {\n  font-family: ', ';\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n\n  &:before,\n  &:after {\n    box-sizing: border-box;\n  }\n\n  button {\n    cursor: pointer;\n\n    &:disabled {\n      cursor: default;\n    }\n  }\n\n  &:lang(ar) {\n    font-family: TheSansArabic, ', ';\n  }\n}\n']);

var _styledComponents = require('styled-components');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

/**
 * Using system font stack as a fallback is fonts took too much time to load or failed ot load
 */
var systemFontsStack = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

var arabicFont = 'TheSansArabic, ' + systemFontsStack;
var englishFont = 'SourceSansPro, ' + systemFontsStack;
/**
 * Injecting global font family to all HTML nodes
 */
var setGlobalFonts = function setGlobalFonts(language) {
  return (0, _styledComponents.injectGlobal)(_templateObject, language === 'en' ? englishFont : arabicFont, systemFontsStack);
};

exports.default = setGlobalFonts;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
exports.CategoryLabel = exports.CategoryLabelContainer = exports.TooltipMessage = exports.TooltipContainer = exports.Container = exports.DisableOverLay = exports.IsRequiredNote = exports.SearchInput = exports.CheckBox = exports.ItemContentContainer = exports.ItemDescription = exports.ItemLabel = exports.ListItem = exports.ListContainer = exports.FieldValue = exports.FieldLabel = exports.LabelValueContainer = exports.FieldContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-color: ', ';\n  width: 100%;\n  height: 56px;\n  display: flex;\n  flex-direction: row;\n  border: ', ';\n  justify-content: space-between;\n  padding: 7px 16px;\n  // margin: 4px 0px;\n  border-radius: 8px;\n  cursor: ', ';\n  ', ';\n'], ['\n  background-color: ', ';\n  width: 100%;\n  height: 56px;\n  display: flex;\n  flex-direction: row;\n  border: ', ';\n  justify-content: space-between;\n  padding: 7px 16px;\n  // margin: 4px 0px;\n  border-radius: 8px;\n  cursor: ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  width: 90%;\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n'], ['\n  width: 90%;\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  color: #9c9c9c;\n  width: 100%;\n  font-size: ', ';\n  line-height: ', ';\n'], ['\n  color: #9c9c9c;\n  width: 100%;\n  font-size: ', ';\n  line-height: ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  font-size: inherit;\n  line-height: 24px;\n  color: ', ';\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  ', ';\n'], ['\n  width: 100%;\n  font-size: inherit;\n  line-height: 24px;\n  color: ', ';\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  top: 6px;\n  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));\n  background-color: #fff;\n  min-width: 300px;\n  width: 100%;\n  z-index: 2;\n  font-size: ', ';\n  max-height: 210px;\n  overflow: auto;\n  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);\n  border-radius: 8px;\n  ::-webkit-scrollbar {\n    width: 4px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #9c9c9c;\n    border-radius: 15px;\n  }\n  // ', ';\n  ', ';\n'], ['\n  position: absolute;\n  top: 6px;\n  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));\n  background-color: #fff;\n  min-width: 300px;\n  width: 100%;\n  z-index: 2;\n  font-size: ', ';\n  max-height: 210px;\n  overflow: auto;\n  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);\n  border-radius: 8px;\n  ::-webkit-scrollbar {\n    width: 4px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #9c9c9c;\n    border-radius: 15px;\n  }\n  // ', ';\n  ', ';\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  box-shadow: inset 0px -1px 0px #f1f4f6;\n  padding: 8px 12px 8px 16px;\n  line-height: 24px;\n  ', ';\n  :hover {\n    background-color: #f5f5f5;\n  }\n  ', ';\n  ', ';\n'], ['\n  box-shadow: inset 0px -1px 0px #f1f4f6;\n  padding: 8px 12px 8px 16px;\n  line-height: 24px;\n  ', ';\n  :hover {\n    background-color: #f5f5f5;\n  }\n  ', ';\n  ', ';\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: inherit;\n'], ['\n  color: ', ';\n  font-size: inherit;\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  font-size: 13px;\n  line-height: 20px;\n  color: ', ';\n  direction: ', ';\n  text-align: ', ';\n'], ['\n  font-size: 13px;\n  line-height: 20px;\n  color: ', ';\n  direction: ', ';\n  text-align: ', ';\n']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n'], ['\n  display: flex;\n  flex-direction: row;\n']),
    _templateObject10 = _taggedTemplateLiteralLoose(['\n  width: 24px;\n  height: 24px;\n  margin: 2% 0%;\n'], ['\n  width: 24px;\n  height: 24px;\n  margin: 2% 0%;\n']),
    _templateObject11 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  border: unset;\n  font-size: inherit;\n  ::placeholder {\n    font-size: inherit;\n  }\n  line-height: 24px;\n  &:focus {\n    outline: none;\n  }\n  ', ';\n'], ['\n  width: 100%;\n  border: unset;\n  font-size: inherit;\n  ::placeholder {\n    font-size: inherit;\n  }\n  line-height: 24px;\n  &:focus {\n    outline: none;\n  }\n  ', ';\n']),
    _templateObject12 = _taggedTemplateLiteralLoose(['\n  display: inline-flex;\n  margin: 0px 3px;\n  color: #db3226;\n'], ['\n  display: inline-flex;\n  margin: 0px 3px;\n  color: #db3226;\n']),
    _templateObject13 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  min-height: 100%;\n  border: 1px solid #e3e6ea;\n  border-radius: 8px;\n  background-color: #f1f4f69c;\n  position: absolute;\n  display: ', ';\n'], ['\n  width: 100%;\n  min-height: 100%;\n  border: 1px solid #e3e6ea;\n  border-radius: 8px;\n  background-color: #f1f4f69c;\n  position: absolute;\n  display: ', ';\n']),
    _templateObject14 = _taggedTemplateLiteralLoose(['\n  position: relative;\n  &:hover > div {\n    visibility: visible;\n  }\n'], ['\n  position: relative;\n  &:hover > div {\n    visibility: visible;\n  }\n']),
    _templateObject15 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  display: inline-block;\n  width: fit-content;\n  text-align: center;\n  border-radius: 6px;\n  font-size: 14px;\n  padding: 5px 8px;\n  width: fit-content;\n  bottom: 100%;\n  left: ', ';\n  margin-left: -60px;\n  visibility: hidden;\n  background: #e2e3e4;\n'], ['\n  position: absolute;\n  display: inline-block;\n  width: fit-content;\n  text-align: center;\n  border-radius: 6px;\n  font-size: 14px;\n  padding: 5px 8px;\n  width: fit-content;\n  bottom: 100%;\n  left: ', ';\n  margin-left: -60px;\n  visibility: hidden;\n  background: #e2e3e4;\n']),
    _templateObject16 = _taggedTemplateLiteralLoose([''], ['']),
    _templateObject17 = _taggedTemplateLiteralLoose(['\n  background: #f1f4f6;\n  height: 38px;\n  width: 100%;\n  padding: 8px 16px;\n'], ['\n  background: #f1f4f6;\n  height: 38px;\n  width: 100%;\n  padding: 8px 16px;\n']),
    _templateObject18 = _taggedTemplateLiteralLoose(['\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  color: #484848;\n'], ['\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  color: #484848;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FieldContainer = exports.FieldContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.isDimmed ? '#f5f5f5 !important' : '#f1f4f6';
}, function (props) {
  return props.isValid ? '1px solid #e3e6ea' : '1px solid #db3226';
}, function (props) {
  return props.isDimmed ? 'unset !important' : 'pointer';
}, function (props) {
  return props.extendDropDownStyle ? props.extendDropDownStyle : '';
});

var LabelValueContainer = exports.LabelValueContainer = _styledComponents2.default.div(_templateObject2);

var FieldLabel = exports.FieldLabel = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.isValueSelected ? '12px' : '16px';
}, function (props) {
  return props.isValueSelected ? '18px' : '40px';
});
var FieldValue = exports.FieldValue = _styledComponents2.default.p(_templateObject4, function (props) {
  return props.isPlaceHolder ? '#9c9c9c' : '#484848';
}, function (props) {
  return props.extendFieldText ? props.extendFieldText : '';
});

var ListContainer = exports.ListContainer = _styledComponents2.default.div(_templateObject5, function (props) {
  return props.fontSize ? props.fontSize + 'px' : '16px';
}, function (props) {
  return props.dropDownToTop && 'top : ' + props.dropDownToTop + 'px';
}, function (props) {
  return props.extendDropDownList ? props.extendDropDownList : '';
});
var ListItem = exports.ListItem = _styledComponents2.default.div(_templateObject6, function (props) {
  return props.isEnabled ? '\n      cursor: ' + (props.isMultipleSelection ? 'unset' : 'pointer') + ';\n    ' : '\n    cursor: unset;\n    ';
}, function (props) {
  return props.extendDropDownListItem ? props.extendDropDownListItem : '';
}, function (props) {
  return props.extendListItem ? props.extendListItem : '';
});
var ItemLabel = exports.ItemLabel = _styledComponents2.default.p(_templateObject7, function (props) {
  return props.isEnabled ? '#484848' : '#B9B9B9';
});
var ItemDescription = exports.ItemDescription = _styledComponents2.default.p(_templateObject8, function (props) {
  return props.isEnabled ? '#9c9c9c' : '#B9B9B9';
}, function (props) {
  return props.language === 'ar' ? 'ltr' : 'unset';
}, function (props) {
  return props.language === 'ar' ? 'right' : 'unset';
});

var ItemContentContainer = exports.ItemContentContainer = _styledComponents2.default.div(_templateObject9);

var CheckBox = exports.CheckBox = _styledComponents2.default.input(_templateObject10);

var SearchInput = exports.SearchInput = _styledComponents2.default.input(_templateObject11, function (props) {
  return props.extendFieldText ? props.extendFieldText : '';
});

var IsRequiredNote = exports.IsRequiredNote = _styledComponents2.default.p(_templateObject12);

var DisableOverLay = exports.DisableOverLay = _styledComponents2.default.div(_templateObject13, function (props) {
  return !props.isDisabled ? 'none' : 'block';
});

var Container = exports.Container = _styledComponents2.default.div(_templateObject14);

var TooltipContainer = exports.TooltipContainer = _styledComponents2.default.div(_templateObject15, function (props) {
  return props.language === 'en' ? '60px' : 'unset';
});
var TooltipMessage = exports.TooltipMessage = _styledComponents2.default.p(_templateObject16);

var CategoryLabelContainer = exports.CategoryLabelContainer = _styledComponents2.default.div(_templateObject17);

var CategoryLabel = exports.CategoryLabel = _styledComponents2.default.p(_templateObject18);
'use strict';

exports.__esModule = true;
exports.TransparentA = exports.PaginationItemPrevious = exports.PaginationItemNext = exports.PaginationItemArrow = exports.PaginationDots = exports.PaginationBox = exports.PaginationItem = exports.PaginationConatiner = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n    margin: 30px auto;\n    // text-align: center;\n    direction: ', ';\n    ', ';\n    ', '\n    ', '\n    ', '\n'], ['\n    margin: 30px auto;\n    // text-align: center;\n    direction: ', ';\n    ', ';\n    ', '\n    ', '\n    ', '\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n        display: ', '\n    '], ['\n        display: ', '\n    ']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  width: 32px;\n  height: 32px;\n  text-align: center;\n  font-size: 14px;\n  line-height: 33px;\n  display: inline-grid;\n  vertical-align: top;\n  margin: 0px 2px;\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n  cursor: ', ';\n  ', ' ', ';\n'], ['\n  width: 32px;\n  height: 32px;\n  text-align: center;\n  font-size: 14px;\n  line-height: 33px;\n  display: inline-grid;\n  vertical-align: top;\n  margin: 0px 2px;\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n  cursor: ', ';\n  ', ' ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n        width: 42px;\n        height: 42px;\n        font-size: 18px;\n        line-height: 42px;\n        margin: 0px 4px;\n    \n    '], ['\n        width: 42px;\n        height: 42px;\n        font-size: 18px;\n        line-height: 42px;\n        margin: 0px 4px;\n    \n    ']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n        width: 32px;\n        height: 32px;\n        font-size: 22px;\n        line-height: 33px;\n        margin: 0px 7px;\n    '], ['\n        width: 32px;\n        height: 32px;\n        font-size: 22px;\n        line-height: 33px;\n        margin: 0px 7px;\n    ']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  background: ', ';\n  border-radius: 4px;\n  // border: 1px solid #d1d1d1;\n  font-size: 14px;\n  line-height: 33px;\n  color: #484848;\n  &:hover {\n    ', ';\n  }\n  ', ';\n'], ['\n  color: ', ';\n  background: ', ';\n  border-radius: 4px;\n  // border: 1px solid #d1d1d1;\n  font-size: 14px;\n  line-height: 33px;\n  color: #484848;\n  &:hover {\n    ', ';\n  }\n  ', ';\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  color: #6f7072;\n'], ['\n  color: #6f7072;\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  opacity: ', ';\n  &:after {\n    color: white;\n    font-weight: ', ';\n    float: left;\n    margin-left: 5px;\n    margin-right: 5px;\n    color: ', ';\n    font-family: \'icomoon\' !important;\n    transform: ', ';\n    font-size: 18px;\n    ', ';\n    ', ';\n  }\n  ', ';\n'], ['\n  opacity: ', ';\n  &:after {\n    color: white;\n    font-weight: ', ';\n    float: left;\n    margin-left: 5px;\n    margin-right: 5px;\n    color: ', ';\n    font-family: \'icomoon\' !important;\n    transform: ', ';\n    font-size: 18px;\n    ', ';\n    ', ';\n  }\n  ', ';\n']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n                font-size: 15px;\n            '], ['\n                font-size: 15px;\n            ']),
    _templateObject10 = _taggedTemplateLiteralLoose(['\n                font-size: 12px;\n            '], ['\n                font-size: 12px;\n            ']),
    _templateObject11 = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n']),
    _templateObject12 = _taggedTemplateLiteralLoose(['\n  color: transparent;\n\n  ', ';\n'], ['\n  color: transparent;\n\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var PaginationConatiner = exports.PaginationConatiner = _styledComponents2.default.div(_templateObject, function (props) {
  return props.theme && props.theme.direction ? 'rtl' : props.theme.direction;
}, function (props) {
  return props.extendContainerStyle ? props.extendContainerStyle : '';
}, function (props) {
  return props.theme.media.phone(_templateObject2, props.desktop ? 'none' : 'inherit');
}, function (props) {
  return props.theme.media.tablet(_templateObject2, props.desktop ? 'inherit' : 'none');
}, function (props) {
  return props.theme.media.desktop(_templateObject2, props.desktop ? 'inherit' : 'none');
});

var PaginationItem = exports.PaginationItem = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.selected || props.disabled ? 'default' : 'pointer';
}, function (props) {
  return props.theme.media.tablet(_templateObject4);
}, function (props) {
  return props.theme.media.desktop(_templateObject5);
});
var PaginationBox = exports.PaginationBox = (0, _styledComponents2.default)(PaginationItem)(_templateObject6, function (props) {
  return props.selected ? props.background : props.color;
}, function (props) {
  return props.selected ? props.color : props.background;
}, function (props) {
  return props.selected || props.disabled ? '' : 'background: #f2f6f9a6;';
}, function (props) {
  return props.extendPageTag ? props.extendPageTag : '';
});

var PaginationDots = exports.PaginationDots = (0, _styledComponents2.default)(PaginationItem)(_templateObject7);

var PaginationItemArrow = exports.PaginationItemArrow = (0, _styledComponents2.default)(PaginationBox)(_templateObject8, function (props) {
  return props.disabled ? '0.5' : '1';
}, function (props) {
  return props.openSymbol ? 'normal' : 'bold';
}, function (props) {
  return props.theme.primary ? props.theme.primary : '#0070cd';
}, function (props) {
  return props.theme.direction === 'ltr' ? 'rotate(180deg)' : 'unset';
}, function (props) {
  return props.theme.media.tablet(_templateObject9);
}, function (props) {
  return props.theme.media.phone(_templateObject10);
}, function (props) {
  return props.extendPageNumberBoxStyle ? props.extendPageNumberBoxStyle : '';
});

var PaginationItemNext = exports.PaginationItemNext = (0, _styledComponents2.default)(PaginationItemArrow)(_templateObject11, function (props) {
  return props.extendArrowsBoxStyle ? props.extendArrowsBoxStyle : '';
}, function (props) {
  return props.extendPageTag ? props.extendPageTag : '';
});

var PaginationItemPrevious = exports.PaginationItemPrevious = (0, _styledComponents2.default)(PaginationItemArrow)(_templateObject11, function (props) {
  return props.extendArrowsBoxStyle ? props.extendArrowsBoxStyle : '';
}, function (props) {
  return props.extendPageTag ? props.extendPageTag : '';
});

var TransparentA = exports.TransparentA = _styledComponents2.default.a(_templateObject12, function (props) {
  return !props.disabled ? '' : '\n        pointer-events: none;\n        cursor: default;';
});
'use strict';

exports.__esModule = true;
exports.MENU_ALIGNMENT = undefined;

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Item = require('./Item');

var _Item2 = _interopRequireDefault(_Item);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./DropDown.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MENU_ALIGNMENT = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

var DropDown = (_temp2 = _class = function (_Component) {
  _inherits(DropDown, _Component);

  function DropDown() {
    var _temp, _this, _ret;

    _classCallCheck(this, DropDown);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      isOpen: false
    }, _this.showMenu = function () {
      var disabled = _this.props.disabled;

      _this.setState(function (prevState) {
        return {
          isOpen: !disabled && !prevState.isOpen
        };
      });
    }, _this.hideMenu = function (event) {
      if (_this.containerRef && !_this.containerRef.contains(event.target) && _this.state.isOpen) {
        _this.setState({
          isOpen: false
        });
      }
    }, _this.renderList = function () {
      var items = _this.props.items;

      return items.map(function (item, index) {
        return _react2.default.createElement(_Item2.default, {
          key: item.text || index,
          icon: item.icon,
          image: item.image,
          text: item.text,
          onClick: item.onClick || _this.props.onChange,
          isExpandable: item.isExpandable,
          disabled: item.disabled,
          component: item.component,
          isDivider: item.isDivider,
          isHighlighted: item.isHighlighted,
          options: item.options
        });
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  DropDown.prototype.componentDidMount = function componentDidMount() {
    document.addEventListener('mousedown', this.hideMenu);
  };

  DropDown.prototype.componentWillUnmount = function componentWillUnmount() {
    document.removeEventListener('mousedown', this.hideMenu);
  };

  DropDown.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        component = _props.component,
        menuAlignment = _props.menuAlignment,
        disabled = _props.disabled,
        className = _props.className;
    var isOpen = this.state.isOpen;


    return _react2.default.createElement(
      'div',
      {
        className: (0, _classnames2.default)('drop-down--container', className),
        onClick: this.showMenu,
        ref: function ref(div) {
          _this2.containerRef = div;
        },
        onKeyDown: function onKeyDown() {}
      },
      _react2.default.createElement(
        'div',
        {
          className: (0, _classnames2.default)('view--container', 'center--vertical', {
            'view--container__disabled': disabled
          })
        },
        component
      ),
      _react2.default.createElement(
        'ul',
        {
          className: (0, _classnames2.default)('menu--container', {
            'menu--container__left': menuAlignment === MENU_ALIGNMENT.LEFT,
            'menu--container__open': isOpen
          })
        },
        this.renderList()
      )
    );
  };

  return DropDown;
}(_react.Component), _class.defaultProps = {
  menuAlignment: MENU_ALIGNMENT.RIGHT,
  onChange: undefined,
  disabled: false,
  className: undefined
}, _temp2);
DropDown.propTypes = process.env.NODE_ENV !== "production" ? {
  items: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    image: _propTypes2.default.string,
    icon: _propTypes2.default.object,
    text: _propTypes2.default.string,
    onClick: _propTypes2.default.func,
    disabled: _propTypes2.default.bool,
    component: _propTypes2.default.any,
    isDivider: _propTypes2.default.bool
  })).isRequired,
  onChange: _propTypes2.default.func,
  component: _propTypes2.default.node.isRequired,
  menuAlignment: _propTypes2.default.oneOf([MENU_ALIGNMENT.RIGHT, MENU_ALIGNMENT.LEFT]),
  disabled: _propTypes2.default.bool,
  className: _propTypes2.default.string
} : {};
exports.MENU_ALIGNMENT = MENU_ALIGNMENT;
exports.default = (0, _WithDisplayName2.default)(DropDown, 'DropDown');
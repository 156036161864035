'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  max-height: 84px;\n  overflow-y: auto;\n  width: calc(100% - 20px);\n'], ['\n  max-height: 84px;\n  overflow-y: auto;\n  width: calc(100% - 20px);\n']);

var _gridStyled = require('../grid-styled');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var TagsContainer = _gridStyled.Flex.extend(_templateObject);

exports.default = TagsContainer;
module.exports = exports['default'];
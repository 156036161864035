'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  width: 500px;\n  ', ';\n'], ['\n  position: relative;\n  width: 500px;\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Search = require('./Search');

var _Search2 = _interopRequireDefault(_Search);

var _MenuList = require('../menuList/MenuList');

var _MenuList2 = _interopRequireDefault(_MenuList);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var SearchContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.extendSearchContainerStyle ? props.extendSearchContainerStyle : '';
});

var typingTimeout = void 0;

var SearchWithMenu = function (_PureComponent) {
  _inherits(SearchWithMenu, _PureComponent);

  function SearchWithMenu(props) {
    _classCallCheck(this, SearchWithMenu);

    var _this = _possibleConstructorReturn(this, _PureComponent.call(this, props));

    _this.onSelect = function (index, value, fieldValue) {
      _this.setState({
        fieldValue: fieldValue,
        showMenuList: false,
        showClearButton: true
      }, function () {
        if (_this.props.onSelect) {
          _this.props.onSelect(value);
        }
      });
    };

    _this.onTyping = function (fieldValue) {
      _this.setState({
        fieldValue: fieldValue,
        showClearButton: true
      }, function () {
        if (fieldValue) {
          _this.showMenuList();
        } else {
          _this.hideMenuList();
        }

        if (Array.from(fieldValue).length >= _this.props.triggerOnTypingAfter) {
          clearTimeout(typingTimeout);
          typingTimeout = setTimeout(function () {
            if (_this.props.onTyping) {
              _this.props.onTyping(fieldValue);
            }
          }, _this.props.onTypingDelay);
        }
      });
    };

    _this.onReset = function () {
      _this.setState({
        fieldValue: '',
        showClearButton: false
      }, function () {
        _this.hideMenuList();

        if (_this.props.onReset) {
          _this.props.onReset();
        }
      });
    };

    _this.toggleMenuList = function () {
      if (!_this.state.showMenuList) {
        _this.showMenuList();
      } else {
        _this.hideMenuList();
      }
    };

    _this.showMenuList = function () {
      _this.setState({
        showMenuList: true
      });
    };

    _this.hideMenuList = function () {
      _this.setState({
        showMenuList: false
      });
    };

    _this.state = {
      fieldValue: '',
      showMenuList: false,
      showClearButton: false
    };
    return _this;
  }

  SearchWithMenu.prototype.render = function render() {
    var _props = this.props,
        extendSearchContainerStyle = _props.extendSearchContainerStyle,
        extendSearchStyle = _props.extendSearchStyle,
        isMenuDisabled = _props.isMenuDisabled;
    var placeholder = this.props.placeholder;

    var iconProps = {
      mr: 3
    };

    return _react2.default.createElement(
      SearchContainer,
      { extendSearchContainerStyle: extendSearchContainerStyle },
      _react2.default.createElement(_Search2.default, {
        width: 1,
        px: 3,
        value: this.state.fieldValue,
        onChange: this.onTyping,
        onClick: this.toggleMenuList,
        placeholder: placeholder,
        onReset: this.onReset,
        showClearButton: this.state.showClearButton && this.state.fieldValue,
        iconProps: iconProps,
        clearText: this.props.clearText,
        extendSearchStyle: extendSearchStyle
      }),
      !isMenuDisabled && _react2.default.createElement(_MenuList2.default, {
        items: this.props.items,
        disabledItems: this.props.disabledItems,
        show: this.state.showMenuList,
        onClick: this.onSelect
      })
    );
  };

  return SearchWithMenu;
}(_react.PureComponent);

SearchWithMenu.propTypes = process.env.NODE_ENV !== "production" ? {
  isMenuDisabled: _propTypes2.default.bool,
  placeholder: _propTypes2.default.string,
  onTyping: _propTypes2.default.func,
  onSelect: _propTypes2.default.func,
  onTypingDelay: _propTypes2.default.number,
  triggerOnTypingAfter: _propTypes2.default.number,
  onReset: _propTypes2.default.func,
  items: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    children: _propTypes2.default.any,
    value: _propTypes2.default.any,
    fieldValue: _propTypes2.default.string,
    disable: _propTypes2.default.bool
  })),
  disabledItems: _propTypes2.default.arrayOf(_propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number])),
  clearText: _propTypes2.default.string,
  extendSearchContainerStyle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.array]),
  extendSearchStyle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.array])
} : {};

SearchWithMenu.defaultProps = {
  placeholder: 'Search',
  items: [],
  onTypingDelay: 2000,
  triggerOnTypingAfter: 3,
  onSelect: function onSelect() {},
  onTyping: function onTyping() {},
  onReset: function onReset() {},
  disabledItems: [],
  clearText: 'Reset'
};

exports.default = (0, _WithDisplayName2.default)(SearchWithMenu, 'SearchWithMenu');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _MenuItem = require('./MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _ShimmerEffect = require('../shimmerEffect/ShimmerEffect');

var _ShimmerEffect2 = _interopRequireDefault(_ShimmerEffect);

var _Rect = require('../shimmerEffect/Rect');

var _Rect2 = _interopRequireDefault(_Rect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* eslint-disable */


// TODO: Use this in ComboBox
var counter = 0;
var UP_ARROW = 38;
var DOWN_ARROW = 40;
var ENTER = 13;
var ESCAPE = 27;

var SUPPORTED_SHORTCUTS = [UP_ARROW, DOWN_ARROW, ENTER, ESCAPE];

var MenuList = function (_Component) {
  _inherits(MenuList, _Component);

  function MenuList(props) {
    _classCallCheck(this, MenuList);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.toggleMenu = function () {
      if (!_this.props.disabled) {
        if (_this.state.isMenuOpened) {
          _this.hideMenuList();
        } else {
          _this.showMenuList();
        }
      }
    };

    _this.showMenuList = function () {
      _this.setState({
        isMenuOpened: true
      }, function () {
        _this.menu.style.display = 'block';
      });
    };

    _this.hideMenuList = function () {
      _this.setState({
        isMenuOpened: false
      }, function () {
        _this.menu.style.display = 'none';
      });
    };

    _this.state = {
      items: _this.props.items,
      disabledItems: _this.props.disabledItems,
      itemsRefs: new Map(),
      isLoading: !_this.props.items
    };
    return _this;
  }

  MenuList.prototype.componentWillMount = function componentWillMount() {
    // if (this.props.disabledItems) {
    //   this.disableItems(this.props.disabledItems);
    // }

    if (this.props.toggleShow) {
      this.showMenuList();
    }
  };

  MenuList.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (nextProps.items && !_lodash2.default.isEqual(nextProps.items, this.props.items)) {
      this.setState({
        items: nextProps.items,
        isLoading: false
      });
    }
    // if (nextProps.disabledItems !== this.props.disabledItems) {
    //   this.disableItems(nextProps.disabledItems);
    // }

    if (nextProps.show) {
      this.showMenuList();
    } else {
      this.hideMenuList();
    }
  };

  /**
   * Show/hide menu list
   */


  /**
   * Shows/hides the menu list
   */


  /**
   * Hides the list
   */


  MenuList.prototype.render = function render() {
    var _this2 = this;

    var items = void 0;
    if (this.state.isLoading) {
      items = _react2.default.createElement(
        _ShimmerEffect2.default,
        null,
        _react2.default.createElement(_Rect2.default, null),
        _react2.default.createElement(_Rect2.default, null),
        _react2.default.createElement(_Rect2.default, null),
        _react2.default.createElement(_Rect2.default, null)
      );
    } else {
      items = this.state.items.map(function (item, index) {
        return _react2.default.createElement(
          _MenuItem2.default,
          {
            key: counter++,
            index: index,
            onClick: function onClick(index, value, fieldValue) {
              if (_this2.props.onClick) {
                _this2.props.onClick(index, value, fieldValue);
              }
              _this2.hideMenuList();
            },
            onMouseEnter: _this2.props.onMouseEnter,
            onFocus: _this2.props.onFocus,
            value: item.value,
            fieldValue: item.fieldValue,
            disable: item.disable,
            ref: function ref(menuItem) {
              return _this2.state.itemsRefs.set(index, menuItem);
            }
          },
          item.children
        );
      });
    }

    return _react2.default.createElement(
      'ul',
      {
        className: 'menu',
        ref: function ref(ul) {
          _this2.menu = ul;
        },
        onKeyDown: this.navigateWithKeyboard,
        tabIndex: 0
      },
      items
    );
  };

  return MenuList;
}(_react.Component);

MenuList.propTypes = process.env.NODE_ENV !== "production" ? {
  items: _propTypes.PropTypes.arrayOf(_propTypes.PropTypes.shape({
    children: _propTypes.PropTypes.any,
    value: _propTypes.PropTypes.any,
    fieldValue: _propTypes.PropTypes.string,
    disable: _propTypes.PropTypes.bool
  })),
  disabledItems: _propTypes.PropTypes.array,
  toggleShow: _propTypes.PropTypes.bool,
  show: _propTypes.PropTypes.bool,
  select: _propTypes.PropTypes.any,
  onClick: _propTypes.PropTypes.func,
  onMouseEnter: _propTypes.PropTypes.func,
  onMouseLeave: _propTypes.PropTypes.func,
  onFocus: _propTypes.PropTypes.func
} : {};

exports.default = (0, _WithDisplayName2.default)(MenuList, 'MenuList');
module.exports = exports['default'];
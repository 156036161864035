"use strict";

exports.__esModule = true;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IconsStore = function IconsStore(icons) {
  var _this = this;

  _classCallCheck(this, IconsStore);

  this.icons = {};

  this.getIcons = function () {
    return _this.icons;
  };

  this.getIcon = function (name) {
    var iconName = "ic_" + name;
    var requestedIcon = _this.icons.icons.filter(function (icon) {
      return icon.properties.name === iconName;
    });
    if (requestedIcon.length === 0) {
      requestedIcon = {};
    } else {
      requestedIcon = requestedIcon.pop();
    }

    return requestedIcon;
  };

  this.icons = icons;
};

exports.default = IconsStore;
module.exports = exports["default"];
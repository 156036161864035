'use strict';

exports.__esModule = true;

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Typography = require('../base/Typography');

var _Colors = require('../base/Colors');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MenuItem = (_temp2 = _class = function (_Component) {
  _inherits(MenuItem, _Component);

  function MenuItem() {
    var _temp, _this, _ret;

    _classCallCheck(this, MenuItem);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      isSelected: false,
      isFocused: false
    }, _this.onClick = function () {
      var _this$props = _this.props,
          disabled = _this$props.disabled,
          isGroupHeadline = _this$props.isGroupHeadline,
          onClick = _this$props.onClick;

      if (!disabled && !isGroupHeadline) {
        onClick(_this.props);
      }
    }, _this.onHover = function () {
      var _this$props2 = _this.props,
          index = _this$props2.index,
          itemKey = _this$props2.itemKey,
          onHover = _this$props2.onHover;
      var isFocused = _this.state.isFocused;

      if (!isFocused) {
        onHover(itemKey, index);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  MenuItem.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isSelected !== prevState.isSelected) {
      return {
        isSelected: nextProps.isSelected
      };
    }

    if (nextProps.isFocused !== prevState.isFocused) {
      return {
        isFocused: nextProps.isFocused
      };
    }

    return null;
  };

  MenuItem.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    if (!this.props.isMultiple && this.state.isSelected && prevProps.isMenuOpen !== this.props.isMenuOpen || this.state.isFocused && !this.props.disableScroll) {
      this.item.parentNode.scrollTop = this.item.offsetTop - (this.props.isMultiple ? 51 : 0);
    }
  };

  MenuItem.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        children = _props.children,
        isSelected = _props.isSelected,
        isFocused = _props.isFocused,
        disabled = _props.disabled,
        itemKey = _props.itemKey,
        keyPrefix = _props.keyPrefix,
        isGroupHeadline = _props.isGroupHeadline;

    var fontType = isGroupHeadline ? _Typography.FONT_TYPES.CAPTION : _Typography.FONT_TYPES.BODY;
    var fontWeight = isGroupHeadline ? _Typography.FONT_WEIGHTS.SEMI_BOLD : _Typography.FONT_WEIGHTS.NORMAL;
    var color = isGroupHeadline ? _Colors.COLORS.HELP_TEXT : _Colors.COLORS.TEXT;
    var cursor = isGroupHeadline ? 'default' : 'pointer';

    return _react2.default.createElement(
      'li',
      {
        className: (0, _classnames2.default)({
          selected: !disabled && isSelected,
          focused: !disabled && isFocused && !isGroupHeadline,
          disabled: disabled,
          headline: isGroupHeadline
        }),
        onMouseDown: this.onClick,
        onMouseMove: this.onHover,
        onKeyDown: function onKeyDown() {},
        tabIndex: 0,
        key: keyPrefix + '-' + itemKey,
        ref: function ref(item) {
          _this2.item = item;
        },
        role: 'button' // eslint-disable-line
      },
      typeof children === 'string' || typeof children === 'number' ? _react2.default.createElement(
        _Text2.default,
        { cursor: cursor, type: fontType, fontWeight: fontWeight, color: color },
        children
      ) : children
    );
  };

  return MenuItem;
}(_react.Component), _class.defaultProps = {
  isSelected: false,
  isFocused: false,
  isMenuOpen: false,
  isMultiple: false,
  disableScroll: false,
  disabled: false,
  isGroupHeadline: false,
  index: 0
}, _class.defaultProps = {
  onClick: function onClick() {},
  onHover: function onHover() {}
}, _temp2);
MenuItem.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.any.isRequired, // eslint-disable-line
  onClick: _propTypes2.default.func,
  onHover: _propTypes2.default.func,
  isSelected: _propTypes2.default.bool,
  isFocused: _propTypes2.default.bool,
  isMenuOpen: _propTypes2.default.bool,
  isMultiple: _propTypes2.default.bool,
  disableScroll: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  isGroupHeadline: _propTypes2.default.bool,
  itemKey: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired,
  index: _propTypes2.default.number,
  keyPrefix: _propTypes2.default.string.isRequired
} : {};
exports.default = (0, _WithDisplayName2.default)(MenuItem, 'MenuItem');
module.exports = exports['default'];
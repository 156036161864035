'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 4px;\n  background-color: ', ';\n  height: 34px;\n  ', ';\n'], ['\n  border-radius: 4px;\n  background-color: ', ';\n  height: 34px;\n  ', ';\n']);

var _gridStyled = require('../grid-styled');

var _Colors = require('../base/Colors');

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var SearchContainer = _gridStyled.Flex.extend(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.PAGE_BACKGROUND], function (props) {
  return props.extendSearchStyle ? props.extendSearchStyle : '';
});

exports.default = SearchContainer;
module.exports = exports['default'];
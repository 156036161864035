'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

require('./SnackBar.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LOADING = 'loading';
var NOTIFICATIONS = 'notifications';

var SnackBar = function (_Component) {
  _inherits(SnackBar, _Component);

  function SnackBar(props) {
    _classCallCheck(this, SnackBar);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.toggleSnackBar = function (newState, message) {
      var messageType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : NOTIFICATIONS;

      if (newState === true) {
        _this.showSnackBar(message, messageType);
      } else {
        _this.hideSnackBar();
      }
    };

    _this.showSnackBar = function (message) {
      var messageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NOTIFICATIONS;

      _this.setState({
        message: messageType === NOTIFICATIONS ? message : message + '...'
      }, function () {
        if (_this.snackBar) {
          _this.snackBar.style.marginBottom = '40px';
        }

        // Auto hide the snack-bar after set of time
        // Default autoHide = true
        // Default timeout = 5000ms
        if (_this.props.autoHide) {
          setTimeout(function () {
            _this.hideSnackBar();
          }, _this.props.autoHideTime);
        }
      });
    };

    _this.hideSnackBar = function () {
      if (_this.snackBar) {
        _this.snackBar.style.marginBottom = '-90px';
      }

      if (_this.props.onHide) {
        _this.props.onHide();
      }
    };

    _this.iconsStore = new _IconsStore2.default(_webIcons2.default);
    _this.state = {
      message: _this.props.message,
      messageType: _this.props.messageType
    };
    return _this;
  }

  SnackBar.prototype.componentDidMount = function componentDidMount() {
    if (this.props.show === true) {
      this.showSnackBar(this.props.message, NOTIFICATIONS);
    }
  };

  SnackBar.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show && nextProps.show === true) {
      this.showSnackBar(nextProps.message, NOTIFICATIONS);
    }
  };

  /**
   * Change state between show/hide snackBar
   * @param {bool} newState, true = show, false = hide
   * @param {string} message, message that will be displayed
   * @param {string} messageType, notifications or loading
   */


  /**
   * Show the snack-bar
   * @param {string} message, the message that will be displayed
   * @param {string} messageType, notifications or loading
   */


  /**
   * Hide the snack-bar
   */


  SnackBar.prototype.render = function render() {
    var _this2 = this;

    return _react2.default.createElement(
      'div',
      { className: 'snack-bar-window' },
      _react2.default.createElement(
        'div',
        {
          className: (0, _classnames2.default)('snack-bar-container', {
            'snack-bar-container--danger': this.state.isDanger,
            'snack-bar-container--success': !this.state.isDanger
          }),
          ref: function ref(snackBar) {
            _this2.snackBar = snackBar;
          }
        },
        _react2.default.createElement(
          _Text2.default,
          {
            tag: 'h3',
            type: _Typography.FONT_TYPES.SUBHEADING,
            fontWeight: _Typography.FONT_WEIGHTS.NORMAL,
            className: 'message-text'
          },
          this.state.message
        ),
        this.state.messageType === NOTIFICATIONS && _react2.default.createElement(
          'button',
          { className: 'close-btn', onClick: this.hideSnackBar },
          _react2.default.createElement(_Icon2.default, {
            className: 'close-icon',
            icon: this.iconsStore.getIcon('close'),
            width: 14,
            color: '#fff'
          })
        )
      )
    );
  };

  return SnackBar;
}(_react.Component);

SnackBar.propTypes = process.env.NODE_ENV !== "production" ? {
  onHide: _propTypes2.default.func,
  autoHide: _propTypes2.default.bool,
  autoHideTime: _propTypes2.default.number,
  show: _propTypes2.default.bool,
  message: _propTypes2.default.string,
  messageType: _propTypes2.default.oneOf([NOTIFICATIONS, LOADING])
} : {};

SnackBar.defaultProps = {
  autoHide: true,
  autoHideTime: 5000,
  show: false,
  message: '',
  messageType: NOTIFICATIONS,
  onHide: function onHide() {}
};

exports.default = (0, _WithDisplayName2.default)(SnackBar, 'SnackBar');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _webUtils = require('@vezeeta/web-utils');

var _Icon = require('../../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _DropDown = require('./DropDown.style');

var _DropDown2 = require('./DropDown.helper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */


var DropDown = function DropDown(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var fieldLabel = props.fieldLabel,
      fieldValue = props.fieldValue,
      searchable = props.searchable,
      multipleSelection = props.multipleSelection,
      options = props.options,
      extendDropDownStyle = props.extendDropDownStyle,
      extendFieldText = props.extendFieldText,
      icon = props.icon,
      iconSize = props.iconSize,
      onChanges = props.onChanges,
      onSearch = props.onSearch,
      isRequired = props.isRequired,
      extendDropDownList = props.extendDropDownList,
      extendDropDownListItem = props.extendDropDownListItem,
      onClickIconAction = props.onClickIconAction,
      componentName = props.componentName,
      isDisabled = props.isDisabled,
      language = props.language,
      showTooltip = props.showTooltip,
      isValid = props.isValid,
      placeHolder = props.placeHolder,
      fontSize = props.fontSize,
      enableOtherOption = props.enableOtherOption,
      fieldIcon = props.fieldIcon,
      fieldIconSize = props.fieldIconSize,
      variationB = props.variationB,
      isCategorized = props.isCategorized,
      scrollToElementById = props.scrollToElementById;

  var _useState = (0, _react.useState)(options),
      optionList = _useState[0],
      setOptionList = _useState[1]; // for List View


  var _useState2 = (0, _react.useState)(options),
      searchedOptionList = _useState2[0],
      setSearchedOptionList = _useState2[1]; // for List View


  var _useState3 = (0, _react.useState)([]),
      selectedOptions = _useState3[0],
      setSelectOption = _useState3[1]; // for selected items


  var _useState4 = (0, _react.useState)(''),
      typedValue = _useState4[0],
      setTypedValue = _useState4[1];
  // const [dropDownToTop, setDropDownToTop] = useState(false);

  var toggleDropDowns = function toggleDropDowns() {
    var dropDowns = document.querySelectorAll('div[class^="dropdown"]');
    var anotherDropDownsLabels = [];

    // Get all onther dropdown other than the current clicked one
    dropDowns.forEach(function (dropDown) {
      var dropDownClassName = Array.from(dropDown.classList).filter(function (className) {
        return className.includes('dropdown-');
      });
      if (dropDownClassName[0] !== 'dropdown-' + componentName) {
        anotherDropDownsLabels.push(dropDownClassName[0]);
      }
    });

    // Close opened lists of all onther dropdowns
    anotherDropDownsLabels.forEach(function (dropDown) {
      var label = dropDown.split('-')[1];
      var list = document.getElementById('dropdown-list-' + label);
      if (list) list.style.display = 'none';
    });
  };

  var prevOptions = (0, _webUtils.usePrevious)(options);
  (0, _react.useEffect)(function () {
    if (!_lodash2.default.isEqual(prevOptions, options)) {
      setOptionList(options);
      setSearchedOptionList(options);
    }
  }, [options]);

  var scrollParentToChild = function scrollParentToChild() {
    var parent = document.getElementById('dropdown-list-' + componentName);
    var child = document.getElementById(scrollToElementById);
    if (parent && child) {
      var parentList = parent.firstElementChild;
      /* // Where is the parent on page
      const parentRect = parentList.getBoundingClientRect();
      // What can you see?
      const parentViewableArea = {
        height: parentList.clientHeight,
        width: parentList.clientWidth,
      }; */
      // Where is the child
      // Is the child viewable?
      parentList.scrollTo({ top: child.offsetTop - 15, behavior: 'smooth' });

      /* console.log(parentViewableArea.height);
      const isViewable =
        child.offsetTop >= parentRect.top & !(child.offsetTop > parentRect.top + parentViewableArea.height);
       // if you can't see the child try to scroll parent
      if (!isViewable) {
        // Should we scroll using top or bottom? Find the smaller ABS adjustment
        let scrollTop = child.offsetTop - parentRect.top;
        scrollTop = scrollTop - 10 < 0 ? 0 : scrollTop - 10;
      } */
    }
  };

  // eslint-disable-next-line no-unused-vars
  var onItemSelect = function onItemSelect(option, index) {
    if (searchable) setTypedValue(option.fieldValue);
    setSelectOption([option]);
    if (onChanges) {
      onChanges(option.value);
    }
    (0, _DropDown2.dropDownListToggler)(optionList, componentName, variationB);
    setSearchedOptionList(options);
  };

  (0, _react.useEffect)(function () {
    if (fieldValue !== undefined && optionList && optionList.length) {
      var filteredOption = optionList.filter(function (option) {
        return option.value === fieldValue;
      });
      if (filteredOption && filteredOption[0]) {
        setTypedValue(filteredOption[0].fieldValue);
        setSelectOption(filteredOption);
      }
    } else {
      var list = document.getElementById('dropdown-list-' + componentName);
      list.style.display = 'none';
      setTypedValue('');
      setSelectOption([]);
      setSearchedOptionList(options);
    }
  }, [fieldValue, optionList]);

  var onSearchInList = function onSearchInList(value) {
    onChanges(undefined);
    if (value) {
      var serachResult = optionList.filter(function (option) {
        return String(option.fieldValue).toLowerCase().includes(String(value).toLowerCase()) || String(option.key).toLowerCase().includes('other');
      });

      setSearchedOptionList(serachResult);
      (0, _DropDown2.dropDownListTogglerBySearchOptions)(serachResult, 'dropdown-list-' + componentName);
    } else {
      setSearchedOptionList(options);
    }

    setTypedValue(value);
    setTimeout(function () {
      if (onSearch) onSearch(value);
    }, 1000);
  };

  var isFieldValueRendered = !searchable && selectedOptions.length > 0;
  var isInputFieldRendered = searchable;

  var getTooltipMessage = function getTooltipMessage() {
    if (showTooltip && fieldValue) {
      return (0, _DropDown2.renderSelectedOptions)(selectedOptions, multipleSelection);
    }
    return false;
  };

  var onFieldClick = function onFieldClick() {
    // setFocus(true);
    if (searchable) {
      if (document.getElementById('input-container-' + componentName)) {
        document.getElementById('input-container-' + componentName).focus();
      }
    }
    toggleDropDowns();
    (0, _DropDown2.dropDownListToggler)(optionList, componentName, variationB, scrollParentToChild);

    /* // Get DropDown
    const listComponent = document.getElementsByClassName(`dropdown-${componentName}`);
    // Get Parent Hight
    const parentHeight = listComponent[0].offsetParent.clientHeight;
    // Position top of dropdown
    const listTopPosition = listComponent[0].offsetTop;
    // DropDown height
    const listComponentHeight = listComponent[0].clientHeight;
    // List Height
    const list = document.getElementById(`dropdown-list-${componentName}`);
    const itemListHight = list.children[0].clientHeight;
    if (parentHeight - listTopPosition - listComponentHeight < itemListHight) {
      // list open to top
      setDropDownToTop(-(6 + itemListHight + listComponentHeight));
    } else {
      // list open to down
      setDropDownToTop(6);
    } */
  };

  var listListenerHandler = function listListenerHandler(event) {
    var myElementToCheckIfClicksAreInsideOf = document.querySelector('#' + componentName);
    if (!myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
      var list = document.getElementById('dropdown-list-' + componentName);
      list.style.display = 'none';
      if (variationB) {
        var listBtn = document.getElementById('dropdown-list-btn-' + componentName);
        listBtn.style.backgroundColor = 'unset';
      }
    }
  };

  var addListener = function addListener() {
    document.body.addEventListener('click', listListenerHandler, true);
  };

  var removeListener = function removeListener() {
    document.body.removeEventListener('click', listListenerHandler, true);
  };

  (0, _react.useEffect)(function () {
    addListener();
    return function () {
      removeListener();
    };
  }, []);

  var showNoResultMessage = searchedOptionList && searchedOptionList[0] && searchedOptionList[0].key === 'other' && enableOtherOption;

  return _react2.default.createElement(
    _DropDown.Container,
    { className: 'dropdown dropdown-' + componentName, id: componentName },
    _react2.default.createElement(_DropDown.DisableOverLay, { isDisabled: isDisabled }),
    getTooltipMessage() && _react2.default.createElement(
      _DropDown.TooltipContainer,
      { language: language },
      _react2.default.createElement(
        _DropDown.TooltipMessage,
        null,
        getTooltipMessage()
      )
    ),
    _react2.default.createElement(
      _DropDown.FieldContainer,
      {
        id: 'dropdown-list-btn-' + componentName,
        isValid: isValid,
        isDimmed: false,
        extendDropDownStyle: extendDropDownStyle,
        onClick: function onClick() {
          if (options && options.length || searchable) onFieldClick();
        }
      },
      fieldIcon && _react2.default.createElement(
        'div',
        {
          style: {
            margin: '0px 4px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }
        },
        _react2.default.createElement(_Icon2.default, { className: 'icon', icon: _IconsStore2.default.getIcon(fieldIcon), width: fieldIconSize })
      ),
      _react2.default.createElement(
        _DropDown.LabelValueContainer,
        { style: { width: '90%' } },
        fieldLabel && _react2.default.createElement(
          _DropDown.FieldLabel,
          { isValueSelected: searchable || selectedOptions.length },
          fieldLabel,
          isRequired && _react2.default.createElement(
            _DropDown.IsRequiredNote,
            null,
            '*'
          )
        ),
        isFieldValueRendered ? _react2.default.createElement(
          _DropDown.FieldValue,
          { fontSize: fontSize, extendFieldText: extendFieldText },
          (0, _DropDown2.renderSelectedOptions)(selectedOptions, multipleSelection)
        ) : _react2.default.createElement(
          _DropDown.FieldValue,
          { isPlaceHolder: true, fontSize: fontSize, extendFieldText: extendFieldText },
          placeHolder
        ),
        isInputFieldRendered && _react2.default.createElement(_DropDown.SearchInput, {
          placeholder: placeHolder,
          extendFieldText: extendFieldText,
          autoComplete: 'off',
          id: 'input-container-' + componentName,
          type: 'text',
          value: typedValue,
          onChange: function onChange(e) {
            onSearchInList(e.target.value);
          }
        })
      ),
      icon && _react2.default.createElement(
        'div',
        {
          style: {
            margin: '0px 4px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }
        },
        _react2.default.createElement(_Icon2.default, {
          className: 'icon',
          onClick: function onClick(e) {
            if (onClickIconAction) {
              onClickIconAction();
              e.stopPropagation();
            }
          },
          icon: _IconsStore2.default.getIcon(icon),
          width: iconSize
        })
      )
    ),
    _react2.default.createElement(
      'div',
      { id: 'dropdown-list-' + componentName, style: { display: 'none', position: 'relative' } },
      _react2.default.createElement(
        _DropDown.ListContainer
        // dropDownToTop={dropDownToTop}
        ,
        { fontSize: fontSize,
          extendDropDownList: extendDropDownList
        },
        showNoResultMessage && _react2.default.createElement(
          'div',
          {
            style: {
              padding: '13px 26px',
              boxShadow: 'inset 0px -1px 0px #f1f4f6'
            }
          },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'p',
              {
                style: {
                  color: '#7E7E7E',
                  fontSize: '13px'
                }
              },
              language === 'en' ? 'No results found. Try different keywords or choose Other' : 'لم يتم العثور على نتائج مماثلة، يرجى التأكد من كتابة اسم التأمين بشكل صحيح أو اختيار "أخرى"'
            )
          )
        ),
        (0, _DropDown2.renderDropDownList)(searchedOptionList, multipleSelection, onItemSelect, {
          extendDropDownListItem: extendDropDownListItem
        }, language, isCategorized)
      )
    )
  );
};

DropDown.propTypes = process.env.NODE_ENV !== "production" ? {
  showTooltip: _propTypes2.default.bool,
  fieldValue: _propTypes2.default.string,
  fieldLabel: _propTypes2.default.string,
  icon: _propTypes2.default.string,
  searchable: _propTypes2.default.bool,
  selectFirst: _propTypes2.default.bool,
  multipleSelection: _propTypes2.default.bool,
  options: _propTypes2.default.array,
  onChanges: _propTypes2.default.func,
  iconSize: _propTypes2.default.number,
  extendDropDownStyle: _propTypes2.default.string,
  onSearch: _propTypes2.default.func,
  isHorizontallySorted: _propTypes2.default.bool,
  isRequired: _propTypes2.default.bool,
  extendDropDownList: _propTypes2.default.array,
  extendDropDownListItem: _propTypes2.default.string,
  onClickIconAction: _propTypes2.default.func,
  componentName: _propTypes2.default.string,
  isDisabled: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  isValid: _propTypes2.default.bool,
  placeHolder: _propTypes2.default.string,
  fontSize: _propTypes2.default.number,
  extendFieldText: _propTypes2.default.array,
  enableOtherOption: _propTypes2.default.bool,
  variationB: _propTypes2.default.bool,
  fieldIcon: _propTypes2.default.string,
  fieldIconSize: _propTypes2.default.number,
  isCategorized: _propTypes2.default.bool,
  scrollToElementById: _propTypes2.default.string
} : {};
exports.default = DropDown;
module.exports = exports['default'];
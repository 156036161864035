'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Option = require('./Option');

var _Option2 = _interopRequireDefault(_Option);

var _Rect = require('../shimmerEffect/Rect');

var _Rect2 = _interopRequireDefault(_Rect);

var _Circle = require('../shimmerEffect/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

var _NoAnimationContainer = require('../shimmerEffect/NoAnimationContainer');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./RadioButton.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RadioButton = function (_Component) {
  _inherits(RadioButton, _Component);

  function RadioButton(props) {
    _classCallCheck(this, RadioButton);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.getInputValue = function () {
      return _this.state.value;
    };

    _this.updateValue = function (value) {
      _this.setState({
        value: value
      }, function () {
        if (_this.props.onChange) {
          _this.props.onChange(_this.state.value);
        }
      });
    };

    _this.showErrorMessage = function () {};

    _this.hideErrorMessage = function () {};

    _this.isValid = function () {
      return true;
    };

    _this.validate = function () {};

    _this.focus = function () {};

    _this.state = {
      value: _this.props.select,
      isLoading: !_this.props.options
    };
    return _this;
  }

  RadioButton.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (!_lodash2.default.isEqual(nextProps.options, this.props.options) && nextProps.options.length !== 0) {
      this.setState({
        isLoading: false
      });
    }

    if (!_lodash2.default.isEqual(nextProps.select, this.props.select)) {
      this.updateValue(nextProps.select);
    }
  };

  /**
   * Get radio button value
   */


  /**
   * Updates this.state.value and call
   * this.props.onChange
   * @param value
   */


  /**
   * Interfaces that should be in all components that require user's input
   */


  RadioButton.prototype.render = function render() {
    var _this2 = this;

    var options = null;
    var _props = this.props,
        direction = _props.direction,
        disabled = _props.disabled;


    if (!this.state.isLoading) {
      options = this.props.options.map(function (option) {
        return _react2.default.createElement(_Option2.default, {
          key: option.value,
          value: option.value,
          name: option.name,
          defaultChecked: option.value === _this2.state.value,
          updateValue: _this2.updateValue,
          component: option.component,
          description: option.description,
          direction: direction,
          disable: disabled || !!option.disable
        });
      });
    } else {
      options = _react2.default.createElement(
        _NoAnimationContainer.NoAnimationFlex,
        { className: 'lazy-loading ' + this.props.listAlignment },
        _react2.default.createElement(
          _NoAnimationContainer.NoAnimationFlex,
          { alignItems: 'center' },
          _react2.default.createElement(_Circle2.default, { mr: 2, radius: 30 }),
          _react2.default.createElement(_Rect2.default, { width: 160 })
        ),
        _react2.default.createElement(
          _NoAnimationContainer.NoAnimationFlex,
          { alignItems: 'center' },
          _react2.default.createElement(_Circle2.default, { mr: 2, radius: 30 }),
          _react2.default.createElement(_Rect2.default, { width: 160 })
        )
      );
    }

    return _react2.default.createElement(
      'ul',
      {
        className: (0, _classnames2.default)('radio-button-list', this.props.listAlignment, this.props.className, {
          'radio-button-list--disabled': this.props.disabled
        })
      },
      options
    );
  };

  return RadioButton;
}(_react.Component);

RadioButton.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  select: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  options: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    name: _propTypes2.default.string,
    component: _propTypes2.default.element,
    description: _propTypes2.default.element
  })),
  listAlignment: _propTypes2.default.string,
  onChange: _propTypes2.default.func,
  disabled: _propTypes2.default.bool,
  direction: _propTypes2.default.string
} : {};

RadioButton.defaultProps = {
  className: '',
  listAlignment: 'flex-col',
  select: undefined,
  onChange: function onChange() {},
  disabled: false,
  direction: 'row'
};

exports.default = (0, _WithDisplayName2.default)(RadioButton, 'RadioButton');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('./IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _NotificationBar = require('./NotificationBar.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NotificationBar = function NotificationBar(_ref) {
  var isShowed = _ref.isShowed,
      extendBarContainer = _ref.extendBarContainer,
      iconName = _ref.iconName,
      iconSize = _ref.iconSize,
      iconColor = _ref.iconColor,
      notificationMessage = _ref.notificationMessage;
  return _react2.default.createElement(
    'div',
    { style: { display: isShowed ? 'block' : 'none' } },
    _react2.default.createElement(
      _NotificationBar.BarContainer,
      { extendBarContainer: extendBarContainer },
      _react2.default.createElement(
        _NotificationBar.IconContainer,
        null,
        iconName && _react2.default.createElement(_Icon2.default, {
          className: 'icon',
          icon: _IconsStore2.default.getIcon(iconName),
          width: iconSize,
          color: iconColor
        })
      ),
      _react2.default.createElement(
        'div',
        { style: { padding: '0px 10px' } },
        _react2.default.createElement(
          'p',
          null,
          notificationMessage
        )
      )
    )
  );
}; /* eslint-disable react/forbid-prop-types */


NotificationBar.propTypes = process.env.NODE_ENV !== "production" ? {
  extendBarContainer: _propTypes2.default.object,
  iconName: _propTypes2.default.string,
  iconColor: _propTypes2.default.string,
  notificationMessage: _propTypes2.default.string,
  isShowed: _propTypes2.default.bool,
  iconSize: _propTypes2.default.number
} : {};

exports.default = NotificationBar;
module.exports = exports['default'];
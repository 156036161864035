'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _SearchContainer = require('./SearchContainer');

var _SearchContainer2 = _interopRequireDefault(_SearchContainer);

var _Field = require('./Field');

var _Field2 = _interopRequireDefault(_Field);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Colors = require('../base/Colors');

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Search = function Search(_ref) {
  var placeholder = _ref.placeholder,
      clearText = _ref.clearText,
      value = _ref.value,
      _onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      onReset = _ref.onReset,
      showClearButton = _ref.showClearButton,
      iconProps = _ref.iconProps,
      language = _ref.language,
      extendSearchStyle = _ref.extendSearchStyle,
      otherProps = _objectWithoutProperties(_ref, ['placeholder', 'clearText', 'value', 'onChange', 'onBlur', 'onReset', 'showClearButton', 'iconProps', 'language', 'extendSearchStyle']);

  return _react2.default.createElement(
    _SearchContainer2.default,
    _extends({ extendSearchStyle: extendSearchStyle, alignItems: 'center' }, otherProps),
    _react2.default.createElement(_Icon2.default, _extends({
      icon: new _IconsStore2.default(_webIcons2.default).getIcon('search'),
      width: 12,
      color: _Colors.COLORS.HELP_TEXT
    }, iconProps)),
    _react2.default.createElement(_Field2.default, {
      type: 'text',
      autoComplete: 'off',
      value: value,
      onChange: function onChange(e) {
        return _onChange(e.target.value);
      },
      onBlur: onBlur,
      placeholder: placeholder,
      fontSize: _Typography.FONT_SIZES[_Typography.FONT_TYPES.BODY],
      fontWeight: _Typography.FONT_WEIGHTS.NORMAL,
      m: language === 'en' ? '0 8px 0 0' : '0 0 0 8px',
      extendSearchStyle: extendSearchStyle
    }),
    showClearButton && _react2.default.createElement(
      _Text2.default,
      {
        color: _Colors.COLORS.PRIMARY_BLUE,
        cursor: 'pointer',
        fontWeight: _Typography.FONT_WEIGHTS.NORMAL,
        mr: 1,
        onClick: onReset
      },
      clearText
    )
  );
};

Search.propTypes = process.env.NODE_ENV !== "production" ? {
  placeholder: _propTypes2.default.string,
  onChange: _propTypes2.default.func,
  onBlur: _propTypes2.default.func,
  onReset: _propTypes2.default.func,
  clearText: _propTypes2.default.string,
  value: _propTypes2.default.string,
  showClearButton: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  iconProps: _propTypes2.default.object // eslint-disable-line
} : {};

Search.defaultProps = {
  placeholder: 'Search',
  clearText: 'Reset',
  value: '',
  showClearButton: false,
  onChange: function onChange() {},
  onBlur: function onBlur() {},
  onReset: function onReset() {},
  language: 'en',
  iconProps: {}
};

exports.default = (0, _WithDisplayName2.default)(Search, 'Search');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Select = require('../select/Select');

var _Select2 = _interopRequireDefault(_Select);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HOURS_IN_DAY = 24;
var MINUTES_IN_HOUR = 60;
var MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;

var TimeInput = (_temp2 = _class = function (_Component) {
  _inherits(TimeInput, _Component);

  function TimeInput() {
    var _temp, _this, _ret;

    _classCallCheck(this, TimeInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      timeSlots: [],
      timeDuration: undefined
    }, _this.get24Time = function (totalMinutes) {
      var hours = Math.floor(totalMinutes / 60);
      var minutes = totalMinutes % 60;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ':00';
    }, _this.getChildRef = function () {
      return _this.select;
    }, _this.convertTo12HourFormat = function (time) {
      var newFormat = time;
      var oldHours = +newFormat.substr(0, 2);
      var newHours = oldHours % 12 || 12;
      newHours = newHours < 10 ? '0' + newHours : newHours;
      var amOrPm = oldHours < 12 ? ' AM' : ' PM';
      newFormat = newHours + newFormat.substr(2, 3) + amOrPm;
      return newFormat;
    }, _this.generateTimeSlots = function (timeDuration, timeFormat, startTime) {
      var language = _this.props.language;

      var timeSlots = [];
      var startTimeArray = startTime.split(':');
      var startTimeHoursInMinutes = parseInt(startTimeArray[0]) * 60;
      var startTimeTotalMinutes = startTimeHoursInMinutes + parseInt(startTimeArray[1]);

      for (var minutes = startTimeTotalMinutes; minutes < MINUTES_IN_DAY + startTimeTotalMinutes; minutes += timeDuration) {
        var displayMinutes = void 0;
        var displayTime = void 0;

        // Checking if the minutes is bigger than MINUTES_IN_DAY in a day
        // then subtract MINUTES_IN_DAY from minutes to start from
        if (minutes >= MINUTES_IN_DAY) {
          displayMinutes = minutes - MINUTES_IN_DAY;
        } else {
          displayMinutes = minutes;
        }

        displayTime = _this.get24Time(displayMinutes);
        displayTime = displayTime.split(':');
        displayTime = new Date(0, 0, 0, displayTime[0], displayTime[1]);

        if (timeFormat === 24) {
          displayTime = displayTime.toLocaleTimeString(language + '-Eg', {
            hour: '2-digit',
            hour12: false,
            minute: '2-digit'
          });
        } else {
          displayTime = displayTime.toLocaleTimeString(language + '-Eg', {
            hour: '2-digit',
            hour12: true,
            minute: '2-digit'
          });
        }

        timeSlots.push({
          data: {
            placeholder: displayTime,
            value: _this.get24Time(displayMinutes),
            searchable: [displayTime]
          },
          component: _react2.default.createElement(
            _Text2.default,
            null,
            displayTime
          )
        });
      }

      _this.setState({
        timeSlots: timeSlots
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  TimeInput.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.timeDuration !== prevState.timeDuration) {
      return {
        timeDuration: nextProps.timeDuration
      };
    }

    return null;
  };

  TimeInput.prototype.componentDidMount = function componentDidMount() {
    this.generateTimeSlots(this.props.timeDuration, this.props.timeFormat, this.props.startTime);
  };

  TimeInput.prototype.componentDidUpdate = function componentDidUpdate(prevProps, prevState) {
    if (prevState.timeDuration !== this.state.timeDuration) {
      this.generateTimeSlots(this.props.timeDuration, this.props.timeFormat, this.props.startTime);
    }
  };

  TimeInput.prototype.render = function render() {
    var _this2 = this;

    var timeSlots = this.state.timeSlots;

    return _react2.default.createElement(_Select2.default, _extends({}, this.props, {
      items: timeSlots,
      noIcon: true,
      ref: function ref(select) {
        _this2.select = select;
      }
    }));
  };

  return TimeInput;
}(_react.Component), _class.defaultProps = {
  timeDuration: 30,
  timeFormat: 12,
  startTime: '00:00',
  language: 'en'
}, _temp2);
TimeInput.propTypes = process.env.NODE_ENV !== "production" ? {
  timeDuration: _propTypes2.default.number,
  timeFormat: _propTypes2.default.oneOf([12, 24]),
  startTime: _propTypes2.default.string,
  language: _propTypes2.default.string
} : {};
exports.default = (0, _WithDisplayName2.default)(TimeInput, 'TimeInput');
module.exports = exports['default'];
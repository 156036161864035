'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  display: flex;\n  align-items: center;\n  min-width: 200px;\n'], ['\n  display: flex;\n  align-items: center;\n  min-width: 200px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  margin: 0;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 24px;\n  color: #484848;\n'], ['\n  margin: 0;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 24px;\n  color: #484848;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  color: #7e7e7e;\n'], ['\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  color: #7e7e7e;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Image = require('../image/Image');

var _Image2 = _interopRequireDefault(_Image);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var UserContainer = _styledComponents2.default.div(_templateObject);

var UserName = (0, _styledComponents2.default)(_Text2.default)(_templateObject2);

var UserEmail = (0, _styledComponents2.default)(_Text2.default)(_templateObject3);
var UserPreview = function UserPreview(_ref) {
  var photo = _ref.photo,
      name = _ref.name,
      email = _ref.email,
      reverse = _ref.reverse;

  var margin = reverse ? { ml: 3 } : { mr: 3 };

  return _react2.default.createElement(
    UserContainer,
    null,
    _react2.default.createElement(_Image2.default, _extends({
      src: photo,
      alt: 'doctor',
      id: 'doctor-img--preview',
      radius: 40,
      name: name
    }, margin)),
    _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement(
        UserName,
        null,
        name
      ),
      _react2.default.createElement('br', null),
      _react2.default.createElement(
        UserEmail,
        null,
        email
      )
    )
  );
};

UserPreview.propTypes = process.env.NODE_ENV !== "production" ? {
  photo: _propTypes2.default.string,
  name: _propTypes2.default.string.isRequired,
  email: _propTypes2.default.string.isRequired,
  reverse: _propTypes2.default.bool
} : {};

UserPreview.defaultProps = {
  photo: undefined,
  reverse: false
};

exports.default = UserPreview;
module.exports = exports['default'];
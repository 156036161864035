'use strict';

exports.__esModule = true;
exports.HeaderContainer = exports.BlockBorder = exports.ExtendedToolTip = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  .tooltip--message {\n    background-color: #1e1e1e !important;\n    min-width: unset !important;\n    top: unset !important;\n    bottom: 110% !important;\n    padding: 6px 12px !important;\n  }\n  .arrow--bottom {\n    &::after {\n      border-style: solid;\n      border-width: 5px;\n      content: \'\';\n      position: absolute;\n\n      border-color: #1e1e1e transparent transparent transparent !important;\n      left: 50%;\n      margin-left: -5px;\n      top: 100%;\n    }\n  }\n'], ['\n  .tooltip--message {\n    background-color: #1e1e1e !important;\n    min-width: unset !important;\n    top: unset !important;\n    bottom: 110% !important;\n    padding: 6px 12px !important;\n  }\n  .arrow--bottom {\n    &::after {\n      border-style: solid;\n      border-width: 5px;\n      content: \'\';\n      position: absolute;\n\n      border-color: #1e1e1e transparent transparent transparent !important;\n      left: 50%;\n      margin-left: -5px;\n      top: 100%;\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  border: 1px solid ', ';\n  border-radius: 4px;\n'], ['\n  border: 1px solid ', ';\n  border-radius: 4px;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  min-height: 52px;\n'], ['\n  min-height: 52px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Tooltip = require('../tooltip/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _Colors = require('../base/Colors');

var _gridStyled = require('../grid-styled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ExtendedToolTip = exports.ExtendedToolTip = (0, _styledComponents2.default)(_Tooltip2.default)(_templateObject);

var BlockBorder = exports.BlockBorder = _gridStyled.Flex.extend(_templateObject2, _Colors.COLORS_VALUES[_Colors.COLORS.BORDER]);

var HeaderContainer = exports.HeaderContainer = _gridStyled.Flex.extend(_templateObject3);
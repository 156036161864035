'use strict';

exports.__esModule = true;

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EntityIconsStore = new _IconsStore2.default(_webIcons2.default);

exports.default = EntityIconsStore;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  ', ';\n  ', ';\n'], ['\n  width: 100%;\n  ', ';\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Text = require('../../text/Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ErrorMessage = (0, _styledComponents2.default)(_Text2.default)(_templateObject, _styledSystem.textAlign, _styledSystem.space);

exports.default = ErrorMessage;
module.exports = exports['default'];
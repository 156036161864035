/* global process */

export const configurations = {
  payfortMerchantPageLink: process.env.REACT_APP_PAYFORT_MERCHANT_PAGE_LINK,
  returnUrl: process.env.REACT_APP_RETURN_URL,
  secure3dreturnurl: process.env.REACT_APP_SECURE3DRETURN_URL,
  accessCode: process.env.REACT_APP_ACCESS_CODE,
  servicesCommand: 'TOKENIZATION',
  language: 'en',
  rememberMe: 'YES',
  merchantIdentifier: process.env.REACT_APP_MERCHANT_IDENTIFIER,
  requestSHASignature: 'TESTSHAINBV',
  responseSHASignature: 'TESTSHAOUTBV',
  transactionTypeKey: 'trnstyp1078156da83be326',
  transactionDataUrl: process.env.REACT_APP_TRANSACTIONDATA_URL,
  currencyDataUrl: process.env.REACT_APP_CURRENCYDATA_URL,
  cancelReservationUrl: process.env.REACT_APP_CANCEL_RESERVATION_URL,
  addAccountCardAndGetMerchantPageInfoUrl:
    process.env.REACT_APP_ADDACCOUNTCARDANDGETMERCHENTPAGEINFO_URL,
  chargeAndUpdateUrl: process.env.REACT_APP_CHARGEANDUPDATE_URL,
  chargeUrl: process.env.REACT_APP_CHARGE_URL,
  successUrl: process.env.REACT_APP_SUCCESSRETURN_URL,
  returnToVezeetaUrl: process.env.REACT_APP_RETURN_TO_VEZEETA_URL,
};

export const PaymentAuthHeaders = [
  {
    key: 'x-vzt-Component',
    value: 'paymentportal',
  },
  {
    key: 'x-vzt-authorization',
    value:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6InNlcnZpY2VzIiwiZXhwIjoxNTI0NDE0MDkxLjAsIm5uYyI6IjU4MDllYmIyYWQ2YjdmMmMifQ.lcVf-afeEm8KIpqFaMT3Y7hpyrPWg6fNic8Ew9YyU6k',
  },
];

export const cookies = [
  'CD',
  'AK',
  'TK',
  'RK',
  'CID',
  'PTK',
  'CallBack',
  'VezeetaUrl',
  'BookingCallBack',
  'LogoClickStatus',
  'callBackAttributes',
  'callbackHost',
  'callbackRelativePath',
  'RKcallbackQuery',
  'amountPaid',
];

export const encryptionKey = 'MadMed117';

'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Spinner = require('../spinner/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('./IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _DropDown = require('./DropDown.style');

var _DropDown2 = require('./DropDown.helper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */


var DropDown = function DropDown(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var placeHolder = props.placeHolder,
      fieldLabel = props.fieldLabel,
      fieldValue = props.fieldValue,
      searchable = props.searchable,
      multipleSelection = props.multipleSelection,
      options = props.options,
      extendDropDownStyle = props.extendDropDownStyle,
      icon = props.icon,
      iconSize = props.iconSize,
      onChanges = props.onChanges,
      onSearch = props.onSearch,
      isRequired = props.isRequired,
      extendDropDownList = props.extendDropDownList,
      extendDropDownListItem = props.extendDropDownListItem,
      onClickIconAction = props.onClickIconAction,
      componentName = props.componentName,
      isDisabled = props.isDisabled,
      language = props.language,
      isLoading = props.isLoading,
      isValid = props.isValid,
      onTypingDelay = props.onTypingDelay,
      searchCharacterLimit = props.searchCharacterLimit;

  var _useState = (0, _react.useState)([]),
      selectedOptions = _useState[0],
      setSelectOption = _useState[1]; // for selected items


  var _useState2 = (0, _react.useState)(''),
      typedValue = _useState2[0],
      setTypedValue = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      dropDownToTop = _useState3[0],
      setDropDownToTop = _useState3[1];

  var timer = (0, _react.useRef)(null);

  var calculateListPosition = function calculateListPosition() {
    var listComponent = document.getElementsByClassName('dropdown-' + componentName);
    // Get Parent Hight
    var parentHeight = listComponent[0].offsetParent.clientHeight;
    var parentRect = listComponent[0].getBoundingClientRect();
    // DropDown height
    var listComponentHeight = listComponent[0].clientHeight;
    // List Height
    var listItems = document.getElementById('dropdown-list-items-' + componentName);
    var itemListHight = listItems.clientHeight;

    if (parentRect.top + 6 + itemListHight > parentHeight) {
      // list open to top
      setDropDownToTop(-(6 + itemListHight + listComponentHeight));
    } else {
      // list open to down
      setDropDownToTop(6);
    }
  };

  var toggleDropDowns = function toggleDropDowns() {
    var dropDowns = document.querySelectorAll('div[class^="dropdown"]');
    var anotherDropDownsLabels = [];

    // Get all onther dropdown other than the current clicked one
    dropDowns.forEach(function (dropDown) {
      var dropDownClassName = Array.from(dropDown.classList).filter(function (className) {
        return className.includes('dropdown-');
      });
      if (dropDownClassName[0] !== 'dropdown-' + componentName) {
        anotherDropDownsLabels.push(dropDownClassName[0]);
      }
    });

    // Close opened lists of all onther dropdowns
    anotherDropDownsLabels.forEach(function (dropDown) {
      var label = dropDown.split('-')[1];
      var list = document.getElementById('dropdown-list-' + label);
      if (list) list.style.display = 'none';
    });
  };

  var onItemSelect = function onItemSelect(option) {
    setSelectOption([option]);
    if (onChanges) {
      onChanges(option);
    }
    (0, _DropDown2.dropDownListToggler)(options, componentName);
  };

  (0, _react.useEffect)(function () {
    if (fieldValue !== undefined && options && options.length) {
      var filteredOption = options.filter(function (option) {
        return option.value === fieldValue;
      });
      if (filteredOption && filteredOption[0]) {
        setTypedValue(filteredOption[0].fieldValue);
        setSelectOption(filteredOption);
      }
      calculateListPosition();
    }
  }, [fieldValue, options]);

  var onSearchInList = function onSearchInList(value) {
    setTypedValue(value);
    var list = document.getElementById('dropdown-list-' + componentName);
    if (list && list.style.display === 'none') {
      list.style.display = 'block';
    }
    clearTimeout(timer.current);
    timer.current = setTimeout(function () {
      if (onSearch && value && value.length >= searchCharacterLimit) {
        onSearch(value);
      }
    }, onTypingDelay);
  };

  var isInputFieldRendered = searchable;

  var onFieldClick = function onFieldClick() {
    if (searchable) {
      if (document.getElementById('input-container-' + componentName)) {
        document.getElementById('input-container-' + componentName).focus();
      }
    }
    toggleDropDowns();
    calculateListPosition();
    (0, _DropDown2.dropDownListToggler)(options, componentName);
  };

  return _react2.default.createElement(
    'div',
    { style: { position: 'relative' }, className: 'dropdown dropdown-' + componentName },
    _react2.default.createElement(_DropDown.DisableOverLay, { isDisabled: isDisabled }),
    _react2.default.createElement(
      _DropDown.FieldContainer,
      { isValid: isValid, isDimmed: false, extendDropDownStyle: extendDropDownStyle },
      icon && _react2.default.createElement(
        'div',
        { style: { display: 'inline-flex', alignItems: 'center' } },
        isLoading ? _react2.default.createElement(_Spinner2.default, { radius: 20, color: '#58595b' }) : _react2.default.createElement(_Icon2.default, {
          className: 'icon',
          onClick: function onClick() {
            if (onClickIconAction) {
              onClickIconAction();
            } else {
              onFieldClick();
            }
          },
          icon: _IconsStore2.default.getIcon(icon),
          width: iconSize
        })
      ),
      _react2.default.createElement(
        _DropDown.LabelValueContainer,
        {
          style: { width: '97%' },
          onClick: function onClick() {
            if (options && options.length || searchable) onFieldClick();
          }
        },
        fieldLabel && _react2.default.createElement(
          _DropDown.FieldLabel,
          { isValueSelected: searchable || selectedOptions.length },
          fieldLabel,
          isRequired && _react2.default.createElement(
            _DropDown.IsRequiredNote,
            null,
            '*'
          )
        ),
        isInputFieldRendered && _react2.default.createElement(_DropDown.SearchInput, {
          autoComplete: 'off',
          placeholder: placeHolder,
          id: 'input-container-' + componentName,
          type: 'text',
          value: typedValue,
          onChange: function onChange(e) {
            onSearchInList(e.target.value);
          },
          extendDropDownStyle: extendDropDownStyle
        })
      )
    ),
    _react2.default.createElement(
      'div',
      { id: 'dropdown-list-' + componentName, style: { display: 'none', position: 'relative' } },
      _react2.default.createElement(
        _DropDown.ListContainer,
        {
          dropDownToTop: dropDownToTop,
          extendDropDownList: extendDropDownList,
          id: 'dropdown-list-items-' + componentName
        },
        (0, _DropDown2.renderDropDownList)(options, multipleSelection, onItemSelect, {
          extendDropDownListItem: extendDropDownListItem
        }, componentName, language)
      )
    )
  );
};

DropDown.propTypes = process.env.NODE_ENV !== "production" ? {
  fieldValue: _propTypes2.default.string,
  fieldLabel: _propTypes2.default.string.isRequired,
  icon: _propTypes2.default.string.isRequired,
  searchable: _propTypes2.default.bool,
  selectFirst: _propTypes2.default.bool,
  multipleSelection: _propTypes2.default.bool,
  options: _propTypes2.default.array,
  onChanges: _propTypes2.default.func.isRequired,
  iconSize: _propTypes2.default.number.isRequired,
  extendDropDownStyle: _propTypes2.default.string,
  onSearch: _propTypes2.default.func,
  isHorizontallySorted: _propTypes2.default.bool,
  isRequired: _propTypes2.default.bool,
  extendDropDownList: _propTypes2.default.array,
  extendDropDownListItem: _propTypes2.default.string,
  onClickIconAction: _propTypes2.default.func,
  componentName: _propTypes2.default.string.isRequired,
  isDisabled: _propTypes2.default.bool,
  isLoading: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  isValid: _propTypes2.default.bool,
  onTypingDelay: _propTypes2.default.number,
  placeHolder: _propTypes2.default.string,
  searchCharacterLimit: _propTypes2.default.number
} : {};

DropDown.defaultProps = {
  searchCharacterLimit: 3
};

exports.default = DropDown;
module.exports = exports['default'];
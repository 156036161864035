'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _color = require('color');

var _color2 = _interopRequireDefault(_color);

var _colorString = require('color-string');

var _colorString2 = _interopRequireDefault(_colorString);

var _ButtonBase = require('./ButtonBase');

var _ButtonBase2 = _interopRequireDefault(_ButtonBase);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Spinner = require('../spinner/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Typography = require('../base/Typography');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Button = (_temp2 = _class = function (_Component) {
  _inherits(Button, _Component);

  function Button() {
    var _temp, _this, _ret;

    _classCallCheck(this, Button);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.getButtonColors = function () {
      var _this$props = _this.props,
          primary = _this$props.primary,
          disabled = _this$props.disabled,
          isLoading = _this$props.isLoading;
      var color = _this.props.color;

      var isDisabled = disabled || isLoading;
      var textColor = void 0;
      var buttonBackground = void 0;
      var buttonBackgroundOnHover = void 0;
      var borderColor = void 0;
      var borderColorOnHover = void 0;

      // Set the default colors if the color prop is not provided
      if (!color) {
        color = primary ? _Colors.COLORS.PRIMARY_RED : _Colors.COLORS.TEXT;
      }

      if (primary) {
        if (isDisabled) {
          textColor = _Colors.COLORS.TEXT;
          buttonBackground = _Colors.COLORS.DISABLED;
          buttonBackgroundOnHover = buttonBackground;
        } else {
          textColor = _Colors.COLORS.WHITE;
          buttonBackground = color;
          buttonBackgroundOnHover = (0, _color2.default)(_Colors.COLORS_VALUES[buttonBackground]).darken(0.15).hex();
        }
        borderColor = buttonBackground;
        borderColorOnHover = buttonBackgroundOnHover;
      } else {
        if (isDisabled) {
          textColor = _Colors.COLORS.DISABLED;
          buttonBackgroundOnHover = _Colors.COLORS.TRANSPARENT;
        } else {
          textColor = color;
          buttonBackgroundOnHover = (0, _color2.default)(_Colors.COLORS_VALUES[color]).alpha(0.08).rgb().array();
          buttonBackgroundOnHover = _colorString2.default.to.rgb(buttonBackgroundOnHover);
        }

        borderColor = _Colors.COLORS.BUTTON_BORDER;
        buttonBackground = _Colors.COLORS.TRANSPARENT;
        borderColorOnHover = borderColor;
      }

      return {
        textColor: textColor,
        buttonBackground: buttonBackground,
        borderColor: borderColor,
        buttonBackgroundOnHover: buttonBackgroundOnHover,
        borderColorOnHover: borderColorOnHover
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  /**
   * Decides button background and text color depends on the button type
   * and whether it's disabled or not
   * Types:
   * - Primary: Background will inherit color prop and the text will be white
   * - Secondary: Text will inherit color prop and the background will be transparent
   *
   * @returns {object} contains {textColor, buttonBackground}
   */


  Button.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        isLoading = _props.isLoading,
        disabled = _props.disabled,
        icon = _props.icon,
        iconWidth = _props.iconWidth,
        reverse = _props.reverse;

    var _getButtonColors = this.getButtonColors(),
        textColor = _getButtonColors.textColor,
        buttonBackground = _getButtonColors.buttonBackground,
        borderColor = _getButtonColors.borderColor,
        buttonBackgroundOnHover = _getButtonColors.buttonBackgroundOnHover,
        borderColorOnHover = _getButtonColors.borderColorOnHover;

    var shouldRenderIcon = icon && !isLoading;
    var flexDirection = reverse ? 'row-reverse' : 'row';
    var cursor = disabled || isLoading ? 'default' : 'pointer';

    return _react2.default.createElement(
      _ButtonBase2.default,
      _extends({}, this.props, {
        disabled: disabled || isLoading,
        bg: buttonBackground,
        borderColor: borderColor,
        hover: { backgroundColor: buttonBackgroundOnHover, borderColor: borderColorOnHover },
        flexDirection: flexDirection
      }),
      isLoading && _react2.default.createElement(_Spinner2.default, { radius: 16, color: textColor, ml: reverse && 2, mr: !reverse && 2 }),
      shouldRenderIcon && _react2.default.createElement(_Icon2.default, {
        icon: icon,
        width: iconWidth,
        color: textColor,
        ml: reverse && 2,
        mr: !reverse && 2
      }),
      typeof children === 'string' ? _react2.default.createElement(
        _Text2.default,
        {
          tag: 'span',
          width: 'max-content',
          color: textColor,
          fontWeight: _Typography.FONT_WEIGHTS.SEMI_BOLD,
          lineHeight: 14,
          cursor: cursor
        },
        children
      ) : children
    );
  };

  return Button;
}(_react.Component), _class.defaultProps = {
  color: undefined,
  primary: true,
  disabled: false,
  isLoading: false,
  icon: undefined,
  iconWidth: 15,
  reverse: false
}, _temp2);
Button.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.string.isRequired,
  color: _propTypes2.default.string,
  primary: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool,
  isLoading: _propTypes2.default.bool,
  icon: _Icon.iconPropTypes,
  iconWidth: _propTypes2.default.number,
  reverse: _propTypes2.default.bool
} : {};
exports.default = Button;
module.exports = exports['default'];
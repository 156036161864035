'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactAvatarEditor = require('react-avatar-editor');

var _reactAvatarEditor2 = _interopRequireDefault(_reactAvatarEditor);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Text = require('../../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Button = require('../../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Icon = require('../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Colors = require('../../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

var _Typography = require('../../base/Typography');

var _WithDisplayName = require('../../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./PhotoCropper.css');

var _Colors3 = require('../../base/Colors');

var _constants = require('../constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PhotoCropper = function (_Component) {
  _inherits(PhotoCropper, _Component);

  function PhotoCropper(props) {
    _classCallCheck(this, PhotoCropper);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.setImage = function () {
      var _this$props = _this.props,
          updatePhoto = _this$props.updatePhoto,
          photoCrop = _this$props.photoCrop;


      updatePhoto({
        preview: _this.imageCropped.getImage().toDataURL(),
        crop: photoCrop,
        isPhoto: true,
        showCropper: false
      });
      _this.photoOriginal.value = photoCrop;
    };

    _this.setZoomImageValue = function () {
      _this.setState({ zoomImage: Number(_this.zoomImageRange.value) });
      _this.photoOriginal.zoom = Number(_this.zoomImageRange.value);

      _this.zoomImageRange.oninput = function () {
        var value = (_this.zoomImageRange.value - _this.zoomImageRange.min) / (_this.zoomImageRange.max - _this.zoomImageRange.min);
        _this.photoOriginal.styleRuleValue = value;
        _this.insertStyleRule(value);
      };

      _this.zoomImageRange.onchange = function () {
        var value = (_this.zoomImageRange.value - _this.zoomImageRange.min) / (_this.zoomImageRange.max - _this.zoomImageRange.min);
        _this.photoOriginal.styleRuleValue = value;
        _this.insertStyleRule(value);
      };
    };

    _this.insertStyleRule = function (value) {
      var rangeClassName = _this.props.rangeClassName;


      if (global.window) {
        var prefixBrowser = null;

        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
          prefixBrowser = '::-webkit-slider-runnable-track';
        } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          prefixBrowser = '::-moz-range-track';
        } else {
          prefixBrowser = '::-ms-track';
        }

        var style = function () {
          var cssStyle = document.createElement('style');
          cssStyle.appendChild(document.createTextNode(''));
          document.head.appendChild(cssStyle);
          return cssStyle;
        }();

        style.sheet.insertRule('input.' + rangeClassName + '[type=range]' + prefixBrowser + ' { background: -ms-linear-gradient(left, ' + _Colors2.default.vezeetaBlue + ' ' + value + ', ' + _Colors2.default.mediumGrey + ' ' + value + ')!important;\n              background: -moz-linear-gradient(left, ' + _Colors2.default.vezeetaBlue + ' ' + value + ', ' + _Colors2.default.mediumGrey + ' ' + value + ')!important;\n              background: -o-linear-gradient(left, ' + _Colors2.default.vezeetaBlue + ' ' + value + ', ' + _Colors2.default.mediumGrey + ' ' + value + ')!important;\n              background: -webkit-gradient(linear,left top,right top,color-stop(' + value + ', ' + _Colors2.default.vezeetaBlue + '),color-stop(' + value + ', ' + _Colors2.default.mediumGrey + '))!important;\n              background: -webkit-linear-gradient(left, ' + _Colors2.default.vezeetaBlue + ' ' + value + ', ' + _Colors2.default.mediumGrey + ' ' + value + ')!important;\n              background: linear-gradient(to right, ' + _Colors2.default.vezeetaBlue + ' ' + value + ', ' + _Colors2.default.mediumGrey + ' ' + value + ')!important; }', 0);
      }
    };

    _this.loadPreview = function () {
      var _this$props2 = _this.props,
          loadPreview = _this$props2.loadPreview,
          updatePhoto = _this$props2.updatePhoto,
          photoPreview = _this$props2.photoPreview,
          isPhoto = _this$props2.isPhoto;


      loadPreview(_this.input, function (photo) {
        var reader = new FileReader();
        var value = 0.5;
        reader.readAsDataURL(photo);

        reader.onloadend = function () {
          updatePhoto({
            preview: photoPreview,
            crop: reader.result,
            isPhoto: isPhoto,
            showCropper: true
          });
          _this.input.value = null;
          _this.setState({ zoomImage: 1.5 });
          _this.insertStyleRule(value);
        };
      });
    };

    _this.drawImageGuideLine = function () {
      var c = document.getElementById('image-guide-lines');
      var ctx = c.getContext('2d');

      ctx.beginPath();
      ctx.moveTo(85, 0);
      ctx.lineTo(85, 150);
      ctx.lineWidth = 0.3;
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(215, 0);
      ctx.lineTo(215, 150);
      ctx.lineWidth = 0.3;
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(0, 114.5);
      ctx.lineTo(300, 114.5);
      ctx.lineWidth = 0.15;
      ctx.stroke();
    };

    _this.openFileDialog = function () {
      _this.input.click();
    };

    _this.cancelImage = function () {
      var _this$props3 = _this.props,
          updatePhoto = _this$props3.updatePhoto,
          photoPreview = _this$props3.photoPreview,
          isPhoto = _this$props3.isPhoto;


      updatePhoto({
        preview: photoPreview,
        crop: _this.photoOriginal.value,
        isPhoto: isPhoto,
        showCropper: false
      });

      if (!isPhoto) {
        var value = 0.5;
        _this.setState({ zoomImage: 1.5 });
        _this.insertStyleRule(value);
      }

      _this.setState({ zoomImage: _this.photoOriginal.zoom });
      _this.insertStyleRule(_this.photoOriginal.styleRuleValue);
    };

    _this.iconsStore = new _IconsStore2.default(_webIcons2.default);
    _this.state = {
      zoomImage: 1.5
    };
    _this.photoOriginal = {
      value: _this.props.photoCrop,
      zoom: _this.state.zoomImage,
      styleRuleValue: 0.5
    };
    return _this;
  }

  PhotoCropper.prototype.componentWillMount = function componentWillMount() {
    var value = 0.5;
    this.insertStyleRule(value);
  };

  /**
   * sets the cropped image value and preview it
   */


  /**
   * sets the scale value and its' style
   */


  /**
   * Loads the image from local device and load a preview
   */


  /**
   * Draws the guide line in the crop area
   */


  /**
   * Opens file dialog
   */


  /**
   * Cancels the image that uploaded and doesn't crop it or preview it
   */


  PhotoCropper.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        showCropper = _props.showCropper,
        photoCrop = _props.photoCrop,
        rangeClassName = _props.rangeClassName,
        shape = _props.shape,
        language = _props.language;
    var zoomImage = this.state.zoomImage;

    var _ref = shape ? _constants.PHOTO_SHAPE[shape] : _constants.PHOTO_SHAPE.CIRCLE,
        radius = _ref.radius;

    return _react2.default.createElement(
      'div',
      { className: (0, _classnames2.default)('crop-container', showCropper ? '' : 'hide') },
      _react2.default.createElement(
        'div',
        { className: 'crop-image-tool-container' },
        _react2.default.createElement('input', {
          type: 'file',
          ref: function ref(input) {
            _this2.input = input;
          },
          accept: '.png, .jpg, .jpeg',
          onChange: this.loadPreview,
          autoComplete: 'off'
        }),
        _react2.default.createElement(
          'div',
          { className: 'header flex-row' },
          _react2.default.createElement(
            'div',
            { className: 'center' },
            _react2.default.createElement(
              _Text2.default,
              { tag: 'h1', type: _Typography.FONT_TYPES.TITLE, className: 'title' },
              language === 'en' ? 'Upload Photo' : 'رفع صورة'
            )
          ),
          _react2.default.createElement(
            'button',
            { className: 'close-btn', onClick: this.cancelImage },
            _react2.default.createElement(_Icon2.default, { icon: this.iconsStore.getIcon('close'), width: 12, color: _Colors3.COLORS.TEXT })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'crop-area-container' },
          _react2.default.createElement('canvas', { id: 'image-guide-lines' }),
          _react2.default.createElement(_reactAvatarEditor2.default, {
            ref: function ref(imageCropped) {
              _this2.imageCropped = imageCropped;
            },
            image: photoCrop,
            width: 200,
            height: 200,
            border: 50,
            borderRadius: radius,
            color: [0, 0, 0, 0.3] // RGBA
            , scale: zoomImage,
            rotate: 0,
            onImageReady: this.drawImageGuideLine
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'zoom-range-container' },
          _react2.default.createElement(
            _Text2.default,
            { m: language === 'en' ? '0 16px 0 0' : '0 0 0 16px' },
            language === 'en' ? 'Zoom' : 'تكبير'
          ),
          _react2.default.createElement('input', {
            className: 'track_range ' + rangeClassName,
            type: 'range',
            step: '0.01',
            min: '1',
            max: '2',
            name: 'scale',
            ref: function ref(zoomImageRange) {
              _this2.zoomImageRange = zoomImageRange;
            },
            onChange: this.setZoomImageValue,
            value: zoomImage,
            autoComplete: 'off'
          })
        ),
        _react2.default.createElement(
          'div',
          { className: 'btns-container' },
          _react2.default.createElement(
            'div',
            { className: 'button-container' },
            _react2.default.createElement(
              _Button2.default,
              { primary: false, onClick: this.openFileDialog },
              language === 'en' ? 'Change Photo' : 'تغيير الصورة'
            )
          ),
          _react2.default.createElement(
            'div',
            { className: 'button-container' },
            _react2.default.createElement(
              _Button2.default,
              { primary: false, color: _Colors3.COLORS.PRIMARY_BLUE, onClick: this.setImage },
              language === 'en' ? 'Save And Crop' : 'حفظ'
            )
          )
        )
      )
    );
  };

  return PhotoCropper;
}(_react.Component);

PhotoCropper.propTypes = process.env.NODE_ENV !== "production" ? {
  rangeClassName: _propTypes2.default.string.isRequired,
  photoPreview: _propTypes2.default.string,
  photoCrop: _propTypes2.default.string,
  isPhoto: _propTypes2.default.bool,
  showCropper: _propTypes2.default.bool,
  updatePhoto: _propTypes2.default.func.isRequired,
  loadPreview: _propTypes2.default.func.isRequired,
  shape: _propTypes2.default.oneOf(['CIRCLE', 'SQUARE']),
  language: _propTypes2.default.string.isRequired
} : {};

PhotoCropper.defaultProps = {
  photoPreview: undefined,
  photoCrop: undefined,
  isPhoto: false,
  showCropper: false,
  shape: 'CIRCLE'
};

exports.default = (0, _WithDisplayName2.default)(PhotoCropper, 'PhotoCropper');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _StyledSeparator = require('./StyledSeparator');

var _StyledSeparator2 = _interopRequireDefault(_StyledSeparator);

var _StyledTextSeparator = require('./StyledTextSeparator');

var _StyledTextSeparator2 = _interopRequireDefault(_StyledTextSeparator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Separator = function Separator(props) {
  return props.text ? _react2.default.createElement(_StyledTextSeparator2.default, props) : _react2.default.createElement(_StyledSeparator2.default, props);
};

Separator.propTypes = process.env.NODE_ENV !== "production" ? {
  text: _propTypes2.default.string
} : {};

Separator.defaultProps = {
  text: ''
};

exports.default = Separator;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Separator = require('../separator/Separator');

var _Separator2 = _interopRequireDefault(_Separator);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var key = 0;
var getKey = function getKey() {
  key += 1;
  return key;
};

var List = function (_Component) {
  _inherits(List, _Component);

  function List() {
    var _temp, _this, _ret;

    _classCallCheck(this, List);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      list: []
    }, _this.renderList = function (list) {
      var _this$props = _this.props,
          onItemWillRender = _this$props.onItemWillRender,
          customSeparatorCallback = _this$props.customSeparatorCallback;

      list.forEach(function (item) {
        var customSeparatorComponent = void 0;
        if (customSeparatorCallback) {
          customSeparatorComponent = customSeparatorCallback(item.callbackItems);

          if (!customSeparatorComponent) {
            customSeparatorComponent = _react2.default.createElement(_react.Fragment, { key: 'custom-' + getKey() });
          }
        }

        // Calling this.props.onItemWillRender to know which separator type will be rendered
        var separatorText = onItemWillRender(item.callbackItems);

        // Initialize components with keys
        var separatorComponent = _react2.default.createElement(_Separator2.default, {
          key: 'divider-' + getKey(),
          className: (0, _classnames2.default)(_this.props.separatorClassName),
          text: separatorText,
          my: 5
        });
        var component = _react2.default.createElement(
          _react.Fragment,
          { key: 'item-' + getKey() },
          item.component
        );

        // Pushing separator and component into this.state.list
        var newState = _this.state.list;
        newState.push(customSeparatorComponent);
        newState.push(separatorComponent);
        newState.push(component);

        _this.setState({
          list: newState
        });
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  List.prototype.componentWillMount = function componentWillMount() {
    if (this.props.list) {
      this.renderList(this.props.list);
    }
  };

  List.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    var _this2 = this;

    if (nextProps.list && !_lodash2.default.isEqual(nextProps.list, this.props.list)) {
      this.setState({
        list: []
      }, function () {
        _this2.renderList(nextProps.list);
      });
    }
  };

  List.prototype.render = function render() {
    return _react2.default.createElement(
      'div',
      { className: (0, _classnames2.default)(this.props.className) },
      this.state.list
    );
  };

  return List;
}(_react.Component);

// Props docs here https://vezeeta-final.visualstudio.com/web-packages/_wiki/wikis/Vezeeta.Account.Web.wiki?wikiVersion=GBwikiMaster&pagePath=%2FWeb-components%2FList


List.propTypes = process.env.NODE_ENV !== "production" ? {
  list: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    component: _propTypes2.default.any,
    callbackItems: _propTypes2.default.any
  })).isRequired,
  onItemWillRender: _propTypes2.default.func,
  customSeparatorCallback: _propTypes2.default.func,
  className: _propTypes2.default.string,
  separatorClassName: _propTypes2.default.string
} : {};

List.defaultProps = {
  onItemWillRender: function onItemWillRender() {},
  customSeparatorCallback: function customSeparatorCallback() {},
  className: undefined,
  separatorClassName: undefined
};

exports.default = (0, _WithDisplayName2.default)(List, 'List');
module.exports = exports['default'];
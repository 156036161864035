'use strict';

exports.__esModule = true;
exports.Caption = exports.Title = exports.Subheading = exports.Heading = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Text = require('./Text');

var _Text2 = _interopRequireDefault(_Text);

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Title = function Title(props) {
  return _react2.default.createElement(_Text2.default, _extends({ tag: 'h1', type: _Typography.FONT_TYPES.TITLE }, props));
};
var Heading = function Heading(props) {
  return _react2.default.createElement(_Text2.default, _extends({ tag: 'h3', type: _Typography.FONT_TYPES.HEADING }, props));
};
var Subheading = function Subheading(props) {
  return _react2.default.createElement(_Text2.default, _extends({ tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING }, props));
};
var Caption = function Caption(props) {
  return _react2.default.createElement(_Text2.default, _extends({ tag: 'span', type: _Typography.FONT_TYPES.CAPTION }, props));
};

exports.Heading = Heading;
exports.Subheading = Subheading;
exports.Title = Title;
exports.Caption = Caption;
'use strict';

exports.__esModule = true;

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  min-height: ', 'px;\n  height: ', 'px;\n  min-width: ', 'px;\n  width: ', 'px;\n  border-radius: 50%;\n  ', ';\n  ', ';\n'], ['\n  min-height: ', 'px;\n  height: ', 'px;\n  min-width: ', 'px;\n  width: ', 'px;\n  border-radius: 50%;\n  ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  height: min-content;\n'], ['\n  height: min-content;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

var _Step = require('./Step');

var _Step2 = _interopRequireDefault(_Step);

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var dots = [1, 2, 3, 4];
var dotRadius = 2;
var Dot = _styledComponents2.default.div(_templateObject, dotRadius, dotRadius, dotRadius, dotRadius, _styledSystem.bgColor, _styledSystem.space);

var StepsBarContainer = _gridStyled.Flex.extend(_templateObject2);

var StepsBar = (_temp2 = _class = function (_Component) {
  _inherits(StepsBar, _Component);

  function StepsBar() {
    var _temp, _this, _ret;

    _classCallCheck(this, StepsBar);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      currentStep: _this.props.currentStep
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  StepsBar.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.currentStep !== nextProps.currentStep) {
      return {
        currentStep: nextProps.currentStep
      };
    }

    return null;
  };

  StepsBar.prototype.render = function render() {
    var _props = this.props,
        steps = _props.steps,
        activeColor = _props.activeColor,
        defaultColor = _props.defaultColor;
    var currentStep = this.state.currentStep;


    return _react2.default.createElement(
      StepsBarContainer,
      { width: 1, alignItems: 'center', justifyContent: 'space-between' },
      steps.map(function (step, index) {
        return _react2.default.createElement(
          _react2.default.Fragment,
          { key: 'dot-c-' + step + '-' + index /* eslint-disable-line */ },
          index !== 0 && _react2.default.createElement(
            _gridStyled.Flex,
            { alignItems: 'center' },
            dots.map(function (_, dotIndex) {
              return _react2.default.createElement(Dot, {
                key: 'dot-c-' + step + '-' + dotIndex // eslint-disable-line
                , mx: '2px',
                bg: index === currentStep ? _Colors.COLORS.PRIMARY_BLUE : _Colors.COLORS.TEXT
              });
            })
          ),
          _react2.default.createElement(_Step2.default, {
            key: step,
            title: step,
            number: index + 1,
            isActive: currentStep === index,
            isVisited: currentStep >= index,
            activeColor: activeColor,
            defaultColor: defaultColor
          })
        );
      })
    );
  };

  return StepsBar;
}(_react.Component), _class.defaultProps = {
  activeColor: _Colors.COLORS.PRIMARY_BLUE,
  defaultColor: _Colors.COLORS.HELP_TEXT
}, _temp2);
StepsBar.propTypes = process.env.NODE_ENV !== "production" ? {
  steps: _propTypes2.default.arrayOf(_propTypes2.default.string).isRequired,
  currentStep: _propTypes2.default.number.isRequired,
  activeColor: _propTypes2.default.string,
  defaultColor: _propTypes2.default.string
} : {};
exports.default = StepsBar;
module.exports = exports['default'];
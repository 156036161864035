'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _ = require('../');

var _Pagination = require('./Pagination');

var _Pagination2 = _interopRequireDefault(_Pagination);

var _TableRow = require('./TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

var _Table = require('./Table.styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */


var Table = function (_React$Component) {
  _inherits(Table, _React$Component);

  function Table() {
    var _temp, _this, _ret;

    _classCallCheck(this, Table);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$Component.call.apply(_React$Component, [this].concat(args))), _this), _this.setPage = function (selectedPage) {
      _this.props.onClickPage(selectedPage);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Table.prototype.render = function render() {
    var _props = this.props,
        data = _props.data,
        onClickRecord = _props.onClickRecord,
        extendPageTag = _props.extendPageTag,
        extendTableWidth = _props.extendTableWidth,
        tableColumnHeaders = _props.tableColumnHeaders,
        extendTableGrid = _props.extendTableGrid,
        totalNumberOfPages = _props.totalNumberOfPages,
        pagination = _props.pagination,
        isExpandableRows = _props.isExpandableRows,
        language = _props.language,
        extendTableRow = _props.extendTableRow,
        extendTableHeaderRow = _props.extendTableHeaderRow,
        extendMainItem = _props.extendMainItem,
        extendAdditionalItem = _props.extendAdditionalItem,
        selectedPage = _props.selectedPage,
        mixpanel = _props.mixpanel,
        source = _props.source;

    return _react2.default.createElement(
      _Table.TableContainer,
      { extendTableWidth: extendTableWidth },
      _react2.default.createElement(
        _Table.TableHeader,
        { extendTableHeaderRow: extendTableHeaderRow },
        _react2.default.createElement(
          _Table.HeaderGrid,
          { extendTableGrid: extendTableGrid },
          tableColumnHeaders && tableColumnHeaders.map(function (header) {
            return _react2.default.createElement(
              _Table.HeaderRowItems,
              { key: 'rowItem-item-' + header.id, language: language },
              header.name
            );
          })
        )
      ),
      data && data.map(function (record, index) {
        var recordLenght = Object.keys(record.mainRow).length;
        var items = [];

        var _loop = function _loop(i) {
          var _record$mainRow$ = record.mainRow['c' + String(i)],
              additional = _record$mainRow$.additional,
              isIcon = _record$mainRow$.isIcon,
              showToolTip = _record$mainRow$.showToolTip,
              main = _record$mainRow$.main,
              isBold = _record$mainRow$.isBold,
              onClickAction = _record$mainRow$.onClickAction;

          items.push(_react2.default.createElement(
            _Table.RowItem,
            { language: language, key: record.key + '-' + i },
            _react2.default.createElement(
              _Table.RowItemWrapper,
              { isOneRow: !additional && !isIcon },
              _react2.default.createElement(
                _Table.InnerWrapper,
                null,
                _react2.default.createElement(
                  _Table.HeaderRowItemsPhone,
                  { key: 'rowItem-item-' + i, language: language },
                  tableColumnHeaders && tableColumnHeaders[i] && tableColumnHeaders[i].name
                ),
                _react2.default.createElement(
                  _Table.RowItemContentWrapper,
                  null,
                  showToolTip && main && _react2.default.createElement(
                    _Table.TooltipContainer,
                    { language: language },
                    main
                  ),
                  _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                      'div',
                      { style: { display: 'block', height: '100%' } },
                      _react2.default.createElement(
                        _Table.MainItem,
                        {
                          isRtl: language === 'ar',
                          onClick: function onClick() {
                            if (onClickAction) onClickAction();
                          },
                          isClickable: onClickAction,
                          isBold: isBold,
                          extendMainItem: extendMainItem
                        },
                        main
                      )
                    ),
                    additional ? _react2.default.createElement(
                      'div',
                      { style: { display: 'block', height: '100%' } },
                      _react2.default.createElement(
                        _Table.AdditionalItem,
                        {
                          isRtl: language === 'ar',
                          extendAdditionalItem: extendAdditionalItem
                        },
                        additional
                      )
                    ) : ''
                  )
                )
              )
            )
          ));
        };

        for (var i = 0; i < recordLenght; i += 1) {
          _loop(i);
        }
        if (isExpandableRows) {
          items.push(_react2.default.createElement(
            _Table.ExpandIcon,
            { language: language },
            _react2.default.createElement(
              _Table.IconContainer,
              { id: 'rowitem-icon-' + index },
              _react2.default.createElement(_.Icon, {
                className: 'icon',
                icon: new _.IconsStore(_webIcons2.default).getIcon('downcarret'),
                width: 18
              })
            )
          ));
        }
        return _react2.default.createElement(_TableRow2.default, {
          extendTableRow: extendTableRow,
          onClickRecord: onClickRecord,
          extendTableGrid: extendTableGrid,
          record: record,
          items: items,
          isExpandableRows: isExpandableRows,
          language: language,
          rowIndex: index,
          mixpanel: mixpanel,
          source: source
        });
      }),
      pagination && _react2.default.createElement(
        _Table.PaginationContainer,
        null,
        _react2.default.createElement(_Pagination2.default, {
          extendPageTag: extendPageTag,
          totalNumberOfPages: totalNumberOfPages,
          selectedPage: selectedPage,
          setPage: this.setPage,
          language: language
        })
      )
    );
  };

  return Table;
}(_react2.default.Component);

Table.propTypes = process.env.NODE_ENV !== "production" ? {
  mixpanel: _propTypes2.default.object,
  source: _propTypes2.default.string,
  data: _propTypes2.default.array,
  onClickRecord: _propTypes2.default.func,
  extendPageTag: _propTypes2.default.object,
  extendTableWidth: _propTypes2.default.object,
  tableColumnHeaders: _propTypes2.default.array,
  extendTableGrid: _propTypes2.default.array,
  totalNumberOfPages: _propTypes2.default.number,
  pagination: _propTypes2.default.bool,
  language: _propTypes2.default.string,
  extendTableRow: _propTypes2.default.array,
  extendTableHeaderRow: _propTypes2.default.array,
  isExpandableRows: _propTypes2.default.bool,
  extendMainItem: _propTypes2.default.array,
  extendAdditionalItem: _propTypes2.default.array,
  selectedPage: _propTypes2.default.number,
  onClickPage: _propTypes2.default.func
} : {};

exports.default = Table;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
exports.ButtonText = exports.ButtonContainer = exports.LoadingButton = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 8px;\n'], ['\n  border-radius: 8px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  background: ', ';\n  color: ', ';\n  border-radius: 8px;\n  width: fit-content;\n  cursor: ', ';\n  padding: 10px 10px;\n  display: flex;\n  justify-content: center;\n  font-weight: bold;\n  font-size: 13px;\n  line-height: 20px;\n  ', ';\n'], ['\n  background: ', ';\n  color: ', ';\n  border-radius: 8px;\n  width: fit-content;\n  cursor: ', ';\n  padding: 10px 10px;\n  display: flex;\n  justify-content: center;\n  font-weight: bold;\n  font-size: 13px;\n  line-height: 20px;\n  ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  text-align: center;\n  margin: 0px 5px;\n'], ['\n  text-align: center;\n  margin: 0px 5px;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Rect = require('../shimmerEffect/Rect');

var _Rect2 = _interopRequireDefault(_Rect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var LoadingButton = exports.LoadingButton = (0, _styledComponents2.default)(_Rect2.default)(_templateObject);

var ButtonContainer = exports.ButtonContainer = _styledComponents2.default.div(_templateObject2, function (props) {
  return props.disabled ? '#f1f4f6 !important' : '#f1f4f6';
}, function (props) {
  return props.disabled ? '#484848 !important' : ' #484848';
}, function (props) {
  return props.disabled ? 'unset' : 'pointer';
}, function (props) {
  return props.extendButtonStyle ? props.extendButtonStyle : '';
});

var ButtonText = exports.ButtonText = _styledComponents2.default.p(_templateObject3);
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Pagination = require('./Pagination.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */


var Pagination = function (_React$PureComponent) {
  _inherits(Pagination, _React$PureComponent);

  function Pagination(props) {
    _classCallCheck(this, Pagination);

    var _this = _possibleConstructorReturn(this, _React$PureComponent.call(this, props));

    _this.setPage = function (no) {
      var newPageNo = no;
      if (!newPageNo || newPageNo > _this.props.totalNumberOfPages || newPageNo < 1) return;
      _this.props.setPage(newPageNo);
    };

    _this.toIndiaDigits = function (number) {
      var id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      return number.replace(/[0-9]/g, function (w) {
        return id[+w];
      });
    };

    _this.state = {
      colorFallback: '#EEF0F2',
      backgroundFallback: '#fff'
    };
    return _this;
  }

  Pagination.prototype.renderMobilePagination = function renderMobilePagination() {
    return this.renderPagination(4, 5, 1, 1);
  };

  Pagination.prototype.renderDesktopPagination = function renderDesktopPagination() {
    return this.renderPagination(6, 8, 2, 3, true);
  };

  Pagination.prototype.renderPagination = function renderPagination(startDotsAt, maxTotalPagesNumberWithoutDots, pagesAfterSelectedPage, pagesBeforeSelectedPage, desktop) {
    var _this2 = this;

    var _props = this.props,
        extendContainerStyle = _props.extendContainerStyle,
        extendArrowsBoxStyle = _props.extendArrowsBoxStyle,
        getPaginationHref = _props.getPaginationHref,
        extendPageTag = _props.extendPageTag;
    var IDForAutomatedTesting = this.props.IDForAutomatedTesting;

    if (!desktop) IDForAutomatedTesting += '-mobile';
    var _props2 = this.props,
        totalNumberOfPages = _props2.totalNumberOfPages,
        selectedPage = _props2.selectedPage;

    var color = this.props.color ? this.props.color : this.state.colorFallback;
    var background = this.props.background ? this.props.background : this.state.backgroundFallback;
    var items = [];
    var dotsAtTheBegining = totalNumberOfPages > maxTotalPagesNumberWithoutDots && selectedPage >= startDotsAt;
    var dotsAtTheEnd = totalNumberOfPages > maxTotalPagesNumberWithoutDots && selectedPage < totalNumberOfPages - pagesAfterSelectedPage - 1;
    var startingIndex = dotsAtTheBegining ? dotsAtTheEnd ? selectedPage - pagesBeforeSelectedPage : totalNumberOfPages - startDotsAt + 1 : 2;
    var endingIndex = dotsAtTheEnd ? dotsAtTheBegining ? selectedPage + pagesAfterSelectedPage : startDotsAt : totalNumberOfPages - 1;

    for (var i = startingIndex; i <= endingIndex; i += 1) {
      items.push(this.renderPaginationBox(i, null, IDForAutomatedTesting));
    }

    return _react2.default.createElement(
      _Pagination.PaginationConatiner,
      { desktop: desktop, extendContainerStyle: extendContainerStyle },
      _react2.default.createElement(
        _Pagination.TransparentA,
        {
          as: 'a',
          'aria-label': 'navigate to page previous number',
          disabled: selectedPage === 1,
          href: getPaginationHref && getPaginationHref(selectedPage - 1)
        },
        _react2.default.createElement(
          _Pagination.PaginationItemPrevious,
          {
            extendPageTag: extendPageTag,
            id: IDForAutomatedTesting + '__Pagination-page--Previous',
            'data-testid': 'page-previous',
            key: 'Pagination_Prev',
            color: color,
            extendArrowsBoxStyle: extendArrowsBoxStyle,
            background: background,
            disabled: selectedPage === 1,
            onClick: function onClick() {
              if (!getPaginationHref && !(selectedPage === 1)) _this2.setPage(selectedPage - 1);
            }
          },
          ' ',
          '<',
          ' '
        )
      ),
      this.renderPaginationBox(1, null, IDForAutomatedTesting),
      !dotsAtTheBegining ? '' : this.renderPaginationBox(startingIndex - 1, true, IDForAutomatedTesting),
      items,
      !dotsAtTheEnd ? '' : this.renderPaginationBox(endingIndex + 1, true, IDForAutomatedTesting),
      !totalNumberOfPages > 2 ? '' : this.renderPaginationBox(totalNumberOfPages, null, IDForAutomatedTesting),
      _react2.default.createElement(
        _Pagination.TransparentA,
        {
          as: 'a',
          'aria-label': 'navigate to page next number',
          disabled: selectedPage === totalNumberOfPages,
          href: getPaginationHref && getPaginationHref(selectedPage + 1)
        },
        _react2.default.createElement(
          _Pagination.PaginationItemNext,
          {
            extendPageTag: extendPageTag,
            id: IDForAutomatedTesting + '__Pagination-page--next',
            'data-testid': 'page-next',
            key: 'Pagination_next',
            color: color,
            extendArrowsBoxStyle: extendArrowsBoxStyle,
            background: background,
            disabled: selectedPage === totalNumberOfPages,
            onClick: function onClick() {
              if (!getPaginationHref && !(selectedPage === totalNumberOfPages)) _this2.setPage(selectedPage + 1);
            }
          },
          ' ',
          '>',
          ' '
        )
      )
    );
  };

  Pagination.prototype.renderPaginationBox = function renderPaginationBox(pageNo, dots, IDForAutomatedTesting) {
    var _this3 = this;

    var _props3 = this.props,
        extendPageNumberBoxStyle = _props3.extendPageNumberBoxStyle,
        getPaginationHref = _props3.getPaginationHref,
        extendPageTag = _props3.extendPageTag,
        language = _props3.language;

    var color = this.props.color ? this.props.color : this.state.colorFallback;
    var background = this.props.background ? this.props.background : this.state.backgroundFallback;
    var selectedPage = this.props.selectedPage;

    var selected = selectedPage === pageNo;
    return _react2.default.createElement(
      _Pagination.TransparentA,
      {
        as: selected ? 'span' : 'a',
        'aria-label': 'navigate to page number ' + pageNo,
        href: getPaginationHref && !selected && getPaginationHref(pageNo),
        key: 'Pagination_Page_' + pageNo
      },
      dots ? _react2.default.createElement(
        _Pagination.PaginationDots,
        {
          id: IDForAutomatedTesting + '__Pagination--dots-page-no--' + pageNo,
          'data-testid': 'page-' + pageNo,
          color: color,
          extendPageNumberBoxStyle: extendPageNumberBoxStyle,
          background: background,
          selected: selected
          // onClick={()=>{!getPaginationHref ? this.setPage(pageNo) : null}}
        },
        '...'
      ) : _react2.default.createElement(
        _Pagination.PaginationBox,
        {
          id: IDForAutomatedTesting + '__Pagination-page-no--' + pageNo,
          'data-testid': 'page-' + pageNo,
          color: color,
          extendPageNumberBoxStyle: extendPageNumberBoxStyle,
          background: background,
          selected: selected,
          extendPageTag: extendPageTag,
          onClick: function onClick() {
            return !getPaginationHref && !selected ? _this3.setPage(pageNo) : null;
          }
        },
        language === 'ar' ? this.toIndiaDigits(pageNo.toString()) : pageNo.toString()
      )
    );
  };

  Pagination.prototype.render = function render() {
    return _react2.default.createElement(
      'div',
      { style: { borderTop: '1px solid #EEF0F2' } },
      this.renderMobilePagination(),
      this.renderDesktopPagination()
    );
  };

  return Pagination;
}(_react2.default.PureComponent);

Pagination.propTypes = process.env.NODE_ENV !== "production" ? {
  totalNumberOfPages: _propTypes2.default.number,
  IDForAutomatedTesting: _propTypes2.default.string,
  setPage: _propTypes2.default.func,
  getPaginationHref: _propTypes2.default.func,
  selectedPage: _propTypes2.default.number,
  color: _propTypes2.default.string,
  background: _propTypes2.default.string,
  extendContainerStyle: _propTypes2.default.string,
  extendPageNumberBoxStyle: _propTypes2.default.string,
  extendArrowsBoxStyle: _propTypes2.default.string,
  language: _propTypes2.default.string,
  extendPageTag: _propTypes2.default.object
} : {};

Pagination.defaultProps = {
  IDForAutomatedTesting: '',
  totalNumberOfPages: 1,
  selectedPage: 1
};

exports.default = Pagination;
module.exports = exports['default'];
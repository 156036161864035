'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-clip: padding-box;\n  background-color: ', ';\n  border-radius: 15px;\n  display: block;\n  height: 4px;\n  overflow: hidden;\n  position: relative;\n  ', ';\n'], ['\n  background-clip: padding-box;\n  background-color: ', ';\n  border-radius: 15px;\n  display: block;\n  height: 4px;\n  overflow: hidden;\n  position: relative;\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  background-color: ', ';\n  border-radius: 15px;\n\n  &::before {\n    animation: ', ' 1.75s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;\n    background-color: inherit;\n    bottom: 0;\n    content: \'\';\n    left: 0;\n    position: absolute;\n    top: 0;\n    will-change: left, right;\n  }\n\n  &::after {\n    animation: ', ' 1.75s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;\n    animation-delay: 1.15s;\n    background-color: inherit;\n    bottom: 0;\n    content: \'\';\n    left: 0;\n    position: absolute;\n    top: 0;\n    will-change: left, right;\n  }\n'], ['\n  background-color: ', ';\n  border-radius: 15px;\n\n  &::before {\n    animation: ', ' 1.75s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;\n    background-color: inherit;\n    bottom: 0;\n    content: \'\';\n    left: 0;\n    position: absolute;\n    top: 0;\n    will-change: left, right;\n  }\n\n  &::after {\n    animation: ', ' 1.75s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;\n    animation-delay: 1.15s;\n    background-color: inherit;\n    bottom: 0;\n    content: \'\';\n    left: 0;\n    position: absolute;\n    top: 0;\n    will-change: left, right;\n  }\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Animations = require('../base/Animations');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ProgressContainer = _styledComponents2.default.div(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.PAGE_BACKGROUND], _styledSystem.width);

var Indeterminate = _styledComponents2.default.div(_templateObject2, _Colors.COLORS_VALUES[_Colors.COLORS.PRIMARY_BLUE], _Animations.indeterminate, _Animations.indeterminateShort);

var InfiniteLoadingBar = function InfiniteLoadingBar(props) {
  return _react2.default.createElement(
    ProgressContainer,
    props,
    _react2.default.createElement(Indeterminate, null)
  );
};

exports.default = InfiniteLoadingBar;
module.exports = exports['default'];
import Cookies from 'js-cookie';
import { jsonToQueryString } from '@vezeeta/web-utils';

const deleteCookies = cookies => {
  cookies.map(cookie => {
    Cookies.remove(cookie);
  });
};

const detectCultureFromDoctorUrl = doctorUrl => {
  const arCulture = '/ar/';
  let CultureLanguage = {};
  if (doctorUrl && doctorUrl.includes(arCulture)) {
    CultureLanguage = {
      LanguageId: 2,
      culture: 'ar-EG',
      RedirectCulture: '/ar/',
    };
  } else {
    CultureLanguage = {
      LanguageId: 1,
      culture: 'en-US',
      RedirectCulture: '/en/',
    };
  }
  return CultureLanguage;
};

const getDomain = (url, subdomain) => {
  let result = '';
  if (url) {
    result = url.replace(/(https?:\/\/)?(www.)?/i, '');
    result = result.replace('//', '');

    if (!subdomain) {
      result = result.split('.');
      result = result.slice(url.length - 2).join('.');
    }

    if (result.indexOf('/') !== -1) {
      return result.split('/')[0];
    }
  }

  return result;
};

const SSLDecision = https => {
  if (https === 'true') {
    if (window.location.protocol !== 'https:') {
      window.location.href = window.location.href.replace('http://', 'https://');
    }
  }
};

const detectPaymentType = data => {
  debugger
  let paymentType = {};
  if ('reservationKey' in data) {
    paymentType = {
      type: 'reservation',
      url: process.env.REACT_APP_RETURN_URL,
    };
  } else {
    const callBackAttributes = {
      GuestType: data.GuestType,
      MobileNumber: data.MobileNumber,
      RoomKey: data.RoomKey,
      ExpectedStartingDate: data.ExpectedStartingDate,
      waitingDuration: data.waitingDuration,
      DoctorNameAr: data.DoctorNameAr,
    };
    paymentType = {
      type: 'queueing',
      url: `${data.callBackUrl}${'&'}${jsonToQueryString(callBackAttributes)}`,
    };
  }
  return paymentType;
};
export { deleteCookies, detectCultureFromDoctorUrl, getDomain, SSLDecision, detectPaymentType };

'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 50%;\n  height: ', 'px;\n  min-height: ', 'px;\n  width: ', 'px;\n  min-width: ', 'px;\n  ', ';\n'], ['\n  border-radius: 50%;\n  height: ', 'px;\n  min-height: ', 'px;\n  width: ', 'px;\n  min-width: ', 'px;\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Circle = _styledComponents2.default.div(_templateObject, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
}, _styledSystem.space);

Circle.propTypes = {
  radius: _propTypes2.default.number
};

Circle.defaultProps = {
  radius: 25
};

exports.default = Circle;
module.exports = exports['default'];
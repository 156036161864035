'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Colors = require('../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

require('./SignupStep.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function SignupStep(props) {
  return _react2.default.createElement(
    'div',
    { className: 'signup-step' },
    (!props.hideBackButton || props.hideBackButton === false) && _react2.default.createElement(
      'div',
      { className: 'back-container' },
      _react2.default.createElement(
        'button',
        { className: 'back-btn', onClick: props.backBtnAction },
        _react2.default.createElement(_Icon2.default, {
          icon: new _IconsStore2.default(_webIcons2.default).getIcon('back_arrow'),
          width: 14,
          color: _Colors2.default.vezeetaBlue
        })
      )
    ),
    _react2.default.createElement(
      'div',
      { className: 'content-container' },
      _react2.default.createElement(
        _Text2.default,
        { tag: 'h1', type: _Typography.FONT_TYPES.TITLE, className: 'title' },
        props.title
      ),
      _react2.default.createElement(
        _Text2.default,
        { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'subheading' },
        props.desc
      )
    )
  );
}

SignupStep.propTypes = process.env.NODE_ENV !== "production" ? {
  title: _propTypes2.default.string.isRequired,
  desc: _propTypes2.default.string.isRequired,
  hideBackButton: _propTypes2.default.bool,
  backBtnAction: _propTypes2.default.func
} : {};

SignupStep.defaultProps = {
  hideBackButton: false,
  backBtnAction: function backBtnAction() {}
};

exports.default = (0, _WithDisplayName2.default)(SignupStep, 'SignupStep');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  animation: ', ' 2s linear infinite;\n  width: ', 'px;\n  height: ', 'px;\n  ', ';\n\n  & .path {\n    stroke: ', ';\n    stroke-linecap: round;\n    animation: ', ' 1.5s ease-in-out infinite;\n  }\n'], ['\n  animation: ', ' 2s linear infinite;\n  width: ', 'px;\n  height: ', 'px;\n  ', ';\n\n  & .path {\n    stroke: ', ';\n    stroke-linecap: round;\n    animation: ', ' 1.5s ease-in-out infinite;\n  }\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Colors = require('../base/Colors');

var _Animations = require('../base/Animations');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Spinner = function Spinner(props) {
  return _react2.default.createElement(
    StyledSpinner,
    _extends({}, props, { viewBox: '0 0 50 50' }),
    _react2.default.createElement('circle', { className: 'path', cx: '25', cy: '25', r: '20', fill: 'none', strokeWidth: '4' })
  );
};

var StyledSpinner = _styledComponents2.default.svg(_templateObject, _Animations.rotate, function (props) {
  return props.radius;
}, function (props) {
  return props.radius;
}, _styledSystem.space, function (props) {
  return _Colors.COLORS_VALUES[props.color] || props.color;
}, _Animations.spin);

Spinner.propTypes = process.env.NODE_ENV !== "production" ? {
  radius: _propTypes2.default.number,
  color: _propTypes2.default.string
} : {};

Spinner.defaultProps = {
  radius: 60,
  color: _Colors.COLORS.PRIMARY_BLUE
};

exports.default = Spinner;
module.exports = exports['default'];
import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

export const Confirmation = Loadable({
  loader: () => import('views/Confirmation/Confirmation'),
  loading: Loading,
});

export const Payment = Loadable({
  loader: () => import('views/Payment/Payment'),
  loading: Loading,
});

'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  min-height: ', 'px;\n  height: ', 'px;\n  min-width: ', 'px;\n  width: ', 'px;\n  border: 1px solid;\n  border-radius: 50%;\n  border-color: ', ';\n'], ['\n  min-height: ', 'px;\n  height: ', 'px;\n  min-width: ', 'px;\n  width: ', 'px;\n  border: 1px solid;\n  border-radius: 50%;\n  border-color: ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Colors = require('../base/Colors');

var _Typography = require('../base/Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var circleRadius = 26;

var Circle = _gridStyled.Flex.extend(_templateObject, circleRadius, circleRadius, circleRadius, circleRadius, function (props) {
  return _Colors.COLORS_VALUES[props.border];
});

var Step = function Step(_ref) {
  var title = _ref.title,
      isActive = _ref.isActive,
      isVisited = _ref.isVisited,
      number = _ref.number,
      defaultColor = _ref.defaultColor,
      activeColor = _ref.activeColor;

  var textColor = void 0;
  var stepColor = void 0;
  var border = void 0;
  var bg = void 0;

  if (isActive) {
    textColor = activeColor;
    stepColor = activeColor;
    border = activeColor;
    bg = _Colors.COLORS.TRANSPARENT;
  } else if (isVisited) {
    textColor = activeColor;
    stepColor = _Colors.COLORS.WHITE;
    border = activeColor;
    bg = activeColor;
  } else {
    textColor = defaultColor;
    stepColor = defaultColor;
    border = defaultColor;
    bg = _Colors.COLORS.TRANSPARENT;
  }

  return _react2.default.createElement(
    _gridStyled.Flex,
    { key: title, alignItems: 'center' },
    _react2.default.createElement(
      Circle,
      { alignItems: 'center', justifyContent: 'center', bg: bg, border: border },
      isVisited && !isActive ? _react2.default.createElement(_Icon2.default, { icon: new _IconsStore2.default(_webIcons2.default).getIcon('success'), width: 12, color: _Colors.COLORS.WHITE }) : _react2.default.createElement(
        _Text2.default,
        { fontWeight: _Typography.FONT_WEIGHTS.SEMI_BOLD, color: stepColor },
        number
      )
    ),
    _react2.default.createElement(
      _Text2.default,
      { fontWeight: _Typography.FONT_WEIGHTS.NORMAL, type: _Typography.FONT_TYPES.SUBHEADING, color: textColor, ml: 2 },
      title
    )
  );
};

Step.propTypes = process.env.NODE_ENV !== "production" ? {
  title: _propTypes2.default.string.isRequired,
  isActive: _propTypes2.default.bool.isRequired,
  isVisited: _propTypes2.default.bool.isRequired,
  number: _propTypes2.default.number.isRequired,
  activeColor: _propTypes2.default.string.isRequired,
  defaultColor: _propTypes2.default.string.isRequired
} : {};

exports.default = Step;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background: ', ';\n  border: 0;\n  box-shadow: none;\n  height: 6px;\n  outline: none;\n  width: 100%;\n  overflow: hidden;\n  ', ';\n'], ['\n  background: ', ';\n  border: 0;\n  box-shadow: none;\n  height: 6px;\n  outline: none;\n  width: 100%;\n  overflow: hidden;\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  transition: width 150ms ease-out;\n  background-color: ', ';\n  height: 100%;\n  ', ';\n'], ['\n  transition: width 150ms ease-out;\n  background-color: ', ';\n  height: 100%;\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _color = require('color');

var _color2 = _interopRequireDefault(_color);

var _colorString = require('color-string');

var _colorString2 = _interopRequireDefault(_colorString);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Colors = require('../base/Colors');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ProgressBarContainer = _styledComponents2.default.div(_templateObject, _colorString2.default.to.rgb((0, _color2.default)(_Colors.COLORS_VALUES[_Colors.COLORS.PRIMARY_BLUE]).alpha(0.3).rgb().array()), _styledSystem.borderRadius);

var Indicator = _styledComponents2.default.div(_templateObject2, _Colors.COLORS_VALUES[_Colors.COLORS.PRIMARY_BLUE], _styledSystem.width);

var ProgressBar = function (_Component) {
  _inherits(ProgressBar, _Component);

  function ProgressBar() {
    var _temp, _this, _ret;

    _classCallCheck(this, ProgressBar);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      widthPercentage: 0
    }, _this.updateProgress = function (currentStep) {
      var totalSteps = _this.props.totalSteps;

      var widthPercentage = currentStep / totalSteps;
      _this.setState({
        widthPercentage: widthPercentage
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  ProgressBar.prototype.componentDidMount = function componentDidMount() {
    var currentStep = this.props.currentStep;

    this.updateProgress(currentStep);
  };

  ProgressBar.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    if (prevProps.currentStep !== this.props.currentStep) {
      var currentStep = this.props.currentStep;

      this.updateProgress(currentStep);
    }
  };

  /**
   * Takes the current function and generate the percentage then change the
   * indicator width
   * @param {number} currentStep
   */


  ProgressBar.prototype.render = function render() {
    var widthPercentage = this.state.widthPercentage;


    return _react2.default.createElement(
      ProgressBarContainer,
      this.props,
      _react2.default.createElement(Indicator, { width: widthPercentage })
    );
  };

  return ProgressBar;
}(_react.Component);

ProgressBar.propTypes = process.env.NODE_ENV !== "production" ? {
  currentStep: _propTypes2.default.number.isRequired,
  totalSteps: _propTypes2.default.number.isRequired
} : {};
exports.default = (0, _WithDisplayName2.default)(ProgressBar, 'ProgressBar');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
exports.ExtendComboboxMenuList = exports.ExtendInputContainer = exports.DropDownWithCheckboxListContainer = exports.DropDownWithCheckboxIcon = exports.DropDownWithCheckboxTitle = exports.DropDownWithCheckbox = exports.DropDownWithCheckboxContainer = exports.DropDownContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border: 1px solid #a9aaac;\n  padding: 4px 0px;\n  height: 44px;\n  border-radius: 5px;\n  width: 235px;\n'], ['\n  border: 1px solid #a9aaac;\n  padding: 4px 0px;\n  height: 44px;\n  border-radius: 5px;\n  width: 235px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  width: 235px;\n  position: relative;\n'], ['\n  width: 235px;\n  position: relative;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  display: flex; \n  justify-content: space-between;\n  padding: 4px 15px;\n'], ['\n  display: flex; \n  justify-content: space-between;\n  padding: 4px 15px;\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  font-size: 13px;\n  color: #58595b;\n  padding: 8px 0px;\n'], ['\n  font-size: 13px;\n  color: #58595b;\n  padding: 8px 0px;\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  padding: 6px 5px;\n'], ['\n  padding: 6px 5px;\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  display: ', ';\n  border: 1px solid rgba(224, 224, 224, 0.4);\n  box-shadow: 0 2px 2px rgba(224, 224, 224, 0.4);\n  z-index: 5;\n  padding: 13px 17px;\n  position: absolute;\n  background-color: #fff;\n  width: 100%;\n'], ['\n  display: ', ';\n  border: 1px solid rgba(224, 224, 224, 0.4);\n  box-shadow: 0 2px 2px rgba(224, 224, 224, 0.4);\n  z-index: 5;\n  padding: 13px 17px;\n  position: absolute;\n  background-color: #fff;\n  width: 100%;\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  padding: 0px 15px !important;\n'], ['\n  padding: 0px 15px !important;\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  margin-top: 5px !important;\n'], ['\n  margin-top: 5px !important;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var DropDownContainer = exports.DropDownContainer = _styledComponents2.default.div(_templateObject);

var DropDownWithCheckboxContainer = exports.DropDownWithCheckboxContainer = _styledComponents2.default.div(_templateObject2);

var DropDownWithCheckbox = exports.DropDownWithCheckbox = (0, _styledComponents2.default)(DropDownContainer)(_templateObject3);

var DropDownWithCheckboxTitle = exports.DropDownWithCheckboxTitle = _styledComponents2.default.p(_templateObject4);
var DropDownWithCheckboxIcon = exports.DropDownWithCheckboxIcon = _styledComponents2.default.div(_templateObject5);

var DropDownWithCheckboxListContainer = exports.DropDownWithCheckboxListContainer = _styledComponents2.default.div(_templateObject6, function (props) {
  return props.showCheckBoxMenuList ? 'block' : 'none';
});

var ExtendInputContainer = exports.ExtendInputContainer = (0, _styledComponents.css)(_templateObject7);

var ExtendComboboxMenuList = exports.ExtendComboboxMenuList = (0, _styledComponents.css)(_templateObject8);
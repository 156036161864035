'use strict';

exports.__esModule = true;
exports.IconContainer = exports.BarContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background: #db3226;\n  border-radius: 8px;\n  width: 100%;\n  height: 45px;\n  display: inline-flex;\n  align-items: center;\n  font-size: 14px;\n  color: #fff;\n  padding: 0px 17px;\n  ', ';\n'], ['\n  background: #db3226;\n  border-radius: 8px;\n  width: 100%;\n  height: 45px;\n  display: inline-flex;\n  align-items: center;\n  font-size: 14px;\n  color: #fff;\n  padding: 0px 17px;\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  display: inline-flex;\n'], ['\n  display: inline-flex;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var BarContainer = exports.BarContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.extendBarContainer ? props.extendBarContainer : '';
});

var IconContainer = exports.IconContainer = _styledComponents2.default.div(_templateObject2);
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icon = require('../../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _DropdownMultiSelect = require('./DropdownMultiSelect.style');

var _DropdownMultiSelect2 = require('./DropdownMultiSelect.helper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */


var DropDown = function DropDown(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var onClickIconAction = props.onClickIconAction,
      fieldLabel = props.fieldLabel,
      fieldValue = props.fieldValue,
      options = props.options,
      extendDropDownStyle = props.extendDropDownStyle,
      extendFieldText = props.extendFieldText,
      icon = props.icon,
      iconSize = props.iconSize,
      onChanges = props.onChanges,
      isRequired = props.isRequired,
      extendDropDownList = props.extendDropDownList,
      extendDropDownListItem = props.extendDropDownListItem,
      componentName = props.componentName,
      isDisabled = props.isDisabled,
      isValid = props.isValid,
      tags = props.tags,
      isCategorized = props.isCategorized,
      enableAllOption = props.enableAllOption,
      placeHolder = props.placeHolder,
      fontSize = props.fontSize,
      fieldIcon = props.fieldIcon,
      fieldIconSize = props.fieldIconSize,
      allLabel = props.allLabel,
      isCounter = props.isCounter,
      variationB = props.variationB;

  var _useState = (0, _react.useState)([]),
      optionList = _useState[0],
      setOptionList = _useState[1]; // for List View


  var _useState2 = (0, _react.useState)(false),
      isAllTagsShown = _useState2[0],
      toggleTagsShown = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      dropDownToTop = _useState3[0],
      setDropDownToTop = _useState3[1];

  var toggleDropDowns = function toggleDropDowns() {
    var dropDowns = document.querySelectorAll('div[class^="dropdown"]');
    var anotherDropDownsLabels = [];

    // Get all onther dropdown other than the current clicked one
    dropDowns.forEach(function (dropDown) {
      var dropDownClassName = Array.from(dropDown.classList).filter(function (className) {
        return className.includes('dropdown-');
      });
      if (dropDownClassName[0] !== 'dropdown-' + componentName) {
        anotherDropDownsLabels.push(dropDownClassName[0]);
      }
    });

    // Close opened lists of all onther dropdowns
    anotherDropDownsLabels.forEach(function (dropDown) {
      var label = dropDown.split('-')[1];
      var list = document.getElementById('dropdown-list-' + label);
      if (list) list.style.display = 'none';
    });
  };

  var isAllSelected = (fieldValue && fieldValue.length) === (options && options.length);

  var onItemSelect = function onItemSelect(option, itemIndex) {
    var updatedOptionsList = [].concat(optionList);

    if (option === -1) {
      updatedOptionsList.forEach(function (listItem, index) {
        updatedOptionsList[index].isChecked = !isAllSelected;
      });
      var checkedSelections = [];
      updatedOptionsList.forEach(function (optionItem) {
        if (optionItem.isChecked) checkedSelections.push(optionItem.value);
      });
      setOptionList(updatedOptionsList);
      if (onChanges) onChanges(checkedSelections);
    } else {
      updatedOptionsList[itemIndex].isChecked = !option.isChecked;
      setOptionList(updatedOptionsList);
      var _checkedSelections = [];
      updatedOptionsList.forEach(function (optionItem) {
        if (optionItem.isChecked) _checkedSelections.push(optionItem.value);
      });
      if (onChanges) onChanges(_checkedSelections);
    }
  };

  // Load for first time
  (0, _react.useEffect)(function () {
    var resetedOptionsList = [];
    if (options && options.length && !optionList.length) {
      options.forEach(function (item) {
        resetedOptionsList.push(_extends({}, item, {
          isChecked: false
        }));
      });
      setOptionList(resetedOptionsList);
    }
  }, [options]);

  (0, _react.useEffect)(function () {
    var resetedOptionsList = [];
    if (options && options.length) {
      options.forEach(function (item) {
        if (fieldValue.includes(item.key)) {
          resetedOptionsList.push(_extends({}, item, {
            isChecked: true
          }));
        } else {
          resetedOptionsList.push(_extends({}, item, {
            isChecked: false
          }));
        }
      });

      setOptionList(resetedOptionsList);
    }
  }, [fieldValue]);

  var isFieldValueRendered = fieldValue.length > 0;

  var onFieldClick = function onFieldClick() {
    toggleDropDowns();
    (0, _DropdownMultiSelect2.dropDownListToggler)(optionList, componentName, variationB);

    // Get DropDown
    var listComponent = document.getElementsByClassName('dropdown-' + componentName);
    // Get Parent Hight
    var parentHeight = listComponent[0].offsetParent.clientHeight;
    // Position top of dropdown
    var listTopPosition = listComponent[0].offsetTop;
    // DropDown height
    var listComponentHeight = listComponent[0].clientHeight;
    // List Height
    var list = document.getElementById('dropdown-list-' + componentName);
    var itemListHight = list.children[0].clientHeight;
    if (parentHeight - listTopPosition - listComponentHeight < itemListHight) {
      // list open to top
      setDropDownToTop(-(6 + itemListHight + listComponentHeight));
    } else {
      // list open to down
      setDropDownToTop(6);
    }
  };

  var listListenerHandler = function listListenerHandler(event) {
    var myElementToCheckIfClicksAreInsideOf = document.querySelector('#' + componentName);
    if (!myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
      var list = document.getElementById('dropdown-list-' + componentName);
      list.style.display = 'none';
      if (variationB) {
        var listBtn = document.getElementById('dropdown-list-btn-' + componentName);
        listBtn.style.backgroundColor = 'unset';
      }
    }
  };

  var addListener = function addListener() {
    document.body.addEventListener('click', listListenerHandler, true);
  };

  var removeListener = function removeListener() {
    document.body.removeEventListener('click', listListenerHandler, true);
  };

  (0, _react.useEffect)(function () {
    addListener();
    return function () {
      removeListener();
    };
  }, []);

  var renderSelectedOptionsField = function renderSelectedOptionsField() {
    if (isFieldValueRendered && optionList && optionList.length) {
      if (tags) {
        return _react2.default.createElement(
          _DropdownMultiSelect.TagsContainer,
          { extendFieldText: extendFieldText },
          (0, _DropdownMultiSelect2.renderTags)(optionList, onItemSelect, isAllTagsShown, isCategorized),
          fieldValue.length > 2 && _react2.default.createElement(
            _DropdownMultiSelect.TagContainer,
            {
              extendTagContainer: _DropdownMultiSelect.extendTagContainer,
              onClick: function onClick() {
                return toggleTagsShown(!isAllTagsShown);
              }
            },
            _react2.default.createElement(
              _DropdownMultiSelect.TagWrapper,
              null,
              _react2.default.createElement(
                _DropdownMultiSelect.TagLabel,
                null,
                isAllTagsShown ? 'Show less' : fieldValue.length - 2 + ' more'
              ),
              _react2.default.createElement(
                _DropdownMultiSelect.IconContainer,
                { isAllTagsShown: isAllTagsShown },
                _react2.default.createElement(_Icon2.default, { className: 'icon', icon: _IconsStore2.default.getIcon('dropdown'), width: 16 })
              )
            )
          )
        );
      }
      return _react2.default.createElement(
        _DropdownMultiSelect.FieldValue,
        { extendFieldText: extendFieldText },
        (0, _DropdownMultiSelect2.renderSelectedOptions)(optionList, isCounter, placeHolder, allLabel)
      );
    }
    return '';
  };

  return _react2.default.createElement(
    'div',
    {
      style: { position: 'relative' },
      className: 'dropdown dropdown-' + componentName,
      id: componentName,
      key: componentName
    },
    _react2.default.createElement(_DropdownMultiSelect.DisableOverLay, { isDisabled: isDisabled }),
    _react2.default.createElement(
      _DropdownMultiSelect.FieldContainer,
      {
        id: 'dropdown-list-btn-' + componentName,
        isValid: isValid,
        isDimmed: false,
        extendDropDownStyle: extendDropDownStyle,
        onClick: function onClick() {
          if (options && options.length) onFieldClick();
        }
      },
      fieldIcon && _react2.default.createElement(
        'div',
        {
          style: {
            margin: '0px 4px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }
        },
        _react2.default.createElement(_Icon2.default, { className: 'icon', icon: _IconsStore2.default.getIcon(fieldIcon), width: fieldIconSize })
      ),
      _react2.default.createElement(
        _DropdownMultiSelect.LabelValueContainer,
        { style: { width: '90%' } },
        fieldLabel && _react2.default.createElement(
          _DropdownMultiSelect.FieldLabel,
          { isValueSelected: fieldValue.length },
          fieldLabel,
          isRequired && _react2.default.createElement(
            _DropdownMultiSelect.IsRequiredNote,
            null,
            '*'
          )
        ),
        isFieldValueRendered ? renderSelectedOptionsField(optionList) : _react2.default.createElement(
          _DropdownMultiSelect.FieldValue,
          { isPlaceHolder: true, fontSize: fontSize, extendFieldText: extendFieldText },
          placeHolder
        )
      ),
      icon && _react2.default.createElement(
        'div',
        {
          style: {
            margin: '0px 4px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }
        },
        _react2.default.createElement(_Icon2.default, {
          className: 'icon',
          onClick: function onClick(e) {
            if (onClickIconAction) {
              onClickIconAction();
              e.stopPropagation();
            }
          },
          icon: _IconsStore2.default.getIcon(icon),
          width: iconSize
        })
      )
    ),
    _react2.default.createElement(
      'div',
      { id: 'dropdown-list-' + componentName, style: { display: 'none', position: 'relative' } },
      _react2.default.createElement(
        _DropdownMultiSelect.ListContainer,
        { dropDownToTop: dropDownToTop, extendDropDownList: extendDropDownList },
        (0, _DropdownMultiSelect2.renderDropDownList)(optionList, onItemSelect, {
          extendDropDownListItem: extendDropDownListItem
        }, isCategorized, enableAllOption, isAllSelected, allLabel)
      )
    )
  );
};

DropDown.propTypes = process.env.NODE_ENV !== "production" ? {
  onClickIconAction: _propTypes2.default.func,
  fieldValue: _propTypes2.default.string,
  fieldLabel: _propTypes2.default.string.isRequired,
  icon: _propTypes2.default.string.isRequired,
  options: _propTypes2.default.array,
  onChanges: _propTypes2.default.func.isRequired,
  iconSize: _propTypes2.default.number.isRequired,
  extendDropDownStyle: _propTypes2.default.string,
  isRequired: _propTypes2.default.bool,
  extendDropDownList: _propTypes2.default.array,
  extendDropDownListItem: _propTypes2.default.string,
  componentName: _propTypes2.default.string.isRequired,
  isDisabled: _propTypes2.default.bool,
  isValid: _propTypes2.default.bool,
  extendFieldText: _propTypes2.default.string,
  tags: _propTypes2.default.bool,
  isCategorized: _propTypes2.default.bool,
  enableAllOption: _propTypes2.default.bool,
  placeHolder: _propTypes2.default.string,
  fontSize: _propTypes2.default.number,
  fieldIcon: _propTypes2.default.string,
  fieldIconSize: _propTypes2.default.number,
  allLabel: _propTypes2.default.string,
  isCounter: _propTypes2.default.bool,
  variationB: _propTypes2.default.bool
} : {};
exports.default = DropDown;
module.exports = exports['default'];
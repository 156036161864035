'use strict';

exports.__esModule = true;
exports.AddItemIconWrapper = exports.AddItemIconContainer = exports.DisableOverLay = exports.IsRequiredNote = exports.SearchInput = exports.CheckBox = exports.ItemContentContainer = exports.ItemDescription = exports.ItemLabel = exports.ItemContent = exports.ItemImage = exports.ListItem = exports.ListContainer = exports.FieldValue = exports.FieldLabel = exports.LabelValueContainer = exports.FieldContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-color: ', ';\n  width: 100%;\n  min-height: 56px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 7px 16px;\n  // margin: 4px 0px;\n  border-radius: 8px;\n  border: ', ';\n  cursor: ', ';\n  ', ';\n'], ['\n  background-color: ', ';\n  width: 100%;\n  min-height: 56px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 7px 16px;\n  // margin: 4px 0px;\n  border-radius: 8px;\n  border: ', ';\n  cursor: ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  width: 90%;\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n'], ['\n  width: 90%;\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  color: #9c9c9c;\n  width: 100%;\n  font-size: ', ';\n  line-height: ', ';\n'], ['\n  color: #9c9c9c;\n  width: 100%;\n  font-size: ', ';\n  line-height: ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  font-size: 16px;\n  line-height: 24px;\n  color: #484848;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n'], ['\n  width: 100%;\n  font-size: 16px;\n  line-height: 24px;\n  color: #484848;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));\n  background-color: #fff;\n  width: 100%;\n  z-index: 2;\n  max-height: 210px;\n  overflow: auto;\n  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);\n  border-radius: 8px;\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #9c9c9c;\n    border-radius: 15px;\n  }\n  ', ';\n  ', ';\n'], ['\n  position: absolute;\n  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));\n  background-color: #fff;\n  width: 100%;\n  z-index: 2;\n  max-height: 210px;\n  overflow: auto;\n  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);\n  border-radius: 8px;\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #9c9c9c;\n    border-radius: 15px;\n  }\n  ', ';\n  ', ';\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n  box-shadow: inset 0px -1px 0px #f1f4f6;\n  padding: 8px 12px 8px 16px;\n  font-size: 16px;\n  line-height: 24px;\n  cursor: pointer;\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: row;\n  box-shadow: inset 0px -1px 0px #f1f4f6;\n  padding: 8px 12px 8px 16px;\n  font-size: 16px;\n  line-height: 24px;\n  cursor: pointer;\n  ', ';\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  width: 40px;\n  height: 40px;\n'], ['\n  width: 40px;\n  height: 40px;\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  margin: 0px 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n'], ['\n  margin: 0px 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n  color: #484848;\n  font-size: 14px;\n'], ['\n  color: #484848;\n  font-size: 14px;\n']),
    _templateObject10 = _taggedTemplateLiteralLoose(['\n  font-size: 13px;\n  line-height: 14px;\n  color: #9c9c9c;\n  direction: ', ';\n  text-align: ', ';\n'], ['\n  font-size: 13px;\n  line-height: 14px;\n  color: #9c9c9c;\n  direction: ', ';\n  text-align: ', ';\n']),
    _templateObject11 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n'], ['\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n']),
    _templateObject12 = _taggedTemplateLiteralLoose(['\n  width: 24px;\n  height: 24px;\n  margin: 2% 0%;\n'], ['\n  width: 24px;\n  height: 24px;\n  margin: 2% 0%;\n']),
    _templateObject13 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  border: unset;\n  font-size: 16px;\n  line-height: 24px;\n  &:focus {\n    outline: none;\n  }\n  ', ';\n'], ['\n  width: 100%;\n  border: unset;\n  font-size: 16px;\n  line-height: 24px;\n  &:focus {\n    outline: none;\n  }\n  ', ';\n']),
    _templateObject14 = _taggedTemplateLiteralLoose(['\n  display: inline-flex;\n  margin: 0px 3px;\n  color: #db3226;\n'], ['\n  display: inline-flex;\n  margin: 0px 3px;\n  color: #db3226;\n']),
    _templateObject15 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  min-height: 100%;\n  border: 1px solid #e3e6ea;\n  border-radius: 8px;\n  background-color: #f1f4f69c;\n  position: absolute;\n  display: ', ';\n'], ['\n  width: 100%;\n  min-height: 100%;\n  border: 1px solid #e3e6ea;\n  border-radius: 8px;\n  background-color: #f1f4f69c;\n  position: absolute;\n  display: ', ';\n']),
    _templateObject16 = _taggedTemplateLiteralLoose(['\n  background: #e6f1ff;\n  border-radius: 8px;\n  width: 36px;\n  height: 36px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n'], ['\n  background: #e6f1ff;\n  border-radius: 8px;\n  width: 36px;\n  height: 36px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n']),
    _templateObject17 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n'], ['\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FieldContainer = exports.FieldContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.isDimmed ? '#f5f5f5 !important' : '#f1f4f6';
}, function (props) {
  return props.isValid ? '1px solid #e3e6ea !important' : '1px solid #db3226 !important';
}, function (props) {
  return props.isDimmed ? 'unset !important' : 'pointer';
}, function (props) {
  return props.extendDropDownStyle ? props.extendDropDownStyle : '';
});

var LabelValueContainer = exports.LabelValueContainer = _styledComponents2.default.div(_templateObject2);

var FieldLabel = exports.FieldLabel = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.isValueSelected ? '12px' : '16px';
}, function (props) {
  return props.isValueSelected ? '18px' : '40px';
});
var FieldValue = exports.FieldValue = _styledComponents2.default.p(_templateObject4);

var ListContainer = exports.ListContainer = _styledComponents2.default.div(_templateObject5, function (props) {
  return props.dropDownToTop && 'top : ' + props.dropDownToTop + 'px';
}, function (props) {
  return props.extendDropDownList ? props.extendDropDownList : '';
});
var ListItem = exports.ListItem = _styledComponents2.default.div(_templateObject6, function (props) {
  return props.extendDropDownListItem ? props.extendDropDownListItem : '';
});

var ItemImage = exports.ItemImage = _styledComponents2.default.img(_templateObject7);

var ItemContent = exports.ItemContent = _styledComponents2.default.div(_templateObject8);

var ItemLabel = exports.ItemLabel = _styledComponents2.default.p(_templateObject9);
var ItemDescription = exports.ItemDescription = _styledComponents2.default.p(_templateObject10, function (props) {
  return props.language === 'ar' ? 'ltr' : 'unset';
}, function (props) {
  return props.language === 'ar' ? 'right' : 'unset';
});

var ItemContentContainer = exports.ItemContentContainer = _styledComponents2.default.div(_templateObject11);

var CheckBox = exports.CheckBox = _styledComponents2.default.input(_templateObject12);

var SearchInput = exports.SearchInput = _styledComponents2.default.input(_templateObject13, function (props) {
  return props.extendDropDownStyle ? props.extendDropDownStyle : '';
});

var IsRequiredNote = exports.IsRequiredNote = _styledComponents2.default.p(_templateObject14);

var DisableOverLay = exports.DisableOverLay = _styledComponents2.default.div(_templateObject15, function (props) {
  return !props.isDisabled ? 'none' : 'block';
});

var AddItemIconContainer = exports.AddItemIconContainer = _styledComponents2.default.div(_templateObject16);

var AddItemIconWrapper = exports.AddItemIconWrapper = _styledComponents2.default.div(_templateObject17);
'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border-radius: 4px 4px 0 0;\n  background-color: ', ';\n  opacity: 0.6;\n'], ['\n  border-radius: 4px 4px 0 0;\n  background-color: ', ';\n  opacity: 0.6;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _gridStyled = require('../grid-styled');

var _FilterSection = require('./filterSection/FilterSection');

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FilterTitleContainer = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.PRIMARY_BLUE]);

var Filter = (_temp2 = _class = function (_Component) {
  _inherits(Filter, _Component);

  function Filter() {
    var _temp, _this, _ret;

    _classCallCheck(this, Filter);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      appliedFilters: {},
      sections: []
    }, _this.getSections = function () {
      var _this$props = _this.props,
          sections = _this$props.sections,
          language = _this$props.language,
          emptyStateMessage = _this$props.emptyStateMessage,
          isFilterChanged = _this$props.isFilterChanged;

      var result = sections.map(function (section) {
        return _react2.default.createElement(_FilterSection2.default, {
          key: section.name,
          type: section.type,
          name: section.name,
          icon: section.icon,
          filters: section.filters,
          placeholder: section.placeholder,
          isAllSelected: section.isAllSelected,
          isFilterChanged: isFilterChanged,
          selectedIds: section.selectedIds,
          selectedValue: section.selectedValue,
          noSearch: section.noSearch,
          selectFirst: section.selectFirst,
          onChange: _this.handleFilterSectionChange,
          language: language,
          emptyStateMessage: emptyStateMessage
        });
      });
      return result;
    }, _this.handleFilterSectionChange = function (filterSection) {
      var _extends2;

      var onChange = _this.props.onChange;

      var appliedFilters = _extends({}, _this.state.appliedFilters, (_extends2 = {}, _extends2[filterSection.name] = filterSection.data, _extends2));

      _this.setState({
        appliedFilters: appliedFilters
      }, function () {
        onChange(appliedFilters);
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  /**
   * Resets marked sections applied filters
   * @param {object} nextProps
   * @param {object} prevState
   */
  Filter.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
    if (!_lodash2.default.isEqual(prevState.sections, nextProps.sections)) {
      var resetSections = {};
      nextProps.sections.filter(function (section) {
        return section.resetSection;
      }).forEach(function (section) {
        resetSections[section.name] = null;
      });

      return {
        appliedFilters: _extends({}, prevState.appliedFilters, resetSections),
        sections: nextProps.sections
      };
    }
    return null;
  };

  /**
   * Gets list of sections
   * @returns {array} sections
   */


  /**
   * Handles change in filter section
   * @param {object} filterSection
   */


  Filter.prototype.render = function render() {
    var _props = this.props,
        title = _props.title,
        otherProps = _props.otherProps;

    var sections = this.getSections();
    return _react2.default.createElement(
      _react2.default.Fragment,
      null,
      _react2.default.createElement(
        FilterTitleContainer,
        { width: 1, px: 5, py: 2, alignItems: 'center' },
        _react2.default.createElement(_Icon2.default, _extends({
          icon: new _IconsStore2.default(_webIcons2.default).getIcon('filter'),
          width: 14,
          color: '#fff'
        }, otherProps)),
        _react2.default.createElement(
          _Text2.default,
          { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, color: _Colors.COLORS.WHITE, m: 0 },
          title
        )
      ),
      sections
    );
  };

  return Filter;
}(_react.Component), _class.defaultProps = {
  onChange: function onChange() {},
  otherProps: {},
  language: 'en',
  isFilterChanged: false
}, _temp2);
Filter.propTypes = process.env.NODE_ENV !== "production" ? {
  title: _propTypes2.default.string.isRequired,
  sections: _propTypes2.default.array.isRequired, // eslint-disable-line
  onChange: _propTypes2.default.func,
  language: _propTypes2.default.string,
  emptyStateMessage: _propTypes2.default.string.isRequired,
  otherProps: _propTypes2.default.object, // eslint-disable-line
  isFilterChanged: _propTypes2.default.bool
} : {};
exports.default = (0, _WithDisplayName2.default)(Filter, 'Filter');
module.exports = exports['default'];
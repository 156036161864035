'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Option = function (_Component) {
  _inherits(Option, _Component);

  function Option() {
    var _temp, _this, _ret;

    _classCallCheck(this, Option);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.updateValue = function () {
      _this.input.checked = true;
      _this.props.updateValue(_this.props.value);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Option.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (nextProps.defaultChecked !== undefined && this.input) {
      this.input.checked = nextProps.defaultChecked;
    }
  };

  /**
   * Updates parent value
   */


  Option.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        disable = _props.disable,
        value = _props.value,
        name = _props.name,
        defaultChecked = _props.defaultChecked,
        component = _props.component,
        description = _props.description,
        direction = _props.direction;

    var optionClassName = disable ? 'option--disabled' : '';
    return _react2.default.createElement(
      _gridStyled.Flex,
      { flexDirection: direction },
      _react2.default.createElement(
        'li',
        { className: optionClassName },
        _react2.default.createElement(
          _gridStyled.Flex,
          { flexDirection: 'row' },
          _react2.default.createElement('input', {
            autoComplete: 'off',
            ref: function ref(input) {
              _this2.input = input;
            },
            type: 'radio',
            id: value,
            name: name,
            onClick: this.updateValue,
            defaultChecked: defaultChecked,
            disabled: disable
          }),
          _react2.default.createElement(
            'div',
            { className: 'check', onClick: this.updateValue, onKeyDown: function onKeyDown() {} },
            _react2.default.createElement('div', { className: 'inside' })
          ),
          _react2.default.createElement(
            _Text2.default,
            _extends({ htmlFor: value }, component.props),
            component.props.children
          )
        )
      ),
      description && _react2.default.createElement(
        _Text2.default,
        description.props,
        description.props.children
      )
    );
  };

  return Option;
}(_react.Component);

Option.propTypes = process.env.NODE_ENV !== "production" ? {
  component: _propTypes2.default.element.isRequired,
  description: _propTypes2.default.element.isRequired,
  checked: _propTypes2.default.bool,
  defaultChecked: _propTypes2.default.bool.isRequired,
  value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired,
  updateValue: _propTypes2.default.func.isRequired,
  name: _propTypes2.default.string.isRequired,
  disable: _propTypes2.default.bool.isRequired,
  direction: _propTypes2.default.string.isRequired
} : {};

Option.defaultProps = {
  checked: false
};

exports.default = (0, _WithDisplayName2.default)(Option, 'Option');
module.exports = exports['default'];
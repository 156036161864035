'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  height: 65px;\n  width: inherit;\n  ', ';\n  ', ';\n'], ['\n  height: 65px;\n  width: inherit;\n  ', ';\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; } /* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */


var Image = _styledComponents2.default.img(_templateObject, function (props) {
  return props.placeHolder && '\n    background: url(' + props.placeHolder + ');\n  ';
}, function (props) {
  return props.extendStyle ? props.extendStyle : '';
});

var ImageComponents = function ImageComponents(_ref) {
  var props = _objectWithoutProperties(_ref, []);

  var imageUrl = props.imageUrl,
      componentName = props.componentName,
      placeHolder = props.placeHolder,
      onClickImage = props.onClickImage,
      extendStyle = props.extendStyle;

  (0, _react.useEffect)(function () {
    var img = document.getElementById(componentName);
    img.onerror = function () {
      img.src = placeHolder;
    };
  }, [imageUrl]);
  return _react2.default.createElement(Image, {
    placeHolder: placeHolder,
    extendStyle: extendStyle,
    id: componentName,
    alt: componentName,
    src: imageUrl,
    onClick: function onClick() {
      if (onClickImage) onClickImage();
    }
  });
};

ImageComponents.propTypes = process.env.NODE_ENV !== "production" ? {
  imageUrl: _propTypes2.default.string,
  componentName: _propTypes2.default.string,
  placeHolder: _propTypes2.default.string,
  onClickImage: _propTypes2.default.func,
  extendStyle: _propTypes2.default.array
} : {};

exports.default = ImageComponents;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  object-fit: cover;\n  height: auto;\n  ', ';\n'], ['\n  object-fit: cover;\n  height: auto;\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Typography = require('../base/Typography');

var _Button = require('../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Image = _styledComponents2.default.img(_templateObject, _styledSystem.width);

var EmptyState = function EmptyState(_ref) {
  var text = _ref.text,
      image = _ref.image,
      imageWidth = _ref.imageWidth,
      buttonText = _ref.buttonText,
      buttonIcon = _ref.buttonIcon,
      buttonIconWidth = _ref.buttonIconWidth,
      buttonOnClick = _ref.buttonOnClick;
  return _react2.default.createElement(
    _gridStyled.Flex,
    { flexDirection: 'column', alignItems: 'center' },
    image && _react2.default.createElement(Image, { width: imageWidth, src: image }),
    _react2.default.createElement(
      _Text2.default,
      {
        textAlign: 'center',
        width: imageWidth + imageWidth * 3 / 4,
        tag: 'h3',
        mt: 4,
        mb: 4,
        type: _Typography.FONT_TYPES.HEADING
      },
      text
    ),
    buttonText && _react2.default.createElement(
      _Button2.default,
      {
        primary: false,
        color: _Colors.COLORS.PRIMARY_BLUE,
        icon: buttonIcon,
        iconWidth: buttonIconWidth,
        onClick: buttonOnClick
      },
      buttonText
    )
  );
};

EmptyState.propTypes = process.env.NODE_ENV !== "production" ? {
  text: _propTypes2.default.string.isRequired,
  image: _propTypes2.default.string,
  buttonText: _propTypes2.default.string,
  buttonIcon: _Icon.iconPropTypes,
  buttonIconWidth: _propTypes2.default.number,
  buttonOnClick: _propTypes2.default.func,
  imageWidth: _propTypes2.default.number
} : {};

EmptyState.defaultProps = {
  image: undefined,
  buttonText: undefined,
  buttonIcon: undefined,
  buttonIconWidth: undefined,
  buttonOnClick: function buttonOnClick() {},
  imageWidth: 250
};

exports.default = (0, _WithDisplayName2.default)(EmptyState, 'EmptyState');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-color: rgba(0, 0, 0, 0.3);\n  bottom: 0;\n  box-sizing: border-box;\n  left: 0;\n  opacity: ', ';\n  overflow-x: hidden;\n  overflow-y: auto;\n  position: fixed;\n  right: 0;\n  top: ', ';\n  transition-delay: ', ';\n  transition-duration: 125ms, 50ms, 50ms;\n  transition-property: top, opacity, z-index;\n  transition-timing-function: linear;\n  z-index: ', ';\n'], ['\n  background-color: rgba(0, 0, 0, 0.3);\n  bottom: 0;\n  box-sizing: border-box;\n  left: 0;\n  opacity: ', ';\n  overflow-x: hidden;\n  overflow-y: auto;\n  position: fixed;\n  right: 0;\n  top: ', ';\n  transition-delay: ', ';\n  transition-duration: 125ms, 50ms, 50ms;\n  transition-property: top, opacity, z-index;\n  transition-timing-function: linear;\n  z-index: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  box-sizing: border-box;\n  ', ';\n'], ['\n  box-sizing: border-box;\n  ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n    width: 100%\n  '], ['\n    width: 100%\n  ']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  border-radius: 5px;\n  background-color: #fff;\n  margin: 32px 0;\n  position: relative;\n  width: auto;\n  ', ';\n  ', ';\n'], ['\n  border-radius: 5px;\n  background-color: #fff;\n  margin: 32px 0;\n  position: relative;\n  width: auto;\n  ', ';\n  ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n    width: 500px;\n  '], ['\n    width: 500px;\n  ']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n    height: 100vh;\n    margin: 0px 0;\n  '], ['\n    height: 100vh;\n    margin: 0px 0;\n  ']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  border-bottom: 1px solid #e0e0e0;\n  height: 48px;\n  background-color: #0070cd;\n  border-radius: 5px 5px 0px 0px;\n  padding: ', ';\n  ', ';\n'], ['\n  border-bottom: 1px solid #e0e0e0;\n  height: 48px;\n  background-color: #0070cd;\n  border-radius: 5px 5px 0px 0px;\n  padding: ', ';\n  ', ';\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n    border-radius: 0px;\n  '], ['\n    border-radius: 0px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

require('./Modal.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ModalBackground = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject, function (props) {
  return props.isModalShowed ? '1' : '0';
}, function (props) {
  return props.isModalShowed ? '0' : '50px';
}, function (props) {
  return props.isModalShowed ? '0s, 75ms, 75ms' : '0s, 0s, 0s';
}, function (props) {
  return props.isModalShowed ? '100' : '-1';
});

var ModalScroll = _styledComponents2.default.div(_templateObject2, function (props) {
  return props.theme.media.phone(_templateObject3);
});

var ModalContainer = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject4, function (props) {
  return props.theme.media.desktop(_templateObject5);
}, function (props) {
  return props.theme.media.phone(_templateObject6);
});

var ModalHeader = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject7, function (props) {
  return props.isArabic ? '0 0 0 16px' : '0 16px 0 0';
}, function (props) {
  return props.theme.media.phone(_templateObject8);
});

var FIRST_TAB = 0;
var ESCAPE = 27;

var Modal = function (_Component) {
  _inherits(Modal, _Component);

  function Modal(props) {
    _classCallCheck(this, Modal);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onKeyDown = function (e) {
      var key = e.keyCode;
      if (key === ESCAPE) {
        _this.hideModal();
      }
    };

    _this.getTabs = function () {
      var tabs = _this.props.tabs.map(function (tab, index) {
        var tabNode = _react2.default.createElement(
          'div',
          {
            className: 'tab-container',
            key: tab.tabName,
            ref: function ref(tabRef) {
              _this.tabs.set(index, tabRef);
            },
            onClick: function onClick(event) {
              return _this.changeTab(index, event);
            },
            tabIndex: '0',
            onKeyDown: function onKeyDown() {}
          },
          _react2.default.createElement(
            _Text2.default,
            { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'tab' },
            tab.tabName
          )
        );
        return tabNode;
      });
      return tabs;
    };

    _this.getModalTitle = function (tab) {
      return _react2.default.createElement(
        'div',
        { className: 'tab-container--static' },
        _react2.default.createElement(
          _Text2.default,
          { tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'tab' },
          tab.tabName
        )
      );
    };

    _this.getPages = function () {
      var pages = _this.props.tabs.map(function (tab, index) {
        return _react2.default.createElement(
          'div',
          {
            key: tab.tabName,
            className: 'modal-content',
            ref: function ref(page) {
              _this.pages.set(index, page);
            }
          },
          tab.tabPage
        );
      });
      return pages;
    };

    _this.changeTab = function (index) {
      var selectedTab = _this.tabs.get(_this.state.selectedTabIndex);
      var selectedPage = _this.pages.get(_this.state.selectedTabIndex);
      var newTab = _this.tabs.get(index);
      var newPage = _this.pages.get(index);

      _this.setState({
        selectedTabIndex: index
      }, function () {
        if (selectedTab) {
          selectedTab.classList.remove('tab--active');
          selectedPage.classList.remove('modal-content--show');
        }

        if (_this.state.shouldSwitchTabs) {
          newTab.classList.add('tab--active');
        }
        newPage.classList.add('modal-content--show');
      });
    };

    _this.showModal = function () {
      _this.setState({
        isModalShowed: true
      }, function () {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';

        // Auto focus on the container div
        // if (this.modalContainer) {
        //   this.modalContainer.focus();
        // }

        if (_this.props.onShow) {
          _this.props.onShow();
        }
      });
    };

    _this.hideModal = function () {
      _this.setState({
        isModalShowed: false
      }, function () {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';

        if (_this.props.onHide) {
          _this.props.onHide();
        }
      });
    };

    _this.modalContainer = _react2.default.createRef();

    _this.tabs = new Map();
    _this.pages = new Map();
    _this.iconsStore = new _IconsStore2.default(_webIcons2.default);

    _this.state = {
      selectedTabIndex: 0,
      shouldSwitchTabs: _this.props.tabs.length > 1,
      isModalShowed: _this.props.show
    };
    return _this;
  }

  Modal.prototype.componentDidMount = function componentDidMount() {
    this.changeTab(FIRST_TAB);

    if (this.props.show === true) {
      this.showModal();
    } else if (this.props.show === false) {
      this.hideModal();
    }
  };

  Modal.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.state.isModalShowed) {
      if (nextProps.show === true) {
        this.showModal();
      } else if (nextProps.show === false) {
        this.hideModal();
      }
    }
  };

  Modal.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    if (this.props.tabs !== prevProps.tabs) {
      this.changeTab(FIRST_TAB);
    }
  };

  Modal.prototype.render = function render() {
    var _props = this.props,
        tabs = _props.tabs,
        className = _props.className,
        isArabic = _props.isArabic;
    var isModalShowed = this.state.isModalShowed;

    return _react2.default.createElement(
      ModalBackground,
      {
        justifyContent: 'center',
        width: 1,
        isModalShowed: isModalShowed,
        tabIndex: '0',
        onKeyDown: this.onKeyDown,
        ref: this.modalContainer
      },
      _react2.default.createElement(
        ModalScroll,
        null,
        _react2.default.createElement(
          ModalContainer,
          { flexDirection: 'column', className: className },
          _react2.default.createElement(
            ModalHeader,
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              isArabic: isArabic
            },
            _react2.default.createElement(
              'div',
              { className: 'modal-tabs' },
              tabs.length !== 1 ? this.getTabs() : this.getModalTitle(tabs[0])
            ),
            _react2.default.createElement(
              'button',
              { className: 'modal-close_btn', onClick: this.hideModal },
              _react2.default.createElement(_Icon2.default, {
                className: 'close-icon',
                icon: this.iconsStore.getIcon('close'),
                width: 13,
                color: '#fff'
              })
            )
          ),
          _react2.default.createElement(
            'div',
            { className: 'modal-content-container' },
            this.getPages()
          )
        )
      )
    );
  };

  return Modal;
}(_react.Component);

Modal.propTypes = process.env.NODE_ENV !== "production" ? {
  tabs: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    tabName: _propTypes2.default.string,
    tabPage: _propTypes2.default.node
  })).isRequired,
  show: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  onHide: _propTypes2.default.func,
  onShow: _propTypes2.default.func,
  isArabic: _propTypes2.default.bool
} : {};

Modal.defaultProps = {
  show: false,
  className: undefined,
  onHide: function onHide() {},
  onShow: function onShow() {},
  isArabic: false
};

exports.default = (0, _WithDisplayName2.default)(Modal, 'Modal');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _ShimmerEffect = require('../shimmerEffect/ShimmerEffect');

var _ShimmerEffect2 = _interopRequireDefault(_ShimmerEffect);

var _NoAnimationContainer = require('../shimmerEffect/NoAnimationContainer');

var _Spinner = require('../spinner/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _IconsStore = require('./IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Button = require('./Button.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
var NewButton = function NewButton(_ref) {
  var btnText = _ref.btnText,
      _onClick = _ref.onClick,
      iconName = _ref.iconName,
      iconColor = _ref.iconColor,
      iconSize = _ref.iconSize,
      extendButtonStyle = _ref.extendButtonStyle,
      isLoading = _ref.isLoading,
      isSubmitting = _ref.isSubmitting,
      disabled = _ref.disabled;
  return _react2.default.createElement(
    'div',
    null,
    !isLoading ? _react2.default.createElement(
      _Button.ButtonContainer,
      {
        extendButtonStyle: extendButtonStyle,
        onClick: function onClick() {
          if (_onClick && !isSubmitting && !disabled) _onClick();
        },
        disabled: disabled,
        role: 'button'
      },
      isSubmitting ? _react2.default.createElement(
        'div',
        {
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }
        },
        _react2.default.createElement(_Spinner2.default, { radius: 20, color: iconColor })
      ) : _react2.default.createElement(
        'div',
        { style: { display: 'flex' } },
        iconName && _react2.default.createElement(
          'div',
          { style: { margin: '0px 5px', display: 'flex' } },
          _react2.default.createElement(_Icon2.default, {
            className: 'icon',
            icon: _IconsStore2.default.getIcon(iconName),
            width: iconSize || 12,
            color: disabled ? '#484848' : iconColor
          })
        )
      ),
      btnText && _react2.default.createElement(
        _Button.ButtonText,
        null,
        btnText
      )
    ) : _react2.default.createElement(
      _ShimmerEffect2.default,
      { flexDirection: 'column' },
      _react2.default.createElement(
        _NoAnimationContainer.NoAnimationFlex,
        null,
        _react2.default.createElement(_Button.LoadingButton, { height: 36, width: 160 })
      )
    )
  );
};

NewButton.propTypes = process.env.NODE_ENV !== "production" ? {
  btnText: _propTypes2.default.string,
  iconName: _propTypes2.default.string,
  onClick: _propTypes2.default.func,
  iconColor: _propTypes2.default.string,
  isLoading: _propTypes2.default.bool,
  isSubmitting: _propTypes2.default.bool,
  disabled: _propTypes2.default.bool
} : {};

exports.default = NewButton;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
exports.COLORS_VALUES = exports.COLORS = undefined;

var _COLORS_VALUES;

var _color = require('color');

var _color2 = _interopRequireDefault(_color);

var _colorString = require('color-string');

var _colorString2 = _interopRequireDefault(_colorString);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var baseColors = {
  primaryBlue: '#0070cd',
  primaryRed: '#ef0f0f',
  blueShadeOne: '#3e8ddd',
  blueShadeTwo: '#005cb8',
  primaryGrey: '#58595b',
  greyShadeOne: '#808184',
  greyShadeTwo: '#e0e0e0',
  greyShadeThree: '#f5f5f5',
  primaryGreen: '#7cbb69',
  primaryYellow: '#ffd350',
  white: '#fff',
  transparent: 'transparent',
  facebook: '#4e7ebe',
  google: '#ea4335',
  qitaf: '#712C81'
};

var COLORS = {
  PRIMARY_BLUE: 'primaryBlue',
  PRIMARY_RED: 'primaryRed',
  DANGER: 'danger',
  PAGE_BACKGROUND: 'pageBackground',
  SUCCESS: 'success',
  WARNING: 'warning',
  LINK: 'link',
  DISABLED: 'disabled',
  TEXT: 'text',
  HELP_TEXT: 'helpText',
  BORDER: 'border',
  HEADER: 'header',
  WHITE: 'white',
  SEPARATOR: 'separator',
  SHIMMER_BACKGROUND: 'shimmerBackground',
  SHIMMER_EFFECT: 'shimmerEffect',
  TRANSPARENT: 'transparent',
  BUTTON_BORDER: 'buttonBorder',
  HOVER: 'hover',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  ILLUSTRATION_BACKGROUND: 'illustrationBackground',
  DISPLAY_NAME_BACKGROUND: 'displayNameBackground',
  QITAF: 'qitaf'
};

var COLORS_VALUES = (_COLORS_VALUES = {}, _COLORS_VALUES[COLORS.PRIMARY_BLUE] = baseColors.primaryBlue, _COLORS_VALUES[COLORS.PRIMARY_RED] = baseColors.primaryRed, _COLORS_VALUES[COLORS.DANGER] = baseColors.primaryRed, _COLORS_VALUES[COLORS.PAGE_BACKGROUND] = baseColors.greyShadeThree, _COLORS_VALUES[COLORS.SUCCESS] = baseColors.primaryGreen, _COLORS_VALUES[COLORS.WARNING] = baseColors.primaryYellow, _COLORS_VALUES[COLORS.LINK] = baseColors.primaryBlue, _COLORS_VALUES[COLORS.BUTTON_BORDER] = baseColors.greyShadeTwo, _COLORS_VALUES[COLORS.DISABLED] = baseColors.greyShadeTwo, _COLORS_VALUES[COLORS.TEXT] = baseColors.primaryGrey, _COLORS_VALUES[COLORS.HELP_TEXT] = baseColors.greyShadeOne, _COLORS_VALUES[COLORS.BORDER] = baseColors.greyShadeTwo, _COLORS_VALUES[COLORS.HEADER] = baseColors.primaryBlue, _COLORS_VALUES[COLORS.WHITE] = baseColors.white, _COLORS_VALUES[COLORS.SEPARATOR] = baseColors.greyShadeTwo, _COLORS_VALUES[COLORS.SHIMMER_BACKGROUND] = baseColors.greyShadeThree, _COLORS_VALUES[COLORS.SHIMMER_EFFECT] = baseColors.greyShadeTwo, _COLORS_VALUES[COLORS.HOVER] = baseColors.greyShadeThree, _COLORS_VALUES[COLORS.FACEBOOK] = baseColors.facebook, _COLORS_VALUES[COLORS.GOOGLE] = baseColors.google, _COLORS_VALUES[COLORS.ILLUSTRATION_BACKGROUND] = _colorString2.default.to.rgb((0, _color2.default)(baseColors.blueShadeOne).alpha(0.2).rgb().array()), _COLORS_VALUES[COLORS.DISPLAY_NAME_BACKGROUND] = _colorString2.default.to.rgb((0, _color2.default)(baseColors.primaryBlue).alpha(0.1).rgb().array()), _COLORS_VALUES[COLORS.QITAF] = baseColors.qitaf, _COLORS_VALUES);

exports.COLORS = COLORS;
exports.COLORS_VALUES = COLORS_VALUES;
exports.default = COLORS;
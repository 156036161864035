"use strict";

exports.__esModule = true;
var withDisplayName = function withDisplayName(component, displayName) {
  var componentWithDisplayName = component;
  componentWithDisplayName.displayName = displayName;
  return component;
};

exports.default = withDisplayName;
module.exports = exports["default"];
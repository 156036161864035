'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  position: absolute;\n  cursor: pointer;\n  width: 40px;\n  height: 40px;\n  z-index: 1;\n  background-color: rgba(255, 255, 255, 0.8);\n  box-shadow: 2px 10px 10px 2px rgba(255, 255, 255, 0.6);\n'], ['\n  ', ';\n  ', ';\n  position: absolute;\n  cursor: pointer;\n  width: 40px;\n  height: 40px;\n  z-index: 1;\n  background-color: rgba(255, 255, 255, 0.8);\n  box-shadow: 2px 10px 10px 2px rgba(255, 255, 255, 0.6);\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _gridStyled = require('../grid-styled');

var _Colors = require('../base/Colors');

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _ShimmerEffect = require('../shimmerEffect/ShimmerEffect');

var _ShimmerEffect2 = _interopRequireDefault(_ShimmerEffect);

var _NoAnimationContainer = require('../shimmerEffect/NoAnimationContainer');

var _Rect = require('../shimmerEffect/Rect');

var _Rect2 = _interopRequireDefault(_Rect);

var _Typography = require('../base/Typography');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./Tabs.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FIRST_TAB = 0;
var ARROW = { LEFT: 'left', RIGHT: 'right' };

var ArrowContainer = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject, _styledSystem.left, _styledSystem.right);

var Tabs = function (_Component) {
  _inherits(Tabs, _Component);

  function Tabs() {
    var _temp, _this, _ret;

    _classCallCheck(this, Tabs);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      selectedTabIndex: 0,
      shouldSwitchTabs: _this.props.tabs.length > 1,
      scrollable: false,
      scrollReachLeft: false,
      scrollReachRight: false
    }, _this.onArrowClick = function (direction) {
      var scrollableWidth = _this.tabsNav.scrollWidth - _this.tabsNav.clientWidth;
      var distanceToScroll = _this.tabsNav.scrollWidth / _this.props.tabs.length;

      if (_this.tabsNav.scrollLeft <= scrollableWidth && _this.tabsNav.scrollLeft >= 0) {
        _this.tabsNav.scrollBy({
          left: direction === 'left' ? -distanceToScroll : distanceToScroll,
          behavior: 'smooth'
        });
      }
    }, _this.getTabs = function () {
      var tabs = _this.props.tabs;
      var _this$state = _this.state,
          selectedTabIndex = _this$state.selectedTabIndex,
          scrollable = _this$state.scrollable,
          scrollReachLeft = _this$state.scrollReachLeft,
          scrollReachRight = _this$state.scrollReachRight;


      return _react2.default.createElement(
        _react.Fragment,
        null,
        scrollable && _react2.default.createElement(
          _react.Fragment,
          null,
          !scrollReachLeft && _react2.default.createElement(
            ArrowContainer,
            {
              justifyContent: 'center',
              alignItems: 'center',
              left: '0',
              onClick: function onClick() {
                return _this.onArrowClick(ARROW.LEFT);
              }
            },
            _react2.default.createElement(_Icon2.default, {
              icon: _this.iconsStore.getIcon('arrow_left'),
              color: _Colors.COLORS.PRIMARY_BLUE,
              width: 9
            })
          ),
          !scrollReachRight && _react2.default.createElement(
            ArrowContainer,
            {
              justifyContent: 'center',
              alignItems: 'center',
              right: '0',
              onClick: function onClick() {
                return _this.onArrowClick(ARROW.RIGHT);
              }
            },
            _react2.default.createElement(_Icon2.default, {
              icon: _this.iconsStore.getIcon('arrow_right'),
              color: _Colors.COLORS.PRIMARY_BLUE,
              width: 9
            })
          )
        ),
        tabs.map(function (tab, index) {
          return _react2.default.createElement(
            'div',
            {
              className: (0, _classnames2.default)('tab', {
                'tab--active': selectedTabIndex === index
              }),
              key: 'tab-' + tab.value,
              ref: function ref(tabRef) {
                _this.tabs.set(index, tabRef);
              },
              onClick: function onClick() {
                return _this.changeTab(index);
              },
              tabIndex: index,
              onKeyDown: function onKeyDown() {}
            },
            tab.icon && _react2.default.createElement(
              'div',
              {
                className: (0, _classnames2.default)('tab-icon--container', {
                  'tab-icon--container_active': selectedTabIndex === index
                })
              },
              _react2.default.createElement(_Icon2.default, { icon: tab.icon, color: _Colors.COLORS.PRIMARY_BLUE, width: tab.iconWidth })
            ),
            _react2.default.createElement(
              _Text2.default,
              { cursor: 'pointer', tag: 'h3', type: _Typography.FONT_TYPES.SUBHEADING, className: 'text' },
              tab.name
            )
          );
        })
      );
    }, _this.getPages = function () {
      var _this$props = _this.props,
          tabs = _this$props.tabs,
          YScrollContent = _this$props.YScrollContent;
      var selectedTabIndex = _this.state.selectedTabIndex;

      return tabs.map(function (tab, index) {
        return _react2.default.createElement(
          'div',
          {
            key: 'content-' + tab.value,
            className: (0, _classnames2.default)('tab-content', {
              'tab-content--show': selectedTabIndex === index,
              'tab-content--empty': !tab.component,
              scrollable: YScrollContent
            }),
            ref: function ref(page) {
              _this.pages.set(index, page);
            }
          },
          tab.component
        );
      });
    }, _this.handleTabsScroll = function () {
      var scrollableWidth = _this.tabsNav.scrollWidth - _this.tabsNav.clientWidth;
      var scrollReachLeft = void 0;
      var scrollReachRight = void 0;

      if (_this.tabsNav.scrollLeft === 0) {
        if (_this.tabsNav.scrollLeft === scrollableWidth) {
          scrollReachLeft = true;
          scrollReachRight = true;
        } else {
          scrollReachLeft = true;
          scrollReachRight = false;
        }
      } else if (_this.tabsNav.scrollLeft !== 0) {
        if (_this.tabsNav.scrollLeft === scrollableWidth) {
          scrollReachLeft = false;
          scrollReachRight = true;
        } else {
          scrollReachLeft = false;
          scrollReachRight = false;
        }
      }

      _this.setState({ scrollReachLeft: scrollReachLeft, scrollReachRight: scrollReachRight });
    }, _this.changeNavScrollableStatus = function () {
      if (_this.tabsNav.scrollWidth > _this.tabsNav.clientWidth) {
        _this.setState({ scrollable: true, scrollReachLeft: true });
      }
    }, _this.tabs = new Map(), _this.pages = new Map(), _this.iconsStore = new _IconsStore2.default(_webIcons2.default), _this.changeTab = function (index) {
      if (_this.state.selectedTabIndex !== index) {
        var tabs = _this.props.tabs;
        var _this$state2 = _this.state,
            selectedTabIndex = _this$state2.selectedTabIndex,
            shouldSwitchTabs = _this$state2.shouldSwitchTabs,
            scrollable = _this$state2.scrollable;


        var matchedTab = _this.tabs.get(selectedTabIndex);
        var matchedPage = _this.pages.get(selectedTabIndex);
        var newTab = _this.tabs.get(index);
        var newPage = _this.pages.get(index);

        _this.setState({
          selectedTabIndex: index
        }, function () {
          if (matchedTab) {
            matchedTab.classList.remove('tab--active');
            matchedPage.classList.remove('tab-content--show');
            _this.props.onChange(tabs[index].value);
          }

          if (newPage) {
            if (shouldSwitchTabs) {
              newTab.classList.add('tab--active');

              // In case of the tabs are scrollable horizontally when you select new tab
              // the tabs will scroll to make the new tab visible to the user on the screen.
              if (scrollable) {
                var scrollableWidth = _this.tabsNav.scrollWidth - _this.tabsNav.clientWidth;
                _this.tabsNav.scrollTo({
                  left: (scrollableWidth + newTab.clientWidth) / tabs.length * index,
                  behavior: 'smooth'
                });
              }
            }
            newPage.classList.add('tab-content--show');
          }
        });
      }
    }, _this.renderLazyLoadingTabs = function () {
      var tabsNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2;

      var lazyLoading = function lazyLoading(index) {
        return _react2.default.createElement(
          _ShimmerEffect2.default,
          { key: index },
          _react2.default.createElement(
            _NoAnimationContainer.NoAnimationBox,
            { className: 'tab-loading--container', key: index },
            _react2.default.createElement(_Rect2.default, { width: 125, className: 'tab-loading--text' })
          )
        );
      };

      var loadingTabs = [];
      for (var counter = 0; counter < tabsNumber; counter += 1) {
        loadingTabs.push(lazyLoading(counter));
      }
      return loadingTabs;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Tabs.prototype.componentDidMount = function componentDidMount() {
    var _props = this.props,
        select = _props.select,
        isLoading = _props.isLoading;


    if (!select) {
      this.changeTab(FIRST_TAB);
    } else {
      this.changeTab(select);
    }

    if (!isLoading) {
      this.changeNavScrollableStatus();
    }
  };

  Tabs.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    if (this.props.select !== prevProps.select) {
      this.changeTab(this.props.select);
    }
  };

  Tabs.prototype.render = function render() {
    var _this2 = this;

    var _props2 = this.props,
        tabs = _props2.tabs,
        className = _props2.className,
        isLoading = _props2.isLoading;


    return _react2.default.createElement(
      'div',
      { className: (0, _classnames2.default)('tabs-container', className) },
      isLoading ? _react2.default.createElement(
        'div',
        { className: 'nav-container' },
        this.renderLazyLoadingTabs(tabs.length)
      ) : _react2.default.createElement(
        _react.Fragment,
        null,
        tabs.length !== 0 && _react2.default.createElement(
          'div',
          {
            className: 'nav-container',
            ref: function ref(nav) {
              _this2.tabsNav = nav;
            },
            onScroll: this.handleTabsScroll
          },
          this.getTabs()
        ),
        _react2.default.createElement(
          'div',
          { className: 'tab-content-container' },
          this.getPages()
        )
      )
    );
  };

  return Tabs;
}(_react.Component);

// TODO: add a new prop called renderTopRightComponent to support `Modal` integration
// TODO: add a new prop called renderTabName to support showing
// non-active tab to support `Modal` integration


Tabs.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  tabs: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    name: _propTypes2.default.string,
    value: _propTypes2.default.any,
    icon: _propTypes2.default.object,
    iconWidth: _propTypes2.default.number,
    component: _propTypes2.default.any
  })).isRequired,
  select: _propTypes2.default.number,
  isLoading: _propTypes2.default.bool,
  onChange: _propTypes2.default.func,
  YScrollContent: _propTypes2.default.bool
} : {};

Tabs.defaultProps = {
  className: undefined,
  isLoading: false,
  select: undefined,
  onChange: function onChange() {}
};

exports.default = (0, _WithDisplayName2.default)(Tabs, 'Tabs');
module.exports = exports['default'];
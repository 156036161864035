'use strict';

exports.__esModule = true;
exports.ItemsListContainer = exports.ItemContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  cursor: pointer;\n  padding: 8px 16px;\n'], ['\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  cursor: pointer;\n  padding: 8px 16px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: column;\n  .collapsed {\n    max-height: 0;\n    transition: max-height 1s ease-out;\n    overflow: hidden;\n  }\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: column;\n  .collapsed {\n    max-height: 0;\n    transition: max-height 1s ease-out;\n    overflow: hidden;\n  }\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var ItemContainer = exports.ItemContainer = _styledComponents2.default.div(_templateObject);

var ItemsListContainer = exports.ItemsListContainer = _styledComponents2.default.div(_templateObject2, function (props) {
  return props.hasSubList && '\n    &:hover #options-list--container {\n      max-height: 500px;\n      transition: max-height 1s ease-in;\n    }\n  ';
});
'use strict';

exports.__esModule = true;
exports.DropDownContainer = exports.ButtonContainer = exports.Container = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  width: 100%;\n  ', ';\n  ', ';\n'], ['\n  position: relative;\n  width: 100%;\n  ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n    position: unset;\n  '], ['\n    position: unset;\n  ']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  background: ', ';\n  color: ', ';\n  cursor: pointer;\n  ', ';\n'], ['\n  background: ', ';\n  color: ', ';\n  cursor: pointer;\n  ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  right: ', ';\n  left: ', ';\n  z-index: 1000;\n  margin-top: 8px;\n  ', ';\n  ', ';\n'], ['\n  position: absolute;\n  right: ', ';\n  left: ', ';\n  z-index: 1000;\n  margin-top: 8px;\n  ', ';\n  ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n    margin-top: 0px;\n    top: 0;\n    width: 100%\n    height: 100vh;\n  '], ['\n    margin-top: 0px;\n    top: 0;\n    width: 100%\n    height: 100vh;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Container = exports.Container = _styledComponents2.default.div(_templateObject, function (props) {
  return props.theme.media.tablet(_templateObject2);
}, function (props) {
  return props.theme.media.phone(_templateObject2);
});

var ButtonContainer = exports.ButtonContainer = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.isOpened ? '#E6F1FF' : '#F1F4F6';
}, function (props) {
  return props.isOpened ? '#0070CD' : '#484848';
}, function (props) {
  return props.extendButtonContainer ? props.extendButtonContainer : '';
});
var DropDownContainer = exports.DropDownContainer = _styledComponents2.default.div(_templateObject4, function (props) {
  return props.language === 'en' ? '0' : 'unset';
}, function (props) {
  return props.language === 'ar' ? '0' : 'unset';
}, function (props) {
  return props.theme.media.tablet(_templateObject5);
}, function (props) {
  return props.theme.media.phone(_templateObject5);
});
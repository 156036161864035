'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Typography = require('../base/Typography');

var _SnackBar = require('./SnackBar.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var iconsStore = new _IconsStore2.default(_webIcons2.default); /* eslint-disable react/require-default-props */

var SnackBar = function SnackBar(_ref) {
  var snackBarSettings = _ref.snackBarSettings,
      onHide = _ref.onHide;
  var message = snackBarSettings.message,
      show = snackBarSettings.show,
      color = snackBarSettings.color,
      backgroundColor = snackBarSettings.backgroundColor,
      icon = snackBarSettings.icon;

  var _useState = (0, _react.useState)(),
      notificationMessage = _useState[0],
      setMessage = _useState[1];

  var _useState2 = (0, _react.useState)(),
      textColor = _useState2[0],
      setColor = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      barBackgroundColor = _useState3[0],
      setBackgroundColor = _useState3[1];

  var _useState4 = (0, _react.useState)(''),
      iconName = _useState4[0],
      setIconName = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      showBar = _useState5[0],
      toggleBar = _useState5[1];

  (0, _react.useEffect)(function () {
    if (show) {
      setMessage(message);
      setColor(color);
      setBackgroundColor(backgroundColor);
      setIconName(icon);
      toggleBar(true);
      setTimeout(function () {
        toggleBar(false);
        onHide();
      }, 4000);
    }
  }, [message, show, color, icon, backgroundColor]);

  return _react2.default.createElement(
    _SnackBar.SnackBarWindow,
    null,
    _react2.default.createElement(
      _SnackBar.SnackBarContainer,
      { barBackgroundColor: barBackgroundColor, show: showBar },
      iconName && _react2.default.createElement(_Icon2.default, { icon: iconsStore.getIcon(iconName), width: 14, color: '#fff' }),
      _react2.default.createElement(
        _SnackBar.SnackBarMessage,
        {
          textColor: textColor,
          tag: 'h3',
          type: _Typography.FONT_TYPES.SUBHEADING,
          fontWeight: _Typography.FONT_WEIGHTS.NORMAL,
          className: 'message-text'
        },
        notificationMessage
      )
    )
  );
};

SnackBar.propTypes = process.env.NODE_ENV !== "production" ? {
  // eslint-disable-next-line react/forbid-prop-types
  snackBarSettings: _propTypes2.default.object,
  onHide: _propTypes2.default.func
} : {};

exports.default = SnackBar;
module.exports = exports['default'];
import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API } from '@vezeeta/web-utils';
import {
  PAYMENT,
  addAccountCardAndGetMerchantPageInfoFail,
  addAccountCardAndGetMerchantPageInfoSuccess,
  cancelReservationFail,
  cancelReservationSuccess,
  getValidTransactionByTransactionKeyFail,
  getValidTransactionByTransactionKeySuccess,
  updateAndChargeAccountCardFail,
  updateAndChargeAccountCardSuccess,
} from '../actions/Payment';

/* function* fetchSpecialties(action) {
  const specialtiesAPI = new API();
  const header = [
    {
      key: 'Language',
      value: `en-${countriesISOCodes[action.countryId]}`,
    },
  ];

  const response = yield specialtiesAPI.get(`${URLS.getSpecialties}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchSpecialtiesSuccess({ specialties: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchSpecialtiesFail());
  }
}

function* fetchSpecialties(action) {
  const specialtiesAPI = new API();
  const header = [
    {
      key: 'Language',
      value: `en-${countriesISOCodes[action.countryId]}`,
    },
  ];

  const response = yield specialtiesAPI.get(`${URLS.getSpecialties}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchSpecialtiesSuccess({ specialties: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchSpecialtiesFail());
  }
}

function* fetchSpecialties(action) {
  const specialtiesAPI = new API();
  const header = [
    {
      key: 'Language',
      value: `en-${countriesISOCodes[action.countryId]}`,
    },
  ];

  const response = yield specialtiesAPI.get(`${URLS.getSpecialties}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchSpecialtiesSuccess({ specialties: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchSpecialtiesFail());
  }
}

function* fetchSpecialties(action) {
  const specialtiesAPI = new API();
  const header = [
    {
      key: 'Language',
      value: `en-${countriesISOCodes[action.countryId]}`,
    },
  ];

  const response = yield specialtiesAPI.get(`${URLS.getSpecialties}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(fetchSpecialtiesSuccess({ specialties: response.data, countryId: action.countryId }));
  } else {
    yield put(fetchSpecialtiesFail());
  }
} */

function* paymentSaga() {
  /* yield takeLatest(PAYMENT.ADD_ACCOUNT_CARD_INFO.SUBMIT, fetchSpecialties);
  yield takeLatest(PAYMENT.CANCEL_RESERVATION.FETCH, fetchSpecialties);
  yield takeLatest(PAYMENT.GET_VALID_TRANSACTION.FETCH, fetchSpecialties);
  yield takeLatest(PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.SUBMIT, fetchSpecialties); */
}

export default paymentSaga;

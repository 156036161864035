'use strict';

exports.__esModule = true;
exports.ButtonEdit = exports.AttatchContainer = exports.FileViewItem = exports.CancelIcon = exports.FileViewOverlay = exports.Text = exports.ImagePreview = exports.DisableOverLay = exports.ValueTagContainerImage = exports.SearchInput = exports.FieldContainer = undefined;

var _templateObject = _taggedTemplateLiteralLoose(['\n  border: 1px solid #e3e6ea;\n  width: 100%;\n  height: 56px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 6px 16px;\n  // margin: 4px 0px;\n  cursor: pointer;\n  border-radius: 8px;\n  ', ';\n'], ['\n  border: 1px solid #e3e6ea;\n  width: 100%;\n  height: 56px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 6px 16px;\n  // margin: 4px 0px;\n  cursor: pointer;\n  border-radius: 8px;\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  border: unset;\n  font-size: 16px;\n  line-height: 24px;\n  margin: 0px 8px;\n  &:focus {\n    outline: none;\n  }\n  ', ';\n'], ['\n  width: 100%;\n  border: unset;\n  font-size: 16px;\n  line-height: 24px;\n  margin: 0px 8px;\n  &:focus {\n    outline: none;\n  }\n  ', ';\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: 10px 10px;\n  ', ';\n'], ['\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: 10px 10px;\n  ', ';\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  ', ';\n  width: 100%;\n  min-height: 56px;\n  border: 1px solid #e3e6ea;\n  border-radius: 8px;\n  background-color: #f1f4f69c;\n  position: absolute;\n  display: ', ';\n'], ['\n  ', ';\n  width: 100%;\n  min-height: 56px;\n  border: 1px solid #e3e6ea;\n  border-radius: 8px;\n  background-color: #f1f4f69c;\n  position: absolute;\n  display: ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  object-fit: cover;\n  width: 30px;\n  height: 30px;\n'], ['\n  object-fit: cover;\n  width: 30px;\n  height: 30px;\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  display: inline-flex;\n  align-items: center;\n  padding: 0 5px;\n'], ['\n  display: inline-flex;\n  align-items: center;\n  padding: 0 5px;\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  justify-content: center;\n  align-items: center;\n  z-index: 5;\n  background: rgba(11, 11, 11, 0.8);\n  display: ', ';\n'], ['\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  justify-content: center;\n  align-items: center;\n  z-index: 5;\n  background: rgba(11, 11, 11, 0.8);\n  display: ', ';\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  &:before {\n    content: \'X\';\n    font-size: 21px;\n    color: #fff;\n  }\n  cursor: pointer;\n'], ['\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  &:before {\n    content: \'X\';\n    font-size: 21px;\n    color: #fff;\n  }\n  cursor: pointer;\n']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n  width: 100%;\n  max-width: 900px;\n  max-height: 700px;\n'], ['\n  width: 100%;\n  max-width: 900px;\n  max-height: 700px;\n']),
    _templateObject10 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  align-items: unset;\n'], ['\n  display: flex;\n  align-items: unset;\n']),
    _templateObject11 = _taggedTemplateLiteralLoose(['\n  display: table-cell;\n  ', ';\n'], ['\n  display: table-cell;\n  ', ';\n']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var FieldContainer = exports.FieldContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.extendDropDownStyle ? props.extendDropDownStyle : '';
});

var SearchInput = exports.SearchInput = _styledComponents2.default.input(_templateObject2, function (props) {
  return props.extendFieldText ? props.extendFieldText : '';
});

var ValueTagContainerImage = exports.ValueTagContainerImage = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.preview && 'justify-content: start;';
});

var DisableOverLay = exports.DisableOverLay = _styledComponents2.default.div(_templateObject4, function (props) {
  return props.extendDropDownStyle ? props.extendDropDownStyle : '';
}, function (props) {
  return !props.isDisabled ? 'none' : 'block';
});

var ImagePreview = exports.ImagePreview = _styledComponents2.default.img(_templateObject5);

var Text = exports.Text = _styledComponents2.default.p(_templateObject6);

var FileViewOverlay = exports.FileViewOverlay = _styledComponents2.default.div(_templateObject7, function (props) {
  return props.isFileViewOverlay ? 'flex' : 'none';
});

var CancelIcon = exports.CancelIcon = _styledComponents2.default.span(_templateObject8);

var FileViewItem = exports.FileViewItem = _styledComponents2.default.img(_templateObject9);

var AttatchContainer = exports.AttatchContainer = _styledComponents2.default.div(_templateObject10);

var ButtonEdit = exports.ButtonEdit = _styledComponents2.default.div(_templateObject11, function (props) {
  return props.language === 'ar' ? 'margin-right: auto' : 'margin-left: auto';
});
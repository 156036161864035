'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  height: 34px;\n  font-size: 13px;\n  background-color: transparent;\n  border: 0;\n  border-bottom: 1px solid #58595b;\n  color: #58595b;\n  font-weight: 500;\n  line-height: normal;\n  outline: 0;\n  width: 100%;\n  padding: ', ';\n  ', ';\n'], ['\n  height: 34px;\n  font-size: 13px;\n  background-color: transparent;\n  border: 0;\n  border-bottom: 1px solid #58595b;\n  color: #58595b;\n  font-weight: 500;\n  line-height: normal;\n  outline: 0;\n  width: 100%;\n  padding: ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  ', ';\n  ', ';\n  ', ';\n'], ['\n  ', ';\n  ', ';\n  ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _Icon = require('../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Colors = require('../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

var _Typography = require('../base/Typography');

require('./InputField.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var Input = _styledComponents2.default.input(_templateObject, function (props) {
  return (
    // eslint-disable-next-line no-nested-ternary
    props.reverse ? props.noIcon ? '8px 0px !important' : '8px 25px 8px 2px !important' : props.noIcon ? '8px 0px !important' : '8px 2px 8px 25px !important'
  );
}, function (props) {
  return props.extendInputFieldElement ? props.extendInputFieldElement : '';
});

var InputFieldContainer = _styledComponents2.default.div(_templateObject2, _styledSystem.width, _styledSystem.space, function (props) {
  return props.extendInputField ? props.extendInputField : '';
});

var InputField = function (_Component) {
  _inherits(InputField, _Component);

  function InputField(props) {
    _classCallCheck(this, InputField);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onFocus = function () {
      if (_this.props.center) {
        _this.input.removeAttribute('placeholder');
      }

      if (!_this.state.isDanger) {
        _this.setState({ iconColor: _Colors2.default.vezeetaBlue });
      }
    };

    _this.onBlur = function () {
      _this.validate();
      if (_this.props.onBlur) {
        _this.props.onBlur(_this.getInputValue());
      }
    };

    _this.onTyping = function (e) {
      var value = e.target.value;

      _this.setState({
        value: value,
        dangerLock: false
      }, function () {
        if (_this.props.onChange) {
          _this.props.onChange(value);
        }

        if (_this.state.value !== '') {
          _this.validate();
        }
      });
    };

    _this.getInputValue = function () {
      return _this.input.value;
    };

    _this.showErrorMessage = function (message, dangerLock) {
      _this.setState({
        errorMessage: message,
        isDanger: true,
        iconColor: _Colors2.default.vezeetaRed,
        dangerLock: dangerLock || false
      });
    };

    _this.hideErrorMessage = function (dangerUnlock) {
      if (!_this.state.dangerLock || dangerUnlock) {
        _this.setState({
          isDanger: false,
          iconColor: _this.input === document.activeElement ? _Colors2.default.vezeetaBlue : _Colors2.default.defaultGrey
        });
      }
    };

    _this.validate = function () {
      var shouldBreak = false;
      var result = true;

      if (!_this.props.disabled) {
        if (_this.state.value === '' || _this.state.value === undefined) {
          if (_this.props.isRequired === true) {
            if (_this.props.customErrorMassage && _this.props.customErrorMassage !== '') {
              _this.showErrorMessage(_this.props.customErrorMassage);
            } else {
              _this.showErrorMessage(_this.props.isArabic ? _this.props.placeholder + ' \u0645\u0637\u0644\u0648\u0628' : _this.props.placeholder + ' required');
            }
          } else {
            _this.hideErrorMessage();
          }
        } else if (_this.props.validationChecks) {
          _this.props.validationChecks.forEach(function (check) {
            if (shouldBreak) return;
            var regexResult = check.regex.test(_this.getInputValue());

            if (!regexResult) {
              shouldBreak = true;
              _this.showErrorMessage(_this.props.isArabic ? check.errorMessage.ar : check.errorMessage.en);
            } else {
              _this.hideErrorMessage();
            }
          });
        } else {
          _this.hideErrorMessage();
        }
      }

      return result;
    };

    _this.focus = function () {
      _this.input.focus();
    };

    _this.isValid = function () {
      if (_this.state.isDanger) {
        return false;
      }

      if (_this.props.isRequired) {
        if (_this.state.value === '' || _this.state.value === undefined) {
          return false;
        }
      }

      return true;
    };

    _this.clear = function () {
      _this.setState({
        value: ''
      });
    };

    _this.resetSelect = function () {
      _this.clear();
    };

    _this.state = {
      errorMessage: '',
      isDanger: false,
      iconColor: _Colors2.default.defaultGrey,
      value: _this.props.value || '',
      dangerLock: false
    };

    InputField.defaultProps = {
      className: '',
      value: ''
    };
    return _this;
  }

  /**
   * Update value if it was changed
   */


  InputField.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      if (nextProps.value === undefined || nextProps.value === null) {
        this.resetSelect();
      } else {
        this.setState({ value: nextProps.value });
        if (this.state.isDanger) {
          this.hideErrorMessage();
        }
      }
    }
  };

  /**
   * Changing the icon color depending on input state
   */


  /**
   * Validating the input value and change the icon color to default
   */


  /**
   * Update input's value while writing
   * @param {event} e
   */


  /**
   * Returns the current input value
   */


  /**
   * Change the error message and change the isDanger state to true
   * @param {string} message
   */


  /**
   * Change the idDanger state to false
   */


  /**
   * Get regex tests from validation object and run all tests
   * Shows error message if false
   * Update the data in the parent component
   */


  /**
   * Move focus to the input
   */


  /**
   * Return if the component isDanger
   */


  /**
   * Clears the inputs value
   */


  InputField.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        onChange = _props.onChange,
        onBlur = _props.onBlur,
        className = _props.className,
        reverse = _props.reverse,
        extendInputField = _props.extendInputField,
        extendInputFieldElement = _props.extendInputFieldElement,
        filteredProps = _objectWithoutProperties(_props, ['onChange', 'onBlur', 'className', 'reverse', 'extendInputField', 'extendInputFieldElement']);

    var dangerClass = void 0;
    var icon = void 0;
    var noIconClass = '';
    var centerClass = '';
    var helpers = void 0;
    var maxLength = void 0;
    var disabledClass = '';

    if (!this.state.isDanger) {
      dangerClass = '';
    } else {
      dangerClass = ' input-field--danger';
    }

    var noIcon = void 0;
    if (this.props.icon) {
      noIcon = false;
      icon = _react2.default.createElement(
        'div',
        { className: 'icon-container' },
        _react2.default.createElement(_Icon2.default, { icon: this.props.icon, width: this.props.iconWidth, color: this.state.iconColor })
      );
    } else {
      noIcon = true;
      icon = _react2.default.createElement('div', null);
      noIconClass = ' field-no-icon';
    }

    if (this.props.center) {
      centerClass = ' placeholder--center';
    }

    if (this.props.helperIcon) {
      helpers = _react2.default.createElement(
        'div',
        { className: 'helper-icon' },
        _react2.default.createElement(_Icon2.default, {
          icon: this.props.helperIcon,
          width: this.props.helperIconWidth,
          color: this.state.iconColor
        })
      );
    }

    if (this.props.helperImages && this.props.helperImages.length > 0) {
      helpers = _react2.default.createElement(
        'div',
        { className: 'helper-images' },
        this.props.helperImages.map(function (helper, index) {
          return _react2.default.createElement('img', { key: index, src: helper.image, height: helper.height, alt: helper.image }) // eslint-disable-line
          ;
        })
      );
    }

    if (this.props.maxLength) {
      maxLength = this.props.maxLength; // eslint-disable-line
    } else {
      maxLength = undefined;
    }

    if (this.props.disabled) {
      disabledClass = ' input-field--disabled';
    }

    if (this.props.type === 'hidden') {
      return _react2.default.createElement(Input, {
        type: this.props.type,
        value: this.state.value,
        id: this.props.inputId,
        autoComplete: 'off',
        name: this.props.name,
        ref: function ref(input) {
          _this2.input = input;
        },
        noIcon: noIcon,
        reverse: reverse
      });
    } else if (this.props.type === 'textarea') {
      return _react2.default.createElement(
        InputFieldContainer,
        _extends({
          className: 'input-field ' + className + dangerClass + disabledClass,
          lang: this.props.language
        }, filteredProps),
        _react2.default.createElement('textarea', {
          className: '' + noIconClass + centerClass,
          type: this.props.type,
          placeholder: '' + this.props.placeholder + (this.props.isRequired ? '*' : ''),
          onBlur: this.onBlur,
          onFocus: this.onFocus,
          maxLength: maxLength,
          ref: function ref(input) {
            _this2.input = input;
          },
          value: this.state.value,
          onChange: this.onTyping,
          id: this.props.inputId,
          autoComplete: this.props.autoComplete,
          name: this.props.name,
          disabled: this.props.disabled
        }),
        _react2.default.createElement(
          _Text2.default,
          { tag: 'span', type: _Typography.FONT_TYPES.CAPTION },
          this.state.errorMessage
        )
      );
    }
    return _react2.default.createElement(
      InputFieldContainer,
      _extends({
        className: 'input-field ' + className + dangerClass + disabledClass,
        lang: this.props.language
      }, filteredProps, {
        extendInputField: extendInputField
      }),
      icon,
      _react2.default.createElement(Input, {
        className: '' + noIconClass + centerClass,
        type: this.props.type,
        placeholder: '' + this.props.placeholder + (this.props.isRequired ? '*' : ''),
        onBlur: this.onBlur,
        onFocus: this.onFocus,
        maxLength: maxLength,
        innerRef: function innerRef(input) {
          _this2.input = input;
        },
        value: this.state.value,
        onChange: this.onTyping,
        id: this.props.inputId,
        autoComplete: 'off',
        name: this.props.name,
        disabled: this.props.disabled,
        noIcon: noIcon,
        reverse: reverse,
        extendInputFieldElement: extendInputFieldElement
      }),
      helpers,
      _react2.default.createElement(
        _Text2.default,
        { tag: 'span', type: _Typography.FONT_TYPES.CAPTION },
        this.state.errorMessage
      )
    );
  };

  return InputField;
}(_react.Component);

InputField.propTypes = process.env.NODE_ENV !== "production" ? {
  className: _propTypes2.default.string,
  type: _propTypes2.default.string,
  icon: _Icon.iconPropTypes,
  iconWidth: _propTypes2.default.number,
  center: _propTypes2.default.bool,
  placeholder: _propTypes2.default.string.isRequired,
  value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  validationChecks: _propTypes2.default.arrayOf(_propTypes2.default.object),
  language: _propTypes2.default.string,

  onBlur: _propTypes2.default.func,
  onChange: _propTypes2.default.func,
  helperImages: _propTypes2.default.arrayOf(_propTypes2.default.oneOfType([_propTypes2.default.string, _Icon.iconPropTypes])),
  helperIcon: _Icon.iconPropTypes,
  helperIconWidth: _propTypes2.default.number,
  maxLength: _propTypes2.default.number,
  formatOnTyping: _propTypes2.default.func,
  inputId: _propTypes2.default.string,
  autoComplete: _propTypes2.default.string,
  name: _propTypes2.default.string,
  disabled: _propTypes2.default.bool,
  isRequired: _propTypes2.default.bool,
  reverse: _propTypes2.default.bool,
  isArabic: _propTypes2.default.bool,
  customErrorMassage: _propTypes2.default.string
} : {};

InputField.defaultProps = {
  type: 'text',
  icon: undefined,
  iconWidth: 13,
  center: false,
  className: '',
  value: '',
  validationChecks: [],
  isRequired: true,
  language: 'en',
  onBlur: function onBlur() {},
  onChange: function onChange() {},
  helperImages: [],
  helperIcon: undefined,
  helperIconWidth: 13,
  maxLength: undefined,
  formatOnTyping: function formatOnTyping() {},
  inputId: undefined,
  autoComplete: undefined,
  name: undefined,
  disabled: false,
  reverse: false,
  isArabic: false,
  customErrorMassage: ''
};

exports.default = (0, _WithDisplayName2.default)(InputField, 'InputField');
module.exports = exports['default'];
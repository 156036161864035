'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _gridStyled = require('../grid-styled');

var _ErrorContainer = require('./ErrorContainer');

var _ErrorContainer2 = _interopRequireDefault(_ErrorContainer);

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Button = require('../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _Typography = require('../base/Typography');

var _Colors = require('../base/Colors');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Error = function Error(_ref) {
  var title = _ref.title,
      smallTitle = _ref.smallTitle,
      desc = _ref.desc,
      buttonText = _ref.buttonText,
      buttonIcon = _ref.buttonIcon,
      callbackAction = _ref.callbackAction,
      imageUrl = _ref.imageUrl;
  return _react2.default.createElement(
    _ErrorContainer2.default,
    { width: 1 },
    _react2.default.createElement(
      _gridStyled.Flex,
      {
        width: 1,
        bg: _Colors.COLORS.ILLUSTRATION_BACKGROUND,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around'
      },
      _react2.default.createElement(
        _gridStyled.Flex,
        { alignItems: 'center', justifyContent: 'center', width: 0.7, height: 0.7 },
        _react2.default.createElement(
          _gridStyled.Flex,
          { flexDirection: 'column', mr: 40 },
          _react2.default.createElement(
            _Text2.default,
            { tag: 'h1', type: _Typography.FONT_TYPES.MEGA_TITLE, mb: 8 },
            title
          ),
          _react2.default.createElement(
            _Text2.default,
            { tag: 'h3', type: _Typography.FONT_TYPES.SUPER_TITLE, mb: 2 },
            smallTitle
          ),
          _react2.default.createElement(
            _Text2.default,
            {
              tag: 'h5',
              type: _Typography.FONT_TYPES.TITLE,
              width: 350,
              fontWeight: _Typography.FONT_WEIGHTS.LIGHT,
              color: _Colors.COLORS.HELP_TEXT,
              mb: 8
            },
            desc
          ),
          _react2.default.createElement(
            _gridStyled.Flex,
            { alignItems: 'center', justifyContent: 'center' },
            _react2.default.createElement(
              _Button2.default,
              {
                color: _Colors.COLORS.PRIMARY_BLUE,
                onClick: function onClick() {
                  window.location.reload();
                },
                icon: new _IconsStore2.default(_webIcons2.default).getIcon('dash'),
                iconWidth: 12,
                mr: 4
              },
              'Refresh Page'
            ),
            buttonText !== null && _react2.default.createElement(
              _Button2.default,
              {
                color: _Colors.COLORS.PRIMARY_BLUE,
                onClick: callbackAction,
                icon: new _IconsStore2.default(_webIcons2.default).getIcon(buttonIcon),
                iconWidth: 12,
                primary: true
              },
              buttonText
            )
          )
        ),
        _react2.default.createElement('img', { src: imageUrl, width: '300', alt: 'robot' })
      ),
      _react2.default.createElement(
        _gridStyled.Flex,
        { alignItems: 'center', justifyContent: 'center', width: 1, height: 0.3 },
        _react2.default.createElement('img', { src: process.env.REACT_APP_CDN_URL + '/assets/logo-blue.png', width: '150', alt: 'logo' })
      )
    )
  );
};

Error.propTypes = process.env.NODE_ENV !== "production" ? {
  title: _propTypes2.default.string,
  smallTitle: _propTypes2.default.string,
  desc: _propTypes2.default.string,
  buttonText: _propTypes2.default.string,
  buttonIcon: _propTypes2.default.string,
  callbackAction: _propTypes2.default.func.isRequired,
  imageUrl: _propTypes2.default.string.isRequired
} : {};

Error.defaultProps = {
  title: 'Oops!',
  smallTitle: 'Looks like something went wrong!',
  desc: 'Our engineering team is working on it. If you have any issues please call 16676'
};

exports.default = Error;
module.exports = exports['default'];
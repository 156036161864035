export const PAYMENT = {
  CANCEL_RESERVATION: {
    FETCH: 'CANCEL_RESERVATION',
    SUCCESS: 'CANCEL_RESERVATION_SUCCESS',
    FAIL: 'CANCEL_RESERVATION_FAIL',
  },
  UPDATE_CHARGE_ACCOUNT_CARD: {
    SUBMIT: 'UPDATE_CHARGE_ACCOUNT_CARD',
    SUCCESS: 'UPDATE_CHARGE_ACCOUNT_CARD_SUCCESS',
    FAIL: 'UPDATE_CHARGE_ACCOUNT_CARD_FAIL',
  },
  GET_VALID_TRANSACTION: {
    FETCH: 'GET_VALID_TRANSACTION',
    SUCCESS: 'GET_VALID_TRANSACTION_SUCCESS',
    FAIL: 'GET_VALID_TRANSACTION_FAIL',
  },
  ADD_ACCOUNT_CARD_INFO: {
    SUBMIT: 'ADD_ACCOUNT_CARD_INFO',
    SUCCESS: 'ADD_ACCOUNT_CARD_INFO_SUCCESS',
    FAIL: 'ADD_ACCOUNT_CARD_INFO_FAIL',
  },
};

export const cancelReservation = payload => ({
  type: PAYMENT.CANCEL_RESERVATION.FETCH,
  payload,
});

export const cancelReservationSuccess = payload => ({
  type: PAYMENT.CANCEL_RESERVATION.SUCCESS,
  payload,
});

export const cancelReservationFail = payload => ({
  type: PAYMENT.CANCEL_RESERVATION.FAIL,
  payload,
});

export const updateAndChargeAccountCard = payload => ({
  type: PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.SUBMIT,
  payload,
});

export const updateAndChargeAccountCardSuccess = payload => ({
  type: PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.SUCCESS,
  payload,
});

export const updateAndChargeAccountCardFail = payload => ({
  type: PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.FAIL,
  payload,
});

export const getValidTransactionByTransactionKey = payload => ({
  type: PAYMENT.GET_VALID_TRANSACTION.FETCH,
  payload,
});

export const getValidTransactionByTransactionKeySuccess = payload => ({
  type: PAYMENT.GET_VALID_TRANSACTION.SUCCESS,
  payload,
});

export const getValidTransactionByTransactionKeyFail = payload => ({
  type: PAYMENT.GET_VALID_TRANSACTION.FAIL,
  payload,
});

export const addAccountCardAndGetMerchantPageInfo = payload => ({
  type: PAYMENT.ADD_ACCOUNT_CARD_INFO.SUBMIT,
  payload,
});

export const addAccountCardAndGetMerchantPageInfoSuccess = payload => ({
  type: PAYMENT.ADD_ACCOUNT_CARD_INFO.SUCCESS,
  payload,
});

export const addAccountCardAndGetMerchantPageInfoFail = payload => ({
  type: PAYMENT.ADD_ACCOUNT_CARD_INFO.FAIL,
  payload,
});

'use strict';

exports.__esModule = true;
exports.convertIsoTOMMDDYYYY = exports.transformDate = exports.transformYYYYMMDDtoMMDDYYYY = exports.createDayStyles = exports.WEEK_START_DAY = exports.getIconColor = exports.transformMMDDYYYYtoYYYYMMDD = exports.compareDate = exports.toIndiaDigits = exports.setDateFormat = exports.checkMMDDYYYY = exports.checkYYYYMMDD = exports.findDaysInMonth = exports.getMonthArabicNames = exports.getMonthNames = exports.ARROW_WIDTH = exports.CELL_NUMS = undefined;

var _Colors = require('../shared/Colors');

var _Colors2 = _interopRequireDefault(_Colors);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CELL_NUMS = exports.CELL_NUMS = 42;
var ARROW_WIDTH = exports.ARROW_WIDTH = 9;

var getMonthNames = exports.getMonthNames = function getMonthNames() {
  return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
};

var getMonthArabicNames = exports.getMonthArabicNames = function getMonthArabicNames() {
  return ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
};

var findDaysInMonth = exports.findDaysInMonth = function findDaysInMonth(month, year) {
  if (month === 'January' || month === 'March' || month === 'May' || month === 'July' || month === 'August' || month === 'October' || month === 'December') {
    return 31;
  }
  if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) {
    return 31;
  } else if (month === 'April' || month === 'June' || month === 'September' || month === 'November') {
    return 30;
  } else if (month === 4 || month === 6 || month === 9 || month === 11) {
    return 30;
  } else if ((year - 2016) % 4 !== 0) {
    return 28;
  }
  return 29;
};

var checkYYYYMMDD = exports.checkYYYYMMDD = function checkYYYYMMDD(val) {
  var arr = val.split('-');
  if (arr.length === 3) {
    if (!Number.isNaN(+arr[0])) {
      if (+arr[1] >= 1 && +arr[1] <= 12) {
        var daysInMonth = findDaysInMonth(+arr[1], +arr[0]);
        if (+arr[2] >= 1 && +arr[2] <= daysInMonth) return true;
      }
    }
  }
  return false;
};

var checkMMDDYYYY = exports.checkMMDDYYYY = function checkMMDDYYYY(val) {
  var arr = val.split('/');
  if (arr.length === 3) {
    if (!Number.isNaN(+arr[2])) {
      if (+arr[0] >= 1 && +arr[0] <= 12) {
        var daysInMonth = findDaysInMonth(+arr[0], +arr[2]);
        if (+arr[1] >= 1 && +arr[1] <= daysInMonth) return true;
      }
    }
  }
  return false;
};

var setDateFormat = exports.setDateFormat = function setDateFormat(dateArr) {
  var dateArrLen = dateArr.length;
  var dateFormat = 'mm/dd/yyyy';
  var sumYYYYMMDD = 0;
  for (var i = 0; i < dateArrLen; i += 1) {
    if (dateArr[i] === '' || checkYYYYMMDD(dateArr[i])) sumYYYYMMDD += 1;
  }
  if (sumYYYYMMDD === dateArrLen) {
    dateFormat = 'yyyy-mm-dd';
  }
  return dateFormat;
};

var toIndiaDigits = exports.toIndiaDigits = function toIndiaDigits(number) {
  var id = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  return number.replace(/[0-9]/g, function (w) {
    return id[+w];
  });
};

var compareDate = exports.compareDate = function compareDate(startDate, endDate) {
  var startDateObj = new Date(startDate);
  var endDateObj = new Date(endDate);
  var startYear = startDateObj.getFullYear();
  var startMonth = startDateObj.getMonth();
  var startDateNum = startDateObj.getDate();
  var startTime = startYear * 365 + startMonth * 30 + startDateNum;
  var endYear = endDateObj.getFullYear();
  var endMonth = endDateObj.getMonth();
  var endDateNum = endDateObj.getDate();
  var endTime = endYear * 365 + endMonth * 30 + endDateNum;
  if (startTime > endTime) return false;
  return true;
};

var transformMMDDYYYYtoYYYYMMDD = exports.transformMMDDYYYYtoYYYYMMDD = function transformMMDDYYYYtoYYYYMMDD(mmddyyyy) {
  var arr = mmddyyyy.split('/');
  return arr[2] + '-' + arr[0] + '-' + arr[1]; // eslint-disable-line
};

var getIconColor = exports.getIconColor = function getIconColor(isDisabled) {
  if (isDisabled) {
    return _Colors2.default.mediumGrey;
  }
  return '#484848';
};

var WEEK_START_DAY = exports.WEEK_START_DAY = {
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  MONDAY: 'Monday'
};

var createDayStyles = exports.createDayStyles = function createDayStyles(obj) {
  var val = obj.val,
      now = obj.now,
      type = obj.type,
      date = obj.date,
      startDate = obj.startDate,
      endDate = obj.endDate,
      isArabic = obj.isArabic;

  var arr = val.split('-');
  var startDateObj = startDate !== '' ? new Date(startDate) : '';
  var endDateObj = startDate !== '' ? new Date(endDate) : '';
  var classDiv = '';
  if (type === 'single') {
    if (val === date) {
      classDiv += ' calendar-one-date-v2 ';
    }
  } else if (startDate !== '') {
    var valDate = new Date(val);
    if (val === startDate && val === endDate) {
      classDiv += ' calendar-one-date-v2 ';
    } else if (val === startDate) {
      classDiv += isArabic ? ' calendar-start-date-arabic-v2 ' : ' calendar-start-date-v2 ';
    } else if (endDate !== '') {
      if (valDate > startDateObj && valDate < endDateObj) {
        classDiv += ' calendar-diapason-date-v2 ';
      } else if (val === endDate) {
        classDiv += isArabic ? ' calendar-end-date-arabic-v2 ' : ' calendar-end-date-v2 ';
      }
    }
  }
  var todayClass = +arr[0] === +now.getFullYear() && +arr[1] === now.getMonth() + 1 && +arr[2] === +now.getDate() ? 'calendar-today-v2' : '';
  classDiv += todayClass;
  // if(isArabic&&todayClass==='') classDiv+='calendar-today-v2'
  return classDiv;
};

var transformYYYYMMDDtoMMDDYYYY = exports.transformYYYYMMDDtoMMDDYYYY = function transformYYYYMMDDtoMMDDYYYY(yyyymmdd, isArabic) {
  var arr = yyyymmdd.split('-');
  if (!isArabic || isArabic === undefined) return arr[1] + '/' + arr[2] + '/' + arr[0]; // eslint-disable-line
  return toIndiaDigits(arr[2]) + ' - ' + toIndiaDigits(arr[1]) + ' - ' + toIndiaDigits(arr[0]);
};

var transformDate = exports.transformDate = function transformDate(date, dateFormat) {
  switch (dateFormat) {
    case 'mm/dd/yyyy':
      if (checkMMDDYYYY(date)) return date;
      if (checkYYYYMMDD(date)) return transformYYYYMMDDtoMMDDYYYY(date);
      break;
    case 'yyyy-mm-dd':
      if (checkYYYYMMDD(date)) return date;
      if (checkMMDDYYYY(date)) return transformMMDDYYYYtoYYYYMMDD(date);
      break;
    default:
      return false;
  }
};

var convertIsoTOMMDDYYYY = exports.convertIsoTOMMDDYYYY = function convertIsoTOMMDDYYYY(date) {
  if (date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }
  return '';
};
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Typography = require('../base/Typography');

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./Checkbox.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Checkbox = function (_Component) {
  _inherits(Checkbox, _Component);

  function Checkbox(props) {
    _classCallCheck(this, Checkbox);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.onClick = function () {
      var isChecked = !_this.state.isChecked;

      _this.setState({
        isChecked: isChecked
      });

      _this.props.onChange(isChecked);
    };

    _this.state = {
      isChecked: props.isChecked
    };
    return _this;
  }

  /**
   * Updates state with received isChecked prop
   * @param {object} nextProps
   */


  Checkbox.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    this.setState({ isChecked: nextProps.isChecked });
  };

  /**
   * Handles checkbox click
   */


  Checkbox.prototype.render = function render() {
    var _props = this.props,
        textType = _props.textType,
        textClassName = _props.textClassName,
        children = _props.children,
        label = _props.label,
        htmlFor = _props.htmlFor,
        isDisabled = _props.isDisabled;


    return _react2.default.createElement(
      'span',
      { className: 'checkbox-container' },
      _react2.default.createElement('div', {
        className: (0, _classnames2.default)('checkbox', {
          'checkbox-checked': this.state.isChecked && !isDisabled,
          'checkbox-disabled': isDisabled
        }),
        onClick: isDisabled ? '' : this.onClick,
        onKeyPress: isDisabled ? '' : this.onClick
      }),
      children ? _react2.default.createElement(
        _gridStyled.Flex,
        { pl: 3 },
        children
      ) : _react2.default.createElement(
        _Text2.default,
        {
          type: textType,
          className: (0, _classnames2.default)('checkbox-label', textClassName),
          onClick: isDisabled ? '' : this.onClick,
          onKeyPress: isDisabled ? '' : this.onClick,
          htmlFor: htmlFor,
          cursor: isDisabled ? '' : 'pointer'
        },
        label
      )
    );
  };

  return Checkbox;
}(_react.Component);

Checkbox.propTypes = process.env.NODE_ENV !== "production" ? {
  label: _propTypes2.default.string,
  textType: _propTypes2.default.string,
  textClassName: _propTypes2.default.string,
  isChecked: _propTypes2.default.bool,
  isDisabled: _propTypes2.default.bool,
  onChange: _propTypes2.default.func,
  htmlFor: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  children: _propTypes2.default.node
} : {};

Checkbox.defaultProps = {
  label: '',
  textType: _Typography.FONT_TYPES.BODY,
  textClassName: undefined,
  isChecked: false,
  isDisabled: false,
  onChange: function onChange() {},
  htmlFor: '',
  children: undefined
};

exports.default = (0, _WithDisplayName2.default)(Checkbox, 'Checkbox');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _webUtils = require('@vezeeta/web-utils');

var _Colors = require('./Colors');

var _Media = require('./Media');

var _Media2 = _interopRequireDefault(_Media);

var _Space = require('./Space');

var _Space2 = _interopRequireDefault(_Space);

var _Typography = require('./Typography');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var letterSpacings = {
  normal: 'normal',
  caps: '0.25em'
};

var culture = _webUtils.Cookie.get(_webUtils.Cookie.CULTURE);
var language = String(culture).split('-');
var rtlLanguages = ['ar'];
var isRtl = false;
if (language && language[0]) {
  isRtl = rtlLanguages.includes(language[0]);
}

// border-radius
// const radii = [0, 2, 4, 8];

// const borders = [0, '1px solid', '2px solid'];

// const shadows = [`0 1px 2px 0 ${colors.text}`, `0 1px 4px 0 ${colors.text}`];

var theme = {
  media: _Media2.default,
  colors: _Colors.COLORS_VALUES,
  fontSizes: _Typography.FONT_SIZES,
  lineHeights: _Typography.LINE_HEIGHTS,
  fontWeights: _Typography.FONT_WEIGHTS,
  letterSpacings: letterSpacings,
  space: _Space2.default,
  isRtl: isRtl
  // radii,
  // borders,
  // shadows,
};

exports.default = theme;
module.exports = exports['default'];
"use strict";

exports.__esModule = true;
var gridBase = 4;
var space = [];

for (var counter = 0; counter < 60; counter += 1) {
  space.push(counter * gridBase);
}

exports.default = space;
module.exports = exports["default"];
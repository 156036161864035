'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  background-color: ', ';\n  height: 1px;\n  ', ';\n  ', ';\n'], ['\n  background-color: ', ';\n  height: 1px;\n  ', ';\n  ', ';\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-weight: ', ';\n  display: inline-table;\n  margin: 0 ', 'px;\n  white-space: nowrap;\n'], ['\n  color: ', ';\n  font-weight: ', ';\n  display: inline-table;\n  margin: 0 ', 'px;\n  white-space: nowrap;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledSystem = require('styled-system');

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Colors = require('../base/Colors');

var _Typography = require('../base/Typography');

var _Space = require('../base/Space');

var _Space2 = _interopRequireDefault(_Space);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var StyledLine = _styledComponents2.default.div(_templateObject, _Colors.COLORS_VALUES[_Colors.COLORS.SEPARATOR], _styledSystem.width, _styledSystem.space);

var SeparatorText = (0, _styledComponents2.default)(_Text2.default)(_templateObject2, _Colors.COLORS_VALUES[_Colors.COLORS.SEPARATOR], _Typography.FONT_WEIGHTS.LIGHT, _Space2.default[2]);

var TextSeparator = function TextSeparator(props) {
  return _react2.default.createElement(
    _gridStyled.Flex,
    _extends({ alignItems: 'center', justifyContent: 'center' }, props),
    _react2.default.createElement(StyledLine, { width: 30 }),
    _react2.default.createElement(
      SeparatorText,
      { tag: 'span', type: _Typography.FONT_TYPES.CAPTION },
      props.text
    ),
    _react2.default.createElement(StyledLine, { width: 1 })
  );
};

TextSeparator.propTypes = process.env.NODE_ENV !== "production" ? {
  text: _propTypes2.default.string.isRequired
} : {};

exports.default = TextSeparator;
module.exports = exports['default'];
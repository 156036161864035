'use strict';

exports.__esModule = true;

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: ', 'px;\n  min-width: ', 'px;\n  height: ', 'px;\n  min-height: ', 'px;\n  border-radius: 4px;\n  overflow: hidden;\n'], ['\n  width: ', 'px;\n  min-width: ', 'px;\n  height: ', 'px;\n  min-height: ', 'px;\n  border-radius: 4px;\n  overflow: hidden;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  ', ';\n  object-fit: cover;\n'], ['\n  ', ';\n  object-fit: cover;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styledSystem = require('styled-system');

var _gridStyled = require('../../grid-styled');

var _Button = require('../../buttons/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Colors = require('../../base/Colors');

var _Typography = require('../../base/Typography');

var _Icon = require('../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _Text = require('../../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _IconsStore = require('../../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _WithDisplayName = require('../../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

var previewRadius = 75;
var ImageContainer = _gridStyled.Flex.extend(_templateObject, previewRadius, previewRadius, previewRadius, previewRadius);

var Image = _styledComponents2.default.img(_templateObject2, _styledSystem.bgColor);

var AttachInput = function (_Component) {
  _inherits(AttachInput, _Component);

  function AttachInput() {
    var _temp, _this, _ret;

    _classCallCheck(this, AttachInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.iconsStore = new _IconsStore2.default(_webIcons2.default), _this.loadPreview = function () {
      var _this$props = _this.props,
          loadPreview = _this$props.loadPreview,
          updatePhoto = _this$props.updatePhoto,
          photoPreview = _this$props.photoPreview;


      loadPreview(_this.input, function (photo) {
        var reader = new FileReader();
        reader.readAsDataURL(photo);
        reader.onloadend = function () {
          updatePhoto({
            preview: reader.result ? reader.result : photoPreview,
            crop: reader.result,
            isPhoto: true,
            showCropper: false
          });
        };
      });
    }, _this.openFileDialog = function () {
      _this.input.click();
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  /**
   * Returns the photo object
   */


  /**
   * Opens file dialog
   */


  AttachInput.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        desc = _props.desc,
        photoPreview = _props.photoPreview,
        isPhoto = _props.isPhoto,
        isRequired = _props.isRequired,
        disabled = _props.disabled,
        isDanger = _props.isDanger,
        errorMessage = _props.errorMessage,
        language = _props.language;


    return _react2.default.createElement(
      _gridStyled.Flex,
      { alignItems: 'center' },
      _react2.default.createElement('input', {
        type: 'file',
        ref: function ref(input) {
          _this2.input = input;
        },
        accept: '.png, .jpg, .jpeg',
        onChange: this.loadPreview,
        hidden: true,
        autoComplete: 'off'
      }),
      _react2.default.createElement(
        ImageContainer,
        { alignItems: 'center', justifyContent: 'center', bg: _Colors.COLORS.PAGE_BACKGROUND },
        photoPreview && isPhoto ? _react2.default.createElement(Image, {
          src: photoPreview,
          ref: function ref(img) {
            _this2.img = img;
          },
          alt: 'attachment',
          width: previewRadius,
          height: previewRadius
        }) : _react2.default.createElement(_Icon2.default, {
          icon: this.iconsStore.getIcon('camera'),
          width: 22,
          color: disabled ? _Colors.COLORS.DISABLED : _Colors.COLORS.PRIMARY_BLUE
        })
      ),
      _react2.default.createElement(
        _gridStyled.Flex,
        { flexDirection: 'column', m: language === 'en' ? '0 0 0 12px' : '0 12px 0 0' },
        _react2.default.createElement(
          _gridStyled.Flex,
          { alignItems: 'center' },
          _react2.default.createElement(
            _Text2.default,
            { fontWeight: _Typography.FONT_WEIGHTS.SEMI_BOLD },
            '' + desc + (isRequired ? '*' : '')
          ),
          isDanger && _react2.default.createElement(
            _Text2.default,
            { ml: 2, color: _Colors.COLORS.PRIMARY_RED, type: _Typography.FONT_TYPES.CAPTION },
            errorMessage
          )
        ),
        _react2.default.createElement(
          _Button2.default,
          { primary: false, mt: 2, onClick: this.openFileDialog, color: _Colors.COLORS.PRIMARY_BLUE },
          language === 'en' ? 'Upload' : 'رفع'
        )
      )
    );
  };

  return AttachInput;
}(_react.Component);

AttachInput.propTypes = process.env.NODE_ENV !== "production" ? {
  desc: _propTypes2.default.string.isRequired,
  updatePhoto: _propTypes2.default.func.isRequired,
  loadPreview: _propTypes2.default.func.isRequired,
  isDanger: _propTypes2.default.bool.isRequired,
  isPhoto: _propTypes2.default.bool,
  errorMessage: _propTypes2.default.string,
  isRequired: _propTypes2.default.bool.isRequired,
  photoPreview: _propTypes2.default.string,
  disabled: _propTypes2.default.bool,
  language: _propTypes2.default.string.isRequired
} : {};

AttachInput.defaultProps = {
  errorMessage: '',
  isPhoto: false,
  photoPreview: undefined,
  disabled: false
};

exports.default = (0, _WithDisplayName2.default)(AttachInput, 'PhotoInput');
module.exports = exports['default'];
'use strict';

exports.__esModule = true;
var counter = 0;
var generateKey = function generateKey() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  counter += 1;
  return prefix + '-' + counter;
};

exports.default = generateKey;
module.exports = exports['default'];
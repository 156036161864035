'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Table = require('./Table.styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableRow = function TableRow(_ref) {
  var extendTableRow = _ref.extendTableRow,
      onClickRecord = _ref.onClickRecord,
      extendTableGrid = _ref.extendTableGrid,
      record = _ref.record,
      items = _ref.items,
      isExpandableRows = _ref.isExpandableRows,
      rowIndex = _ref.rowIndex,
      source = _ref.source,
      mixpanel = _ref.mixpanel;

  var _useState = (0, _react.useState)(false),
      isExpanded = _useState[0],
      toggleExpandingState = _useState[1];

  var onRowClick = function onRowClick() {
    if (onClickRecord) onClickRecord(record);
    if (isExpandableRows) {
      var getdownCarretItem = document.getElementById('rowitem-icon-' + rowIndex);
      if (!isExpanded) {
        if (mixpanel) mixpanel.track(source + ' Record Expansion');
        getdownCarretItem.style.transform = 'rotateZ(180deg)';
      } else {
        getdownCarretItem.style.transform = 'rotateZ(0deg)';
      }
      toggleExpandingState(!isExpanded);
    }
  };

  var getExpandedRowsHeight = function getExpandedRowsHeight(id) {
    var expandedRows = document.getElementById(id);
    if (expandedRows) return expandedRows.offsetHeight;
  };

  var expanded = record.expanded;


  return _react2.default.createElement(
    _Table.RowContainer,
    { key: record.key + '-' + rowIndex },
    _react2.default.createElement(
      _Table.ShowDesktop,
      null,
      _react2.default.createElement(
        _Table.Row,
        { extendTableRow: extendTableRow },
        _react2.default.createElement(
          _Table.RowGrid,
          {
            isClickable: onClickRecord || isExpandableRows && expanded && expanded.length,
            extendTableGrid: extendTableGrid,
            onClick: function onClick() {
              return onRowClick();
            }
          },
          items
        )
      ),
      expanded && expanded.length ? _react2.default.createElement(
        _Table.ExpandedRow,
        {
          totalHeight: getExpandedRowsHeight('expandedRows-' + rowIndex),
          isExpanded: isExpanded
        },
        _react2.default.createElement(
          'div',
          { style: { width: '100%' }, id: 'expandedRows-' + rowIndex },
          expanded
        )
      ) : ''
    ),
    _react2.default.createElement(
      _Table.ShowDevice,
      null,
      _react2.default.createElement(
        _Table.Row,
        { extendTableRow: extendTableRow },
        _react2.default.createElement(
          _Table.RowGrid,
          { isClickable: false, extendTableGrid: extendTableGrid },
          items
        )
      )
    )
  );
}; /* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */


TableRow.propTypes = process.env.NODE_ENV !== "production" ? {
  extendTableRow: _propTypes2.default.array,
  onClickRecord: _propTypes2.default.func,
  extendTableGrid: _propTypes2.default.array,
  record: _propTypes2.default.object,
  items: _propTypes2.default.array,
  isExpandableRows: _propTypes2.default.bool,
  rowIndex: _propTypes2.default.number,
  source: _propTypes2.default.string,
  mixpanel: _propTypes2.default.object
} : {};

exports.default = TableRow;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _StaticField = require('./StaticField');

var _StaticField2 = _interopRequireDefault(_StaticField);

var _DynamicField = require('./DynamicField');

var _DynamicField2 = _interopRequireDefault(_DynamicField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var FieldSelector = function FieldSelector(_ref) {
  var isDynamic = _ref.isDynamic,
      props = _objectWithoutProperties(_ref, ['isDynamic']);

  if (isDynamic) return _react2.default.createElement(_DynamicField2.default, props);
  return _react2.default.createElement(_StaticField2.default, props);
};

exports.default = FieldSelector;
module.exports = exports['default'];
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Container = require('./Container');

var _Container2 = _interopRequireDefault(_Container);

var _Spinner = require('../spinner/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Loading = function Loading(_ref) {
  var height = _ref.height,
      radius = _ref.radius;
  return _react2.default.createElement(
    _Container2.default,
    { alignItems: 'center', justifyContent: 'center', height: height },
    _react2.default.createElement(_Spinner2.default, { radius: radius })
  );
};

Loading.propTypes = process.env.NODE_ENV !== "production" ? {
  height: _propTypes2.default.number.isRequired,
  radius: _propTypes2.default.number
} : {};

Loading.defaultProps = {
  radius: 30
};

exports.default = Loading;
module.exports = exports['default'];
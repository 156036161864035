import { status } from 'app/enum';
import { PAYMENT } from '../actions/Payment';

const initialState = {
  addAccountCardInfo: undefined,
  addAccountCardInfoLoadState: status.SHOULD_CALL_API,
  cancelReservation: undefined,
  cancelReservationLoadState: status.SHOULD_CALL_API,
  getValidTransaction: undefined,
  getValidTransactionLoadState: status.SHOULD_CALL_API,
  updateChargeAccountCard: undefined,
  updateChargeAccountCardLoadState: status.SHOULD_CALL_API,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case PAYMENT.ADD_ACCOUNT_CARD_INFO.SUBMIT:
      return { ...state, addAccountCardInfoLoadState: status.SUBMITING };
    case PAYMENT.ADD_ACCOUNT_CARD_INFO.SUCCESS: {
      return {
        ...state,
        addAccountCardInfoLoadState: status.SUCCESS,
        addAccountCardInfo: payload.payload,
      };
    }
    case PAYMENT.ADD_ACCOUNT_CARD_INFO.FAIL:
      return { ...state, addAccountCardInfo: status.FAIL };

    case PAYMENT.CANCEL_RESERVATION.FETCH:
      return { ...state, cancelReservationLoadState: status.FETCHING };
    case PAYMENT.CANCEL_RESERVATION.SUCCESS: {
      return {
        ...state,
        cancelReservationLoadState: status.SUCCESS,
        cancelReservation: payload.payload,
      };
    }
    case PAYMENT.CANCEL_RESERVATION.FAIL:
      return { ...state, cancelReservationLoadState: status.FAIL };

    case PAYMENT.GET_VALID_TRANSACTION.FETCH:
      return { ...state, getValidTransactionLoadState: status.FETCHING };
    case PAYMENT.GET_VALID_TRANSACTION.SUCCESS: {
      return {
        ...state,
        getValidTransactionLoadState: status.SUCCESS,
        getValidTransaction: payload.payload,
      };
    }
    case PAYMENT.GET_VALID_TRANSACTION.FAIL:
      return { ...state, getValidTransactionLoadState: status.FAIL };

    case PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.SUBMIT:
      return { ...state, updateChargeAccountCardLoadState: status.SUBMITING };
    case PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.SUCCESS: {
      return {
        ...state,
        updateChargeAccountCardLoadState: status.SUCCESS,
        updateChargeAccountCard: payload.payload,
      };
    }
    case PAYMENT.UPDATE_CHARGE_ACCOUNT_CARD.FAIL:
      return { ...state, updateChargeAccountCardLoadState: status.FAIL };
    default:
      return state;
  }
};

'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _FeatureFlagContainer = require('./FeatureFlagContainer');

var _FeatureFlagContainer2 = _interopRequireDefault(_FeatureFlagContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FeatureFlag = function FeatureFlag(_ref) {
  var flagKey = _ref.flagKey,
      children = _ref.children;
  return _react2.default.createElement(
    _FeatureFlagContainer2.default.Consumer,
    null,
    function (flags) {
      return flags[flagKey] && children;
    }
  );
};

FeatureFlag.propTypes = process.env.NODE_ENV !== "production" ? {
  flagKey: _propTypes2.default.string.isRequired,
  children: _propTypes2.default.any // eslint-disable-line
} : {};

exports.default = FeatureFlag;
module.exports = exports['default'];
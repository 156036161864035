'use strict';

exports.__esModule = true;
exports.getErrorMessage = exports.isListsEqual = exports.isItemsEqual = exports.generateListFromItems = exports.itemsAdapter = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _articles = require('articles');

var _articles2 = _interopRequireDefault(_articles);

var _gridStyled = require('../grid-styled');

var _Text = require('../text/Text');

var _Text2 = _interopRequireDefault(_Text);

var _Image = require('../image/Image');

var _Image2 = _interopRequireDefault(_Image);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Adapts old items object into the new one
 * @param {Array} items
 * @return {Array} items
 */
var itemsAdapter = function itemsAdapter(items) {
  if (!items || items.length === 0) return [];
  if (Array.isArray(items)) {
    var newItems = [];

    if (!items[0].data) {
      items.forEach(function (item) {
        return newItems.push(_extends({}, item, {
          itemKey: item.key || item.value
        }));
      });
    } else {
      items.forEach(function (item) {
        var component = item.component;
        var _item$data = item.data,
            placeholder = _item$data.placeholder,
            value = _item$data.value,
            searchable = _item$data.searchable,
            img = _item$data.img,
            disable = _item$data.disable,
            key = _item$data.key,
            groupBy = _item$data.groupBy,
            groupIcon = _item$data.groupIcon;


        newItems.push({
          children: component,
          fieldValue: placeholder,
          value: value,
          searchable: searchable,
          fieldImage: img,
          disabled: disable,
          itemKey: key || value,
          groupBy: groupBy,
          groupIcon: groupIcon
        });
      });
    }
    return newItems;
  }

  return [];
};

/**
 * Converts an array of items to a Map
 * @param {Array} items
 * @returns {Map} items
 */
var generateListFromItems = function generateListFromItems() {
  var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var language = arguments[1];

  var list = new Map();
  var groupedList = new Map();
  var disabledItems = 0;

  items.forEach(function (item, index) {
    var itemKey = item.itemKey,
        disabled = item.disabled,
        searchable = item.searchable,
        groupBy = item.groupBy,
        groupIcon = item.groupIcon;

    // Throw an error is key isn't string or number

    if (typeof itemKey !== 'string' && typeof itemKey !== 'number' && item.key !== undefined) {
      throw new Error(item.fieldValue + ' value\'s type isn\'t string or number, if this isn\'t a mistake pass \'key\' to component object');
    }

    if (groupBy) {
      var groupParent = groupedList.get(groupBy);
      var groupParentSearchable = groupParent ? groupParent.searchable : [];

      if (searchable) {
        searchable.forEach(function (search) {
          return groupParentSearchable.push(search);
        });
      }

      groupedList.set(groupBy, {
        children: _react2.default.createElement(
          _gridStyled.Flex,
          { alignItems: 'center' },
          _react2.default.createElement(_Image2.default, {
            src: groupIcon,
            radius: 14,
            alt: groupBy,
            name: groupBy,
            borderRadius: '0',
            objectFit: 'contain'
          }),
          _react2.default.createElement(
            _Text2.default,
            { ml: language === 'en' ? 2 : 0, mr: language === 'ar' ? 2 : 0 },
            groupBy
          )
        ),
        value: groupBy,
        itemKey: groupBy,
        searchable: groupParentSearchable,
        isGroupHeadline: true
      });
    }

    // Count disabled items
    if (disabled) disabledItems += 1;
    list.set(itemKey, _extends({}, item, { index: index }));
    groupedList.set(itemKey, _extends({}, item, { index: index }));
  });

  return { list: list, groupedList: groupedList, isAllItemsDisabled: disabledItems === items.length };
};

/**
 * Compares two items
 * @param {Object} firstItem
 * @param {Object} secondItem
 */
var isItemsEqual = function isItemsEqual(firstItem, secondItem) {
  if (firstItem.fieldValue !== secondItem.fieldValue || firstItem.fieldImage !== secondItem.fieldImage || firstItem.disable !== secondItem.disable || firstItem.itemKey !== secondItem.itemKey || !_lodash2.default.isEqual(firstItem.searchable, secondItem.searchable) || !_lodash2.default.isEqual(firstItem.value, secondItem.value)) {
    return false;
  }

  return true;
};

/**
 * Compares two lists
 * @param {Array} firstList
 * @param {Array} secondList
 */
var isListsEqual = function isListsEqual(firstList, secondList) {
  // If two lists have two different length, there is no need to compare items
  if (firstList.length !== secondList.length) {
    return false;
  }

  var isEqual = true;
  firstList.forEach(function (item, index) {
    if (isEqual) {
      isEqual = isItemsEqual(firstList[index], secondList[index]);
    }
  });
  return isEqual;
};

var getErrorMessage = function getErrorMessage(props) {
  var multiple = props.multiple,
      placeholder = props.placeholder,
      allowCustomChoice = props.allowCustomChoice,
      language = props.language,
      customErrorMassage = props.customErrorMassage;

  if (multiple) {
    return language === 'en' ? 'Select ' + placeholder.toLowerCase() : '\u0627\u062E\u062A\u0631 ' + placeholder;
  }
  if (customErrorMassage || customErrorMassage !== '') {
    return customErrorMassage;
  }
  var enter = language === 'en' ? 'Enter' : 'ادخل';
  var select = language === 'en' ? 'Select' : 'اختر';
  return (allowCustomChoice ? enter : select) + ' ' + (language === 'en' ? _articles2.default.articlize(placeholder.toLowerCase()) : placeholder);
};

exports.itemsAdapter = itemsAdapter;
exports.generateListFromItems = generateListFromItems;
exports.isItemsEqual = isItemsEqual;
exports.isListsEqual = isListsEqual;
exports.getErrorMessage = getErrorMessage;
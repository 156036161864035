'use strict';

exports.__esModule = true;
exports.renderTags = exports.renderDropDownList = exports.renderSelectedOptions = exports.dropDownListTogglerBySearchOptions = exports.dropDownListToggler = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Checkbox = require('../../../checkbox/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _Icon = require('../../../icon/Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _IconsStore = require('../../IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _DropdownMultiSelect = require('./DropdownMultiSelect.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var dropDownListToggler = exports.dropDownListToggler = function dropDownListToggler(optionsList, componentName, variationB) {
  if (optionsList && optionsList.length) {
    var listBtn = document.getElementById('dropdown-list-btn-' + componentName);
    var list = document.getElementById('dropdown-list-' + componentName);
    if (list.style.display === 'none') {
      list.style.display = 'block';
      if (variationB) listBtn.style.backgroundColor = '#F7F8FA';
    } else {
      list.style.display = 'none';
      if (variationB) listBtn.style.backgroundColor = 'unset';
    }
  }
}; /* eslint-disable react/no-array-index-key */
var dropDownListTogglerBySearchOptions = exports.dropDownListTogglerBySearchOptions = function dropDownListTogglerBySearchOptions(optionsList, listId) {
  if (optionsList && optionsList.length) {
    var list = document.getElementById(listId);
    if (list.style.display === 'none') {
      list.style.display = 'block';
    }
  }
};

var renderSelectedOptions = exports.renderSelectedOptions = function renderSelectedOptions(optionList, isCounter, placeHolder, allLabel) {
  if (optionList.length) {
    if (isCounter) {
      var itemsCount = 0;
      optionList.forEach(function (item) {
        if (item.isChecked) {
          itemsCount += 1;
        }
      });
      if (itemsCount === optionList.length) {
        return '' + allLabel;
      }
      return '' + (itemsCount > 0 ? itemsCount + ' ' : '') + placeHolder;
    }
    var NUMBER_OF_DISPLAYED_VALUES = 2;
    var composedValue = '';
    var checkedItemsCounter = 0;
    var extraItems = 0;
    optionList.forEach(function (item) {
      if (item.isChecked) {
        if (checkedItemsCounter < NUMBER_OF_DISPLAYED_VALUES) {
          if (!composedValue) {
            composedValue = item.fieldValue;
          } else {
            composedValue += ', ' + item.fieldValue;
          }
        } else {
          extraItems += 1;
        }
        checkedItemsCounter += 1;
      }
    });
    return '' + composedValue + (extraItems > 0 ? ' +' + extraItems : '');
  }
};

var renderDropDownList = exports.renderDropDownList = function renderDropDownList(optionsList, callback, extendedStyle, isCategorized, enableAllOption, isAllSelected, allLabel) {
  var mappedOptions = [];
  if (optionsList && optionsList.length) {
    // Categorized List
    if (isCategorized) {
      var categoryLetter = void 0;
      if (enableAllOption) {
        mappedOptions.push(_react2.default.createElement(
          _DropdownMultiSelect.ListItem,
          {
            key: 'all',
            extendDropDownListItem: extendedStyle,
            isMultipleSelection: true,
            style: { boxShadow: 'inset 0px -1px 0px #f1f4f6' }
          },
          _react2.default.createElement(
            _DropdownMultiSelect.ItemContentContainer,
            null,
            _react2.default.createElement(
              _Checkbox2.default,
              { isChecked: isAllSelected, onChange: function onChange() {
                  return callback(-1);
                } },
              _react2.default.createElement(
                _DropdownMultiSelect.OptionItemContent,
                { withOutDesciption: true },
                _react2.default.createElement(
                  _DropdownMultiSelect.ItemLabel,
                  { isChecked: isAllSelected, style: { fontWeight: 'bold' } },
                  allLabel
                )
              )
            )
          )
        ));
      }

      optionsList.forEach(function (option, index) {
        if (categoryLetter !== String(option.fieldValue).charAt(0)) {
          categoryLetter = String(option.fieldValue).charAt(0);
          mappedOptions.push(_react2.default.createElement(
            _DropdownMultiSelect.CategoryLabelContainer,
            null,
            _react2.default.createElement(
              _DropdownMultiSelect.CategoryLabel,
              null,
              categoryLetter
            )
          ));
        }

        mappedOptions.push(_react2.default.createElement(
          _DropdownMultiSelect.ListItem,
          {
            key: option.key + '-' + index,
            extendDropDownListItem: extendedStyle,
            isMultipleSelection: true
          },
          _react2.default.createElement(
            _DropdownMultiSelect.ItemContentContainer,
            null,
            _react2.default.createElement(
              _Checkbox2.default,
              { isChecked: option.isChecked, onChange: function onChange() {
                  return callback(option, index);
                } },
              _react2.default.createElement(
                _DropdownMultiSelect.OptionItemContent,
                { withOutDesciption: !option.description },
                _react2.default.createElement(
                  _DropdownMultiSelect.ItemLabel,
                  { isChecked: option.isChecked },
                  option.fieldValue
                ),
                option.description && _react2.default.createElement(
                  _DropdownMultiSelect.ItemDescription,
                  null,
                  option.description
                )
              )
            )
          )
        ));
      });
    } else {
      // Normal List
      if (enableAllOption) {
        mappedOptions.push(_react2.default.createElement(
          _DropdownMultiSelect.ListItem,
          {
            key: 'all',
            extendDropDownListItem: extendedStyle,
            isMultipleSelection: true,
            style: { boxShadow: 'inset 0px -1px 0px #f1f4f6' }
          },
          _react2.default.createElement(
            _DropdownMultiSelect.ItemContentContainer,
            null,
            _react2.default.createElement(
              _Checkbox2.default,
              { isChecked: isAllSelected, onChange: function onChange() {
                  return callback(-1);
                } },
              _react2.default.createElement(
                _DropdownMultiSelect.OptionItemContent,
                { withOutDesciption: true },
                _react2.default.createElement(
                  _DropdownMultiSelect.ItemLabel,
                  { isChecked: isAllSelected, style: { fontWeight: 'bold' } },
                  allLabel
                )
              )
            )
          )
        ));
      }

      optionsList.forEach(function (option, index) {
        mappedOptions.push(_react2.default.createElement(
          _DropdownMultiSelect.ListItem,
          {
            key: option.key + '-' + index,
            extendDropDownListItem: extendedStyle,
            isMultipleSelection: true
          },
          _react2.default.createElement(
            _DropdownMultiSelect.ItemContentContainer,
            null,
            _react2.default.createElement(
              _Checkbox2.default,
              { isChecked: option.isChecked, onChange: function onChange() {
                  return callback(option, index);
                } },
              _react2.default.createElement(
                _DropdownMultiSelect.OptionItemContent,
                { withOutDesciption: !option.description },
                _react2.default.createElement(
                  _DropdownMultiSelect.ItemLabel,
                  { isChecked: option.isChecked },
                  option.fieldValue
                ),
                option.description && _react2.default.createElement(
                  _DropdownMultiSelect.ItemDescription,
                  null,
                  option.description
                )
              )
            )
          )
        ));
      });
    }
  }

  return mappedOptions;
};

var renderTags = exports.renderTags = function renderTags(optionList, callback, isAllTagsShown, isCategorized) {
  var tagsList = [];
  if (optionList.length) {
    if (isCategorized) {
      optionList.forEach(function (option, index) {
        option.items.forEach(function (item, indexx) {
          if (item.isChecked) {
            if (!isAllTagsShown && tagsList.length < 2) {
              tagsList.push(_react2.default.createElement(
                _DropdownMultiSelect.TagContainer,
                null,
                _react2.default.createElement(
                  _DropdownMultiSelect.TagWrapper,
                  null,
                  _react2.default.createElement(
                    _DropdownMultiSelect.TagLabel,
                    null,
                    item.fieldValue
                  ),
                  _react2.default.createElement(
                    _DropdownMultiSelect.IconContainer,
                    null,
                    _react2.default.createElement(_Icon2.default, {
                      className: 'icon',
                      onClick: function onClick() {
                        return callback(item, index, indexx);
                      },
                      icon: _IconsStore2.default.getIcon('close'),
                      width: 8
                    })
                  )
                )
              ));
            } else if (isAllTagsShown) {
              tagsList.push(_react2.default.createElement(
                _DropdownMultiSelect.TagContainer,
                null,
                _react2.default.createElement(
                  _DropdownMultiSelect.TagWrapper,
                  null,
                  _react2.default.createElement(
                    _DropdownMultiSelect.TagLabel,
                    null,
                    item.fieldValue
                  ),
                  _react2.default.createElement(
                    _DropdownMultiSelect.IconContainer,
                    null,
                    _react2.default.createElement(_Icon2.default, {
                      className: 'icon',
                      onClick: function onClick() {
                        return callback(item, index, indexx);
                      },
                      icon: _IconsStore2.default.getIcon('close'),
                      width: 8
                    })
                  )
                )
              ));
            }
          }
        });
      });
    } else {
      optionList.forEach(function (item, index) {
        if (item.isChecked) {
          if (!isAllTagsShown && tagsList.length < 2) {
            tagsList.push(_react2.default.createElement(
              _DropdownMultiSelect.TagContainer,
              null,
              _react2.default.createElement(
                _DropdownMultiSelect.TagWrapper,
                null,
                _react2.default.createElement(
                  _DropdownMultiSelect.TagLabel,
                  null,
                  item.fieldValue
                ),
                _react2.default.createElement(
                  _DropdownMultiSelect.IconContainer,
                  null,
                  _react2.default.createElement(_Icon2.default, {
                    className: 'icon',
                    onClick: function onClick() {
                      return callback(item, index);
                    },
                    icon: _IconsStore2.default.getIcon('close'),
                    width: 8
                  })
                )
              )
            ));
          } else if (isAllTagsShown) {
            tagsList.push(_react2.default.createElement(
              _DropdownMultiSelect.TagContainer,
              null,
              _react2.default.createElement(
                _DropdownMultiSelect.TagWrapper,
                null,
                _react2.default.createElement(
                  _DropdownMultiSelect.TagLabel,
                  null,
                  item.fieldValue
                ),
                _react2.default.createElement(
                  _DropdownMultiSelect.IconContainer,
                  null,
                  _react2.default.createElement(_Icon2.default, {
                    className: 'icon',
                    onClick: function onClick() {
                      return callback(item, index);
                    },
                    icon: _IconsStore2.default.getIcon('close'),
                    width: 8
                  })
                )
              )
            ));
          }
        }
      });
    }
  }
  return tagsList;
};
'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _webIcons = require('@vezeeta/web-icons');

var _webIcons2 = _interopRequireDefault(_webIcons);

var _IconsStore = require('../icon/IconsStore');

var _IconsStore2 = _interopRequireDefault(_IconsStore);

var _EmptyState = require('../emptyState/EmptyState');

var _EmptyState2 = _interopRequireDefault(_EmptyState);

var _Spinner = require('../spinner/Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _WithDisplayName = require('../WithDisplayName');

var _WithDisplayName2 = _interopRequireDefault(_WithDisplayName);

require('./Loading.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var refreshPage = function refreshPage() {
  window.location.reload();
};

var Loading = function Loading(props) {
  if (props.isLoading) {
    // While our other component is loading...

    if (props.timedOut) {
      // In case we've timed out loading our other component.
      return _react2.default.createElement(_EmptyState2.default, {
        text: 'Connection timed out..',
        image: process.env.REACT_APP_CDN_URL + '/assets/secure_server.svg',
        buttonText: 'Refresh Page',
        buttonIcon: new _IconsStore2.default(_webIcons2.default).getIcon('refresh'),
        buttonIconWidth: 12,
        buttonOnClick: refreshPage
      });
    } else if (props.pastDelay) {
      // Display a loading screen after a set delay.
      return _react2.default.createElement(
        'div',
        { className: 'global-loading' },
        _react2.default.createElement(_Spinner2.default, null)
      );
    }
    // Don't flash "Loading..." when we don't need to.
    return null;
  } else if (props.error) {
    // If we aren't loading, maybe
    return _react2.default.createElement(_EmptyState2.default, {
      text: 'Page failed to load, there was a network error!',
      image: process.env.REACT_APP_CDN_URL + '/assets/secure_server.svg',
      buttonText: 'Refresh Page',
      buttonIcon: new _IconsStore2.default(_webIcons2.default).getIcon('refresh'),
      buttonIconWidth: 12,
      buttonOnClick: refreshPage
    });
  }
  // This case shouldn't happen... but we'll return null anyways.
  return null;
};

Loading.propTypes = process.env.NODE_ENV !== "production" ? {
  isLoading: _propTypes2.default.bool,
  timedOut: _propTypes2.default.bool,
  error: _propTypes2.default.bool,
  pastDelay: _propTypes2.default.bool
} : {};

Loading.defaultProps = {
  isLoading: false,
  timedOut: false,
  error: false,
  pastDelay: false
};

exports.default = (0, _WithDisplayName2.default)(Loading, 'Loading');
module.exports = exports['default'];
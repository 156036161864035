'use strict';

exports.__esModule = true;
exports.renderDropDownList = exports.renderSelectedOptions = exports.dropDownListTogglerBySearchOptions = exports.dropDownListToggler = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DropDown = require('./DropDown.style');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var dropDownListToggler = exports.dropDownListToggler = function dropDownListToggler(optionsList, componentName, variationB, callback) {
  if (optionsList && optionsList.length) {
    var list = document.getElementById('dropdown-list-' + componentName);
    var listBtn = document.getElementById('dropdown-list-btn-' + componentName);
    if (list.style.display === 'none') {
      list.style.display = 'block';
      if (variationB) listBtn.style.backgroundColor = '#F7F8FA';
    } else {
      list.style.display = 'none';
      if (variationB) listBtn.style.backgroundColor = 'unset';
    }
  }
  if (callback) callback();
};

var dropDownListTogglerBySearchOptions = exports.dropDownListTogglerBySearchOptions = function dropDownListTogglerBySearchOptions(optionsList, listId) {
  if (optionsList && optionsList.length) {
    var list = document.getElementById(listId);
    if (list.style.display === 'none') {
      list.style.display = 'block';
    }
  }
};

var renderSelectedOptions = exports.renderSelectedOptions = function renderSelectedOptions(selectedOptionsList, isMultipleSelection) {
  if (selectedOptionsList.length) {
    if (isMultipleSelection) {
      var firstTwoItems = selectedOptionsList.slice(0, 3);
      var composedValue = '';
      firstTwoItems.forEach(function (item) {
        if (!composedValue) {
          composedValue = item.fieldValue;
        } else {
          composedValue += ', ' + item.fieldValue;
        }
      });
      composedValue += selectedOptionsList.length > 3 ? ' +' + (selectedOptionsList.length - 3) : '';
      return composedValue;
    }
    return selectedOptionsList[0].fieldValue;
  }
};

var renderDropDownList = exports.renderDropDownList = function renderDropDownList(optionsList, isMultipleSelection, callback, extendedStyle, language, isCategorized) {
  var mappedOptions = [];

  if (optionsList && optionsList.length) {
    // Categorized List
    if (isCategorized) {
      var categoryLetter = void 0;

      optionsList.forEach(function (option, index) {
        if (String(categoryLetter).toUpperCase() !== String(option.fieldValue).charAt(0).toUpperCase()) {
          categoryLetter = String(option.fieldValue).charAt(0).toUpperCase();
          mappedOptions.push(_react2.default.createElement(
            _DropDown.CategoryLabelContainer,
            null,
            _react2.default.createElement(
              _DropDown.CategoryLabel,
              null,
              categoryLetter
            )
          ));
        }

        mappedOptions.push(_react2.default.createElement(
          _DropDown.ListItem,
          {
            isEnabled: !(option.isEnabled === false),
            key: option.key + '-' + option.fieldValue,
            id: '' + option.key,
            extendListItem: option.extendListItem,
            extendDropDownListItem: extendedStyle.extendDropDownListItem,
            onClick: function onClick() {
              if (!(option.isEnabled === false)) callback(option, index);
            },
            isMultipleSelection: isMultipleSelection
          },
          _react2.default.createElement(
            _DropDown.ItemContentContainer,
            null,
            isMultipleSelection && _react2.default.createElement(_DropDown.CheckBox, { type: 'checkbox', checked: option.isChecked }),
            _react2.default.createElement(
              'div',
              { style: { margin: '0px 10px', display: 'flex', flexDirection: 'column' } },
              _react2.default.createElement(
                _DropDown.ItemLabel,
                { isEnabled: !(option.isEnabled === false) },
                option.fieldValue
              ),
              option.description && _react2.default.createElement(
                _DropDown.ItemDescription,
                { isEnabled: !(option.isEnabled === false), language: language },
                option.description
              )
            )
          )
        ));
      });
    } else {
      mappedOptions = optionsList.map(function (option, index) {
        return _react2.default.createElement(
          _DropDown.ListItem,
          {
            isEnabled: !(option.isEnabled === false),
            key: option.key + '-' + option.fieldValue,
            id: '' + option.key,
            extendListItem: option.extendListItem,
            extendDropDownListItem: extendedStyle.extendDropDownListItem,
            onClick: function onClick() {
              if (!(option.isEnabled === false)) callback(option, index);
            },
            isMultipleSelection: isMultipleSelection
          },
          _react2.default.createElement(
            _DropDown.ItemContentContainer,
            null,
            isMultipleSelection && _react2.default.createElement(_DropDown.CheckBox, { type: 'checkbox', checked: option.isChecked }),
            _react2.default.createElement(
              'div',
              { style: { margin: '0px 10px', display: 'flex', flexDirection: 'column' } },
              _react2.default.createElement(
                _DropDown.ItemLabel,
                { isEnabled: !(option.isEnabled === false) },
                option.fieldValue
              ),
              option.description && _react2.default.createElement(
                _DropDown.ItemDescription,
                { isEnabled: !(option.isEnabled === false), language: language },
                option.description
              )
            )
          )
        );
      });
    }
  }
  return mappedOptions;
};